import React, {useEffect, useRef, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import Button from 'react-bootstrap/Button';
import {deleteAPI, getAPI, postAPI, putAPI} from "../APIUtil";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import {withRouter} from "react-router";
import AppNav from "./AppNav";
import WntyRegNav from "./WntyRegNav";
import 'react-toastify/dist/ReactToastify.css';
import Toast, {success} from "../Toast";
import {Card, Col, Container, Form, InputGroup, Row, Modal} from "react-bootstrap";
import {nvl, addField, parseDate, toNull, formatNumber, parseNumber, dateformatDB, setFocusCalendar, dateformatYYYYMMDDHHMMSS, onSearchPref, useDebounce} from "../CMUtil";
import MdDatatable, {InitialLoading, sortCaret} from "../MdDataTable";
import AsyncSelect from "react-select/async";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons";
import DatePicker, {registerLocale} from "react-datepicker";
import ja from "date-fns/locale/ja";

registerLocale('ja', ja)

export const WarrantyRegistration = ({match: {params: {book_id}}}) => {

  const hist = useHistory();

  const [updateType, setUpdateType] = useState("");
  const [updateTempType, setUpdateTempType] = useState("");

  const [bookId, setBookId] = useState("");
  const [bookNo, setBookNo] = useState("");
  const [bookName, setBookName] = useState("");
  const [constId, setConstId] = useState("");
  const [constName, setConstName] = useState("");
  const [warrantyDate, setWarrantyDate] = useState("");
  const [warrantyText, setWarrantyText] = useState("");
  const [constWtyTextMethod, setConstWtyTextMethod] = useState("");
  const [constWtyTextLoc, setConstWtyTextLoc] = useState("");
  const [wtyTextNote, setWtyTextNote] = useState("");
  const [constMethod, setConstMethod] = useState("");
  const [constLoc, setConstLoc] = useState("");
  const [note, setNote] = useState("");
  const [wtyStartDate, setWtyStartDate] = useState("");
  const [wtyEndDate, setWtyEndDate] = useState("");
  const [wtyTmpList, setWtyTmpList] = useState([]);
  const [wtyId, setWtyId] = useState("");
  const [wtyTmpName, setWtyTmpName] = useState("");
  const [wtyContents, setWtyContents] = useState("");
  const [createPg, setCreatePg] = useState("");

  const [modalWarrantyTemplate, setModalWarrantyTemplate] = useState(false);

  //初期化
  useEffect(() => {
    const update = async () => {
      if (!book_id) {
        return;
      }
      let obj = await getAPI("tr_book/" + book_id);
      
      setUpdateType("update");
      setBookId(book_id);
      setBookNo(obj.book_no);
      setBookName(obj.book_name);

      if (obj.const !== null) {
        setConstId(obj.const.const_id);
        setConstName(obj.const.name);
      }

      setWarrantyText(obj.warranty_text);
      setWarrantyDate(parseDate(obj.warranty_date));
      setWtyStartDate(parseDate(obj.wty_start_date));
      setWtyEndDate(parseDate(obj.wty_end_date));
      
      if(obj.warranty_text !== null) {
        const warrantyTextArray = obj.warranty_text.split("\\n");
        setConstWtyTextMethod(warrantyTextArray[0]);
        setConstWtyTextLoc(warrantyTextArray[1]);
        setWtyTextNote(warrantyTextArray[2]);
      }

      setCreatePg(nvl(obj.create_pg));

      let conditionWnty = {};
      const res = await getAPI("ms_wnty_tmp_search_view", conditionWnty);
      
      getWtyTempData(res, wtyId);
    }
    update();

  }, []);

  const onClickInsert = async () => {
    let wtyTempObj = newWntyTempData();
    let wtyTmpId = "";

    if(wtyId == "") {
      wtyTempObj = await postAPI("ms_wnty_tmp/", wtyTempObj);
      setWtyId(wtyTempObj.wnty_tmp_id);
      wtyTmpId = wtyTempObj.wnty_tmp_id;
    } else {
      await putAPI("ms_wnty_tmp/" + wtyId + "/", wtyTempObj);
      wtyTmpId = wtyId;
    }
    setUpdateTempType("update");
    success("登録完了");

    let condition = {};
    const res = await getAPI("ms_wnty_tmp_search_view", condition);

    getWtyTempData(res, wtyTmpId);
  }

  const onClickUpdate = async () => {
    let bookWtyObj = newBookWntyData();
    await putAPI("tr_book_wty/" + book_id + "/", bookWtyObj);
  
    success("登録完了");
    hist.push("/warranty_registration/" + book_id);
  }

  const getWtyTempData = (res, wtyTmpId) => {
    let tempData = [];
    res.results.map((result) => {
      tempData.push({wnty_tmp_id: result.wnty_tmp_id, wnty_tmp_name: result.wnty_tmp_name, contents: result.contents});
    });

    setWtyTmpList(tempData);
    setWtyTempSelected(wtyTmpId);
  }

  const setWtyTempSelected = (wtyTmpId) => {
    let templates = $("[name='chooseTemplate']");
    for (var temp of templates) {
      if (temp.checked) {
        temp.checked = false;
      }
      if (temp.value === wtyTmpId) {
        temp.checked = true;
      }
    }
  }

  const splitWtyContents = (contents) => {
    const contentArray = contents.split("\\n");
    setConstMethod(contentArray[0]);
    setConstLoc(contentArray[1]);
    setNote(contentArray[2]);
  }

  const setWtyTmpData = async (tmpId) => {
    setWtyId("");
    setWtyTmpName("");
    setWtyContents("");
    setConstMethod("");
    setConstLoc("");
    setNote("");
    setUpdateTempType("");
    if (tmpId !== "") {
      let condition = {};
      addField(condition, "wnty_tmp_id", tmpId);
      const res = await getAPI("ms_wnty_tmp_search_view", condition);
      
      setWtyId(res.results[0].wnty_tmp_id);
      setWtyTmpName(res.results[0].wnty_tmp_name);
      setWtyContents(res.results[0].contents);
      splitWtyContents(res.results[0].contents);
      setUpdateTempType("update");
    }
  }

  const setWarrantyTextOnTemplate = () => {
    setConstWtyTextMethod(constMethod);
    setConstWtyTextLoc(constLoc);
    setWtyTextNote(note);
    setModalWarrantyTemplate(false);
  }

  const newBookWntyData = () => {
    return {
      book_id: toNull(book_id),
      warranty_date: toNull(dateformatDB(warrantyDate)),
      wty_start_date: toNull(dateformatDB(wtyStartDate)),
      wty_end_date: toNull(dateformatDB(wtyEndDate)),
      warranty_text: toNull(constWtyTextMethod + " \\n" + constWtyTextLoc + " \\n" + wtyTextNote),
      create_pg: toNull(createPg),
      update_pg: 'WarrantyRegistration'
    };
  }

  const newWntyTempData = () => {
    return {
      wnty_tmp_id: toNull(wtyId),
      wnty_tmp_name: toNull(wtyTmpName),
      contents: toNull(constMethod + " \\n" + constLoc + " \\n" + note),
      create_pg: 'WarrantyRegistration',
      update_pg: 'WarrantyRegistration'
    };
  }

  return (
    <>
      <AppNav/>
      <WntyRegNav bookId={book_id}/>
      <Form.Group as={Row} className="h1">
        <Col sm="11">
        </Col>
        <Col sm="1">
          <Button className="float-left" id="wty_book_register" variant="warning" onClick={() => onClickUpdate()}>Register</Button>
        </Col>
      </Form.Group>

      <Container className="container-main-search">

        <Form.Group as={Row}>
          <Form.Label column sm="1">施工業者</Form.Label>
          <Col sm="5">
            <Form.Control type="text" id="construction_contractors" readOnly value={constName}/>
          </Col>
          <Col sm="1">
          </Col>
          <Col sm="4" className="input-group">
            <DatePicker id="warrantyDate" selected={warrantyDate}
                        onChange={(date) => setWarrantyDate(date)} dateFormat="yyyy年MM月dd日" locale="ja"/>
            <Button as={Link} variant="link" to={"/"} style={{paddingRight: "0"}}
                    onClick={e => setFocusCalendar(e, 'warrantyDate')}>
              <FontAwesomeIcon icon={faCalendarAlt} size="2x"/>
            </Button>
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm="1">現場名</Form.Label>
          <Col sm="5">
            <Form.Control type="text" id="site_name" readOnly value={bookName}/>
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm="1">保証期間</Form.Label>
          <Col sm="4" className="input-group">
            <DatePicker id="wtyStartDate" selected={wtyStartDate}
                        onChange={(date) => setWtyStartDate(date)} dateFormat="yyyy年MM月dd日" locale="ja"/>
            <Button as={Link} variant="link" to={"/"} style={{paddingRight: "0"}}
                    onClick={e => setFocusCalendar(e, 'wtyStartDate')}>
              <FontAwesomeIcon icon={faCalendarAlt} size="2x"/>
            </Button>
            <InputGroup.Text style={{border: "0px", backgroundColor: "#ffffff"}}>～</InputGroup.Text>
            <DatePicker id="wtyEndDate" selected={wtyEndDate} onChange={(date) => setWtyEndDate(date)}
                        dateFormat="yyyy年MM月dd日" locale="ja"/>
            <Button as={Link} variant="link" to={"/"} style={{paddingRight: "0"}}
                    onClick={e => setFocusCalendar(e, 'wtyEndDate')}>
              <FontAwesomeIcon icon={faCalendarAlt} size="2x"/>
            </Button>
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Col sm="10"></Col>
          <Col sm="2">
            <a href='#' className='warranty_links' onClick={() => setModalWarrantyTemplate(true)}>テンプレートから選択する</a>
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm="1">現場名</Form.Label>
          <Col sm="10" id="warranty_text">
            <Form.Group as={Row} className="ms-0 ps-0">
              <Col sm="12">
                <Form.Control 
                className="ms-0 pl-0" 
                as="textarea" 
                rows={6} 
                readOnly 
                value={wtyTextNote}/>
              </Col>
            </Form.Group>
          </Col>
        </Form.Group>
      </Container>

      <Modal size='lg' centered show={modalWarrantyTemplate} onHide={() => setModalWarrantyTemplate(false)}>
        <Form>
          <Modal.Header id="warranty_modal_header" closeButton>
            <Form.Group as={Row}>
              <Modal.Title id="warranty_modal_title">テンプレート選択</Modal.Title>
              {wtyTmpList.map((title, key) => (
                <Col sm="12" key={key}>
                  <Form.Check
                    type="radio"
                    value={title.wnty_tmp_id}
                    label={title.wnty_tmp_name}
                    className="warranty_modal_radios"
                    name="chooseTemplate"
                    onChange={e => setWtyTmpData(e.target.value)}
                  />
                </Col>
              ))}
              <Col sm="12">
                <Form.Check
                  key="00"
                  type="radio"
                  label="新規追加"
                  value=""
                  className="warranty_modal_radios"
                  name="chooseTemplate"
                  onChange={e => setWtyTmpData(e.target.value)}
                />
              </Col>
              <Col sm="12" className="mt-2">
                <Form.Control 
                  id="warranty_template_title" 
                  type="text" 
                  value={wtyTmpName}
                  maxLength={80}
                  onChange={e => setWtyTmpName(e.target.value)}
                  onBlur={e => setWtyTmpName(e.target.value)}
                />
              </Col>
            </Form.Group>
          </Modal.Header>

          <Modal.Body id="warranty_modal_body" className="pr-2 pl-2 pb-1">
            <Form.Group as={Row} className="mb-0 mt-1">
                  <Form.Control 
                  className="border border-2 ml-3 mr-3" 
                  id="warranty_textarea" 
                  as="textarea" 
                  rows={7} 
                  value={note} 
                  maxLength={255} 
                  onChange={e => setNote(e.target.value)} 
                  onBlur={e => setNote(e.target.value)}/>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer id="warranty_modal_footer">
            {updateTempType !== "update" && 
              <Button variant="gray" onClick={() => onClickInsert()}>
                テンプレート追加
              </Button>
            }
            {updateTempType == "update" && 
              <Button variant="gray" onClick={() => onClickInsert()}>
                テンプレート更新
              </Button>
            }
            <Button variant="gray" onClick={() => setWarrantyTextOnTemplate()}>
              入力内容を反映する
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Toast/>
    </>
  );
}

export default withRouter(WarrantyRegistration);
