import React, {useEffect, useRef, useState} from 'react';
import {Link, useHistory, withRouter} from "react-router-dom";
import Button from 'react-bootstrap/Button';
import {getAPI} from "../APIUtil";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import AppNav from "./AppNav";
import {Card, Col, Container, Form, InputGroup, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import {addField, dateformat, dateformatJPN, dateformatJPNByType, dateformatJPNYYYYMMDD, linkFormatter, parseDate, setFocusCalendar, useDebounce} from "../CMUtil";
import MdDatatable, {InitialLoading, sortCaret} from "../MdDataTable";
import AsyncSelect from "react-select/async";
import DatePicker from "react-datepicker";

export const DocList = () => {

  const history = useHistory();
  const iniSearch = useRef(true);

  const [loadingCss, setLoadingCss] = useState("loading_panel_init");

  const [loading, setLoading] = useState(true);
  const [locPage, setLocPage] = useState(null);
  const [locSizePerPage, setLocSizePerPage] = useState(null);
  const [locTotalSize, setLocTotalSize] = useState(null);
  const [locSortField, setLocSortField] = useState("doc_update_date");
  const [locSortOrder, setLocSortOrder] = useState("desc");

  const [bookId, setBookId] = useState("");
  const debouncedBookId = useDebounce(bookId, 500, setLocPage);
  const [bookNo, setBookNo] = useState("");
  const debouncedBookNo = useDebounce(bookNo, 500, setLocPage);
  const [bookName, setBookName] = useState("");
  const debouncedBookName = useDebounce(bookName, 500, setLocPage);
  const [constId, setConstId] = useState("");
  const debouncedConstId = useDebounce(constId, 500, setLocPage);
  const [designId, setDesignId] = useState("");
  const debouncedDesignId = useDebounce(designId, 500, setLocPage);
  const [fileName, setFileName] = useState("");
  const debouncedFileName = useDebounce(fileName, 500, setLocPage);
  const [category, setCategory] = useState("");
  const debouncedCategory = useDebounce(category, 500, setLocPage);
  const [docCreateUserId, setDocCreateUserId] = useState("");
  const debouncedDocCreateUserId = useDebounce(docCreateUserId, 500, setLocPage);
  const [docCreateDateFrom, setDocCreateDateFrom] = useState("");
  const debouncedDocCreateDateFrom = useDebounce(docCreateDateFrom, 500, setLocPage);
  const [docCreateDateTo, setDocCreateDateTo] = useState("");
  const debouncedDocCreateDateTo = useDebounce(docCreateDateFrom, 500, setLocPage);
  const [docUpdateUserId, setDocUpdateUserId] = useState("");
  const debounceddocUpdateUserId = useDebounce(docUpdateUserId, 500, setLocPage);
  const [docUpdateDateFrom, setDocUpdateDateFrom] = useState("");
  const debouncedDocUpdateDateFrom = useDebounce(docUpdateDateFrom, 500, setLocPage);
  const [docUpdateDateTo, setDocUpdateDateTo] = useState("");
  const debouncedDocUpdateDateTo = useDebounce(docUpdateDateTo, 500, setLocPage);

  const [listData, setListData] = useState([]);

  // const [bookSelected, setBookSelected] = useState([]);
  const [constSelected, setConstSelected] = useState([]);
  const [designSelected, setDesignSelected] = useState([]);
  const [docCreateSelected, setDocCreateSelected] = useState([]);
  const [docUpdateSelected, setDocUpdateSelected] = useState([]);

  // 項目定義
  const columns = [
    {
      dataField: 'doc_id',
      text: 'ID',
      hidden: true,
      sort: false,
      headerStyle: {width: '80px'}
    },
    {
      dataField: 'book_name',
      text: '現場名',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '150px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      },
      formatter: (cell, row) => (
        <span>{(row.book_no ? row.book_no + ' ' : '') + (row.book_name ? row.book_name : '')}</span>
      )
    },
    {
      dataField: 'category',
      text: '区分',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '60px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'file_name',
      text: 'ファイル名',
      classes: 'tbl-col link',
      sort: true,
      headerStyle: {width: '200px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      },
      formatter: (cell, row) => (
        <div><a onClick={() => onClickLinkToDetail(row.doc_id)}>{cell}</a></div>
      )
    },
    {
      dataField: 'const_name',
      text: '施工業者',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '100px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      },
    },
    {
      dataField: 'ext_design_name',
      text: '設計事務所',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '60px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'doc_create_date',
      text: '登録日',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '65px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      },
      formatter: (cell, row) => (
        <span>{dateformatJPNYYYYMMDD(parseDate(cell))}</span>
      )
    },
    {
      dataField: 'doc_update_date',
      text: '更新',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '65px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      },
      formatter: (cell, row) => (
        <span>{dateformatJPNYYYYMMDD(parseDate(cell))}</span>
      )
    }
  ];

  // 初期化
  useEffect(() => {
    const init = async () => {
      if (!history.location.state) {
        setLocPage(1);
      } else if (!history.location.state.pages) {
        setLocPage(1);
      } else if (!history.location.state.pages.filters) {
        setLocPage(1);
      } else if (Object.keys(history.location.state.pages.filters).length === 0) {
        setLocPage(1);
      } else {
        setLocPage(history.location.state.pages.page);
        setLocSizePerPage(history.location.state.pages.sizePerPage);
        setLocSortField(history.location.state.pages.sortField);
        setLocSortOrder(history.location.state.pages.sortOrder);
      }
    }
    init();
  }, []);

  // 検索処理
  useEffect(() => {
    if (iniSearch.current) {
      iniSearch.current = false;
      return;
    }
    const update = async () => {
      let condition = {};
      addField(condition, "page", locPage);
      addField(condition, "size_per_page", locSizePerPage);
      addField(condition, "sort_field", locSortField);
      addField(condition, "sort_order", locSortOrder);
      addField(condition, "book_id", bookId);
      addField(condition, "book_no", bookNo);
      addField(condition, "book_name", bookName);
      addField(condition, "const_id", constId);
      addField(condition, "design_id", designId);
      addField(condition, "file_name", fileName);
      addField(condition, "category", category);
      addField(condition, "doc_create_user_id", docCreateUserId);
      addField(condition, "doc_create_date_from", dateformat(docCreateDateFrom));
      addField(condition, "doc_create_date_to", dateformat(docCreateDateTo));
      addField(condition, "doc_update_user_id", docUpdateUserId);
      addField(condition, "doc_update_date_from", dateformat(docUpdateDateFrom));
      addField(condition, "doc_update_date_to", dateformat(docUpdateDateTo));
      const res = await getAPI("tr_doc_search_view", condition);
      setListData(res);
      setLoading(false);
      setLoadingCss("loading_panel_fadeout");
    }
    update();
  }, [
    locPage,
    locSizePerPage,
    locSortField,
    locSortOrder,
    debouncedBookId,
    debouncedBookNo,
    debouncedBookName,
    debouncedConstId,
    debouncedDesignId,
    debouncedFileName,
    debouncedCategory,
    debouncedDocCreateUserId,
    debouncedDocCreateDateFrom,
    debouncedDocCreateDateTo,
    debounceddocUpdateUserId,
    debouncedDocUpdateDateFrom,
    debouncedDocUpdateDateTo,
  ]);

  const onTableChange = (type, {page, sizePerPage, filters, sortField, sortOrder, cellEdit}) => {
    if ("pagination" === type) {
      setLocPage(page);
    }
    if ("sizePerPage" === type) {
      setLocSizePerPage(sizePerPage);
    }
    if ("sort" === type) {
      setLocSortField(sortField);
      setLocSortOrder(sortOrder);
    }
  }

  const onClickLinkToDetail = (doc_id) => {
    history.push("/doc/" + doc_id);
  }

  const onSearchConst = async (query) => {
    let searchCondition = {
      name: query
    }
    const res = await getAPI("ms_const_typeahead_view", searchCondition);
    let locOptions = [];
    res.results.map((result) => {
      locOptions.push({name: result.name, id: result.const_id});
    });
    return locOptions;
  }

  const handleChangeConst = (value) => {
    setConstSelected(value ? value : "");
    setConstId(value ? value.id : "");
  }

  const onSearchDesign = async (query) => {
    let searchCondition = {
      name: query
    }
    const res = await getAPI("ms_design_typeahead_view", searchCondition);
    let locOptions = [];
    res.results.map((result) => {
      locOptions.push({name: result.name, id: result.design_id});
    });
    return locOptions;
  }

  const handleChangeDesign = (value) => {
    setDesignSelected(value ? value : "");
    setDesignId(value ? value.id : "");
  }

  const onSearchDocCreateUser = async (query) => {
    let searchCondition = {
      name: query
    }
    const res = await getAPI("ms_user_typeahead_view", searchCondition);
    let locOptions = [];
    res.results.map((result) => {
      locOptions.push({name: result.last_name + " " + result.first_name, id: result.user_id});
    });
    return locOptions;
  }

  const handleChangeDocCreateUser = (value) => {
    setDocCreateSelected(value ? value : "");
    setDocCreateUserId(value ? value.id : "");
  }

  const onSearchDocUpdateUser = async (query) => {
    let searchCondition = {
      name: query
    }
    const res = await getAPI("ms_user_typeahead_view", searchCondition);
    let locOptions = [];
    res.results.map((result) => {
      locOptions.push({name: result.last_name + " " + result.first_name, id: result.user_id});
    });
    return locOptions;
  }

  const handleChangeDocUpdateUser = (value) => {
    setDocUpdateSelected(value ? value : "");
    setDocUpdateUserId(value ? value.id : "");
  }

  const onClickClear = () =>{
    setConstId("");
    setBookNo("");
    setBookName("");
    setConstId("")
    setConstSelected(null);
    setDesignId("");
    setDesignSelected(null);
    setFileName("");
    setCategory("");
    setDocCreateDateFrom("");
    setDocCreateDateTo("");
    setDocUpdateDateFrom("");
    setDocUpdateDateTo("");
    setDocCreateUserId("");
    setDocCreateSelected(null);
    setDocUpdateUserId("");
    setDocUpdateSelected(null);
  }

  return (
    <>
      <AppNav/>
      <div className="h1">
        <h1>
          ドキュメント
        </h1>
      </div>
      <Card className="card-search">
        <Container className="container-main-detail">
          <Form.Group as={Row}>
            <Form.Label column sm="1">工事番号</Form.Label>
            <Col sm="4">
              <Form.Control type="text" value={bookNo} onChange={e => setBookNo(e.target.value)}/>
            </Col>
            <Col sm="1"></Col>
            <Form.Label column sm="1">現場名</Form.Label>
            <Col sm="4">
              <Form.Control type="text" value={bookName} onChange={e => setBookName(e.target.value)}/>
            </Col>
            <Col sm="1">
              <Button variant="clear" onClick={e => onClickClear()}>条件クリア</Button>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="1">施工業者</Form.Label>
            <Col sm="4">
              <AsyncSelect
                isClearable
                className="async-select"
                cacheOptions
                defaultOptions
                value={constSelected}
                getOptionLabel={e => e.name}
                getOptionValue={e => e.id}
                loadOptions={onSearchConst}
                onChange={handleChangeConst}
                placeholder=""
              />
            </Col>
            <Col sm="1"></Col>
            <Form.Label column sm="1">設計事務所</Form.Label>
            <Col sm="4">
              <AsyncSelect
                isClearable
                className="async-select"
                cacheOptions
                defaultOptions
                value={designSelected}
                getOptionLabel={e => e.name}
                getOptionValue={e => e.id}
                loadOptions={onSearchDesign}
                onChange={handleChangeDesign}
                placeholder=""
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="1">ファイル名</Form.Label>
            <Col sm="4">
              <Form.Control type="text" value={fileName} onChange={e => setFileName(e.target.value)}/>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="1">区分</Form.Label>
            <Col sm="10">
              <Form.Check
                inline
                id="category1"
                type="radio"
                name="category"
                label="見積り"
                value="見積り"
                onChange={e => setCategory(e.target.value)}
                checked={category === "見積り"}/>
              <Form.Check
                inline
                id="category2"
                type="radio"
                name="category"
                label="出来高調書"
                value="出来高調書"
                onChange={e => setCategory(e.target.value)}
                checked={category === "出来高調書"}/>
              <Form.Check
                inline
                id="category3"
                type="radio"
                name="category"
                label="請求書"
                value="請求書"
                onChange={e => setCategory(e.target.value)}
                checked={category === "請求書"}/>
              <Form.Check
                inline
                id="category4"
                type="radio"
                name="category"
                label="保証書"
                value="保証書"
                onChange={e => setCategory(e.target.value)}
                checked={category === "保証書"}/>
              <Form.Check
                inline
                id="category5"
                type="radio"
                name="category"
                label="その他"
                value="その他"
                onChange={e => setCategory(e.target.value)}
                checked={category === "その他"}/>
            </Col>
          </Form.Group>


          <Form.Group as={Row}>
            <Form.Label column sm="1">作成日</Form.Label>
            <Col sm="4" className="input-group">
              <DatePicker id="docCreateDateFrom" selected={docCreateDateFrom} onChange={(date) => setDocCreateDateFrom(date)} dateFormat="yyyy年MM月dd日" locale="ja"/>
              <Button as={Link} variant="link" to={"/"} style={{paddingRight: "0"}} onClick={e => setFocusCalendar(e, 'docCreateDateFrom')}>
                <FontAwesomeIcon icon={faCalendarAlt} size="2x"/>
              </Button>
              <InputGroup.Text style={{border: "0px", backgroundColor: "#ffffff"}}>～</InputGroup.Text>
              <DatePicker id="docCreateDateTo" selected={docCreateDateTo} onChange={(date) => setDocCreateDateTo(date)} dateFormat="yyyy年MM月dd日" locale="ja"/>
              <Button as={Link} variant="link" to={"/"} style={{paddingRight: "0"}} onClick={e => setFocusCalendar(e, 'docCreateDateTo')}>
                <FontAwesomeIcon icon={faCalendarAlt} size="2x"/>
              </Button>
            </Col>
            <Col sm="1"></Col>
            <Form.Label column sm="1">更新日</Form.Label>
            <Col sm="4" className="input-group">
              <DatePicker id="docUpdateDateFrom" selected={docUpdateDateFrom} onChange={(date) => setDocUpdateDateFrom(date)} dateFormat="yyyy年MM月dd日" locale="ja"/>
              <Button as={Link} variant="link" to={"/"} style={{paddingRight: "0"}} onClick={e => setFocusCalendar(e, 'docUpdateDateFrom')}>
                <FontAwesomeIcon icon={faCalendarAlt} size="2x"/>
              </Button>
              <InputGroup.Text style={{border: "0px", backgroundColor: "#ffffff"}}>～</InputGroup.Text>
              <DatePicker id="docUpdateDateTo" selected={docUpdateDateTo} onChange={(date) => setDocUpdateDateTo(date)} dateFormat="yyyy年MM月dd日" locale="ja"/>
              <Button as={Link} variant="link" to={"/"} style={{paddingRight: "0"}} onClick={e => setFocusCalendar(e, 'docUpdateDateTo')}>
                <FontAwesomeIcon icon={faCalendarAlt} size="2x"/>
              </Button>
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm="1">作成者</Form.Label>
            <Col sm="4">
              <AsyncSelect
                isClearable
                className="async-select"
                cacheOptions
                defaultOptions
                value={docCreateSelected}
                getOptionLabel={e => e.name}
                getOptionValue={e => e.id}
                loadOptions={onSearchDocCreateUser}
                onChange={handleChangeDocCreateUser}
                placeholder=""
              />
            </Col>
            <Col sm="1"></Col>
            <Form.Label column sm="1">更新者</Form.Label>
            <Col sm="4">
              <AsyncSelect
                isClearable
                className="async-select"
                cacheOptions
                defaultOptions
                value={docUpdateSelected}
                getOptionLabel={e => e.name}
                getOptionValue={e => e.id}
                loadOptions={onSearchDocUpdateUser}
                onChange={handleChangeDocUpdateUser}
                placeholder=""
              />
            </Col>
          </Form.Group>

        </Container>
      </Card>

      <Container className="container-main-list">
        {/* *** 一覧 *** */}
        <InitialLoading loadingCss={loadingCss}/>
        <MdDatatable
          keyField="doc_id"
          listData={listData}
          columns={columns}
          loading={loading}
          onTableChange={onTableChange}
          page={locPage}
          sizePerPage={locSizePerPage}
          totalSize={locTotalSize}
          sortField={locSortField}
          sortOrder={locSortOrder}
          showPagenation={true}
          linkToAdd="/doc/"
        />
      </Container>    </>
  );
}

export default withRouter(DocList);