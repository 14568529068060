import React, {useEffect, useImperativeHandle, useState} from "react";
import {Col, Form, Row} from "react-bootstrap";
import {
  formatNumber,
  nvl, onFocusSelect, onSearchItem, onSearchUnit,
  parseNumber,
} from "../CMUtil";
import {getAPI} from "../APIUtil";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinusCircle} from "@fortawesome/free-solid-svg-icons";

export const InvDtlRow = React.forwardRef((props, ref) => {

    const [index, setIndex] = useState(0);

    const [id, setId] = useState("");
    const [lineNo, setLineNo] = useState(0);

    const [itemType, setItemType] = useState("");
    const [itemId, setItemId] = useState("");
    const [itemName, setItemName] = useState("");
    const [qty, setQty] = useState("");
    const [unit, setUnit] = useState("");
    const [price, setPrice] = useState("");
    const [amount, setAmount] = useState("");

    const [itemNameSuggestionDisplay, setItemNameSuggestionDisplay] = useState("none");
    const [itemNameSuggestionList, setItemNameSuggestionList] = useState([]);
    const [mouseOverItemNameSuggestion, setMouseOverItemNameSuggestion] = useState(false);

    const [unitSuggestionDisplay, setUnitSuggestionDisplay] = useState("none");
    const [unitSuggestionList, setUnitSuggestionList] = useState([]);
    const [mouseOverUnitSuggestion, setMouseOverUnitSuggestion] = useState(false);

    //INITIALIZE
    useEffect(() => {

      const update = async () => {

        let locDtl = props.dtl;
        setId(nvl(locDtl.id));
        setLineNo(locDtl.line_no);
        setIndex(props.index);
        setItemId(nvl(locDtl.item_id));
        setItemName(nvl(locDtl.item_name));
        setItemType("opn");
        setQty(formatNumber(nvl(locDtl.qty)));
        setUnit(nvl(locDtl.unit));
        setPrice(formatNumber(nvl(locDtl.price)));
        setAmount(nvl(locDtl.amount));
      }
      update();
    }, []);

    useEffect(() => {

      const update = async () => {
        let locItemList = await onSearchItem(itemName, 10);
        setItemNameSuggestionList(locItemList);
      }
      update();

    }, [itemName]);

    useEffect(() => {

      const update = async () => {

        setAmount("");
        let locQty = parseNumber(qty);
        let locPrice = parseNumber(price);
        if (!locQty || !locPrice) {
          return;
        }
        let locAmount = locQty * locPrice;
        setAmount(formatNumber(locAmount));
      }

      update();
    }, [qty, price]);

    useEffect(() => {

      props.totalCalcHandler();

    }, [amount]);

    useImperativeHandle(ref, () => ({
      getStates: () => {
        return {
          inv_dtl_id: id,
          index: index,
          line_no: lineNo,
          item_id: itemId,
          item_name: itemName,
          qty: qty,
          unit: unit,
          price: price,
          amount: amount
        };
      },
      setData: (data) => {
        setId(nvl(data.inv_dtl_id));
        setIndex(nvl(data.index));
        setLineNo(nvl(data.line_no));
        setItemId(nvl(data.item_id));
        setItemName(nvl(data.item_name));
        setQty(nvl(data.qty));
        setUnit(nvl(data.unit));
        setPrice(nvl(data.price));
        setAmount(nvl(data.amount));
      }
    }), [
      index,
      id,
      lineNo,
      itemName,
      qty,
      unit,
      price,
      amount]);

    const onClickDeleteRow = (e) => {

      props.deleteRowHandler(index);

    }

    const onFocusItemName = async (e) => {
      let locItemList = await onSearchItem(itemName, 10);
      setItemNameSuggestionList(locItemList);
      setItemNameSuggestionDisplay("block");
      onFocusSelect(e);
    }
    const onMouseOverItemNameSuggestion = (e) => {
      setMouseOverItemNameSuggestion(true);
    }
    const onMouseOutItemNameSuggestion = (e) => {
      setMouseOverItemNameSuggestion(false);
    }
    const onBlurItemNameSuggestion = (e) => {
      if (!mouseOverItemNameSuggestion) {
        setItemNameSuggestionDisplay("none");
      }
    }
    const selectItemName = async (itemId) => {
      let item = await getAPI("ms_item/" + itemId);
      if (item) {
        setItemId(nvl(item.item_id));
        setItemName(nvl(item.item_name));
        setUnit(nvl(item.unit));
        setPrice(nvl(item.price));
      }
      setItemNameSuggestionDisplay("none");
    }

    const onFocusUnit = async (e) => {
      let locUnitList = await onSearchUnit("");
      setUnitSuggestionList(locUnitList);
      setUnitSuggestionDisplay("block");
      onFocusSelect(e);
    }
    const onMouseOverUnitSuggestion = (e) => {
      setMouseOverUnitSuggestion(true);
    }
    const onMouseOutUnitSuggestion = (e) => {
      setMouseOverUnitSuggestion(false);
    }
    const onBlurUnitSuggestion = (e) => {
      if (!mouseOverUnitSuggestion) {
        setUnitSuggestionDisplay("none");
      }
    }
    const selectUnit = async (unit) => {
      setUnit(unit);
      setUnitSuggestionDisplay("none");
    }

    return (
      <Row>
        <Col sm="5"
             className={"dtl-data-col dtl-data-col-left " + (props.isLastRow ? "dtl-data-col-bottom" : "")}
             style={{position: "relative"}}>
          <Form.Control
            id="itemName"
            type="text"
            value={itemName}
            onFocus={e => onFocusItemName(e)}
            onBlur={e => onBlurItemNameSuggestion(e)}
            onChange={e => setItemName(e.target.value)}
            placeholder=""
          />
          <div
            className="suggestion"
            style={{display: itemNameSuggestionDisplay}}
            onMouseOver={(e) => onMouseOverItemNameSuggestion(e)}
            onMouseOut={(e) => onMouseOutItemNameSuggestion(e)}
          >
            {itemNameSuggestionList.map((obj, index) => (
              <div key={obj.id}
                   className="suggestion-option"
                   onClick={e => selectItemName(obj.id)}
              >
                {obj.name}
              </div>
            ))}
          </div>
        </Col>
        <Col sm="1" className={"dtl-data-col "  + (props.isLastRow ? "dtl-data-col-bottom" : "")}>
          <Form.Control
            id="qty"
            type="tel"
            className="text-right"
            value={qty}
            maxLength={4}
            onFocus={e => onFocusSelect(e)}
            onChange={e => setQty(e.target.value)}
            onBlur={e => setQty(formatNumber(parseNumber(e.target.value)))}
            placeholder=""
          />
        </Col>
        <Col sm="1" className={"dtl-data-col "  + (props.isLastRow ? "dtl-data-col-bottom" : "")}>
          <Form.Control
            id="unit"
            type="text"
            value={unit}
            onFocus={e => onFocusUnit(e)}
            onBlur={e => onBlurUnitSuggestion(e)}
            onChange={e => setUnit(e.target.value)}
            placeholder=""
          />
          <div
            className="suggestion"
            style={{display: unitSuggestionDisplay}}
            onMouseOver={(e) => onMouseOverUnitSuggestion(e)}
            onMouseOut={(e) => onMouseOutUnitSuggestion(e)}
          >
            {unitSuggestionList.map((obj, index) => (
              <div key={obj.code_id}
                   className="suggestion-option"
                   onClick={e => selectUnit(obj.name)}
              >
                {obj.name}
              </div>
            ))}
          </div>
        </Col>
        <Col sm="2" className={"dtl-data-col "  + (props.isLastRow ? "dtl-data-col-bottom" : "")}>
          <Form.Control
            id="price"
            type="tel"
            className="text-right"
            value={price}
            maxLength={10}
            onFocus={e => onFocusSelect(e)}
            onChange={e => setPrice(e.target.value)}
            onBlur={e => setPrice(formatNumber(parseNumber(e.target.value)))}
            placeholder=""
          />
        </Col>
        <Col sm="2" className={"dtl-data-col "  + (props.isLastRow ? "dtl-data-col-bottom" : "")}>
          <Form.Control
            id="amount"
            type="tel"
            className="text-right"
            value={amount}
            readOnly={true}
            placeholder=""
          />
        </Col>
        <Col sm="1" className={"dtl-data-col "  + (props.isLastRow ? "dtl-data-col-bottom" : "")}>
          <Button variant="add" style={{
            width: "100%",
            height: "2.2rem",
            margin: "0rem 0.05rem 0rem 0.05rem",
            borderRadius: "0rem"
          }}
                  onClick={e => onClickDeleteRow(e)}>
            <FontAwesomeIcon icon={faMinusCircle} size="lg" style={{marginBottom: "0.1rem"}}/>
            削除
          </Button>
        </Col>
      </Row>
    );
  }
);

export default React.memo(InvDtlRow);