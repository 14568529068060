import React, {useEffect, useImperativeHandle, useState} from "react";
import {Col, Form, Row} from "react-bootstrap";
import {
  formatNumber,
  nvl,
} from "../CMUtil";

export const ChDtlRowLineTtl = React.forwardRef((props, ref) => {

  const [lineNo, setLineNo] = useState(0);
  const [lineTtlQty, setLineTtlQty] = useState("");
  const [lineTtlAmount, setLineTtlAmount] = useState("");

  useEffect(() => {

    const update = async () => {
      let locDtl = props.dtl;
      setLineNo(locDtl.line_no);
      setLineTtlQty(formatNumber(nvl(locDtl.line_ttl_qty)));
      setLineTtlAmount(formatNumber(nvl(locDtl.line_ttl_amount)));
    }
    update();
  }, []);

  useImperativeHandle(ref, () => ({
    getStates: () => {
      return {
        line_no: lineNo,
        line_ttl_qty: lineTtlQty,
        line_ttl_amount: lineTtlAmount
      };
    },
    setData: (data) => {
      setLineNo(nvl(data.line_no));
      setLineTtlQty(nvl(data.line_ttl_qty));
      setLineTtlAmount(nvl(data.line_ttl_amount));
    }
  }), [
    lineNo,
    lineTtlQty,
    lineTtlAmount
  ]);

  return (
    <Row key={"ttl_dtl_index_" + props.invIndex + "_" + props.chNo}>
      <Col sm="5"
           className={"dtl-data-col " + (props.isLastRow ? "dtl-data-col-bottom" : "")}>
        <Form.Control
          id="line_ttl_qty"
          type="tel"
          className="text-right"
          value={lineTtlQty}
          readOnly={true}
          placeholder=""
        />
      </Col>
      <Col sm="7" className={"dtl-data-col " + (props.isLastRow ? "dtl-data-col-bottom" : "")}>
        <Form.Control
          id="line_ttl_amount"
          type="tel"
          className="text-right"
          value={lineTtlAmount}
          readOnly={true}
          placeholder=""
        />
      </Col>
    </Row>
  )
});

export default React.memo(ChDtlRowLineTtl);