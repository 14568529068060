import React, {useEffect, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import {Card, Col, Container, Form, InputGroup, Row} from "react-bootstrap";
import {deleteAPI, getAPI, postAPI, putAPI} from "../APIUtil";
import Button from "react-bootstrap/Button";
import {withRouter} from "react-router";
import AppNav from "./AppNav";
import 'react-toastify/dist/ReactToastify.css';
import Toast, {success} from "../Toast";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMapMarkerAlt, faPen} from "@fortawesome/free-solid-svg-icons";
import AsyncSelect from "react-select/async";
import {
  checkKey,
  formatZip,
  nvl,
  onSearchPref,
  setZipAuto,
  toMultibyte,
  toNull,
  toSingleByte,
  toSingleByteAlphaNumberOnly
} from "../CMUtil";

export const DesignDtl = ({match: {params: {design_id}}}) => {

  const hist = useHistory();

  const [updateType, setUpdateType] = useState("");

  const [designId, setDesignId] = useState("");
  const [name, setName] = useState("");
  const [nameSub, setNameSub] = useState("");
  const [tel, setTel] = useState("");
  const [fax, setFax] = useState("");
  const [zip, setZip] = useState("");
  const [pref, setPref] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [rep, setRep] = useState("");
  const [designType, setDesignType] = useState("");
  const [note, setNote] = useState("");

  const [prefSelected, setPrefSelected] = useState([]);

  //INITIALIZE
  useEffect(() => {

    const update = async () => {
      if (!design_id) {
        return;
      }
      let obj = await getAPI("ms_design/" + design_id);
      setUpdateType("update");
      setDesignId(nvl(obj.design_id));
      setName(nvl(obj.name));
      setNameSub(nvl(obj.name_sub));
      setTel(nvl(obj.tel));
      setFax(nvl(obj.fax));
      setZip(nvl(obj.zip));
      setPref(nvl(obj.pref));
      setPrefSelected({name: obj.pref, id: obj.pref});
      setCity(nvl(obj.city));
      setAddress(nvl(obj.address));
      setRep(nvl(obj.rep));
      setDesignType(nvl(obj.design_type));
      setNote(nvl(obj.note));
    }
    update();

  }, []);

  const handleChangePref = (value) => {
    setPrefSelected(value ? value : "");
    setPref(value ? value.id : "");
  }

  const onClickInsert = async () => {
    let obj = newData();
    obj = await postAPI("ms_design/", obj);
    setDesignId(obj.design_id);
    hist.push("/design/" + obj.design_id);
    setUpdateType("update");
    success("登録完了");
  }

  const onClickUpdate = async () => {
    let obj = newData();
    await putAPI("ms_design/" + design_id + "/", obj);
    success("更新完了");
  }

  const onClickDelete = async () => {
    let obj = newData();
    let res = await deleteAPI("ms_design/" + design_id + "/", obj);
    if (res) {
      success("削除完了");
      hist.push("/design_list/");
    }
  }

  const newData = () => {
    return {
      design_id: toNull(designId),
      name: toNull(name),
      name_sub: toNull(nameSub),
      tel: toNull(tel),
      fax: toNull(fax),
      zip: toNull(zip),
      pref: toNull(pref),
      city: toNull(city),
      address: toNull(address),
      rep: toNull(rep),
      design_type: toNull(designType),
      note: toNull(note),
      pg: 'DesignDtl'
    };
  }

  return (
    <>
      <div className="body-dtl body-dtl-const">
        <AppNav/>
        <div className="h1">
          <h1 className="float-left align-items-end">
            設計事務所
          </h1>
          <div className="float-none" style={{height: "30px"}}>&nbsp;</div>
        </div>

        <Container className="container-main-detail">

          <Row className="h3">
            <Col>
              <h3 style={{paddingTop: ".7rem"}}>
                基本情報
              </h3>
            </Col>
            <Col className="text-right">
              {updateType !== 'update' && <Button variant="primary" onClick={onClickInsert}><FontAwesomeIcon icon={faPen} size="lg"/>登録</Button>}
              {updateType === 'update' && <Button variant="primary" onClick={onClickUpdate}><FontAwesomeIcon icon={faPen} size="lg"/>登録</Button>}
            </Col>
          </Row>

          <Card className="card-secondary w-80 mx-auto mb-0">
            <Form.Group as={Row}>
              <Form.Label column sm="1">事務所名</Form.Label>
              <Col sm="4">
                <Form.Control
                  id="name"
                  type="text"
                  value={nvl(name)}
                  onChange={e => setName(e.target.value)}
                  onBlur={e => setName(toSingleByteAlphaNumberOnly(e.target.value))}
                />
              </Col>
              <Col sm="1"></Col>
              <Form.Label column sm="1">フリガナ</Form.Label>
              <Col sm="4">
                <Form.Control
                  id="nameSub"
                  type="text"
                  value={nvl(nameSub)}
                  onChange={e => setNameSub(e.target.value)}
                  onBlur={e => setNameSub(toSingleByteAlphaNumberOnly(e.target.value))}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="1">電話</Form.Label>
              <Col sm="4">
                <Form.Control
                  id="tel"
                  type="tel"
                  value={tel} style={{imeMode: "disabled"}}
                  onKeyPress={e => checkKey(e, /[0-9\-]/)}
                  maxLength={20}
                  onChange={e => setTel(e.target.value)}
                  onBlur={e => setTel(toSingleByte(e.target.value))}
                />
              </Col>
              <Col sm="1"></Col>
              <Form.Label column sm="1">ＦＡＸ</Form.Label>
              <Col sm="4">
                <Form.Control
                  id="fax"
                  type="text"
                  value={fax}
                  onKeyPress={e => checkKey(e, /[0-9\-]/)}
                  maxLength={20}
                  onChange={e => setFax(e.target.value)}
                  onBlur={e => setFax(toSingleByte(e.target.value))}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="1">住所</Form.Label>
              <Col sm="2" className="input-group">
                <InputGroup.Text className="input-group-text-cm" style={{fontWeight: "700"}}>〒</InputGroup.Text>
                <Form.Control id="zip"
                              type="text"
                              value={zip}
                              style={{textAlign: "center"}}
                              onKeyPress={e => checkKey(e, /[0-9\-]/)}
                              maxLength={8}
                              onChange={e => setZipAuto(e.target.value, setZip, setPref, setPrefSelected, setCity)}
                              onBlur={e => formatZip(e.target.value, setZip, setPref, setPrefSelected, setCity)}
                />
              </Col>
              <Col sm="2">
                <AsyncSelect sm="2"
                             className="async-select"
                             cacheOptions
                             defaultOptions
                             isClearable
                             value={prefSelected}
                             getOptionLabel={e => e.name}
                             getOptionValue={e => e.id}
                             loadOptions={onSearchPref}
                             onChange={handleChangePref}
                             placeholder=""
                />
              </Col>
              <Col sm="6" className="input-group">
                <Form.Control type="text" value={nvl(city)} id="address2"
                              style={{borderRadius: "0"}}
                              onChange={e => setCity(e.target.value)}/>
                <Form.Control type="text" value={address}
                              style={{width: "20%", borderTopLeftRadius: "0", borderBottomLeftRadius: "0"}}
                              onChange={e => setAddress(e.target.value)}
                              onBlur={e => setAddress(toMultibyte(e.target.value))}/>
                <Button as={Link} variant="link" to={"/map?design_id=" + designId} style={{paddingRight: "0"}}>
                  <FontAwesomeIcon icon={faMapMarkerAlt} size="2x"/>
                </Button>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="1">代表者</Form.Label>
              <Col sm="4" className="align-middle">
                <Form.Control type="text" value={nvl(rep)} onChange={e => setRep(e.target.value)}/>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="1">種別</Form.Label>
              <Col sm="3">
                <Form.Check
                  id="designType1"
                  type="radio"
                  name="designType"
                  label="意匠・構造"
                  value="意匠・構造"
                  onChange={e => setDesignType(e.target.value)}
                  checked={designType === "意匠・構造"}/>
              </Col>
              <Col sm="3">
                <Form.Check
                  id="designType2"
                  type="radio"
                  name="designType"
                  label="意匠"
                  value="意匠"
                  onChange={e => setDesignType(e.target.value)}
                  checked={designType === "意匠"}/>
              </Col>
              <Col sm="3">
                <Form.Check
                  id="designType3"
                  type="radio"
                  name="designType"
                  label="構造"
                  value="構造"
                  onChange={e => setDesignType(e.target.value)}
                  checked={designType === "構造"}/>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="1">備考</Form.Label>
              <Col sm="10">
                <Form.Control as="textarea" rows={20} value={note} onChange={e => setNote(e.target.value)}/>
              </Col>
            </Form.Group>
          </Card>

          <Row>
            <Col>
              {updateType === 'update' && <Button variant="danger" onClick={onClickDelete}>削除</Button>}
            </Col>
          </Row>

        </Container>
      </div>
      <Toast/>
    </>
  );
}

export default withRouter(DesignDtl);