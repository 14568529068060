import React, {useEffect, useRef, useState} from 'react';
import {Link, useHistory, withRouter} from "react-router-dom";
import Button from 'react-bootstrap/Button';
import {getAPI} from "../APIUtil";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import AppNav from "./AppNav";
import {Card, Col, Container, Form, InputGroup, Row} from "react-bootstrap";
import {
  addField,
  useDebounce,
  dateformat,
  dateformatJPNYYYYMMDD,
  parseDate,
  formatNumber,
  setFocusCalendar
} from "../CMUtil";
import MdDatatable, {InitialLoading, sortCaret} from "../MdDataTable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons";
import DatePicker, {registerLocale} from "react-datepicker"
import ja from "date-fns/locale/ja";
import AsyncSelect from "react-select/async";

registerLocale('ja', ja)

export const QuotList = () => {

  const history = useHistory();
  const iniSearch = useRef(true);

  const [loadingCss, setLoadingCss] = useState("loading_panel_init");

  const [loading, setLoading] = useState(true);
  const [locPage, setLocPage] = useState(null);
  const [locSizePerPage, setLocSizePerPage] = useState(50);
  const [locTotalSize, setLocTotalSize] = useState(null);
  const [locSortField, setLocSortField] = useState(null);
  const [locSortOrder, setLocSortOrder] = useState(null);

  const [constSelected, setConstSelected] = useState([]);
  const [constId, setConstId] = useState("");

  const [name, setName] = useState("");
  const debouncedName = useDebounce(name, 500, setLocPage);
  const [quotNo, setQuotNo] = useState("");
  const debouncedQuotNo = useDebounce(quotNo, 500, setLocPage);
  const [quotDateFrom, setQuotDateFrom] = useState("");
  const debouncedQuotDateFrom = useDebounce(quotDateFrom, 500, setLocPage);
  const [quotDateTo, setQuotDateTo] = useState("");
  const debouncedQuotDateTo = useDebounce(quotDateTo, 500, setLocPage);


  const [listData, setListData] = useState([]);

  const [prefSelected, setPrefSelected] = useState([]);

  // 項目定義
  const columns = [
    {
      dataField: "quot_no",
      text: '見積番号',
      classes: 'tbl-col link',
      sort: true,
      headerStyle: {width: '180px'},
      formatter: (cell, row) => (
        <div><a onClick={() => onClickLinkToDetail(row.quot_id)}>{cell}</a></div>
      )
    },
    {
      dataField: 'const.name',
      text: '施工業者',
      sort: true,
      classes: 'tbl-col',
      headerStyle: {width: '180px'},
      sortCaret: (order, column) => {
        console.log(order)
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'quot_date',
      text: '見積日',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '180px'},
      formatter: (cell, row) => (
        <span>{dateformatJPNYYYYMMDD(parseDate(cell))}</span>
      ),
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'quot_title',
      text: '件名',
      classes: 'tbl-col',
      sort: false,
      headerStyle: {width: '200px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'amount_total',
      text: '金額',
      classes: 'tbl-col ',
      sort: false,
      align: 'right',
      headerStyle: {width: '100px'},
      formatter: (cell, row) => (
        <span>{formatNumber(cell)}</span>
      ),
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
  ];

  // 初期化
  useEffect(() => {
    const init = async () => {
      setLocPage(1);
    }
    init();
  }, []);

  // 検索処理
  useEffect(() => {
    if (iniSearch.current) {
      iniSearch.current = false;
      return;
    }
    const update = async () => {

      setLoading(true);
      let sortField = locSortField;
      let sortOrder = locSortOrder;
      if (!sortField && !sortOrder) {
        //デフォルト　着工日降順
        sortField = null;
        sortOrder = null;
      }
      let condition = {};
      addField(condition, "page", locPage);
      addField(condition, "size_per_page", locSizePerPage);
      addField(condition, "sort_field", sortField);
      addField(condition, "sort_order", sortOrder);
      addField(condition, "quot_no", quotNo);
      addField(condition, "const_id", constId);
      addField(condition, "quot_title", name);
      addField(condition, "quot_date_from", dateformat(quotDateFrom));
      addField(condition, "quot_date_to", dateformat(quotDateTo));

      const res = await getAPI("tr_quot_search_view", condition);
      setListData(res);
      setLoading(false);
      setLoadingCss("loading_panel_fadeout");
    }
    update();
  }, [
    locPage,
    locSizePerPage,
    locSortField,
    locSortOrder,
    constId,
    debouncedQuotNo,
    debouncedName,
    debouncedQuotDateFrom,
    debouncedQuotDateTo
  ]);

  const onTableChange = (type, {page, sizePerPage, filters, sortField, sortOrder, cellEdit}) => {
    if ("pagination" === type) {
      setLocPage(page);
    }
    if ("sizePerPage" === type) {
      setLocSizePerPage(sizePerPage);
    }
    if ("sort" === type) {
      setLocSortField(sortField);
      setLocSortOrder(sortOrder);
    }
  }

  const onClickLinkToDetail = (quot_id) => {
    history.push("/quot/" + quot_id);
  }

  const handleChangeConst = (value) => {
    setConstSelected(value ? value : "");
    setConstId(value ? value.id : "");
  }

  const onSearchConst = async (query) => {
    let searchCondition = {
      name: query
    }
    const res = await getAPI("ms_const_typeahead_view", searchCondition);
    // const res = await getAPI("ms_supp_typeahead_view", searchCondition);
    let constOptions = [];
    res.results.map((result) => {
      constOptions.push({name: result.name, id: result.const_id});
    });
    return constOptions;
  }

  return (
    <>
      <AppNav/>
      <Row className="h1">
        <Col sm={8}>
          <h1>
            見積一覧
          </h1>
        </Col>
      </Row>

      <Card className="card-search">
        <Container className="container-main-search">
          <Form.Group as={Row}>
            <Form.Label column sm="1">得意先</Form.Label>
            <Col sm="4">
              <AsyncSelect
                isClearable
                className="async-select"
                cacheOptions
                defaultOptions
                value={constSelected}
                getOptionLabel={e => e.name}
                getOptionValue={e => e.id}
                loadOptions={onSearchConst}
                onChange={handleChangeConst}
                placeholder=""
              />
            </Col>
            <Col sm="1"></Col>
            <Form.Label column sm="1">見積番号</Form.Label>
            <Col sm="4">
              <Form.Control type="text" value={quotNo} onChange={e => setQuotNo(e.target.value)}/>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="1">件名</Form.Label>
            <Col sm="4">
              <Form.Control type="text" value={name} onChange={e => setName(e.target.value)}/>
            </Col>
            <Col sm="1"></Col>
            <Form.Label column sm="1">見積日</Form.Label>
            <Col sm="4" className="input-group">
              <DatePicker id="quotDateFrom" selected={quotDateFrom}
                          onChange={(date) => setQuotDateFrom(date)} dateFormat="yyyy年MM月dd日" locale="ja"/>
              <Button as={Link} variant="link" to={"/"} style={{paddingRight: "0"}}
                      onClick={e => setFocusCalendar(e, 'quotDateFrom')}>
                <FontAwesomeIcon icon={faCalendarAlt} size="2x"/>
              </Button>
              <InputGroup.Text style={{border: "0px", backgroundColor: "#ffffff"}}>～</InputGroup.Text>
              <DatePicker id="quotDateTo" selected={quotDateTo} onChange={(date) => setQuotDateTo(date)}
                          dateFormat="yyyy年MM月dd日" locale="ja"/>
              <Button as={Link} variant="link" to={"/"} style={{paddingRight: "0"}}
                      onClick={e => setFocusCalendar(e, 'quotDateTo')}>
                <FontAwesomeIcon icon={faCalendarAlt} size="2x"/>
              </Button>
            </Col>
          </Form.Group>
        </Container>
      </Card>

      <Container className="container-main-list">
        <InitialLoading loadingCss={loadingCss}/>
        <MdDatatable
          keyField="quot_id"
          listData={listData}
          columns={columns}
          loading={loading}
          onTableChange={onTableChange}
          page={locPage}
          sizePerPage={locSizePerPage}
          totalSize={locTotalSize}
          sortField={locSortField}
          sortOrder={locSortOrder}
          showPagenation={true}
          linkToAdd="/quot/"
        />
      </Container>
    </>
  );
}

export default withRouter(QuotList);