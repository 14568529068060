import React, {useEffect, useRef, useState} from 'react';
import {Link, useHistory, withRouter} from "react-router-dom";
import {Card, Col, Container, Form, Row} from "react-bootstrap";
import {deleteAPI, getAPI, getSpAPI, patchSpAPI, postAPI, putAPI} from "../APIUtil";
import Button from "react-bootstrap/Button";
import AppNav from "./AppNav";
import 'react-toastify/dist/ReactToastify.css';
import Toast, {success} from "../Toast";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt, faPen, faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker"
import AsyncSelect from "react-select/async";
import QuotPDF from '../doc/QuotPDF';
import {PDFDownloadLink} from '@react-pdf/renderer';
import {
  setFocusCalendar,
  toSingleByte,
  toNull,
  nvl,
  dateformatDB,
  parseDate,
  addField,
  formatNumber,
  parseNumber
} from "../CMUtil";
import {QuotDtlRow} from "./QuotDtlRow";

export const QuotDtl = ({match: {params: {quot_id}}}) => {

  const hist = useHistory();

  const [quotId, setQuotId] = useState("");
  const [quotNo, setQuotNo] = useState("");
  const [quotTitle, setQuotTitle] = useState("");
  const [constSelected, setConstSelected] = useState([]);
  const [constId, setConstId] = useState("");
  const [constName, setConstName] = useState("");
  const [quotDate, setQuotDate] = useState("");
  const [note, setNote] = useState("");
  const [subTotal, setSubTotal] = useState("");
  const [tax, setTax] = useState("");
  const [ttlAmount, setTtlAmount] = useState("");
  const [updateCnt, setUpdateCnt] = useState(0);

  const [dtlList, setDtlList] = useState([]);
  const dtlListRef = useRef([]);

  //初期化
  useEffect(() => {
    const update = async () => {

      let locDtlList = [];
      if (!quot_id) {

        let obj = await getAPI("next_no", {"type":"quot"});
        setQuotNo(obj.new_no);

        //INSERT
        for (let i = 0; i < 10; i++) {
          locDtlList.push({
            index: i,
            line_no: i + 1,
            id: null,
            item_type: null,
            item_id: null,
            qty: null,
            unit: null,
            price: null,
            amount: null
          });
        }

      } else {
        //UPDATE
        let obj = await getAPI("tr_quot/" + quot_id);
        setQuotId(quot_id);
        setQuotId(obj.quot_id);
        setQuotNo(obj.quot_no);
        setQuotTitle(nvl(obj.quot_title));
        if (obj.const) {
          setConstId(obj.const.const_id);
          setConstSelected([{const_id: obj.const.const_id, name: obj.const.name}]);
          setConstName(obj.const.name);
        }
        setQuotDate(nvl(parseDate(obj.quot_date)));
        setSubTotal(formatNumber(obj.sub_total));
        setTax(formatNumber(obj.tax));
        setTtlAmount(formatNumber(obj.ttl_amount));
        setNote(nvl(obj.note));

        let condition = {};
        addField(condition, "quot_id", quot_id);
        const res = await getAPI("tr_quot_dtl_search_view", condition);

        for (let i = 0; i < Math.max(10, res.results.length); i++) {
          locDtlList.push({
            id: i < res.results.length ? res.results[i].quot_dtl_id : null,
            index: i,
            line_no: i + 1,
            item_type: null,
            item_id: i < res.results.length ? res.results[i].item_id : null,
            item_name: i < res.results.length ? res.results[i].item_name : null,
            qty: i < res.results.length ? res.results[i].qty : null,
            unit: i < res.results.length ? res.results[i].unit : null,
            price: i < res.results.length ? res.results[i].price : null,
            amount: i < res.results.length ? res.results[i].amount : null
          });
        }
      }

      for (let i = 0; i < locDtlList.length; i++) {
        dtlListRef.current[i] = React.createRef();
      }
      setDtlList(locDtlList);
    }
    update();

  }, []);

  const onClickAddRow = () => {
    let locDtlList = JSON.parse(JSON.stringify(dtlList));
    locDtlList.push({
      index: locDtlList.length,
      line_no: locDtlList.length + 1,
      id: null,
      item_type: null,
      item_id: null,
      item_name: null,
      qty: null,
      unit: null,
      price: null,
      amount: null
    });
    for (let i = 0; i < locDtlList.length; i++) {
      dtlListRef.current[i] = React.createRef();
    }
    setDtlList(locDtlList);
  }

  const onClickInsert = async () => {

    let obj = newData();
    obj = await postAPI("tr_quot/", obj);

    let id = obj.quot_id;
    for (const dtl of dtlListRef.current) {
      let dtlObj = dtl.current.getStates();
      if (!dtlObj.item_name || !dtlObj.amount) {
        continue;
      }
      dtlObj = newDtlData(id, dtlObj);
      dtlObj = await postAPI("tr_quot_dtl/", dtlObj);
    }

    setQuotId(obj.quot_id);
    hist.push("/quot/" + obj.quot_id);
    setUpdateCnt(updateCnt + 1);
    success("登録完了");
  }

  const onClickUpdate = async () => {
    let obj = newData();
    await putAPI("tr_quot/" + quotId + "/", obj);

    let updateDtlIdList = [];
    for (const dtl of dtlListRef.current) {
      let dtlObj = dtl.current.getStates();
      if (!dtlObj.item_name || !dtlObj.amount) {
        continue;
      }
      dtlObj = newDtlData(quotId, dtlObj);
      if (!dtlObj.quot_dtl_id) {
        dtlObj = await postAPI("tr_quot_dtl/", dtlObj);
      } else {
        dtlObj = await putAPI("tr_quot_dtl/" + dtlObj.quot_dtl_id, dtlObj);
      }
      updateDtlIdList.push(dtlObj.quot_dtl_id);
    }

    let condition = {};
    addField(condition, "quot_id", quotId);
    let res = await getAPI("tr_quot_dtl_search_view", condition);
    for (const dbDtl of res.results) {
      if (!updateDtlIdList.includes(dbDtl.quot_dtl_id)) {
        await deleteAPI("tr_quot_dtl/" + dbDtl.quot_dtl_id + "/", obj);
      }
    }

    success("更新完了");
    setUpdateCnt(updateCnt + 1);
  }

  const onClickDelete = async () => {

    let condition = {};
    addField(condition, "quot_id", quotId);
    res = await getAPI("tr_quot_dtl_search_view", condition);

    let dtlList = res.results;
    for (const dtl of dtlList) {
      await deleteAPI("tr_quot_dtl/" + dtl.quot_dtl_id + "/", {'quot_dtl_id': dtl.quot_dtl_id});
    }

    let res = await deleteAPI("tr_quot/" + quotId + "/", {"quot_id": quotId});
    success("削除完了");
    hist.push("/quot_list/");
  }

  const newData = () => {
    return {
      quot_id: toNull(quotId),
      quot_no: toNull(quotNo),
      quot_title: toNull(quotTitle),
      quot_date: toNull(dateformatDB(quotDate)),
      sub_total: toNull(parseNumber(subTotal)),
      tax: toNull(parseNumber(tax)),
      ttl_amount: toNull(parseNumber(ttlAmount)),
      const_id: toNull(constId),
      const_name: toNull(constName),
      note: toNull(note),
      create_pg: 'QuotDtl',
      update_pg: 'QuotDtl',
    };
  }

  const newDtlData = (quotId, data) => {
    return {
      quot_dtl_id: toNull(data.quot_dtl_id),
      quot_id: toNull(quotId),
      item_id: toNull(data.item_id),
      item_name: toNull(data.item_name),
      line_no: toNull(data.line_no),
      qty: toNull(parseNumber(data.qty)),
      unit: toNull(data.unit),
      price: toNull(parseNumber(data.price)),
      amount: toNull(parseNumber(data.amount)),
      create_pg: 'QuotDtl',
      update_pg: 'QuotDtl',
    };
  }

  const handleChangeConst = (value) => {
    setConstSelected(value ? value : "");
    setConstId(value ? value.id : "");
    setConstName(value ? value.name : "")
  }

  const onSearchConst = async (query) => {
    let searchCondition = {
      name: query
    }
    const res = await getAPI("ms_const_typeahead_view", searchCondition);
    let constOptions = [];
    res.results.map((result) => {
      constOptions.push({name: result.name, id: result.const_id});
    });
    return constOptions;
  }

  const totalCalcHandler = () => {
    let subTotal = 0;
    for (const row of dtlListRef.current) {
      let locAmount = parseNumber(row.current.getStates().amount);
      if (locAmount) {
        subTotal += locAmount;
      }
    }
    setSubTotal(formatNumber(subTotal));
    setTax(formatNumber(subTotal * 0.1));
    setTtlAmount(formatNumber(subTotal + (subTotal * 0.1)));
  }

  const deleteRowHandler = (rowIndex) => {
    let currIndex = 0;
    let newIndex = 0;
    for (currIndex = 0; currIndex < dtlListRef.current.length; currIndex++) {
      if (currIndex === rowIndex) {
        continue;
      }

      let currData = dtlListRef.current[currIndex].current.getStates();
      dtlListRef.current[newIndex].current.setData({
        index: newIndex,
        line_no: newIndex + 1,
        id: currData.quot_dtl_id,
        item_type: currData.item_type,
        item_id: currData.item_id,
        item_name: currData.item_name,
        qty: currData.qty,
        unit: currData.unit,
        price: currData.price,
        amount: currData.amount
      });
      newIndex++;
    }
    dtlListRef.current[dtlListRef.current.length - 1].current.setData({
      index: dtlListRef.current.length - 1,
      line_no: dtlListRef.current.length,
      id: null,
      item_type: null,
      item_id: null,
      item_name: null,
      qty: null,
      unit: null,
      price: null,
      amount: null
    });

    let locDtlList = JSON.parse(JSON.stringify(dtlList));
    if (locDtlList.length > 10) {
      locDtlList.splice(locDtlList.length - 1, 1);
      dtlListRef.current.splice(dtlListRef.current.length - 1, 1);
    }
    for (let i = 0; i < locDtlList.length; i++) {
      dtlListRef.current[i] = React.createRef();
    }
    setDtlList(locDtlList);

  }

  return (
    <>
      <div className="body-dtl body-dtl-const">
        <AppNav/>
        <div className="h1">
          <h1 className="float-left align-items-end">
            見積詳細
          </h1>
          <div className="float-none" style={{height: "30px"}}>&nbsp;</div>
        </div>

        <Container className="container-main-detail">

          <Row className="h3">
            <Col>
            </Col>
            <Col sm="1" className="text-right">
              {quotId &&
                <PDFDownloadLink document={<QuotPDF quot_id={quotId} updateCnt={updateCnt}/>}
                                 fileName={quotNo + "_" + quotTitle + ".pdf"} className=".btn .btn-secondary">
                  {({blob, url, loading, error}) => (loading ? <Button disabled variant="secondary">印刷</Button> :
                    <Button variant="secondary">印刷</Button>)}
                </PDFDownloadLink>
              }
            </Col>
            <Col sm="1" className="text-right">
              {quotId &&
                <Button variant="secondary" as={Link} to={"/co/?quot_id=" + quotId}>受注作成</Button>
              }
            </Col>
            <Col sm="1" className="text-right">
              {!quotId &&
                <Button variant="primary" onClick={onClickInsert}><FontAwesomeIcon icon={faPen} size="lg"/>登録</Button>}
              {quotId &&
                <Button variant="primary" onClick={onClickUpdate}><FontAwesomeIcon icon={faPen} size="lg"/>登録</Button>}
            </Col>
          </Row>

          <Card className="card-secondary w-80 mx-auto mb-0">
            <Form.Group as={Row}>
              <Form.Label column sm="1">施工業者名</Form.Label>
              <Col sm="4">
                <AsyncSelect
                  isClearable
                  className="async-select"
                  cacheOptions
                  defaultOptions
                  value={constSelected}
                  getOptionLabel={e => e.name}
                  getOptionValue={e => e.id}
                  loadOptions={onSearchConst}
                  onChange={handleChangeConst}
                  placeholder=""
                />
              </Col>
              <Col sm="1"></Col>
              <Form.Label column sm="1">見積日</Form.Label>
              <Col sm="4" className="input-group">
                <DatePicker id="quotDate" selected={quotDate} onChange={(date) => setQuotDate(date)}
                            dateFormat="yyyy年MM月dd日" locale="ja"/>
                <Button as={Link} variant="link" to={"/"} style={{paddingRight: "0"}}
                        onClick={e => setFocusCalendar(e, 'quotDate')}>
                  <FontAwesomeIcon icon={faCalendarAlt} size="2x"/>
                </Button>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="1">件名</Form.Label>
              <Col sm="4">
                <Form.Control
                  id="quotTitle"
                  type="text"
                  value={quotTitle}
                  maxLength={80}
                  onChange={e => setQuotTitle(e.target.value)}
                />
              </Col>
              <Col sm="1"></Col>
              <Form.Label column sm="1">見積番号</Form.Label>
              <Col sm="4">
                <Form.Control
                  id="quotNo"
                  type="text"
                  value={quotNo}
                  maxLength={20}
                  onChange={e => setQuotNo(e.target.value)}
                  onBlur={e => setQuotNo(toSingleByte(e.target.value))}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="1"></Form.Label>
              <Col sm="4">
              </Col>
              <Col sm="1"></Col>
              <Form.Label column sm="1">小計</Form.Label>
              <Col sm="2" className="text-right">
                <Form.Label column sm="10">{subTotal} 円</Form.Label>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="1"></Form.Label>
              <Col sm="4">
              </Col>
              <Col sm="1"></Col>
              <Form.Label column sm="1">消費税</Form.Label>
              <Col sm="2" className="text-right">
                <Form.Label column sm="10">{tax} 円</Form.Label>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="1"></Form.Label>
              <Col sm="4">
              </Col>
              <Col sm="1"></Col>
              <Form.Label column sm="1">合計金額</Form.Label>
              <Col sm="2" className="text-right">
                <Form.Label column sm="10">{ttlAmount} 円</Form.Label>
              </Col>
            </Form.Group>

            <Container className="container-main-list">
              <Row className="dtl_list_header">
                <Col sm="5" className="dtl-head-col">詳細</Col>
                <Col sm="1" className="dtl-head-col">数量</Col>
                <Col sm="1" className="dtl-head-col">単位</Col>
                <Col sm="2" className="dtl-head-col">単価</Col>
                <Col sm="2" className="dtl-head-col">金額</Col>
                <Col sm="1"></Col>
              </Row>
              {dtlList.map((dtl, index) => (
                <QuotDtlRow key={index}
                            ref={dtlListRef.current[index]}
                            index={index}
                            dtl={dtl}
                            totalCalcHandler={totalCalcHandler}
                            deleteRowHandler={deleteRowHandler}
                            isLastRow={dtlList.length === index + 1}
                />
              ))}
            </Container>
            <Row>
              <Col sm="9" className="justify-content-md-center">
              </Col>
              <Col sm="3" className="text-right">
                <Button onClick={onClickAddRow}>
                  <FontAwesomeIcon icon={faPlusCircle} size="lg"/>
                  行追加
                </Button>
              </Col>
            </Row>
            <Form.Group as={Row}>
              <Col>
                <Form.Control as="textarea" rows={10} value={note} onChange={e => setNote(e.target.value)}/>
              </Col>
            </Form.Group>
          </Card>
          <Row>
            <Col>
              {quotId && <Button variant="danger" onClick={onClickDelete}>削除</Button>}
            </Col>
          </Row>
        </Container>

      </div>
      <Toast/>
    </>
  );
}

export default withRouter(QuotDtl);