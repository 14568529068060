import React, {useEffect, useState} from 'react';
import {getAPI} from "../APIUtil";
import {Document, Page, Text, View, StyleSheet, Font} from '@react-pdf/renderer';
import {formatNumber, parseDate, dateformatJPNYYYYMMDD} from "../CMUtil";
import PoDtlPDF from "./PoDtlPDF";

Font.register({
  family: "jpFont",
  fonts: [
    {src: '/static/font/NotoSansJP-Regular.otf'},
    {src: '/static/font/NotoSansJP-Bold.otf'},
  ]
});

// Create styles
const styles = StyleSheet.create({
  page: {
    fontFamily: "jpFont",
    padding: 40
  },
  header: {
    paddingBottom: 40,
    fontSize: 12
  },
  table: {
    display: "table",
    width: "auto",
    fontSize: 10,
    paddingBottom: 10
  },
  tableNotes: {
    display: "table",
    width: "auto",
    fontSize: 12,
    borderStyle: "solid",
    borderTopWidth: 1,
    marginRight: 10,
    marginLeft: 10,
    marginTop: 50
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row"
  },
  tableCol1: {
    width: "100%"
  },
  tableCol2: {
    width: "50%"
  },
  tableCol5Total: {
    width: "20%",
    borderStyle: "solid",
    borderBottomWidth: 1
  },
  tableCol5: {
    width: "20%"
  },
  tableCell: {
    margin: "auto",
    marginTop: 5
  },
  label: {
    textAlign: "left",
    paddingLeft: 50
  },
  label2: {
    textAlign: "left",
    paddingLeft: 60
  },
  textR: {
    textAlign: "right"
  },
  textL: {
    textAlign: "left"
  },
  labelCell: {
    width: "25%"
  },
  headerBGR: {
    backgroundColor: "black",
    color: "white",
    fontWeight: 'bold',
    textAlign: "right"
  },
  headerBG: {
    backgroundColor: "black",
    color: "white",
    fontWeight: 'bold',
    textAlign: "center"
  },
  underline: {
    textDecoration: "underline"
  },
  tableDtlCol1: {
    width: "55%"
  },
  tableDtlCol2: {
    width: "10%"
  },
  tableDtlCol3: {
    width: "5%"
  },
  tableDtlCol4: {
    width: "15%"
  },
});

export const PoPDF = ({po_id, updateCnt}) => {

  const [poDate, setPoDate] = useState("");
  const [subTotal, setSubTotal] = useState("");
  const [tax, setTax] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [poObj, setPoObj] = useState({});
  const [comObj, setComObj] = useState({});
  // const [constObj, setConstObj] = useState({});
  const [suppObj, setSuppObj] = useState({});
  const [poDtlObj, setPoDtlObj] = useState([]);

  //初期化
  useEffect(() => {
    const load = async () => {

      let res = await getAPI("current_login_user");
      let userObj = res.results[0];
      let comObj = await getAPI("ms_com/" + userObj.com.com_id);
      const poObj = await getAPI("tr_po/" + po_id);
      setPoDate(dateformatJPNYYYYMMDD((parseDate(poObj.po_date))));
      setSubTotal(formatNumber(poObj.sub_amount));
      setTax(formatNumber(poObj.tax));
      setTotalAmount(formatNumber(poObj.ttl_amount));
      setPoObj(poObj);
      setComObj(comObj);
      setSuppObj(poObj.supp);
    }
    load();

  }, [updateCnt]);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell, styles.header}>資材発注管理書</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}></Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell, styles.textL}>{suppObj.name}</Text>
            </View>
            <View style={styles.labelCell}>
              <Text style={styles.tableCell, styles.label}>発注日：</Text>
            </View>
            <View style={styles.labelCell}>
              <Text style={styles.tableCell, styles.textR}>{poDate}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}></Text>
            </View>
            <View style={styles.labelCell}>
              <Text style={styles.tableCell, styles.label}>発注番号：</Text>
            </View>
            <View style={styles.labelCell}>
              <Text style={styles.tableCell, styles.textR}>{poObj.po_no}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell, styles.textL}>〒 {suppObj.zip}</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}></Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell, styles.textL}>{suppObj.pref} {suppObj.city}</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell, styles.label}>{comObj.com_name}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell, styles.textL}>{suppObj.address}</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell, styles.label2}>〒 {comObj.zip}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell, styles.textL}></Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell, styles.label2}>{comObj.pref} {comObj.city}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell, styles.textL, styles.underline}>件名： {poObj.po_title}</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell, styles.label2}>{comObj.address}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}> </Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}> </Text>
            </View>
          </View>
        </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol1}>
              <Text style={styles.tableCell, styles.textL}>下記の通り発注致します。</Text>
            </View>
          </View>
        </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol5}>
              <Text style={styles.tableCell, styles.headerBGR}>小計</Text>
            </View>
            <View style={styles.tableCol5}>
              <Text style={styles.tableCell, styles.headerBGR}>消費税</Text>
            </View>
            <View style={styles.tableCol5}>
              <Text style={styles.tableCell, styles.headerBGR}>合計金額</Text>
            </View>
            <View style={styles.tableCol5}>
              <Text style={styles.tableCell, styles.textR}> </Text>
            </View>
            <View style={styles.tableCol5}>
              <Text style={styles.tableCell, styles.textR}> </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol5Total}>
              <Text style={styles.tableCell, styles.textR}>{subTotal} 円</Text>
            </View>
            <View style={styles.tableCol5Total}>
              <Text style={styles.tableCell, styles.textR}>{tax} 円</Text>
            </View>
            <View style={styles.tableCol5Total}>
              <Text style={styles.tableCell, styles.textR}>{totalAmount} 円</Text>
            </View>
            <View style={styles.tableCol5}>
              <Text style={styles.tableCell, styles.textR}> </Text>
            </View>
            <View style={styles.tableCol5}>
              <Text style={styles.tableCell, styles.textR}> </Text>
            </View>
          </View>
        </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableDtlCol1}>
              <Text style={styles.tableCell, styles.headerBG}>詳細</Text>
            </View>
            <View style={styles.tableDtlCol2}>
              <Text style={styles.tableCell, styles.headerBG}>数量</Text>
            </View>
            <View style={styles.tableDtlCol3}>
              <Text style={styles.tableCell, styles.headerBG}>単位</Text>
            </View>
            <View style={styles.tableDtlCol4}>
              <Text style={styles.tableCell, styles.headerBG}>単価</Text>
            </View>
            <View style={styles.tableDtlCol4}>
              <Text style={styles.tableCell, styles.headerBG}>金額</Text>
            </View>
          </View>
          <PoDtlPDF po_id={po_id} updateCnt={updateCnt}/>
        </View>
        <View style={styles.tableNotes}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol1}>
              <Text style={styles.tableCell, styles.textL}>{poObj.note}</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )

};

export default PoPDF;