import React, {useEffect, useRef, useState} from 'react';
import {useHistory, withRouter} from "react-router-dom";
import {getAPI} from "../APIUtil";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import AppNav from "./AppNav";
import {Card, Col, Container, Form, Row} from "react-bootstrap";
import {addField, formatNumber, useDebounce} from "../CMUtil";
import MdDatatable, {InitialLoading, sortCaret} from "../MdDataTable";

export const StkList = () => {

  const history = useHistory();
  const iniSearch = useRef(true);

  const [loadingCss, setLoadingCss] = useState("loading_panel_init");

  const [loading, setLoading] = useState(true);
  const [locPage, setLocPage] = useState(null);
  const [locSizePerPage, setLocSizePerPage] = useState(null);
  const [locTotalSize, setLocTotalSize] = useState(null);
  const [locSortField, setLocSortField] = useState(null);
  const [locSortOrder, setLocSortOrder] = useState(null);

  const [stkId, setStkId] = useState("");
  const debouncedStkId = useDebounce(stkId, 500, setLocPage);
  const [item_name, setItemName] = useState("");
  const debouncedItemName = useDebounce(item_name, 500, setLocPage);
  const [qty, setQty] = useState("");
  const debouncedQty = useDebounce(qty, 500, setLocPage);
  const [col_no, setColNo] = useState(0);
  const debouncedColNo = useDebounce(qty, 500, setLocPage);

  const [listData, setListData] = useState([]);

  // 項目定義
  const columns = [
    {
      dataField: 'stk_id',
      text: 'ID',
      hidden: true,
      sort: false,
      headerStyle: {width: '80px'}
    },
    {
      dataField: 'item_name',
      text: '資材',
      classes: 'tbl-col link',
      sort: true,
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      },
      formatter: (cell, row) => (
        <div><a onClick={() => onClickLinkToDetail(row.item_id)}>{cell}</a></div>
      )
    },
    {
      dataField: 'qty',
      text: '在庫数',
      classes: 'tbl-col',
      align: 'right',
      headerStyle: {width: '100px'},
      formatter: (cell, row) => (
        <span>{formatNumber(cell)}</span>
      ),
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
  ];

  // 初期化
  useEffect(() => {

    if (iniSearch.current) {
      iniSearch.current = false;
      return;
    }

    const update = async () => {
      setLoading(true);
      let condition = {};
      addField(condition, "page", locPage);
      addField(condition, "size_per_page", locSizePerPage);
      addField(condition, "sort_field", locSortField);
      addField(condition, "sort_order", locSortOrder);
      addField(condition, "item_name", item_name);
      addField(condition, "qty", qty);
      const res = await getAPI("ms_stk_search_view", condition);

      setListData(res);
      setLoading(false);
      setLoadingCss("loading_panel_fadeout");
    }
    update();
  }, [
    locPage,
    locSizePerPage,
    locSortField,
    locSortOrder,
    debouncedStkId,
    debouncedItemName,
    debouncedQty,
    debouncedColNo
  ]);

  const onTableChange = (type, {page, sizePerPage, filters, sortField, sortOrder, cellEdit}) => {
    if ("pagination" === type) {
      setLocPage(page);
    }
    if ("sizePerPage" === type) {
      setLocSizePerPage(sizePerPage);
    }
    if ("sort" === type) {
      setLocSortField(sortField);
      setLocSortOrder(sortOrder);
    }
  }

  // const onClickLinkToDetail = (itemId) = {
  //   history.
  //   // history.push("/co/" + itemId);
  // }

  const onClickLinkToDetail = (itemId) => {
    history.push("/stk_in_out_list/" + itemId);
  }

  return (
    <>
      <AppNav/>
      <Row className="h1">
        <Col sm={8}>
          <h1>
            資材在庫表
          </h1>
        </Col>
      </Row>

      <Card className="card-search">
        <Container className="container-main-search">
          <Form.Group as={Row}>
            <Form.Label column sm="1">材料名</Form.Label>
            <Col sm="5">
              <Form.Control type="text" value={item_name} onChange={e => setItemName(e.target.value)}/>
            </Col>
          </Form.Group>
        </Container>
      </Card>

      <Container className="container-main-list">
        <InitialLoading loadingCss={loadingCss}/>
        <MdDatatable
          keyField="stk_id"
          listData={listData}
          columns={columns}
          loading={loading}
          onTableChange={onTableChange}
          page={locPage}
          sizePerPage={locSizePerPage}
          totalSize={locTotalSize}
          sortField={locSortField}
          sortOrder={locSortOrder}
          showPagenation={true}
        />
      </Container>
    </>
  );
}

export default withRouter(StkList);