import React, {useEffect, useRef, useState} from 'react';
import {withRouter} from "react-router";
import AppNav from "./AppNav";
import 'react-toastify/dist/ReactToastify.css';
import Toast, {success} from "../Toast";
import {Card, Col, Container, Row} from "react-bootstrap";
import {dateformatDB, dateformatJPNByType, dateformatYYYYMMDD, parseDate} from "../CMUtil";
import {useHistory} from "react-router-dom";
import {getAPI, getSpAPI} from "../APIUtil";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faAngleRight, faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";

export const CalWeek = ({match: {params: {p_opn_date}}}) => {

  const history = useHistory();
  const iniMonth = useRef(true);
  const iniDisp = useRef(true);

  const [comId, setComId] = useState("");
  const [opnDateFrom, setOpnDateFrom] = useState(new Date());
  const [opnDateTo, setOpnDateTo] = useState(new Date());
  const [count, setCount] = useState(0);

  const [dateList, setDateList] = useState([]);
  const [bookList, setBookList] = useState([]);
  const [opnUserList, setOpnUserList] = useState([]);
  const [scheduleList, setScheduleList] = useState([]);

  //INITIALIZE
  useEffect(() => {
    const update = async () => {

      let obj = await getSpAPI("current_login_user");
      setComId(obj.results[0].com.com_id);

      let locOpnDateFrom = new Date();
      if (p_opn_date) {
        locOpnDateFrom = parseDate(p_opn_date);
      }
      setOpnDateFrom(locOpnDateFrom);
    }
    update();

  }, []);

  useEffect(() => {
    if (iniMonth.current) {
      iniMonth.current = false;
      return;
    }

    const update = async () => {

      let locOpnDateFrom = new Date(opnDateFrom.getTime());
      let locOpnDateTo = new Date(locOpnDateFrom.getTime());
      locOpnDateTo.setDate(locOpnDateTo.getDate() + 6);
      setOpnDateTo(locOpnDateTo);

      let res = null;
      let searchCondition = null;
      let locScheduleList = [];
      let locInvMap = {};

      //SET DATE LIST.
      let locDateList = [];
      let currentDate = new Date(locOpnDateFrom.getTime());

      while (currentDate.getTime() <= locOpnDateTo.getTime()) {
        locDateList.push(new Date(currentDate.getTime()));
        currentDate.setDate(currentDate.getDate() + 1);
      }
      setDateList(locDateList);

      searchCondition = {
        opn_date_from: dateformatDB(locOpnDateFrom),
        opn_date_to: dateformatDB(locOpnDateTo),
        sort_field: 'book_id, opn_date, opn_user_id'
      }
      res = await getAPI("tr_report_search_view", searchCondition);
      setScheduleList(res.results);

      // SET BOOK LIST
      let locBookList = [];
      res.results.map((report, index) => {
        let exBookList = locBookList.filter(function (book) {
          return book.book_id == report.book.book_id
        });
        if (exBookList.length == 0) {
          locBookList.push({
            book_id: report.book.book_id,
            book_name: report.book.book_name
          });
        }
      });
      setBookList(locBookList);

      // SET OPN USER LIST.
      searchCondition = {
        dept_id: comId + "DEPT_CONST"
      };
      res = await getAPI("ms_user_search_view", searchCondition);
      setOpnUserList(res.results);


    }
    update();

  }, [opnDateFrom, count]);

  const onClickPrev = (days) => {
    let locOpnDateFrom = new Date(opnDateFrom.getTime());
    locOpnDateFrom.setDate(locOpnDateFrom.getDate() - days);
    setOpnDateFrom(locOpnDateFrom);
  }

  const onClickNext = (days) => {
    let locOpnDateFrom = new Date(opnDateFrom.getTime());
    locOpnDateFrom.setDate(locOpnDateFrom.getDate() + days);
    setOpnDateFrom(locOpnDateFrom);
  }

  const OpnUserBook = (props) => {

    let opnUserScheduleList = props.scheduleList.filter((schedule) =>
      schedule.opn_user.user_id === props.opnUser.user_id && dateformatDB(props.opnDate) === schedule.opn_date
    );

    return (
      <Col className={
        dateformatJPNByType(props.opnDate, 'dateOfWeek') === "土" ? "date-of-week-saturday" : "" +
        dateformatJPNByType(props.opnDate, 'dateOfWeek') === "日" ? "date-of-week-sunday" : ""
      }>
        {opnUserScheduleList.map((schedule, index) => (
          <div key={"book_" + props.opnUser.user_id + "_" + dateformatYYYYMMDD(props.opnDate) + "_" + index}
               className="cal_week_book"
               style={{marginBottom: "0.2rem"}}
          >
            {schedule.book.book_name}
          </div>
        ))}
      </Col>
    )
  }

  return (
    <>
      <div className="body-dtl body-dtl-const">
        <AppNav/>
        <div className="h1">
          <h1 className="float-left align-items-end">
            作業者別予定表
          </h1>
          <div className="float-none" style={{height: "30px"}}>&nbsp;</div>
        </div>

        <Container className="container-main-detail pb-2">
          <Card className="card-secondary w-80 mx-auto mb-0">
            <Row>
              <Col sm="2"></Col>
              <Col sm="1" className="text-center">
                <Button variant="link" style={{paddingRight: "0"}} onClick={e => onClickPrev(7)}>
                  <FontAwesomeIcon icon={faChevronLeft} size="2x" style={{marginRight: "0.2rem"}}/>前週
                </Button>
              </Col>
              <Col sm="1" className="text-center">
                <Button variant="link" style={{paddingRight: "0"}} onClick={e => onClickPrev(1)}>
                  <FontAwesomeIcon icon={faChevronLeft} size="2x" style={{marginRight: "0.2rem"}}/>前日
                </Button>
              </Col>
              <Col sm="4" className="text-center" style={{fontWeight: "700"}}>
                <span style={{marginRight: "1rem"}}>
                {dateformatJPNByType(opnDateFrom, 'year')}年
                </span>
                <span style={{fontSize: "1.2rem"}}>
                {dateformatJPNByType(opnDateFrom, 'month')}
                </span>
                月&nbsp;
                <span style={{fontSize: "1.2rem"}}>
                {dateformatJPNByType(opnDateFrom, 'date')}
                </span>
                日（{dateformatJPNByType(opnDateFrom, 'dateOfWeek')}）
                <span style={{marginRight: "0.4rem"}}>～</span>
                <span style={{fontSize: "1.2rem"}}>
                {dateformatJPNByType(opnDateTo, 'month')}
                </span>
                月&nbsp;
                <span style={{fontSize: "1.2rem"}}>
                {dateformatJPNByType(opnDateTo, 'date')}
                </span>
                日（{dateformatJPNByType(opnDateTo, 'dateOfWeek')}）
              </Col>
              <Col sm="1" className="text-center">
                <Button variant="link" style={{paddingRight: "0"}} onClick={e => onClickNext(1)}>
                  次日<FontAwesomeIcon icon={faChevronRight} size="2x" style={{marginLeft: "0.2rem"}}/>
                </Button>
              </Col>
              <Col sm="1" className="text-center">
                <Button variant="link" style={{paddingRight: "0"}} onClick={e => onClickNext(7)}>
                  次週<FontAwesomeIcon icon={faChevronRight} size="2x" style={{marginLeft: "0.2rem"}}/>
                </Button>
              </Col>
              <Col sm="2"></Col>
            </Row>
          </Card>
        </Container>

        <Container className="container-cal-week">
          <Row className="head">
            <Col>
              作業者
            </Col>
            {dateList.map((opnDate, index) => (
              <Col key={index}>
                {dateformatJPNByType(opnDate, 'date')}日
                ({dateformatJPNByType(opnDate, 'dateOfWeek')})
              </Col>
            ))}
          </Row>

          <div className="body">
            {opnUserList.map((opnUser, index) => (
              <Row key={"row_" + opnUser.user_id}
                   className={opnUserList.length === index + 1 ? "dtl-data-col-bottom" : ""}>
                <Col
                  style={{minHeight: "5rem", borderLeft: "solid 1px #ced4da"}}>
                  {opnUser.last_name} {opnUser.first_name}
                </Col>
                {dateList.map((opnDate, opnDateIdx) => (
                  <OpnUserBook
                    key={"col_" + opnUser.user_id + dateformatYYYYMMDD(opnDate)}
                    opnUser={opnUser}
                    opnDate={opnDate}
                    scheduleList={scheduleList}/>
                ))}
              </Row>
            ))}
          </div>

        </Container>
      </div>

      <Toast/>
    </>
  );
}

export default withRouter(CalWeek);