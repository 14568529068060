import React, {useEffect, useImperativeHandle, useState} from "react";
import {Col, Form, Row} from "react-bootstrap";
import {
  formatNumber,
  nvl, onFocusSelect, onSearchItemByType, onSearchUnit,
  parseNumber,
} from "../CMUtil";
import {getAPI} from "../APIUtil";

export const ChDtlRowPo = React.forwardRef((props, ref) => {

  const [index, setIndex] = useState(0);

  const [chDtlId, setChDtlId] = useState("");
  const [coDtlId, setCoDtlId] = useState("");
  const [chId, setChId] = useState("");
  const [lineNo, setLineNo] = useState(0);
  const [itemId, setItemId] = useState("");
  const [itemName, setItemName] = useState("");
  const [poQty, setPoQty] = useState("");
  const [unit, setUnit] = useState("");
  const [price, setPrice] = useState("");
  const [amount, setAmount] = useState("");

  const [itemNameSuggestionDisplay, setItemNameSuggestionDisplay] = useState("none");
  const [itemNameSuggestionList, setItemNameSuggestionList] = useState([]);
  const [mouseOverItemNameSuggestion, setMouseOverItemNameSuggestion] = useState(false);

  const [unitSuggestionDisplay, setUnitSuggestionDisplay] = useState("none");
  const [unitSuggestionList, setUnitSuggestionList] = useState([]);
  const [mouseOverUnitSuggestion, setMouseOverUnitSuggestion] = useState(false);

  useEffect(() => {

    const update = async () => {
      let locDtl = props.dtl;
      setChDtlId(nvl(locDtl.ch_dtl_id));
      setChId(nvl(locDtl.ch_id));
      setLineNo(locDtl.line_no);
      setCoDtlId(nvl(locDtl.co_dtl_id));
      setIndex(props.index);
      setItemId(nvl(locDtl.item_id));
      setItemName(nvl(locDtl.item_name));
      setPoQty(formatNumber(nvl(locDtl.po_qty)));
      setUnit(nvl(locDtl.unit));
      setPrice(formatNumber(nvl(locDtl.price)));
      setAmount(formatNumber(nvl(locDtl.amount)));

    }
    update();
  }, []);

  useEffect(() => {
    let locPoQty = poQty ? parseNumber(poQty) : 0;
    let locPrice = price ? parseNumber(price) : 0;
    let locAmount = locPoQty * locPrice;
    setAmount(locAmount ? formatNumber(locAmount) : "");

  }, [poQty, price]);

  useImperativeHandle(ref, () => ({
    getStates: () => {
      return {
        line_no: lineNo,
        co_dtl_id: coDtlId,
        index: index,
        item_id: itemId,
        item_name: itemName,
        po_qty: poQty,
        unit: unit,
        price: price,
        amount: amount
      };
    },
    setData: (data) => {
      setChDtlId(nvl(data.ch_dtl_id));
      setChId(nvl(data.ch_id));
      setLineNo(nvl(data.line_no));
      setCoDtlId(nvl(data.co_dtl_id));
      setIndex(nvl(data.index))
      setItemId(nvl(data.item_id));
      setItemName(nvl(data.item_name));
      setPoQty(formatNumber(nvl(data.po_qty)));
      setUnit(nvl(data.unit));
      setPrice(formatNumber(nvl(data.price)));
      setAmount(formatNumber(nvl(data.amount)));
    }
  }), [
    chDtlId,
    chId,
    lineNo,
    index,
    itemId,
    itemName,
    poQty,
    unit,
    price,
    amount]);


  const onFocusItemName = async (e) => {
    let locItemList = await onSearchItemByType(itemName, null,10);
    setItemNameSuggestionList(locItemList);
    setItemNameSuggestionDisplay("block");
    onFocusSelect(e);
  }
  const onMouseOverItemNameSuggestion = (e) => {
    setMouseOverItemNameSuggestion(true);
  }
  const onMouseOutItemNameSuggestion = (e) => {
    setMouseOverItemNameSuggestion(false);
  }
  const onBlurItemNameSuggestion = (e) => {
    if (!mouseOverItemNameSuggestion) {
      setItemNameSuggestionDisplay("none");
    }
  }
  const selectItemName = async (itemId) => {
    let item = await getAPI("ms_item/" + itemId);
    if (item) {
      setItemId(nvl(item.item_id));
      setItemName(nvl(item.item_name));
      setUnit(nvl(item.unit));
      setPrice(formatNumber(nvl(item.cost)));
    }
    setItemNameSuggestionDisplay("none");
  }

  const onFocusUnit = async (e) => {
    let locUnitList = await onSearchUnit("");
    setUnitSuggestionList(locUnitList);
    setUnitSuggestionDisplay("block");
    onFocusSelect(e);
  }
  const onMouseOverUnitSuggestion = (e) => {
    setMouseOverUnitSuggestion(true);
  }
  const onMouseOutUnitSuggestion = (e) => {
    setMouseOverUnitSuggestion(false);
  }
  const onBlurUnitSuggestion = (e) => {
    if (!mouseOverUnitSuggestion) {
      setUnitSuggestionDisplay("none");
    }
  }
  const selectUnit = async (unit) => {
    setUnit(unit);
    setUnitSuggestionDisplay("none");
  }

  return (
    <Row>
      <Col sm="6"
           className={"dtl-data-col dtl-data-col-left " + (props.isLastRow ? "dtl-data-col-bottom" : "")}
           style={{position: "relative"}}>
        <Form.Control
          id="itemName"
          type="text"
          value={itemName}
          readOnly={coDtlId}
          onFocus={e => onFocusItemName(e)}
          onBlur={e => onBlurItemNameSuggestion(e)}
          onChange={e => setItemName(e.target.value)}
          placeholder=""
        />
        <div
          className="suggestion"
          style={{display: itemNameSuggestionDisplay}}
          onMouseOver={(e) => onMouseOverItemNameSuggestion(e)}
          onMouseOut={(e) => onMouseOutItemNameSuggestion(e)}
        >
          {itemNameSuggestionList.map((obj, index) => (
            <div key={obj.id}
                 className="suggestion-option"
                 onClick={e => selectItemName(obj.id)}
            >
              {obj.name}
            </div>
          ))}
        </div>
      </Col>
      <Col sm="1" className={"dtl-data-col " + (props.isLastRow ? "dtl-data-col-bottom" : "")}>
        <Form.Control
          id="poQty"
          type="tel"
          className="text-right"
          value={poQty}
          maxLength={4}
          readOnly={coDtlId}
          onFocus={e => onFocusSelect(e)}
          onChange={e => setPoQty(e.target.value)}
          onBlur={e => setPoQty(formatNumber(parseNumber(e.target.value)))}
          placeholder=""
        />
      </Col>
      <Col sm="1" className={"dtl-data-col " + (props.isLastRow ? "dtl-data-col-bottom" : "")}>
        <Form.Control
          id="unit"
          type="text"
          value={unit}
          readOnly={coDtlId}
          onFocus={e => onFocusUnit(e)}
          onBlur={e => onBlurUnitSuggestion(e)}
          onChange={e => setUnit(e.target.value)}
          placeholder=""
        />
        <div
          className="suggestion"
          style={{display: unitSuggestionDisplay}}
          onMouseOver={(e) => onMouseOverUnitSuggestion(e)}
          onMouseOut={(e) => onMouseOutUnitSuggestion(e)}
        >
          {unitSuggestionList.map((obj, index) => (
            <div key={obj.code_id}
                 className="suggestion-option"
                 onClick={e => selectUnit(obj.name)}
            >
              {obj.name}
            </div>
          ))}
        </div>
      </Col>
      <Col sm="2" className={"dtl-data-col " + (props.isLastRow ? "dtl-data-col-bottom" : "")}>
        <Form.Control
          id="price"
          type="tel"
          className="text-right"
          value={price}
          maxLength={10}
          readOnly={coDtlId}
          onFocus={e => onFocusSelect(e)}
          onChange={e => setPrice(e.target.value)}
          onBlur={e => setPrice(formatNumber(parseNumber(e.target.value)))}
          placeholder=""
        />
      </Col>
      <Col sm="2" className={"dtl-data-col " + (props.isLastRow ? "dtl-data-col-bottom" : "")}>
        <Form.Control
          id="amount"
          type="tel"
          className="text-right"
          value={amount}
          readOnly={true}
          placeholder=""
        />
      </Col>
    </Row>)
});

export default React.memo(ChDtlRowPo);