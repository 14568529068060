import React, {useEffect, useRef, useState} from 'react';
import {Link, useHistory, withRouter} from "react-router-dom";
import {Card, Col, Container, Form, Row} from "react-bootstrap";
import {deleteAPI, getAPI, getSpAPI, patchSpAPI, postAPI, putAPI} from "../APIUtil";
import Button from "react-bootstrap/Button";
import AppNav from "./AppNav";
import 'react-toastify/dist/ReactToastify.css';
import Toast, {success} from "../Toast";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt, faPen, faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker"
import AsyncSelect from "react-select/async";
import PoPDF from '../doc/PoPDF';
import {PDFDownloadLink} from '@react-pdf/renderer';
import {
  setFocusCalendar,
  toSingleByte,
  toNull,
  nvl,
  dateformatDB,
  parseDate,
  addField,
  formatNumber,
  parseNumber
} from "../CMUtil";
import {PoDtlRow} from "./PoDtlRow";
import {useLocation} from "react-router";

export const PoDtl = ({match: {params: {po_id}}}) => {

  const hist = useHistory();
  const location = useLocation();

  const [poId, setPoId] = useState("");
  const [poNo, setPoNo] = useState("");
  const [poType, setPoType] = useState(location.pathname.indexOf("po_item") > 0 ? "資材" : "作業");
  const [poTitle, setPoTitle] = useState("");
  const [suppSelected, setSuppSelected] = useState([]);
  const [suppId, setSuppId] = useState("");
  const [poDate, setPoDate] = useState("");
  const [note, setNote] = useState("");
  const [subAmount, setSubAmount] = useState("");
  const [tax, setTax] = useState("");
  const [ttlAmount, setTtlAmount] = useState("");
  const [updateCnt, setUpdateCnt] = useState(0);

  const [dtlList, setDtlList] = useState([]);
  const dtlListRef = useRef([]);

  const [suppName, setSuppName] = useState("");

  //初期化
  useEffect(() => {

    const update = async () => {

      let locDtlList = [];
      if (!po_id) {
        //INSERT
        for (let i = 0; i < 10; i++) {
          locDtlList.push({
            index: i,
            line_no: i + 1,
            id: null,
            item_type: null,
            item_id: null,
            qty: null,
            unit: null,
            price: null,
            amount: null
          });
        }

      } else {
        //UPDATE
        let obj = await getAPI("tr_po/" + po_id);
        setPoId(po_id);
        setPoNo(obj.po_no);
        setPoDate(nvl(parseDate(obj.po_date)));
        setPoTitle(nvl(obj.po_title));
        if (obj.supp) {
          setSuppId(obj.supp.supp_id);
          setSuppSelected([{supp_id: obj.supp.supp_id, name: obj.supp.name}]);
          setSuppName(obj.supp.name);
        }
        setSubAmount(formatNumber(obj.sub_amount));
        setTax(formatNumber(obj.tax));
        setTtlAmount(formatNumber(obj.ttl_amount));
        setNote(nvl(obj.note));

        let condition = {};
        addField(condition, "po_id", po_id);
        const res = await getAPI("tr_po_dtl_search_view", condition);

        for (let i = 0; i < Math.max(10, res.results.length); i++) {
          locDtlList.push({
            id: i < res.results.length ? res.results[i].po_dtl_id : null,
            index: i,
            line_no: i + 1,
            item_type: null,
            item_id: i < res.results.length ? res.results[i].item_id : null,
            item_name: i < res.results.length ? res.results[i].item_name : null,
            qty: i < res.results.length ? res.results[i].qty : null,
            unit: i < res.results.length ? res.results[i].unit : null,
            price: i < res.results.length ? res.results[i].price : null,
            amount: i < res.results.length ? res.results[i].amount : null
          });
        }
      }

      for (let i = 0; i < locDtlList.length; i++) {
        dtlListRef.current[i] = React.createRef();
      }
      setDtlList(locDtlList);
      console.log(dtlListRef);
    }
    update();

  }, []);

  const onClickAddRow = () => {
    let locDtlList = JSON.parse(JSON.stringify(dtlList));
    locDtlList.push({
      index: locDtlList.length,
      line_no: locDtlList.length + 1,
      id: null,
      item_type: null,
      item_id: null,
      item_name: null,
      qty: null,
      unit: null,
      price: null,
      amount: null
    });
    for (let i = 0; i < locDtlList.length; i++) {
      dtlListRef.current[i] = React.createRef();
    }
    setDtlList(locDtlList);
  }

  const onClickInsert = async () => {
    let obj = newData();
    obj = await postAPI("tr_po/", obj);

    let id = obj.po_id;
    for (const dtl of dtlListRef.current) {
      let dtlObj = dtl.current.getStates();
      if (!dtlObj.item_name || !dtlObj.amount) {
        continue;
      }
      dtlObj = newDtlData(id, dtlObj);
      dtlObj = await postAPI("tr_po_dtl/", dtlObj);
    }

    setPoId(obj.po_id);
    hist.push(location.pathname + obj.po_id);
    success("登録完了");
  }

  const onClickUpdate = async () => {
    let obj = newData();
    await putAPI("tr_po/" + poId + "/", obj);

    let updateDtlIdList = [];
    for (const dtl of dtlListRef.current) {
      let dtlObj = dtl.current.getStates();
      if (!dtlObj.item_name || !dtlObj.amount) {
        continue;
      }
      dtlObj = newDtlData(poId, dtlObj);
      if (!dtlObj.po_dtl_id) {
        dtlObj = await postAPI("tr_po_dtl/", dtlObj);
      } else {
        dtlObj = await putAPI("tr_po_dtl/" + dtlObj.po_dtl_id, dtlObj);
      }
      updateDtlIdList.push(dtlObj.po_dtl_id);
    }

    let condition = {};
    addField(condition, "po_id", poId);
    let res = await getAPI("tr_po_dtl_search_view", condition);
    for (const dbDtl of res.results) {
      if (!updateDtlIdList.includes(dbDtl.po_dtl_id)) {
        await deleteAPI("tr_po_dtl/" + dbDtl.po_dtl_id + "/", obj);
      }
    }

    success("更新完了");
    setUpdateCnt(updateCnt + 1);
  }

  const onClickDelete = async () => {

    let condition = {};
    addField(condition, "po_id", poId);
    res = await getAPI("tr_po_dtl_search_view", condition);

    let dtlList = res.results;
    for (const dtl of dtlList) {
      await deleteAPI("tr_po_dtl/" + dtl.po_dtl_id + "/", {'po_dtl_id': dtl.po_dtl_id});
    }

    let res = await deleteAPI("tr_po/" + poId + "/", {"po_id": poId});
    success("削除完了");
    hist.push("/po_list/");
  }

  const newData = () => {
    return {
      po_id: toNull(poId),
      po_type: poType,
      po_no: toNull(poNo),
      po_date: toNull(dateformatDB(poDate)),
      po_title: toNull(poTitle),
      supp_id: toNull(suppId),
      status: "発注前",
      sub_amount: toNull(parseNumber(subAmount)),
      tax: toNull(parseNumber(tax)),
      ttl_amount: toNull(parseNumber(ttlAmount)),
      note: toNull(note),
      create_pg: 'PoDtl',
      update_pg: 'PoDtl',
    };
  }

  const newDtlData = (poId, data) => {
    return {
      po_dtl_id: toNull(data.po_dtl_id),
      po_id: toNull(poId),
      item_id: toNull(data.item_id),
      item_name: toNull(data.item_name),
      line_no: toNull(data.line_no),
      qty: toNull(parseNumber(data.qty)),
      unit: toNull(data.unit),
      price: toNull(parseNumber(data.price)),
      amount: toNull(parseNumber(data.amount)),
      create_pg: 'PoDtl',
      update_pg: 'PoDtl',
    };
  }

  const handleChangeSupp = (value) => {
    setSuppSelected(value ? value : "");
    setSuppId(value ? value.id : "");
    setSuppName(value ? value.name : "")
  }

  const onSearchSupp = async (query) => {
    let searchCondition = {
      name: query
    }
    const res = await getAPI("ms_supp_typeahead_view", searchCondition);
    let constOptions = [];
    res.results.map((result) => {
      constOptions.push({name: result.name, id: result.supp_id});
    });
    return constOptions;
  }

  const totalCalcHandler = () => {
    let subAmount = 0;
    for (const row of dtlListRef.current) {
      let locAmount = parseNumber(row.current.getStates().amount);
      if (locAmount) {
        subAmount += locAmount;
      }
    }
    setSubAmount(formatNumber(subAmount));
    setTax(formatNumber(subAmount * 0.1));
    setTtlAmount(formatNumber(subAmount + (subAmount * 0.1)));
  }

  const deleteRowHandler = (rowIndex) => {
    let currIndex = 0;
    let newIndex = 0;
    for (currIndex = 0; currIndex < dtlListRef.current.length; currIndex++) {
      if (currIndex === rowIndex) {
        continue;
      }

      let currData = dtlListRef.current[currIndex].current.getStates();
      dtlListRef.current[newIndex].current.setData({
        index: newIndex,
        line_no: newIndex + 1,
        id: currData.po_dtl_id,
        item_type: currData.item_type,
        item_id: currData.item_id,
        item_name: currData.item_name,
        qty: currData.qty,
        unit: currData.unit,
        price: currData.price,
        amount: currData.amount
      });
      newIndex++;
    }
    dtlListRef.current[dtlListRef.current.length - 1].current.setData({
      index: dtlListRef.current.length - 1,
      line_no: dtlListRef.current.length,
      id: null,
      item_type: null,
      item_id: null,
      item_name: null,
      qty: null,
      unit: null,
      price: null,
      amount: null
    });

    let locDtlList = JSON.parse(JSON.stringify(dtlList));
    if (locDtlList.length > 10) {
      locDtlList.splice(locDtlList.length - 1, 1);
      dtlListRef.current.splice(dtlListRef.current.length - 1, 1);
    }
    for (let i = 0; i < locDtlList.length; i++) {
      dtlListRef.current[i] = React.createRef();
    }
    setDtlList(locDtlList);

  }

  return (
    <>
      <div className="body-dtl body-dtl-const">
        <AppNav/>
        <div className="h1">
          <h1 className="float-left align-items-end">
            {poType === "資材" ? "資材発注詳細" : "作業発注詳細"}
          </h1>
          <div className="float-none" style={{height: "30px"}}>&nbsp;</div>
        </div>

        <Container className="container-main-detail">

          <Row className="h3">
            <Col>
            </Col>
            <Col sm="1" className="text-right">
              {poId &&
                <PDFDownloadLink document={<PoPDF po_id={poId} updateCnt={updateCnt}/>}
                                 fileName={poNo + ".pdf"} className=".btn .btn-secondary">
                  {({blob, url, loading, error}) => (loading ? <Button disabled variant="secondary">印刷</Button> :
                    <Button variant="secondary">印刷</Button>)}
                </PDFDownloadLink>
              }
            </Col>
            <Col sm="1" className="text-right">
              {poId &&
                <Button variant="secondary" as={Link} to={"/rcv_ini/" + poId}>入荷登録</Button>
              }
            </Col>
            <Col sm="1" className="text-right">
              {!poId &&
                <Button variant="primary" onClick={onClickInsert}><FontAwesomeIcon icon={faPen} size="lg"/>登録</Button>}
              {poId &&
                <Button variant="primary" onClick={onClickUpdate}><FontAwesomeIcon icon={faPen} size="lg"/>登録</Button>}
            </Col>
          </Row>

          <Card className="card-secondary w-80 mx-auto mb-0">
            <Form.Group as={Row}>
              <Form.Label column sm="1">仕入れ業者</Form.Label>
              <Col sm="4">
                <AsyncSelect
                  isClearable
                  className="async-select"
                  cacheOptions
                  defaultOptions
                  value={suppSelected}
                  getOptionLabel={e => e.name}
                  getOptionValue={e => e.id}
                  loadOptions={onSearchSupp}
                  onChange={handleChangeSupp}
                  placeholder=""
                />
              </Col>
              <Col sm="1"></Col>
              <Form.Label column sm="1">発注日</Form.Label>
              <Col sm="4" className="input-group">
                <DatePicker id="poDate" selected={poDate} onChange={(date) => setPoDate(date)}
                            dateFormat="yyyy年MM月dd日" locale="ja"/>
                <Button as={Link} variant="link" to={"/"} style={{paddingRight: "0"}}
                        onClick={e => setFocusCalendar(e, 'poDate')}>
                  <FontAwesomeIcon icon={faCalendarAlt} size="2x"/>
                </Button>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="1">件名</Form.Label>
              <Col sm="4">
                <Form.Control
                  id="name"
                  type="text"
                  value={poTitle}
                  maxLength={80}
                  onChange={e => setPoTitle(e.target.value)}
                />
              </Col>
              <Col sm="1"></Col>
              <Form.Label column sm="1">発注番号</Form.Label>
              <Col sm="4">
                <Form.Control
                  id="poNo"
                  type="text"
                  value={poNo}
                  maxLength={20}
                  onChange={e => setPoNo(e.target.value)}
                  onBlur={e => setPoNo(toSingleByte(e.target.value))}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="1"></Form.Label>
              <Col sm="4">
              </Col>
              <Col sm="1"></Col>
              <Form.Label column sm="1">小計</Form.Label>
              <Col sm="2" className="text-right">
                <Form.Label column sm="10">{subAmount} 円</Form.Label>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="1"></Form.Label>
              <Col sm="4">
              </Col>
              <Col sm="1"></Col>
              <Form.Label column sm="1">消費税</Form.Label>
              <Col sm="2" className="text-right">
                <Form.Label column sm="10">{tax} 円</Form.Label>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="1"></Form.Label>
              <Col sm="4">
              </Col>
              <Col sm="1"></Col>
              <Form.Label column sm="1">合計金額</Form.Label>
              <Col sm="2" className="text-right">
                <Form.Label column sm="10">{ttlAmount} 円</Form.Label>
              </Col>
            </Form.Group>

            <Container className="container-main-list">
              <Row className="dtl_list_header">
                <Col sm="5" className="dtl-head-col">詳細</Col>
                <Col sm="1" className="dtl-head-col">数量</Col>
                <Col sm="1" className="dtl-head-col">単位</Col>
                <Col sm="2" className="dtl-head-col">単価</Col>
                <Col sm="2" className="dtl-head-col">金額</Col>
                <Col sm="1"></Col>
              </Row>
              {dtlList.map((dtl, index) => (
                <PoDtlRow key={index}
                          ref={dtlListRef.current[index]}
                          index={index}
                          item_type={poType}
                          dtl={dtl}
                          totalCalcHandler={totalCalcHandler}
                          deleteRowHandler={deleteRowHandler}
                          isLastRow={dtlList.length === index + 1}
                />
              ))}
            </Container>
            <Row>
              <Col sm="9" className="justify-content-md-center">
              </Col>
              <Col sm="3" className="text-right">
                <Button onClick={onClickAddRow}>
                  <FontAwesomeIcon icon={faPlusCircle} size="lg"/>
                  行追加
                </Button>
              </Col>
            </Row>
            <Form.Group as={Row}>
              <Col>
                <Form.Control as="textarea" rows={10} value={note} onChange={e => setNote(e.target.value)}/>
              </Col>
            </Form.Group>
          </Card>
          <Row>
            <Col>
              {poId && <Button variant="danger" onClick={onClickDelete}>削除</Button>}
            </Col>
          </Row>
        </Container>

      </div>
      <Toast/>
    </>
  );
}

export default withRouter(PoDtl);