import React, {useEffect, useRef, useState} from 'react';
import {useHistory} from "react-router-dom";
import {Card, Col, Container, Form, Row} from "react-bootstrap";
import {deleteAPI, downloadImageAPI, getAPI, getAccessToken, postAPI, putAPI, postFileUploadAPI} from "../APIUtil";
import Button from "react-bootstrap/Button";
import {withRouter} from "react-router";
import AppNav from "./AppNav";
import 'react-toastify/dist/ReactToastify.css';
import Toast, {success} from "../Toast";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen} from "@fortawesome/free-solid-svg-icons";
import AsyncSelect from "react-select/async";
import {dateformatJPN, dateformatJPNByType, getDocType, nvl, parseDate, toNull} from "../CMUtil";
import {Document, Page} from "react-pdf";

export const DocDtl = ({match: {params: {p_doc_id, p_book_id, p_category}}}) => {

  const [count, setCount] = useState(0);

  const hist = useHistory();
  const iniBookId = useRef(true);
  const iniCount = useRef(true);

  const [dispDocType, setDispDocType] = useState("");
  const [updateType, setUpdateType] = useState("");

  const [docId, setDocId] = useState("");
  const [category, setCategory] = useState("");
  const [note, setNote] = useState("");
  const [fileName, setFileName] = useState("");
  const [bookId, setBookId] = useState("");
  const [constName, setConstName] = useState("");
  const [designName, setDesignName] = useState("");
  const [docCreateDate, setDocCreateDate] = useState("");
  const [docCreateUserName, setDocCreateUserName] = useState("");
  const [docUpdateDate, setDocUpdateDate] = useState("");
  const [docUpdateUserName, setDocUpdateUserName] = useState("");

  const [bookSelected, setBookSelected] = useState([]);

  const [mode, setMode] = useState("normal");
  const [tmpUploadFileName, setTmpUploadFileName] = useState("");
  const [tmpNumPages, setTmpNumPages] = useState([]);
  const [tmpUploadFileNamePdf, setTmpUploadFileNamePdf] = useState("");
  const [tmpUploadFileNameImg, setTmpUploadFileNameImg] = useState("");

  const [numPages, setNumPages] = useState([]); //PDF用
  const [urlImage, setUrlImage] = useState(""); //JPEG用

  const [docType, setDocType] = useState("");

  //初期化
  useEffect(() => {
    const update = async () => {
      if (p_doc_id) {
        setDocId(p_doc_id);
        setCount(count + 1);
      } else if (p_book_id && p_category) {
        let obj = await getAPI("tr_book/" + p_book_id);
        setBookId(obj.book_id);
        setBookSelected([{id: obj.book_id, name: obj.book_no + " " + obj.book_name}]);
        setCategory(p_category);
      }
    }
    update();

  }, []);

  useEffect(() => {

    if (iniCount.current) {
      iniCount.current = false;
      return;
    }

    const update = async () => {
      setMode("normal");
      setUpdateType("update");

      let obj = await getAPI("tr_doc/" + docId);

      if (obj.book) {
        setBookId(obj.book.book_id);
        setBookSelected([{id: obj.book.book_id, name: obj.book.book_no + " " + obj.book.book_name}]);
      }

      let locDocType = "";
      if (obj.file_name) {
        locDocType = getDocType(obj.file_name);
      }

      let locNumPages = [];
      let locUrlImage = "";
      let locDispDocType = ""

      if ("pdf" === locDocType || "docx" === locDocType) {
        let res = await getAPI("download_pdf_num_pages/" + docId);
        for (let i = 1; i <= res.num_pages; i++) {
          locNumPages.push(i);
        }
        locDispDocType = "pdf";

      } else if ("jpeg" === locDocType || "jpg" === locDocType || "png" === locDocType) {
        const imgBlob = await downloadImageAPI("download/pic/" + obj.doc_id);
        const url = (window.URL || window.webkitURL).createObjectURL(imgBlob);
        locUrlImage = url;
        locDispDocType = "img";
      }

      setFileName(nvl(obj.file_name));
      setCategory(nvl(obj.category));
      setNote(nvl(obj.note));
      setDocCreateUserName(obj.doc_create_user ? obj.doc_create_user.name : "");
      setDocCreateDate(nvl(obj.doc_create_date));
      setDocUpdateUserName(obj.doc_update_user ? obj.doc_update_user.name : "");
      setDocUpdateDate(nvl(obj.doc_update_date));
      setDocType(nvl(locDocType));
      setDispDocType(nvl(locDispDocType));
      setNumPages(nvl(locNumPages));
      setUrlImage(nvl(locUrlImage));
    }
    update();

  }, [count]);

  useEffect(() => {

    if (iniBookId.current) {
      iniBookId.current = false;
      return;
    }

    const update = async () => {

      let obj = await getAPI("tr_book/" + bookId);
      let locConstName = "";
      let locDesignName = "";
      if (obj.const) {
        locConstName = obj.const.name;
      }
      if (obj.ext_design) {
        locDesignName = obj.ext_design.name;
      }
      setConstName(locConstName);
      setDesignName(locDesignName);
    }
    update();

  }, [bookId]);

  const onClickInsert = async () => {
    setDispDocType("");
    setMode("");
    let obj = newData();
    obj = await postAPI("doc_update/", obj);
    setDocId(obj.doc_id);
    hist.push("/doc/" + obj.doc_id + "/");
    success("登録完了");
    setCount(count + 1);
  }

  const onClickUpdate = async () => {
    setDispDocType("");
    setMode("");
    let obj = newData();
    await putAPI("doc_update/" + docId + "/", obj);
    success("更新完了");
    setCount(count + 1);
  }

  const onClickDelete = async () => {
    let obj = newData();
    let res = await deleteAPI("tr_doc/" + docId + "/", obj);
    if (res) {
      success("削除完了");
      hist.push("/doc_list/");
    }
  }

  const newData = () => {
    return {
      doc_id: toNull(docId),
      file_name: toNull(fileName),
      tmp_upload_file: toNull(tmpUploadFileName),
      book_id: toNull(bookId),
      category: toNull(category),
      note: toNull(note),
      create_pg: 'DocDtl'
    };
  }

  const onUploadDoc = async () => {

    const formData = new FormData();
    formData.append(
      "upload_file",
      event.target.files[0],
      event.target.files[0].name
    );

    let res = await postFileUploadAPI("doc_upload/", formData);
    let locTmpUploadFileName = res.upload_file;
    let locDocType = getDocType(locTmpUploadFileName);

    let locDispDocType = "";
    let locTmpUploadFileNameImg = "";
    let locTmpUploadFileNamePdf = "";
    let locNumPages = [];

    if (locDocType === 'pdf' || locDocType === 'docx') {
      res = await getAPI("download_pdf_num_pages_tmp/" + locTmpUploadFileName);
      for (let i = 1; i <= res.num_pages; i++) {
        locNumPages.push(i);
      }
      locTmpUploadFileNamePdf = locTmpUploadFileName;
      locDispDocType = "pdf";
    } else {
      locTmpUploadFileNameImg = await downloadImageAPI("download/tmp/" + locTmpUploadFileName);
      locTmpUploadFileNameImg = (window.URL || window.webkitURL).createObjectURL(locTmpUploadFileNameImg);
      locDispDocType = "img";
    }

    setTmpNumPages(locNumPages);
    setTmpUploadFileNamePdf(locTmpUploadFileNamePdf);
    setTmpUploadFileName(locTmpUploadFileName);
    setTmpUploadFileNameImg(locTmpUploadFileNameImg);

    setDocType(locDocType);
    setDispDocType(locDispDocType);
    setMode("preview");
    $("#uploadFileChooser").val("");
  }

  const onSearchBook = async (query) => {
    let searchCondition = {
      name: query
    }
    const res = await getAPI("tr_book_typeahead_view", searchCondition);
    let locOptions = [];
    res.results.map((result) => {
      locOptions.push({name: nvl(result.book_no) + " " + result.book_name, id: result.book_id});
    });
    return locOptions;
  }

  const handleChangeBook = (value) => {
    setBookSelected(value);
    setBookId(value.id);
  }

  return (
    <>
      <div className="body-dtl body-dtl-const">
        <AppNav/>
        <div className="h1">
          <h1 className="float-left align-items-end">
            ドキュメント
          </h1>
          <div className="float-none" style={{height: "30px"}}>&nbsp;</div>
        </div>

        <Container className="container-main-detail">

          <Row className="h3">
            <Col>
              <h3 style={{paddingTop: ".7rem"}}>
                基本情報
              </h3>
            </Col>
            <Col className="text-right">
              {updateType !== 'update' &&
                <Button variant="primary" onClick={onClickInsert}><FontAwesomeIcon icon={faPen} size="lg"/>登録</Button>}
              {updateType === 'update' &&
                <Button variant="primary" onClick={onClickUpdate}><FontAwesomeIcon icon={faPen} size="lg"/>登録</Button>}
            </Col>
          </Row>

          <Card className="card-secondary w-80 mx-auto mb-0">
            <Form.Group as={Row}>
              <Form.Label column sm="1">工事物件</Form.Label>
              <Col sm="4">
                <AsyncSelect
                  className="async-select"
                  cacheOptions
                  defaultOptions
                  value={bookSelected}
                  getOptionLabel={e => e.name}
                  getOptionValue={e => e.id}
                  loadOptions={onSearchBook}
                  onChange={handleChangeBook}
                  placeholder=""
                />

              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="1">施工業者</Form.Label>
              <Col sm="4">
                <Form.Control plaintext readOnly value={constName}/>
              </Col>
              <Col sm="1"></Col>
              <Form.Label column sm="1">設計事務所</Form.Label>
              <Col sm="4">
                <Form.Control plaintext readOnly value={designName}/>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="1">ファイル名</Form.Label>
              <Col sm="6">
                {fileName}
              </Col>
              <Col sm="4" className="text-right">
                <Form id="uploadForm" action="">
                  <label className="upload-label">
                    <div className="btn btn-primary">
                      ファイル
                    </div>
                    <Form.File id="inputGroupFile01" className="custom-file-input" style={{display: "none"}}
                               onChange={onUploadDoc}/>
                  </label>
                </Form>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="1">区分</Form.Label>
              <Col sm="10">
                <Form.Check
                  inline
                  id="category1"
                  type="radio"
                  name="category"
                  label="見積り"
                  value="見積り"
                  onChange={e => setCategory(e.target.value)}
                  checked={category === "見積り"}/>
                <Form.Check
                  inline
                  id="category2"
                  type="radio"
                  name="category"
                  label="出来高調書"
                  value="出来高調書"
                  onChange={e => setCategory(e.target.value)}
                  checked={category === "出来高調書"}/>
                <Form.Check
                  inline
                  id="category3"
                  type="radio"
                  name="category"
                  label="請求書"
                  value="請求書"
                  onChange={e => setCategory(e.target.value)}
                  checked={category === "請求書"}/>
                <Form.Check
                  inline
                  id="category4"
                  type="radio"
                  name="category"
                  label="保証書"
                  value="保証書"
                  onChange={e => setCategory(e.target.value)}
                  checked={category === "保証書"}/>
                <Form.Check
                  inline
                  id="category5"
                  type="radio"
                  name="category"
                  label="その他"
                  value="その他"
                  onChange={e => setCategory(e.target.value)}
                  checked={category === "その他"}/>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="1">メモ</Form.Label>
              <Col sm="4">
                <Form.Control type="text" value={note} onChange={e => setNote(e.target.value)}/>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="1">作成日</Form.Label>
              <Col sm="4">
                {dateformatJPN(parseDate(docCreateDate)) + " " + dateformatJPNByType(parseDate(docCreateDate), "hours") + "時" + dateformatJPNByType(parseDate(docCreateDate), "minutes") + "分"}
              </Col>
              <Form.Label sm="1"></Form.Label>
              <Form.Label column sm="1">更新日</Form.Label>
              <Col sm="4">
                {dateformatJPN(parseDate(docUpdateDate)) + " " + dateformatJPNByType(parseDate(docUpdateDate), "hours") + "時" + dateformatJPNByType(parseDate(docUpdateDate), "minutes") + "分"}
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="1">作成者</Form.Label>
              <Col sm="4">
                {docCreateUserName}
              </Col>
              <Form.Label sm="1"></Form.Label>
              <Form.Label column sm="1">更新者</Form.Label>
              <Col sm="4">
                {docUpdateUserName}
              </Col>
            </Form.Group>
          </Card>

          <Row>
            <Col>
              {updateType === 'update' && <Button variant="danger" onClick={onClickDelete}>削除</Button>}
            </Col>
          </Row>

          <Card className="card-thirdly mx-auto">
            {/* 通常モード */}
            {mode === "normal" &&
              <div>
                {/* PDFの場合 */}
                {dispDocType === "pdf" &&
                  <div className="pdf-viewer">
                    <Document file={{
                      url: '/api/download_pdf_by_doc_id/' + docId,
                      httpHeaders: {'X-AUTH-TOKEN': getAccessToken()}
                    }}>
                      {
                        numPages.map((page, idx) => {
                          return <Page key={page} pageNumber={page}/>
                        })
                      }
                    </Document>
                  </div>
                }
                {/* 画像の場合 */}
                {dispDocType === "img" &&
                  <div style={{marginTop: "3rem"}}>
                    <img src={urlImage}
                         style={{marginLeft: "auto", marginRight: "auto", display: "block", width: "100%"}}/>
                  </div>
                }
              </div>
            }
            {/* プレビューモード */}
            {mode === "preview" &&
              <div>
                {/* PDFの場合 */}
                {dispDocType === "pdf" &&
                  <div className="pdf-viewer">
                    <Document file={{
                      url: '/api/download/tmp_pdf/' + tmpUploadFileNamePdf,
                      httpHeaders: {'X-AUTH-TOKEN': getAccessToken()}
                    }}>
                      {
                        tmpNumPages.map((page, idx) => {
                          return <Page key={page} pageNumber={page}/>
                        })
                      }
                    </Document>
                  </div>
                }
                {/* 画像の場合 */}
                {dispDocType === "img" &&
                  <div style={{marginTop: "3rem"}}>
                    <img src={tmpUploadFileNameImg}
                         style={{marginLeft: "auto", marginRight: "auto", display: "block", width: "100%"}}/>
                  </div>
                }
              </div>
            }
          </Card>

        </Container>
      </div>
      <Toast/>
    </>
  );
}

export default withRouter(DocDtl);