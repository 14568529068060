import React, {useEffect, useRef, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import {withRouter} from 'react-router';
import {getAPI} from "../APIUtil";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import AppNav from "./AppNav";
import {Card, Col, Container, Form, InputGroup, Row} from "react-bootstrap";
import {addField, useDebounce} from "../CMUtil";
import MdDatatable, {InitialLoading, sortCaret} from "../MdDataTable";
import AsyncSelect from "react-select/async";
import Button from "react-bootstrap/Button";

export const UserList = () => {

  const history = useHistory();
  const iniSearch = useRef(true);

  const [loadingCss, setLoadingCss] = useState("loading_panel_init");

  const [loading, setLoading] = useState(true);
  const [locPage, setLocPage] = useState(null);
  const [locSizePerPage, setLocSizePerPage] = useState(null);
  const [locTotalSize, setLocTotalSize] = useState(null);
  const [locSortField, setLocSortField] = useState(null);
  const [locSortOrder, setLocSortOrder] = useState(null);

  const [userId, setUserId] = useState("");
  const debouncedUserId = useDebounce(userId, 500, setLocPage);
  const [name, setName] = useState("");
  const debouncedName = useDebounce(name, 500, setLocPage);
  const [deptId, setDeptId] = useState("");
  const debounceDeptId = useDebounce(deptId, 500, setLocPage);
  const [permissionId, setPermissionId] = useState("");
  const debouncePermissionId = useDebounce(permissionId, 500, setLocPage);

  const [deptIdSelected, setDeptIdSelected] = useState(null);
  const [deptIdList, setDeptIdList] = useState([]);
  const [permissionIdSelected, setPermissionIdSelected] = useState(null);
  const [permissionIdList, setPermissionIdList] = useState([]);

  const [listData, setListData] = useState([]);

  // 項目定義
  const columns = [
    {
      dataField: 'user_id',
      text: '社員番号',
      classes: 'tbl-col link',
      sort: true,
      headerStyle: {width: '80px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      },
      formatter: (cell, row) => (
        <div><a onClick={() => onClickLinkToDetail(row.user_id)}>{cell}</a></div>
      )
    },
    {
      dataField: 'last_name',
      text: '名前',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '150px'},
      formatter: (cell, row) => (
        <span>{row.last_name + ' ' + row.first_name}</span>
      )
    },
    {
      dataField: 'dept.code_name',
      text: '部署',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '150px'},
    },
    {
      dataField: 'permission.code_name',
      text: '権限',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '150px'},
    },
    {
      dataField: 'sp_no',
      text: '携帯番号',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '150px'},
    },
  ];

  // 初期化
  useEffect(() => {
    const init = async () => {
      if (!history.location.state) {
        setLocPage(1);
      } else if (!history.location.state.pages) {
        setLocPage(1);
      } else if (!history.location.state.pages.filters) {
        setLocPage(1);
      } else if (Object.keys(history.location.state.pages.filters).length === 0) {
        setLocPage(1);
      } else {
        setLocPage(history.location.state.pages.page);
        setLocSizePerPage(history.location.state.pages.sizePerPage);
        setLocSortField(history.location.state.pages.sortField);
        setLocSortOrder(history.location.state.pages.sortOrder);
      }
    }
    init();
  }, []);

  // 検索処理
  useEffect(() => {
    if (iniSearch.current) {
      iniSearch.current = false;
      return;
    }
    const update = async () => {
      setLoading(true);
      let searchCondition = {};
      addField(searchCondition, "page", locPage);
      addField(searchCondition, "size_per_page", locSizePerPage);
      addField(searchCondition, "sort_field", locSortField);
      addField(searchCondition, "sort_order", locSortOrder);
      addField(searchCondition, "user_id", userId);
      addField(searchCondition, "name", name);
      addField(searchCondition, "dept_id", deptId);
      addField(searchCondition, "permission_id", permissionId);
      const res = await getAPI("ms_user_search_view", searchCondition);
      setListData(res);
      setLoading(false);
      setLoadingCss("loading_panel_fadeout");
    }
    update();
  }, [
    locPage,
    locSizePerPage,
    locSortField,
    locSortOrder,
    debouncedUserId,
    debouncedName,
    debounceDeptId,
    debouncePermissionId
  ]);

  const onTableChange = async (type, {page, sizePerPage, filters, sortField, sortOrder, cellEdit}) => {
    if ("pagination" === type) {
      setLocPage(page);
    }
    if ("sizePerPage" === type) {
      setLocSizePerPage(sizePerPage);
    }
    if ("sort" === type) {
      setLocSortField(sortField);
      setLocSortOrder(sortOrder);
    }
  }

  const onClickLinkToDetail = (user_id) => {
    history.push("/user/" + user_id);
  }

  const handleChangeDeptId = (value) => {
    setDeptIdSelected(value ? value : "");
    setDeptId(value ? value.id : "");
  }

  const onSearchDeptId = async (query) => {
    let locOptions = [];
    let locDeptIdList = deptIdList;
    if (locDeptIdList.length === 0) {
      //初回のみ選択肢セット
      const res = await getAPI("ms_code_search_view", {categ_id: "DEPT", sort_field: "code_id"});
      locDeptIdList = [];
      res.results.map(code => {
        locDeptIdList.push({
          id:code.code_id,
          name:code.code_name
        });
      });
      setDeptIdList(locDeptIdList);
    }

    locDeptIdList.map((l) => {
      if (l.name.indexOf(query) > -1) {
        locOptions.push(l);
      }
    });
    return locOptions;
  }

  const handleChangePermissionId = (value) => {
    setPermissionIdSelected(value ? value : "");
    setPermissionId(value ? value.id : "");
  }

  const onSearchPermissionId = async (query) => {
    let locOptions = [];

    let locPermissionIdList = permissionIdList;
    if (locPermissionIdList.length === 0) {
      //初回のみ選択肢セット
      const res = await getAPI("ms_code_search_view", {categ_id: "PERMISSION", sort_field: "code_id"});
      locPermissionIdList = [];
      res.results.map(code => {
        locPermissionIdList.push({
          id:code.code_id,
          name:code.code_name
        });
      });
      setPermissionIdList(locPermissionIdList);
    }

    locPermissionIdList.map((l) => {
      if (l.name.indexOf(query) > -1) {
        locOptions.push(l);
      }
    });
    return locOptions;
  }

  const onClickClear = () =>{
    setUserId("");
    setName("");
    setDeptId("")
    setDeptIdSelected(null);
    setPermissionId("");
    setPermissionIdSelected(null);
  }

  return (
    <>
      <AppNav/>
      <div className="h1">
        <h1>
          社員
        </h1>
      </div>
      <Card className="card-search">
        <Container className="container-main-search">
          <Form.Group as={Row}>
            <Form.Label column sm="1">社員番号</Form.Label>
            <Col sm="4">
              <Form.Control type="text" value={userId} onChange={e => setUserId(e.target.value)}/>
            </Col>
            <Col sm="1"></Col>
            <Form.Label column sm="1">名前</Form.Label>
            <Col sm="4">
              <Form.Control type="text" value={name} onChange={e => setName(e.target.value)}/>
            </Col>
            <Col sm="1">
              <Button variant="clear" onClick={e => onClickClear()}>条件クリア</Button>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="1">部署</Form.Label>
            <Col sm="4">
              <AsyncSelect
                className="async-select"
                cacheOptions
                defaultOptions
                isClearable
                value={deptIdSelected}
                getOptionLabel={e => e.name}
                getOptionValue={e => e.id}
                loadOptions={onSearchDeptId}
                onChange={handleChangeDeptId}
                placeholder=""
              />
            </Col>
            <Col sm="1"></Col>
            <Form.Label column sm="1">権限</Form.Label>
            <Col sm="4">
              <AsyncSelect
                className="async-select"
                cacheOptions
                defaultOptions
                isClearable
                value={permissionIdSelected}
                getOptionLabel={e => e.name}
                getOptionValue={e => e.id}
                loadOptions={onSearchPermissionId}
                onChange={handleChangePermissionId}
                placeholder=""
              />
            </Col>
          </Form.Group>
        </Container>
      </Card>

      <Container className="container-main-list">
        {/* *** 一覧 *** */}
        <InitialLoading loadingCss={loadingCss}/>
        <MdDatatable
          keyField="user_id"
          listData={listData}
          columns={columns}
          loading={loading}
          onTableChange={onTableChange}
          page={locPage}
          sizePerPage={locSizePerPage}
          totalSize={locTotalSize}
          sortField={locSortField}
          sortOrder={locSortOrder}
          showPagenation={true}
          linkToAdd="/user/"
        />
      </Container>
    </>
  );
}

export default withRouter(UserList);