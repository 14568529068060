import React, {useEffect, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import {Card, Col, Container, Form, FormGroup, InputGroup, Nav, Row} from "react-bootstrap";
import {deleteAPI, getAPI, postAPI, putAPI} from "../APIUtil";
import Button from "react-bootstrap/Button";
import {withRouter} from "react-router";
import AppNav from "./AppNav";
import 'react-toastify/dist/ReactToastify.css';
import Toast, {success} from "../Toast";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen} from "@fortawesome/free-solid-svg-icons";
import {
  addField,
  checkKey, formatNumber,
  nvl, onSearchUnit, parseNumber,
  toNull,
  toSingleByte,
  toSingleByteAlphaNumberOnly
} from "../CMUtil";
import AsyncSelect from "react-select/async";

export const ItemDtl = ({match: {params: {item_id}}}) => {

  const hist = useHistory();

  const [updateType, setUpdateType] = useState("");

  const [itemId, setItemId] = useState("");
  const [itemName, setItemName] = useState("");
  const [itemType, setItemType] = useState("");
  const [unit, setUnit] = useState("");
  const [cost, setCost] = useState(0);
  const [stdCost, setStdCost] = useState(0);
  const [price, setPrice] = useState(0);
  const [note, setNote] = useState("");

  const [unitSuggestionDisplay, setUnitSuggestionDisplay] = useState("none");
  const [unitSuggestionList, setUnitSuggestionList] = useState([]);
  const [mouseOverUnitSuggestion, setMouseOverUnitSuggestion] = useState(false);

  //初期化
  useEffect(() => {
    const update = async () => {
      if (!item_id) {
        return;
      }
      let obj = await getAPI("ms_item/" + item_id);
      setUpdateType("update");
      setItemId(obj.item_id);
      setItemName(nvl(obj.item_name));
      setItemType(nvl(obj.item_type));
      setUnit(nvl(obj.unit));
      setCost(nvl(formatNumber(obj.cost)));
      setStdCost(nvl(formatNumber(obj.std_cost)));
      setPrice(nvl(formatNumber(obj.price)));
      setNote(nvl(obj.note));

    }
    update();

  }, []);

  const onClickInsert = async () => {
    let obj = newData();
    obj = await postAPI("ms_item/", obj);
    setItemId(obj.item_id);

    let stkObj = {
      item_id: obj.item_id,
      qty: 0,
      create_pg: 'itemDtl',
      update_pg: 'itemDtl'
    }
    await postAPI("ms_stk/", stkObj);

    hist.push("/item/" + obj.item_id);
    setUpdateType("update");
    success("登録完了");
  }

  const onClickUpdate = async () => {
    let obj = newData();
    await putAPI("ms_item/" + itemId + "/", obj);

    let condition = null;
    condition = {};
    addField(condition, "item_id", itemId);
    const stkRes = await getAPI("ms_stk_search_view", condition);
    if (stkRes.count === 0) {
      let stkObj = {
        item_id: itemId,
        qty: 0,
        create_pg: 'itemDtl',
        update_pg: 'itemDtl'
      }
      await postAPI("ms_stk/", stkObj);
    }
    success("更新完了");
  }

  const onClickDelete = async () => {
    let obj = newData();
    let res = await deleteAPI("ms_item/" + item_id + "/", obj);
    if (res) {

      await deleteAPI("ms_stk/" + item_id + "/");
      success("削除完了");
      hist.push("/item_list/");
    }
  }

  const newData = () => {
    return {
      item_id: toNull(itemId),
      item_name: toNull(itemName),
      item_type: toNull(itemType),
      unit: toNull(unit),
      cost: toNull(parseNumber(cost)),
      std_cost: toNull(parseNumber(stdCost)),
      price: toNull(parseNumber(price)),
      note: toNull(note),
      create_pg: 'ItemDtl',
      update_pg: 'ItemDtl'
    };
  }

  const onFocusUnit = async (e) => {
    let locUnitList = await onSearchUnit("");
    setUnitSuggestionList(locUnitList);
    setUnitSuggestionDisplay("block");
  }
  const onMouseOverUnitSuggestion = (e) => {
    setMouseOverUnitSuggestion(true);
  }
  const onMouseOutUnitSuggestion = (e) => {
    setMouseOverUnitSuggestion(false);
  }
  const onBlurUnitSuggestion = (e) => {
    if (!mouseOverUnitSuggestion) {
      setUnitSuggestionDisplay("none");
    }
  }
  const selectUnit = async (unit) => {
    setUnit(unit);
    setUnitSuggestionDisplay("none");
  }

  return (
    <>
      <div className="body-dtl body-dtl-const">
        <AppNav/>
        <div className="h1">
          <h1 className="float-left align-items-end">
            資材（作業）詳細
          </h1>
          <div className="float-none" style={{height: "30px"}}>&nbsp;</div>
        </div>

        <Container className="container-main-detail">

          <Row className="h3">
            <Col>
              <h3 style={{paddingTop: ".7rem"}}>
                基本情報
              </h3>
            </Col>
            <Col className="text-right">
              {updateType !== 'update' &&
                <Button variant="primary" onClick={onClickInsert}><FontAwesomeIcon icon={faPen} size="lg"/>登録</Button>}
              {updateType === 'update' &&
                <Button variant="primary" onClick={onClickUpdate}><FontAwesomeIcon icon={faPen} size="lg"/>登録</Button>}
            </Col>
          </Row>

          <Card className="card-secondary w-80 mx-auto mb-0">
            <Form.Group as={Row}>
              <Form.Label column sm="1">資材名</Form.Label>
              <Col sm="4">
                <Form.Control
                  id="item_name"
                  type="text"
                  value={itemName}
                  maxLength={80}
                  onChange={e => setItemName(e.target.value)}
                  onBlur={e => setItemName(toSingleByteAlphaNumberOnly(e.target.value))}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="1">種別</Form.Label>
              <Col sm="2">
                <Form.Check
                  id="itemType1"
                  type="radio"
                  name="itemType"
                  label="資材"
                  value="資材"
                  onChange={e => setItemType(e.target.value)}
                  checked={itemType === "資材"}/>
              </Col>
              <Col sm="2">
                <Form.Check
                  id="itemType2"
                  type="radio"
                  name="itemType"
                  label="作業"
                  value="作業"
                  onChange={e => setItemType(e.target.value)}
                  checked={itemType === "作業"}/>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="1">単位</Form.Label>
              <Col sm="2">
                <Form.Control
                  id="unit"
                  type="text"
                  value={unit}
                  onFocus={e => onFocusUnit(e)}
                  onBlur={e => onBlurUnitSuggestion(e)}
                  onChange={e => setUnit(e.target.value)}
                  placeholder=""
                />
                <div
                  className="suggestion"
                  style={{display: unitSuggestionDisplay}}
                  onMouseOver={(e) => onMouseOverUnitSuggestion(e)}
                  onMouseOut={(e) => onMouseOutUnitSuggestion(e)}
                >
                  {unitSuggestionList.map((obj, index) => (
                    <div key={index}
                         className="suggestion-option"
                         onClick={e => selectUnit(obj.name)}
                    >
                      {obj.name}
                    </div>
                  ))}
                </div>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="1">仕入れ価格</Form.Label>
              <Col sm="2">
                <Form.Control
                  id="cost"
                  type="text"
                  style={{imeMode: "disabled", textAlign: "right"}}
                  value={cost}
                  maxLength={20}
                  onKeyPress={e => checkKey(e, /[0-9]/)}
                  onChange={e => setCost(e.target.value)}
                  onBlur={e => setCost(formatNumber(toSingleByte(e.target.value)))}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="1">販売価格</Form.Label>
              <Col sm="2">
                <Form.Control
                  id="price"
                  type="text"
                  style={{imeMode: "disabled", textAlign: "right"}}
                  value={price}
                  maxLength={20}
                  onKeyPress={e => checkKey(e, /[0-9]/)}
                  onChange={e => setPrice(e.target.value)}
                  onBlur={e => setPrice(formatNumber(toSingleByte(e.target.value)))}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="1">備考</Form.Label>
              <Col sm="9">
                <Form.Control as="textarea" rows={10} value={note} onChange={e => setNote(e.target.value)}/>
              </Col>
            </Form.Group>

          </Card>

          <Card className="card-secondary w-80 mx-auto mb-0">
            <Form.Group as={Row}>
              <Form.Label column sm="1"></Form.Label>
            </Form.Group>
          </Card>

          <Row>
            <Col>
              {updateType === 'update' && <Button variant="danger" onClick={onClickDelete}>削除</Button>}
            </Col>
          </Row>

        </Container>
      </div>
      <Toast/>
    </>
  );
}

export default withRouter(ItemDtl);