import React, {useCallback, useEffect, useRef, useState} from 'react';
import {withRouter} from "react-router";
import 'react-toastify/dist/ReactToastify.css';
import {GoogleMap, InfoWindow, Marker, useLoadScript} from "@react-google-maps/api";
import AppNav from "./AppNav";
import styles from "./map/styles.json"
import {getAPI} from "../APIUtil";
import {Link, useHistory, useLocation, useParams} from "react-router-dom";
import {parseLocationSearch} from "../CMUtil";
import { Button } from 'react-bootstrap';

export const Map = () => {

  const location = useLocation();
  const params = useParams();
  const history = useHistory();

  const [isDragging, setIsDragging] = useState(false);

  const [libraries] = useState(['places']);
  const mapContainerStyle = {
    width: "100%",
  };

  const options = {
    styles: styles,
    disableDefaultUI: true,
    zoomControl: true,
  };

  const [center, setCenter] = useState({
    lat: 0,
    lng: 0,
  });

  const {isLoaded, loadError} = useLoadScript({
    googleMapsApiKey: "AIzaSyBPQVkO3LWy9BLU6I4VTwXJan5miSdSEc8",
    libraries,
  });

  const mapRef = useRef();
  const [placeList, setPlaceList] = useState([]);

  const onMapLoad = ((map) => {
    mapRef.current = map;
  });

  useEffect(() => {
    const update = async () => {

      let seachMap = parseLocationSearch(location.search);
      let searchCondition = {};
      if(seachMap['book_id']){
        searchCondition['book_id'] = seachMap['book_id'];
      }
      if(seachMap['const_id']){
        searchCondition['const_id'] = seachMap['const_id'];
      }
      if(seachMap['supp_id']){
        searchCondition['supp_id'] = seachMap['supp_id'];
      }
      if(seachMap['design_id']){
        searchCondition['design_id'] = seachMap['design_id'];
      }
      let res = await getAPI("ms_map_pos_search_view", searchCondition);
      let mapPos = await res.results[0];
      setCenter({
        lat: parseFloat(mapPos.lat),
        lng: parseFloat(mapPos.lng)
      });
    }

    update();

  }, []);

  const onDragEnd = async () => {
    setIsDragging(false);
    await onReloadPlace();
  };

  const onBoundsChanged = async () => {
    if (!isDragging) {
      //Drag中の再レンダリングは無視する
      await onReloadPlace();
    }
  }

  if (loadError) return "Error";
  if (!isLoaded) return "Loading...";

  const onSelectPlace = async (location) => {
    mapRef.current.panTo(location);
  }

  const onReloadPlace = async () => {
    let searchCondition = {
      lat_center: mapRef.current.getCenter().lat(),
      lat_from: mapRef.current.getBounds().getSouthWest().lat(),
      lat_to: mapRef.current.getBounds().getNorthEast().lat(),
      lng_center: mapRef.current.getCenter().lng(),
      lng_from: mapRef.current.getBounds().getSouthWest().lng(),
      lng_to: mapRef.current.getBounds().getNorthEast().lng()
    }
    let res = await getAPI("ms_map_pos_search_view", searchCondition);
    let locPlaceList = [];
    res.results.map((mapPos) => {
      locPlaceList.push(
        {
          info: mapPos.map_pos_id,
          map_pos_id: mapPos.map_pos_id,
          type: mapPos.type,
          name: mapPos.type === 'book' ?
            mapPos.book.book_name :
            mapPos.type === 'const' ?
              mapPos.const.name :
            mapPos.type === 'supp' ?
              mapPos.supp.name :
              mapPos.design.name,
          address: mapPos.type === 'book' ?
            mapPos.book.pref + mapPos.book.city + mapPos.book.address :
            mapPos.type === 'const' ?
              mapPos.const.pref + mapPos.const.city + mapPos.const.address :
            mapPos.type === 'supp' ?
              mapPos.supp.pref + mapPos.supp.city + mapPos.supp.address :
              mapPos.design.pref + mapPos.design.city + mapPos.design.address,
          location: {
            lat: parseFloat(mapPos.lat),
            lng: parseFloat(mapPos.lng)
          }
        }
      );
    })
    setPlaceList(locPlaceList);
  }

  const PlaceInfo = ({placeList}) => {

    const [selected, setSelected] = useState(null);

    function getPlaceClassname() {
      const { type } = selected;
      const defaultClass = "map-place-list-title-type float-left";
      return type
        ? `${defaultClass} map-place-list-title-type-${type}`
        : defaultClass;
    }

    function getPlaceLabel() {
      const map = {
        book: "現場",
        const: "施工",
        supp: "仕入",
        design: "設計",
      };
      return map[selected?.type] ?? "";
    }

    function getPlaceLink() {
      const { type, info } = selected;
      if (type === "book") {
        return `/${type}/${info}/1`;
      }
      return type ? `/${type}/${info}` : "";
    }

    return (
      <>
        {placeList.map((place, index) => (
          <Marker
            key={index}
            position={{
              lat: place.location.lat,
              lng: place.location.lng,
            }}
            onMouseOver={() => {
              setSelected(place);
            }}
            icon={{
              url: place.type === 'book' ?
                "/static/marker-book.png" :
                place.type === 'const' ?
                  "/static/marker-const.png" :
                place.type === 'supp' ?
                  "/static/marker-const.png" :
                  "/static/marker-design.png",
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(30, 30),
              // ここでアイコン表示の設定ができます。
            }}
          />
        ))}

        {selected ? (
          // MarkerにマウスオーバーされたときにInfoWindowが表示されます。
          <InfoWindow
            position={{
              lat: selected.location.lat,
              lng: selected.location.lng,
            }}
            onCloseClick={() => {
              setSelected(null);
            }}
          >
            <div>
              <table>
                <tr>
                  <td>
                    <div className={getPlaceClassname()}>
                      {getPlaceLabel()}
                    </div>
                    <div className="map-place-list-title-name">
                      <Button as={Link} variant="link" to={getPlaceLink()} className="p-0">
                        &nbsp;{selected.name}
                      </Button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    {selected.address}
                  </td>
                </tr>
              </table>
            </div>
          </InfoWindow>
        ) : null}
      </>
    );
  }

  return (
    <>
      <div className="body-dtl">
        <AppNav/>
        <div className="float-left" style={{width: "20%", height: 'calc(100vh - 37px)', overflow: 'scroll'}}>
          {placeList.map((place, index) => {
            return (
              <div key={index} className="map-place-list" onClick={(e) => onSelectPlace(place.location)}>
                <div className="map-place-list-title">
                  {place.type === "book" &&
                  <div className={"map-place-list-title-type float-left map-place-list-title-type-book"}>
                    現場
                  </div>
                  }
                  {place.type === "const" &&
                  <div className={"map-place-list-title-type float-left map-place-list-title-type-const"}>
                    施工
                  </div>
                  }
                  {place.type === "supp" &&
                  <div className={"map-place-list-title-type float-left map-place-list-title-type-supp"}>
                    仕入
                  </div>
                  }
                  {place.type === "design" &&
                  <div className={"map-place-list-title-type float-left map-place-list-title-type-design"}>
                    設計
                  </div>
                  }
                  <div className="map-place-list-title-name float-left">{place.name}</div>
                </div>
                <div className="map-place-list-address" style={{clear: "both"}}>{place.address}</div>
              </div>
            )
          })}
        </div>
        <div className="float-left" style={{backgroundColor: "white", width: "80%", height: 'calc(100vh - 37px)'}}>
          <GoogleMap
            id="map"
            mapContainerStyle={mapContainerStyle}
            zoom={16}
            center={center}
            options={options}
            onLoad={onMapLoad}
            onDragStart={(e) => setIsDragging(true)}
            onDragEnd={onDragEnd}
            onBoundsChanged={onBoundsChanged}
          >
            <PlaceInfo placeList={placeList}/>
          </GoogleMap>
        </div>
      </div>
    </>
  );
}
export default withRouter(Map);