import React, {useEffect, useRef, useState} from 'react';
import {Link, useHistory, withRouter} from "react-router-dom";
import Button from 'react-bootstrap/Button';
import {getAPI} from "../APIUtil";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import AppNav from "./AppNav";
import {Card, Col, Container, Form, InputGroup, Row} from "react-bootstrap";
import {
  addField,
  useDebounce,
  dateformat,
  dateformatJPNYYYYMMDD,
  parseDate,
  formatNumber,
  setFocusCalendar, nvl
} from "../CMUtil";
import MdDatatable, {InitialLoading, sortCaret} from "../MdDataTable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons";
import DatePicker, {registerLocale} from "react-datepicker"
import ja from "date-fns/locale/ja";
import AsyncSelect from "react-select/async";

registerLocale('ja', ja)

export const RcvList = () => {

  const history = useHistory();
  const iniSearch = useRef(true);

  const [loadingCss, setLoadingCss] = useState("loading_panel_init");

  const [loading, setLoading] = useState(true);
  const [locPage, setLocPage] = useState(null);
  const [locSizePerPage, setLocSizePerPage] = useState(50);
  const [locTotalSize, setLocTotalSize] = useState(null);
  const [locSortField, setLocSortField] = useState(null);
  const [locSortOrder, setLocSortOrder] = useState(null);

  const [suppSelected, setSuppSelected] = useState([]);
  const [suppId, setSuppId] = useState("");
  const [rcvNo, setRcvNo] = useState("");
  const debouncedRcvNo = useDebounce(rcvNo, 500, setLocPage);

  const [poNo, setPoNo] = useState("");
  const debouncedPoNo = useDebounce(poNo, 500, setLocPage);

  const [poTitle, setPoTitle] = useState("");
  const debouncedPoTitle = useDebounce(poTitle, 500, setLocPage);


  const [rcvDateFrom, setRcvDateFrom] = useState("");
  const debouncedRcvDateFrom = useDebounce(rcvDateFrom, 500, setLocPage);
  const [rcvDateTo, setRcvDateTo] = useState("");
  const debouncedRcvDateTo = useDebounce(rcvDateTo, 500, setLocPage);

  const [listData, setListData] = useState([]);

  // 項目定義
  const columns = [
    {
      dataField: "rcv_no",
      text: '入荷番号',
      classes: 'tbl-col link',
      sort: true,
      headerStyle: {width: '50px'},
      formatter: (cell, row) => (
        <div><a onClick={() => onClickLinkToDetail(row.rcv_id)}>{cell}</a></div>
      )
    },
    {
      dataField: 'supp.name',
      text: '仕入れ業者',
      sort: true,
      classes: 'tbl-col',
      headerStyle: {width: '70px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'po.po_title',
      text: '発注',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '100px'},
      formatter: (cell, row) => (
        <span>{nvl(row.po.po_no) + ' ' + nvl(row.po.po_title)}</span>
      ),
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'rcv_date',
      text: '入荷日',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '40px'},
      formatter: (cell, row) => (
        <span>{dateformatJPNYYYYMMDD(parseDate(cell))}</span>
      ),
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'po.ttl_amount',
      text: '発注金額',
      classes: 'tbl-col',
      sort: false,
      headerStyle: {width: '45px',  textAlign: 'right'},
      align: 'right',
      formatter: (cell, row) => (
        <span>{formatNumber(cell)}</span>
      ),
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    }
  ];

  // 初期化
  useEffect(() => {
    const init = async () => {
      setLocPage(1);
    }
    init();
  }, []);

  // 検索処理
  useEffect(() => {
    if (iniSearch.current) {
      iniSearch.current = false;
      return;
    }
    const update = async () => {

      let sortField = locSortField;
      let sortOrder = locSortOrder;
      if (!sortField && !sortOrder) {
        //デフォルト　着工日降順
        sortField = null;
        sortOrder = null;
      }
      let condition = {};
      addField(condition, "page", locPage);
      addField(condition, "size_per_page", locSizePerPage);
      addField(condition, "sort_field", sortField);
      addField(condition, "sort_order", sortOrder);
      addField(condition, "supp_id", suppId);
      addField(condition, "rcv_id", rcvNo);
      addField(condition, "po_no", poNo);
      addField(condition, "rcv_date_from", dateformat(rcvDateFrom));
      addField(condition, "rcv_date_to", dateformat(rcvDateTo));
      addField(condition, "po_title", poTitle);

      let res = await getAPI("tr_rcv_search_view", condition);
      setListData(res);
      setLoading(false);
      setLoadingCss("loading_panel_fadeout");
    }
    update();
  }, [
    locPage,
    locSizePerPage,
    locSortField,
    locSortOrder,
    suppId,
    debouncedRcvNo,
    debouncedPoTitle,
    debouncedRcvDateFrom,
    debouncedRcvDateTo,
    debouncedPoNo,
  ]);

  const onTableChange = (type, {page, sizePerPage, filters, sortField, sortOrder, cellEdit}) => {
    if ("pagination" === type) {
      setLocPage(page);
    }
    if ("sizePerPage" === type) {
      setLocSizePerPage(sizePerPage);
    }
    if ("sort" === type) {
      setLocSortField(sortField);
      setLocSortOrder(sortOrder);
    }
  }

  const onClickLinkToDetail = (po_id) => {
    history.push("/rcv/" + po_id);
  }

  const handleChangeSupp = (value) => {
    setSuppSelected(value ? value : "");
    setSuppId(value ? value.id : "");
  }

  const onSearchSupp = async (query) => {
    let searchCondition = {
      name: query
    }
    const res = await getAPI("ms_supp_typeahead_view", searchCondition);
    let suppOptions = [];
    res.results.map((result) => {
      suppOptions.push({name: result.name, id: result.supp_id});
    });
    return suppOptions;
  }

  return (
    <>
      <AppNav/>
      <Row className="h1">
        <Col sm={8}>
          <h1>
            仕入一覧
          </h1>
        </Col>
      </Row>

      <Card className="card-search">
        <Container className="container-main-search">
          <Form.Group as={Row}>
            <Form.Label column sm="1">仕入れ業者</Form.Label>
            <Col sm="4">
              <AsyncSelect
                isClearable
                className="async-select"
                cacheOptions
                defaultOptions
                value={suppSelected}
                getOptionLabel={e => e.name}
                getOptionValue={e => e.id}
                loadOptions={onSearchSupp}
                onChange={handleChangeSupp}
                placeholder=""
              />
            </Col>
            <Col sm="1"></Col>
            <Form.Label column sm="1">発注番号</Form.Label>
            <Col sm="4">
              <Form.Control type="text" value={poNo} onChange={e => setPoNo(e.target.value)}/>
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm="1">仕入れ番号</Form.Label>
            <Col sm="4">
              <Form.Control type="text" value={rcvNo} onChange={e => setRcvNo(e.target.value)}/>
            </Col>
            <Col sm="1"></Col>
            <Form.Label column sm="1">発注件名</Form.Label>
            <Col sm="4">
              <Form.Control type="text" value={poTitle} onChange={e => setPoTitle(e.target.value)}/>
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm="1">仕入れ日</Form.Label>
            <Col sm="4" className="input-group">
              <DatePicker id="rcvDateFrom" selected={rcvDateFrom}
                          onChange={(date) => setRcvDateFrom(date)} dateFormat="yyyy年MM月dd日" locale="ja"/>
              <Button as={Link} variant="link" to={"/"} style={{paddingRight: "0"}}
                      onClick={e => setFocusCalendar(e, 'rcvDateFrom')}>
                <FontAwesomeIcon icon={faCalendarAlt} size="2x"/>
              </Button>
              <InputGroup.Text style={{border: "0px", backgroundColor: "#ffffff"}}>～</InputGroup.Text>
              <DatePicker id="rcvDateTo" selected={rcvDateTo} onChange={(date) => setRcvDateTo(date)}
                          dateFormat="yyyy年MM月dd日" locale="ja"/>
              <Button as={Link} variant="link" to={"/"} style={{paddingRight: "0"}}
                      onClick={e => setFocusCalendar(e, 'rcvDateTo')}>
                <FontAwesomeIcon icon={faCalendarAlt} size="2x"/>
              </Button>
            </Col>
          </Form.Group>
        </Container>
      </Card>

      <Container className="container-main-list">
        <InitialLoading loadingCss={loadingCss}/>
        <MdDatatable
          keyField="rcv_id"
          listData={listData}
          columns={columns}
          loading={loading}
          onTableChange={onTableChange}
          page={locPage}
          sizePerPage={locSizePerPage}
          totalSize={locTotalSize}
          sortField={locSortField}
          sortOrder={locSortOrder}
          showPagenation={true}
          linkToAdd="/rcv/"
        />
      </Container>
    </>
  );
}

export default withRouter(RcvList);