import React, {useEffect, useImperativeHandle, useState} from "react";
import {Col, Form, Row} from "react-bootstrap";
import {formatNumber, nvl, onFocusSelect, parseNumber} from "../CMUtil";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinusCircle} from "@fortawesome/free-solid-svg-icons";

export const InvDtlDeposRow = React.forwardRef((props, ref) => {

    const [index, setIndex] = useState(0);

    const [id, setId] = useState("");
    const [deposId, setDeposId] = useState("");
    const [lineNo, setLineNo] = useState(0);
    const [itemName, setItemName] = useState("");
    const [amount, setAmount] = useState("");

    //INITIALIZE
    useEffect(() => {

      const update = async () => {

        let locDtl = props.dtl;
        setId(nvl(locDtl.id));
        setIndex(props.index);
        setDeposId(locDtl.depos_id);
        setLineNo(locDtl.line_no);
        setItemName(nvl(locDtl.item_name));
        setAmount(nvl(locDtl.amount));

      }
      update();
    }, []);

    useEffect(() => {

      props.totalCalcDeposHandler();

    }, [amount]);

    useImperativeHandle(ref, () => ({
      getStates: () => {
        return {
          depos_dtl_id: id,
          index: index,
          line_no: lineNo,
          item_name: itemName,
          amount: amount
        };
      },
      setData: (data) => {
        setId(nvl(data.depos_dtl_id));
        setIndex(nvl(data.index));
        setLineNo(nvl(data.line_no));
        setItemName(nvl(data.item_name));
        setAmount(nvl(data.amount));
      }
    }), [
      index,
      id,
      lineNo,
      itemName,
      amount]);

    const onClickDeleteRow = (e) => {

      props.deleteRowDeposHandler(index);

    }

    return (
      <Row>
        <Col sm="7" className="dtl-data-col"
             className={"dtl-data-col dtl-data-col-left " + (props.isLastRow ? "dtl-data-col-bottom" : "")}
             style={{position: "relative"}}>
          <Form.Control
            id="itemName"
            type="text"
            value={itemName}
            onFocus={e => onFocusSelect(e)}
            onChange={e => setItemName(e.target.value)}
            placeholder=""
          />
        </Col>
        <Col sm="3" className={"dtl-data-col " + (props.isLastRow ? "dtl-data-col-bottom" : "")}>
          <Form.Control
            id="amount"
            type="tel"
            className="text-right"
            value={amount}
            onFocus={e => onFocusSelect(e)}
            onBlur={e => setAmount(formatNumber(parseNumber(e.target.value)))}
            onChange={e => setAmount(e.target.value)}
            placeholder=""
          />
        </Col>
        <Col sm="2" className={"dtl-data-col text-right " + (props.isLastRow ? "dtl-data-col-bottom" : "")}>
          <Button variant="add" style={{
            width: "100%",
            height: "2.2rem",
            margin: "0rem 0.05rem 0rem 0.05rem",
            borderRadius: "0.5rem"
          }}
                  onClick={e => onClickDeleteRow(e)}>
            <FontAwesomeIcon icon={faMinusCircle} size="lg" style={{marginBottom: "0.1rem"}}/>
            削除
          </Button>
        </Col>
      </Row>
    );
  }
);

export default React.memo(InvDtlDeposRow);