import React, {useEffect, useRef, useState} from 'react';
import {Link, useHistory, withRouter} from "react-router-dom";
import Button from 'react-bootstrap/Button';
import {downloadAPI, getAPI} from "../APIUtil";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import AppNav from "./AppNav";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import MdDatatable, {InitialLoading, sortCaret} from "../MdDataTable";
import {
  addField, checkKey,
  dateformat, dateformatJPNYYYYMMDD, finishLoadingBtn, formatZip, isLatestVersion,
  jumpToTopByDevice,
  onSearchCategory,
  onSearchNote2, onSearchOpnArea,
  onSearchPref, onSearchStatus1, parseDate,
  setFocusCalendar, startLoadingBtn,
  useDebounce,
} from "../CMUtil";
import {Card, Col, Container, Form, InputGroup, Row} from "react-bootstrap";
import {faCalendarAlt, faFileDownload} from "@fortawesome/free-solid-svg-icons";
import DatePicker, {registerLocale} from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import ja from "date-fns/locale/ja";
import AsyncSelect from "react-select/async";

registerLocale('ja', ja)

export const BookList = () => {

  const history = useHistory();
  const iniSearch = useRef(true);

  const [loadingCss, setLoadingCss] = useState("loading_panel_init");

  const [loading, setLoading] = useState(true);
  const [locPage, setLocPage] = useState(null);
  const [locSizePerPage, setLocSizePerPage] = useState(null);
  const [locTotalSize, setLocTotalSize] = useState(null);
  const [locSortField, setLocSortField] = useState(null);
  const [locSortOrder, setLocSortOrder] = useState(null);

  const [bookId, setBookId] = useState("");
  const debouncedBookId = useDebounce(bookId, 500, setLocPage);
  const [bookNo, setBookNo] = useState("");
  const debouncedBookNo = useDebounce(bookNo, 500, setLocPage);
  const [bookName, setBookName] = useState("");
  const debouncedBookName = useDebounce(bookName, 500, setLocPage);
  const [constName, setConstName] = useState("");
  const debouncedConstName = useDebounce(constName, 500, setLocPage);
  const [extDesignName, setExtDesignName] = useState("");
  const debouncedExtDesignName = useDebounce(extDesignName, 500, setLocPage);
  const [strDesignName, setStrDesignName] = useState("");
  const debouncedStrDesignName = useDebounce(strDesignName, 500, setLocPage);
  const [zip, setZip] = useState("");
  const debouncedZip = useDebounce(zip, 500, setLocPage);
  const [pref, setPref] = useState("");
  const debouncedPref = useDebounce(pref, 500, setLocPage);
  const [city, setCity] = useState("");
  const debouncedCity = useDebounce(city, 500, setLocPage);
  const [address, setAddress] = useState("");
  const debouncedAddress = useDebounce(address, 500, setLocPage);
  const [category, setCategory] = useState("");
  const debouncedCategory = useDebounce(category, 500, setLocPage);
  const [mainUsage, setMainUsage] = useState("");
  const debouncedMainUsage = useDebounce(mainUsage, 500, setLocPage);
  const [opnArea, setOpnArea] = useState("");
  const debouncedOpnArea = useDebounce(opnArea, 500, setLocPage);
  const [note1, setNote1] = useState("");
  const debouncedNote1 = useDebounce(note1, 500, setLocPage);
  const [note2, setNote2] = useState("");
  const debouncedNote2 = useDebounce(note2, 500, setLocPage);
  const [note3, setNote3] = useState("");
  const debouncedNote3 = useDebounce(note3, 500, setLocPage);
  const [picConst, setPicConst] = useState("");
  const debouncedPicConst = useDebounce(picConst, 500, setLocPage);
  const [picDesign, setPicDesign] = useState("");
  const debouncedPicDesign = useDebounce(picDesign, 500, setLocPage);
  const [startDateFrom, setStartDateFrom] = useState("");
  const debouncedStartDateFrom = useDebounce(startDateFrom, 500, setLocPage);
  const [startDateTo, setStartDateTo] = useState("");
  const debouncedStartDateTo = useDebounce(startDateTo, 500, setLocPage);
  const [completeDateFrom, setCompleteDateFrom] = useState("");
  const debouncedCompleteDateFrom = useDebounce(completeDateFrom, 500, setLocPage);
  const [completeDateTo, setCompleteDateTo] = useState("");
  const debouncedCompleteDateTo = useDebounce(completeDateTo, 500, setLocPage);
  const [warrantyDateFrom, setWarrantyDateFrom] = useState("");
  const debouncedWarrantyDateFrom = useDebounce(warrantyDateFrom, 500, setLocPage);
  const [warrantyDateTo, setWarrantyDateTo] = useState("");
  const debouncedWarrantyDateTo = useDebounce(warrantyDateTo, 500, setLocPage);

  const [status1, setStatus1] = useState("");
  const debouncedStatus1 = useDebounce(status1, 500, setLocPage);

  const [memo, setMemo] = useState("");
  const debouncedMemo = useDebounce(memo, 500, setLocPage);
  const [specialNote, setSpecialNote] = useState("");
  const debouncedSpecialNote = useDebounce(specialNote, 500, setLocPage);

  const [prefSelected, setPrefSelected] = useState([]);
  const [categorySelected, setCategorySelected] = useState([]);
  const [opnAreaSelected, setOpnAreaSelected] = useState([]);
  const [note2Selected, setNote2Selected] = useState([]);
  const [status1Selected, setStatus1Selected] = useState([]);

  const [listData, setListData] = useState([]);

  // 項目定義
  const columns = [
    {
      dataField: 'book_no',
      text: '工事番号',
      classes: 'tbl-col link',
      sort: true,
      headerStyle: {width: '85px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      },
      formatter: (cell, row) => (
        <div><a onClick={() => onClickLinkToDetail(row.book_id)}>{cell ? cell : "未定"}</a></div>
      )
    },
    {
      dataField: 'book_name',
      text: '現場名',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '200px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'const_name',
      text: '施工業者',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '200px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'ext_design_name',
      text: '設計事務所',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '200px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'str_design_name',
      text: '構造設計',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '200px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'book_address',
      text: '住所',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '400px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'category',
      text: '分類',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '150px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'main_usage',
      text: '主要用途',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '150px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'opn_area',
      text: '施工箇所',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '150px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'note1',
      text: '備考',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '150px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'note2',
      text: '改修',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '150px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'note3',
      text: 'デベロッパ',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '150px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'pic_const',
      text: '担当者',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '150px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'pic_design',
      text: '設計担当者',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '150px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'start_date',
      text: '着工日',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '120px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      },
      formatter: (cell, row) => (
        <span>{dateformatJPNYYYYMMDD(parseDate(cell))}</span>
      )
    },

    {
      dataField: 'complete_date',
      text: '完了日',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '120px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      },
      formatter: (cell, row) => (
        <span>{dateformatJPNYYYYMMDD(parseDate(cell))}</span>
      )
    },
    {
      dataField: 'warranty_date',
      text: '保証書',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '120px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      },
      formatter: (cell, row) => (
        <span>{dateformatJPNYYYYMMDD(parseDate(cell))}</span>
      )
    },
    {
      dataField: 'memo',
      text: 'メモ',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '150px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'special_note',
      text: '特記事項',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '150px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    }
  ];

  // 初期化
  useEffect(() => {
    const init = async () => {

      await isLatestVersion(false);
      jumpToTopByDevice(history);

      if (!history.location.state) {
        setLocPage(1);
      } else if (!history.location.state.pages) {
        setLocPage(1);
      } else if (!history.location.state.pages.filters) {
        setLocPage(1);
      } else if (Object.keys(history.location.state.pages.filters).length === 0) {
        setLocPage(1);
      } else {
        setLocPage(history.location.state.pages.page);
        setLocSizePerPage(history.location.state.pages.sizePerPage);
        setLocSortField(history.location.state.pages.sortField);
        setLocSortOrder(history.location.state.pages.sortOrder);
      }
    }
    init();
  }, []);

  // 検索処理
  useEffect(() => {

      if (iniSearch.current) {
        iniSearch.current = false;
        return;
      }

      const update = async () => {
        setLoading(true);
        let condition = {};

        let sortField = locSortField;
        let sortOrder = locSortOrder;
        if (!sortField && !sortOrder) {
          //デフォルト　着工日降順
          sortField = null;
          sortOrder = null;
        }
        addField(condition, "page", locPage);
        addField(condition, "size_per_page", locSizePerPage);
        addField(condition, "sort_field", sortField);
        addField(condition, "sort_order", sortOrder);
        addField(condition, "book_id", bookId);
        addField(condition, "book_no", bookNo);
        addField(condition, "const_name", constName);
        addField(condition, "ext_design_name", extDesignName);
        addField(condition, "str_design_name", strDesignName);
        addField(condition, "book_name", bookName);
        addField(condition, "zip", zip);
        addField(condition, "pref", pref);
        addField(condition, "city", city);
        addField(condition, "address", address);
        addField(condition, "category", category);
        addField(condition, "main_usage", mainUsage);
        addField(condition, "opn_area", opnArea);
        addField(condition, "note1", note1);
        addField(condition, "note2", note2);
        addField(condition, "note3", note3);
        addField(condition, "pic_const", picConst);
        addField(condition, "pic_design", picDesign);
        addField(condition, "start_date_from", dateformat(startDateFrom));
        addField(condition, "start_date_to", dateformat(startDateTo));
        addField(condition, "complete_date_from", dateformat(completeDateFrom));
        addField(condition, "complete_date_to", dateformat(completeDateTo));
        addField(condition, "warranty_date_from", dateformat(warrantyDateFrom));
        addField(condition, "warranty_date_to", dateformat(warrantyDateTo));
        addField(condition, "status1", status1);
        addField(condition, "memo", memo);
        addField(condition, "special_note", specialNote);

        const res = await getAPI("tr_book_search_view", condition);
        setListData(res);
        setLoading(false);
        setLoadingCss("loading_panel_fadeout");
      }
      update();
    },
    [
      locPage,
      locSizePerPage,
      locSortField,
      locSortOrder,
      debouncedBookId,
      debouncedBookNo,
      debouncedBookName,
      debouncedConstName,
      debouncedExtDesignName,
      debouncedStrDesignName,
      debouncedZip,
      debouncedPref,
      debouncedCity,
      debouncedAddress,
      debouncedCategory,
      debouncedMainUsage,
      debouncedOpnArea,
      debouncedNote1,
      debouncedNote2,
      debouncedNote3,
      debouncedPicConst,
      debouncedPicDesign,
      debouncedStartDateFrom,
      debouncedStartDateTo,
      debouncedCompleteDateFrom,
      debouncedCompleteDateTo,
      debouncedWarrantyDateFrom,
      debouncedWarrantyDateTo,
      debouncedStatus1,
      debouncedMemo,
      debouncedSpecialNote
    ]);

  useEffect(() => {

  }, []);

  const onTableChange = (type, {page, sizePerPage, filters, sortField, sortOrder, cellEdit}) => {
    if ("pagination" === type) {
      setLocPage(page);
    }
    if ("sizePerPage" === type) {
      setLocSizePerPage(sizePerPage);
    }
    if ("sort" === type) {
      setLocSortField(sortField);
      setLocSortOrder(sortOrder);
    }
  }

  const onClickLinkToDetail = (book_id) => {
    history.push("/book/" + book_id);
  }

  const handleChangePref = (value) => {
    setPrefSelected(value ? value : "");
    setPref(value ? value.id : "");
  }

  const handleChangeCategory = (value) => {
    setCategorySelected(value ?? "");
    setCategory(value?.id ?? "");
  }

  const handleChangeOpnArea = (value) => {
    setOpnAreaSelected(value ?? "");
    setOpnArea(value?.id ?? "");
  }

  const handleChangeNote2 = (value) => {
    setNote2Selected(value ?? "");
    setNote2(value?.id ?? "");
  }

  const handleChangeStatus1 = (value) => {
    setStatus1Selected(value ?? "");
    setStatus1(value?.id ?? "");
  }

  const onClickDownload = async () => {
    startLoadingBtn("btn-download", "secondary");
    let searchCondition = {
      sort_field: locSortField,
      sort_order: locSortOrder,
      book_id: bookId,
      const_name: constName,
      ext_design_name: extDesignName,
      str_design_name: strDesignName,
      book_name: bookName,
      zip: zip,
      pref: pref,
      city: city,
      address: address,
      category: category,
      main_usage: mainUsage,
      opn_area: opnArea,
      note1: note1,
      note2: note2,
      note3: note3,
      pic_const: picConst,
      pic_design: picDesign,
      start_date_from: dateformat(startDateFrom),
      start_date_to: dateformat(startDateTo),
      complete_date_from: dateformat(completeDateFrom),
      complete_date_to: dateformat(completeDateTo),
      warranty_date_from: dateformat(warrantyDateFrom),
      warranty_date_to: dateformat(warrantyDateTo),
      status1: status1,
      memo: memo,
      special_note: specialNote
    }
    await downloadAPI("download/book_list", searchCondition);
    finishLoadingBtn("btn-download");
  }

  const onClickClear = () => {
    setBookId("");
    setBookName("");
    setConstName("");
    // setConstSelected(null);
    setExtDesignName("");
    // setExtDesignSelected(null);
    setStrDesignName("");
    // setStrDesignSelected(null);
    setZip("");
    setPref("");
    setPrefSelected(null);
    setCity("");
    setAddress("");
    setCategory("");
    setCategorySelected(null);
    setMainUsage("");
    setOpnArea("");
    setOpnAreaSelected(null);
    setNote1("");
    setNote2("");
    setNote2Selected(null);
    setNote3("");
    setPicConst("");
    setPicDesign("");
    setStartDateFrom("");
    setStartDateTo("");
    setCompleteDateFrom("");
    setCompleteDateTo("");
    setWarrantyDateFrom("");
    setWarrantyDateTo("");
    setStatus1Selected(null);
    setMemo("");
    setSpecialNote("");
  }

  return (
    <>
      <AppNav/>
      <Row className="h1">
        <Col sm={8}>
          <h1>
            工事物件検索
          </h1>
        </Col>
        <Col sm="4" className="text-right">
          <Button variant="secondary" id="btn-download" onClick={onClickDownload}>
            <span>
            <FontAwesomeIcon icon={faFileDownload} size="lg"/>工事台帳
            </span>
          </Button>
        </Col>
      </Row>
      <Card className="card-search">
        <Container className="container-main-search">
          <Form.Group as={Row}>
            <Form.Label column sm="1">工事番号</Form.Label>
            <Col sm="4">
              <Form.Control type="text" value={bookNo} onChange={e => setBookNo(e.target.value)}/>
            </Col>
            <Col sm="6"></Col>
            <Col sm="1">
              <Button variant="clear" onClick={e => onClickClear()}>条件クリア</Button>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="1">現場名</Form.Label>
            <Col sm="4">
              <Form.Control type="text" value={bookName} onChange={e => setBookName(e.target.value)}/>
            </Col>
            <Col sm="1"></Col>
            <Form.Label column sm="1">施工業者</Form.Label>
            <Col sm="4">
              <Form.Control type="text" value={constName} onChange={e => setConstName(e.target.value)}/>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="1">意匠設計</Form.Label>
            <Col sm="4">
              <Form.Control type="text" value={extDesignName} onChange={e => setExtDesignName(e.target.value)}/>
            </Col>
            <Col sm="1"></Col>
            <Form.Label column sm="1">構造設計</Form.Label>
            <Col sm="4">
              <Form.Control type="text" value={strDesignName} onChange={e => setStrDesignName(e.target.value)}/>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="1">住所</Form.Label>
            <Col sm="2" className="input-group">
              <InputGroup.Text className="input-group-text-cm"
                               style={{fontWeight: "700", backgroundColor: "#ffffff"}}>〒</InputGroup.Text>
              <Form.Control id="zip"
                            type="text"
                            value={zip}
                            style={{textAlign: "center"}}
                            onKeyPress={e => checkKey(e, /[0-9\-]/)} maxLength={8}
                            onChange={e => setZip(e.target.value)}
                            onBlur={e => formatZip(e.target.value, setZip)}
              />
            </Col>
            <Col sm="2">
              <AsyncSelect sm="2"
                           className="async-select"
                           cacheOptions
                           defaultOptions
                           isClearable
                           value={prefSelected}
                           getOptionLabel={e => e.name}
                           getOptionValue={e => e.id}
                           loadOptions={onSearchPref}
                           onChange={handleChangePref}
                           placeholder=""
              />
            </Col>
            <Col sm="6" className="input-group">
              <Form.Control type="text" value={city}
                            style={{borderRadius: "0"}}
                            placeholder="市区町村"
                            onChange={e => setCity(e.target.value)}/>
              <Form.Control type="text" value={address}
                            style={{width: "20%", borderTopLeftRadius: "0", borderBottomLeftRadius: "0"}}
                            placeholder="住所"
                            onChange={e => setAddress(e.target.value)}/>
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm="1">分類</Form.Label>
            <Col sm="2">
              <AsyncSelect sm="2"
                           id="category"
                           className="async-select"
                           cacheOptions
                           defaultOptions
                           value={categorySelected}
                           getOptionLabel={e => e.name}
                           getOptionValue={e => e.id}
                           loadOptions={onSearchCategory}
                           onChange={handleChangeCategory}
                           placeholder=""
              />
            </Col>
            <Form.Label column sm="1"></Form.Label>
            <Form.Label column sm="1">主要用途</Form.Label>
            <Col sm="2">
              <Form.Control type="text" value={mainUsage} onChange={e => setMainUsage(e.target.value)}/>
            </Col>
            <Form.Label column sm="1"></Form.Label>
            <Form.Label column sm="1">施工箇所</Form.Label>
            <Col sm="2">
              <AsyncSelect sm="2"
                           id="opnArea"
                           className="async-select"
                           cacheOptions
                           defaultOptions
                           value={opnAreaSelected}
                           getOptionLabel={e => e.name}
                           getOptionValue={e => e.id}
                           loadOptions={onSearchOpnArea}
                           onChange={handleChangeOpnArea}
                           placeholder=""
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm="1">備考</Form.Label>
            <Col sm="4">
              <Form.Control type="text" value={note1} onChange={e => setNote1(e.target.value)}/>
            </Col>
            <Col sm="1"></Col>
            <Form.Label column sm="1">改修</Form.Label>
            <Col sm="4">
              <AsyncSelect sm="2"
                           id="note2"
                           className="async-select"
                           cacheOptions
                           defaultOptions
                           value={note2Selected}
                           getOptionLabel={e => e.name}
                           getOptionValue={e => e.id}
                           loadOptions={onSearchNote2}
                           onChange={handleChangeNote2}
                           placeholder=""
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm="1">デベロッパ</Form.Label>
            <Col sm="4">
              <Form.Control type="text" value={note3} onChange={e => setNote3(e.target.value)}/>
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm="1">担当者</Form.Label>
            <Col sm="4">
              <Form.Control type="text" value={picConst} onChange={e => setPicConst(e.target.value)}/>
            </Col>
            <Col sm="1"></Col>
            <Form.Label column sm="1">設計担当</Form.Label>
            <Col sm="4">
              <Form.Control type="text" value={picDesign} onChange={e => setPicDesign(e.target.value)}/>
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm="1">着工日</Form.Label>
            <Col sm="4" className="input-group">
              <DatePicker id="startDateFrom" selected={startDateFrom} onChange={(date) => setStartDateFrom(date)}
                          dateFormat="yyyy年MM月dd日" locale="ja"/>
              <Button as={Link} variant="link" to={"/"} style={{paddingRight: "0"}}
                      onClick={e => setFocusCalendar(e, 'startDateFrom')}>
                <FontAwesomeIcon icon={faCalendarAlt} size="2x"/>
              </Button>
              <InputGroup.Text style={{border: "0px", backgroundColor: "#ffffff"}}>～</InputGroup.Text>
              <DatePicker id="startDateTo" selected={startDateTo} onChange={(date) => setStartDateTo(date)}
                          dateFormat="yyyy年MM月dd日" locale="ja"/>
              <Button as={Link} variant="link" to={"/"} style={{paddingRight: "0"}}
                      onClick={e => setFocusCalendar(e, 'startDateTo')}>
                <FontAwesomeIcon icon={faCalendarAlt} size="2x"/>
              </Button>
            </Col>
            <Col sm="1"></Col>
            <Form.Label column sm="1">完了日</Form.Label>
            <Col sm="4" className="input-group">
              <DatePicker id="completeDateFrom" selected={completeDateFrom}
                          onChange={(date) => setCompleteDateFrom(date)} dateFormat="yyyy年MM月dd日" locale="ja"/>
              <Button as={Link} variant="link" to={"/"} style={{paddingRight: "0"}}
                      onClick={e => setFocusCalendar(e, 'completeDateFrom')}>
                <FontAwesomeIcon icon={faCalendarAlt} size="2x"/>
              </Button>
              <InputGroup.Text style={{border: "0px", backgroundColor: "#ffffff"}}>～</InputGroup.Text>
              <DatePicker id="completeDateTo" selected={completeDateTo} onChange={(date) => setCompleteDateTo(date)}
                          dateFormat="yyyy年MM月dd日" locale="ja"/>
              <Button as={Link} variant="link" to={"/"} style={{paddingRight: "0"}}
                      onClick={e => setFocusCalendar(e, 'completeDateTo')}>
                <FontAwesomeIcon icon={faCalendarAlt} size="2x"/>
              </Button>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="1">保証期間</Form.Label>
            <Col sm="4" className="input-group">
              <DatePicker id="warrantyDateFrom" selected={warrantyDateFrom}
                          onChange={(date) => setWarrantyDateFrom(date)} dateFormat="yyyy年MM月dd日" locale="ja"/>
              <Button as={Link} variant="link" to={"/"} style={{paddingRight: "0"}}
                      onClick={e => setFocusCalendar(e, 'warrantyDateFrom')}>
                <FontAwesomeIcon icon={faCalendarAlt} size="2x"/>
              </Button>
              <InputGroup.Text style={{border: "0px", backgroundColor: "#ffffff"}}>～</InputGroup.Text>
              <DatePicker id="warrantyDateTo" selected={warrantyDateTo} onChange={(date) => setWarrantyDateTo(date)}
                          dateFormat="yyyy年MM月dd日" locale="ja"/>
              <Button as={Link} variant="link" to={"/"} style={{paddingRight: "0"}}
                      onClick={e => setFocusCalendar(e, 'warrantyDateTo')}>
                <FontAwesomeIcon icon={faCalendarAlt} size="2x"/>
              </Button>
            </Col>
            <Col sm="1"></Col>
            <Form.Label column sm="1">ステータス</Form.Label>
            <Col sm="1">
              <AsyncSelect sm="1"
                           id="status1"
                           className="async-select"
                           cacheOptions
                           defaultOptions
                           isClearable
                           value={status1Selected}
                           getOptionLabel={e => e.name}
                           getOptionValue={e => e.id}
                           loadOptions={onSearchStatus1}
                           onChange={handleChangeStatus1}
                           placeholder=""
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="1">メモ</Form.Label>
            <Col sm="4">
              <Form.Control type="text" value={memo} onChange={e => setMemo(e.target.value)}/>
            </Col>
            <Col sm="1"></Col>
            <Form.Label column sm="1">特記事項</Form.Label>
            <Col sm="4">
              <Form.Control type="text" value={specialNote} onChange={e => setSpecialNote(e.target.value)}/>
            </Col>
          </Form.Group>
        </Container>
      </Card>

      <Container className="container-main-list">
        {/* *** 一覧 *** */}
        <InitialLoading loadingCss={loadingCss}/>
        <MdDatatable
          keyField="book_id"
          listData={listData}
          columns={columns}
          loading={loading}
          onTableChange={onTableChange}
          page={locPage}
          sizePerPage={locSizePerPage}
          totalSize={locTotalSize}
          sortField={locSortField}
          sortOrder={locSortOrder}
          showPagenation={true}
          linkToAdd="/book/"
        />
      </Container>
    </>
  );
}

export default withRouter(BookList);