import React, {useEffect, useImperativeHandle, useState} from "react";
import {Col, Form, Row} from "react-bootstrap";
import {
  formatNumber,
  nvl,
  parseNumber,
} from "../CMUtil";

export const RcvDtlRow = React.forwardRef((props, ref) => {

    const [index, setIndex] = useState(0);
    const [readOnly, setReadOnly] = useState(false);

    const [id, setId] = useState("");
    const [lineNo, setLineNo] = useState(0);
    const [itemId, setItemId] = useState("");
    const [itemName, setItemName] = useState("");
    const [unit, setUnit] = useState("");
    const [price, setPrice] = useState("");
    const [amount, setAmount] = useState("");

    const [poQty, setPoQty] = useState("");
    const [rcvQty, setRcvQty] = useState("");
    const [remainQty, setRemainQty] = useState("");
    const [qty, setQty] = useState("");

    //INITIALIZE
    useEffect(() => {

      const update = async () => {

        setReadOnly(props.readOnly);

        let locDtl = props.dtl;
        setIndex(props.index);
        setId(nvl(locDtl.po_dtl_id));
        setLineNo(locDtl.line_no);
        setItemId(nvl(locDtl.item_id));
        setItemName(nvl(locDtl.item_name));
        setUnit(nvl(locDtl.unit));
        setPrice(nvl(locDtl.price));
        setAmount(nvl(locDtl.amount));
        setPoQty(formatNumber(locDtl.po_qty));
        setRcvQty(formatNumber(locDtl.rcv_qty));
        setQty(formatNumber(nvl(locDtl.qty)));
        setRemainQty(formatNumber(locDtl.remain_qty));

      }
      update();
    }, []);

    useEffect(() => {

      if (!poQty) {
        return;
      }

      let locQty = formatNumber(qty);
      if (!locQty) {
        locQty = 0;
      }

      setRemainQty(parseInt(poQty) - parseInt(rcvQty) - parseInt(locQty));

    }, [qty]);

    useImperativeHandle(ref, () => ({
      getStates: () => {
        return {
          index: index,
          po_dtl_id: id,
          line_no: lineNo,
          item_id: itemId,
          item_name: itemName,
          unit: unit,
          price: price,
          amount: amount,
          po_qty: poQty,
          rcv_qty: rcvQty,
          qty: qty,
          remain_qty: remainQty
        };
      },
      setData: (data) => {
        setReadOnly(data.read_only);
      }
    }), [
      index,
      id,
      lineNo,
      itemId,
      itemName,
      unit,
      price,
      amount,
      poQty,
      rcvQty,
      remainQty
    ]);

    return (
      <Row>
        <Col sm="5"
             className={"dtl-data-col dtl-data-col-left " + (props.isLastRow ? "dtl-data-col-bottom" : "")}
             style={{position: "relative"}}>
          <Form.Control
            id="itemName"
            type="text"
            value={itemName}
            readOnly={true}
          />
        </Col>
        <Col sm="1" className={"dtl-data-col " + (props.isLastRow ? "dtl-data-col-bottom" : "")}>
          <Form.Control
            id="unit"
            type="text"
            value={unit}
            readOnly={true}
          />
        </Col>
        <Col sm="1" className={"dtl-data-col " + (props.isLastRow ? "dtl-data-col-bottom" : "")}>
          <Form.Control
            id="poQty"
            type="tel"
            className="text-right"
            value={poQty}
            readOnly={true}
          />
        </Col>
        <Col sm="1" className={"dtl-data-col " + (props.isLastRow ? "dtl-data-col-bottom" : "")}>
          <Form.Control
            id="rcvQty"
            type="tel"
            className="text-right"
            value={rcvQty}
            readOnly={true}
          />
        </Col>
        <Col sm="1" className={"dtl-data-col " + (props.isLastRow ? "dtl-data-col-bottom" : "")}>
          <Form.Control
            id="qty"
            type="tel"
            className="text-right"
            value={qty}
            maxLength={4}
            readOnly={readOnly || poQty === rcvQty}
            onChange={e => setQty(e.target.value)}
            onBlur={e => setQty(formatNumber(parseNumber(e.target.value)))}
            placeholder=""
          />
        </Col>
        <Col sm="1" className={"dtl-data-col " + (props.isLastRow ? "dtl-data-col-bottom" : "")}>
          <Form.Control
            id="remainQty"
            type="tel"
            className="text-right"
            value={remainQty}
            readOnly={true}
          />
        </Col>
      </Row>
    );
  }
);

export default React.memo(RcvDtlRow);