import {DropdownButton, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {Link} from "react-router-dom";
import {RoutePaths} from "./PageRoutesAuthenticated";
import React, {useContext, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faClipboard,
  faCog,
  faCubes,
  faFolderOpen,
  faUser
} from "@fortawesome/free-solid-svg-icons";
import {dateformatYYYYMMDD, logout} from "../CMUtil";
import {GlobalContext} from "../Global";

export const AppNav = () => {

  const [month, setMonth] = useState("");
  const [today, setToday] = useState("");

  const {loginUser, setLoginUser} = useContext(GlobalContext);
  const [permission, setPermission] = useState("");

  //初期化
  useEffect(() => {
    const update = async () => {
      let date = new Date();
      setMonth(dateformatYYYYMMDD(new Date()).substr(0, 6));
      setToday(dateformatYYYYMMDD(new Date()));
    }
    update();

    if (loginUser && loginUser.permission) {
      setPermission(loginUser.permission.code_id);
    }

  }, []);

  const navDropDownSales = (<span><FontAwesomeIcon icon={faFolderOpen} size="1x"/>販売業務　</span>);
  const navDropDownPurchase = (<span><FontAwesomeIcon icon={faCubes}/>購入業務　</span>);
  const navDropDownConstruct = (<span><FontAwesomeIcon icon={faClipboard}/>工事管理　</span>);
  const navDropDownMaster = (<span><FontAwesomeIcon icon={faCog}/>マスタ設定　</span>);
  const navDropDownUser = (
    <span><FontAwesomeIcon icon={faUser}/>{loginUser.last_name + ' ' + loginUser.first_name}　</span>);

  return (
    <Navbar expand="lg">
      <Navbar.Toggle aria-controls="basic-navbar-nav"/>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          <DropdownButton title={navDropDownSales} variant="nav" style={{width: "150px", marginTop: "0"}}>
            <NavDropdown.Item as={Link} to={RoutePaths.root.quot_list}>見積管理</NavDropdown.Item>
            <NavDropdown.Item as={Link} to={RoutePaths.root.co_list}>受注管理</NavDropdown.Item>
            <NavDropdown.Item as={Link} to={RoutePaths.root.inv_list}>請求書管理</NavDropdown.Item>
          </DropdownButton>

          <DropdownButton title={navDropDownPurchase} variant="nav" style={{width: "150px", marginTop: "0"}}>
            <NavDropdown.Item as={Link} to={RoutePaths.root.po_opn_list}>作業発注管理</NavDropdown.Item>
            <NavDropdown.Item as={Link} to={RoutePaths.root.po_item_list}>資材発注管理</NavDropdown.Item>
            <NavDropdown.Item as={Link} to={RoutePaths.root.rcv_list}>仕入管理</NavDropdown.Item>
          </DropdownButton>

          <DropdownButton title={navDropDownConstruct} variant="nav" style={{width: "150px", marginTop: "0"}}>
            <NavDropdown.Item as={Link} to={RoutePaths.root.book_list}>工事台帳管理</NavDropdown.Item>
            <NavDropdown.Item as={Link} to={"/cal/" + month}>作業予定管理</NavDropdown.Item>
            <NavDropdown.Item as={Link} to={RoutePaths.root.book_status}>現場別予定表</NavDropdown.Item>
            <NavDropdown.Item as={Link} to={"/cal_week/" + today}>作業者別予定表</NavDropdown.Item>
          </DropdownButton>

          <DropdownButton title={navDropDownMaster} variant="nav" style={{width: "150px", marginTop: "0"}}>
            <NavDropdown.Item as={Link} to={RoutePaths.root.item_list}>資材</NavDropdown.Item>
            <NavDropdown.Item as={Link} to={RoutePaths.root.stk_list}>在庫表</NavDropdown.Item>
            <NavDropdown.Item as={Link} to={RoutePaths.root.stk_in_out_list}>資材入出庫管理</NavDropdown.Item>
            <NavDropdown.Item as={Link} to={RoutePaths.root.const_list}>施工業者</NavDropdown.Item>
            <NavDropdown.Item as={Link} to={RoutePaths.root.design_list}>設計事務所</NavDropdown.Item>
            <NavDropdown.Item as={Link} to={RoutePaths.root.supp_list}>仕入れ業者</NavDropdown.Item>
            <NavDropdown.Item as={Link} to={RoutePaths.root.code_list}>システム設定</NavDropdown.Item>
            <NavDropdown.Item as={Link} to={RoutePaths.root.user_list}>利用者</NavDropdown.Item>
            <NavDropdown.Item as={Link} to={RoutePaths.root.com_dtl}>会社情報</NavDropdown.Item>
          </DropdownButton>
          {/*<Nav.Link as={Link} to={RoutePaths.root.doc_list}>*/}
          {/*  <FontAwesomeIcon icon={faCog}/>*/}
          {/*  ドキュメント*/}
          {/*</Nav.Link>*/}
        </Nav>
      </Navbar.Collapse>
      <Navbar.Collapse className="justify-content-end">
        <DropdownButton title={navDropDownUser} variant="nav" style={{width: "150px", marginTop: "0"}}>
          <NavDropdown.Item onClick={e => logout()}>ログアウト</NavDropdown.Item>
        </DropdownButton>
      </Navbar.Collapse>
    </Navbar>
  )
};

export default AppNav;