import React, {useEffect, useRef, useState} from 'react';
import {Link, useHistory, withRouter} from "react-router-dom";
import Button from 'react-bootstrap/Button';
import {getAPI} from "../APIUtil";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import AppNav from "./AppNav";
import {Card, Col, Container, Form, InputGroup, Row} from "react-bootstrap";
import {
  addField,
  formatNumber,
  dateformat,
  setFocusCalendar,
  useDebounce,
  dateformatJPNYYYYMMDD,
  parseDate,
  onSearchItem, nvl
} from "../CMUtil";
import MdDatatable, {InitialLoading, sortCaret} from "../MdDataTable";
import AsyncSelect from "react-select/async";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons";
import DatePicker, {registerLocale} from "react-datepicker";
import ja from "date-fns/locale/ja";

registerLocale('ja', ja)

export const StkInOutList = ({match: {params: {item_id}}}) => {

  const history = useHistory();
  const iniSearch = useRef(true);

  const [loadingCss, setLoadingCss] = useState("loading_panel_init");

  const [loading, setLoading] = useState(true);
  const [locPage, setLocPage] = useState(null);
  const [locSizePerPage, setLocSizePerPage] = useState(null);
  const [locTotalSize, setLocTotalSize] = useState(null);
  const [locSortField, setLocSortField] = useState(null);
  const [locSortOrder, setLocSortOrder] = useState(null);

  const [inOutId, setInOutId] = useState("");
  const debouncedInOutId = useDebounce(inOutId, 500, setLocPage);
  const [qty, setQty] = useState("");
  const debouncedQty = useDebounce(qty, 500, setLocPage);
  const [inOutQty, setInOutQty] = useState("");
  const debouncedInOutQty = useDebounce(inOutQty, 500, setLocPage);
  const [itemId, setItemId] = useState("");
  const debouncedItemId = useDebounce(itemId, 500, setLocPage);
  const [loadType1, setLoadType1] = useState("");
  const debouncedLoadType1 = useDebounce(loadType1, 500, setLocPage);
  const [loadType2, setLoadType2] = useState("");
  const debouncedLoadType2 = useDebounce(loadType2, 500, setLocPage);
  const [loadDateFrom, setLoadDateFrom] = useState("");
  const debouncedLoadDateFrom = useDebounce(loadDateFrom, 500, setLocPage);
  const [loadDateTo, setLoadDateTo] = useState("");
  const debouncedLoadDateTo = useDebounce(loadDateTo, 500, setLocPage);

  const [itemSelected, setItemSelected] = useState([]);

  const [checkedLoadType1, setCheckedLoadType1] = useState(false);
  const [checkedLoadType2, setCheckedLoadType2] = useState(false);
  const [listData, setListData] = useState([]);

  // 項目定義
  const columns = [
    {
      dataField: 'in_out_id',
      text: 'ID',
      hidden: true,
      sort: false,
      headerStyle: {width: '80px'}
    },
    // {
    //   dataField: 'col_no',
    //   text: 'No',
    //   classes: 'tbl-col link',
    //   sort: false,
    //   headerStyle: {width: '50px'},
    //   sortCaret: (order, column) => {
    //     return sortCaret(order, column);
    //   },
    //   formatter: (cell, row) => (
    //     <div><a onClick={() => onClickLinkToDetail(row.in_out_id)}>{cell}</a></div>
    //   )
    // },
    {
      dataField: 'load_date',
      text: '日時',
      classes: 'tbl-col link',
      sort: true,
      headerStyle: {width: '200px'},
      formatter: (cell, row) => (
        <div><a onClick={() => onClickLinkToDetail(row.in_out_id)}>{dateformatJPNYYYYMMDD(parseDate(cell))}</a></div>
      ),
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'item_name',
      text: '資材',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '400px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'load_type',
      text: '入出庫',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '100px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'in_out_qty',
      text: '数量',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '80px', textAlign:"right"},
      align: 'right',
      formatter: (cell, row) => (
        <span>{formatNumber(cell)}</span>
      )
    },
    {
      dataField: 'remarks',
      text: '備考',
      classes: 'tbl-col',
      sort: true,
      formatter: (cell, row) => (
        <span>{nvl(row.cause_type) + ' ' + nvl(row.remarks)}</span>
      )
    }
  ];

  // 初期化
  useEffect(() => {

    const update = async () => {

      if (item_id) {
        let obj = await getAPI("ms_item/" + item_id + "/");
        setItemId(obj.item_id);
        setItemSelected({id: obj.item_id, name: obj.item_name});
      }
    }
    update();

  }, []);

  useEffect(() => {

    if (iniSearch.current) {
      iniSearch.current = false;
      return;
    }

    const update = async () => {
      setLoading(true);
      let condition = {};
      addField(condition, "page", locPage);
      addField(condition, "size_per_page", locSizePerPage);
      addField(condition, "sort_field", locSortField);
      addField(condition, "sort_order", locSortOrder);
      addField(condition, "in_out_qty", inOutQty);
      addField(condition, "qty", qty);
      addField(condition, "item_id", itemId);
      addField(condition, "load_type_1", loadType1);
      addField(condition, "load_type_2", loadType2);
      addField(condition, "load_date_from", dateformat(loadDateFrom));
      addField(condition, "load_date_to", dateformat(loadDateTo));

      const res = await getAPI("tr_in_out_search_view", condition);
      setListData(res);
      setLoading(false);
      setLoadingCss("loading_panel_fadeout");
    }
    update();
  }, [
    locPage,
    locSizePerPage,
    locSortField,
    locSortOrder,
    debouncedInOutId,
    debouncedInOutQty,
    debouncedLoadType1,
    debouncedLoadType2,
    debouncedQty,
    debouncedLoadDateFrom,
    debouncedLoadDateTo,
    debouncedItemId
  ]);

  const onTableChange = (type, {page, sizePerPage, filters, sortField, sortOrder, cellEdit}) => {
    if ("pagination" === type) {
      setLocPage(page);
    }
    if ("sizePerPage" === type) {
      setLocSizePerPage(sizePerPage);
    }
    if ("sort" === type) {
      setLocSortField(sortField);
      setLocSortOrder(sortOrder);
    }
  }

  const handleLoadType1Change = (e) => {
    if (e.target.checked) {
      setLoadType1("入庫")
    } else {
      setLoadType1("")
    }

    setCheckedLoadType1(e.target.checked);
  }

  const handleLoadType2Change = (e) => {
    if (e.target.checked) {
      setLoadType2("出庫")
    } else {
      setLoadType2("")
    }

    setCheckedLoadType2(e.target.checked);
  }

  const handleChangeItem = (value) => {
    setItemSelected(value ? value : "");
    setItemId(value ? value.id : "");
  }

  const onClickLinkToDetail = (in_out_id) => {
    history.push("/stk_in_out/" + in_out_id);
  }

  return (
    <>
      <AppNav/>
      <Row className="h1">
        <Col sm={8}>
          <h1>
            資材入出庫管理
          </h1>
        </Col>
      </Row>

      <Card className="card-search">
        <Container className="container-main-search">
          <Form.Group as={Row}>
            <Form.Label column sm="1">資材</Form.Label>
            <Col sm="4">
              <AsyncSelect
                className="async-select"
                isClearable
                cacheOptions
                defaultOptions
                value={itemSelected}
                getOptionLabel={e => e.name}
                getOptionValue={e => e.id}
                loadOptions={e => onSearchItem(e, 10)}
                onChange={handleChangeItem}
                placeholder=""
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm="1">種別</Form.Label>
            <Col sm="2">
              <Row>
                <Col>
                  <Form.Check
                    id="inOutType1"
                    type="checkbox"
                    name="loadType"
                    label="入庫"
                    style={{marginRight: "0rem"}}
                    onChange={e => handleLoadType1Change(e)}
                    checked={checkedLoadType1}/>
                </Col>
              </Row>
            </Col>
            <Col sm="2">
              <Row>
                <Col>
                  <Form.Check
                    id="inOutType2"
                    type="checkbox"
                    name="loadType"
                    label="出庫"
                    style={{marginRight: "0rem"}}
                    onChange={e => handleLoadType2Change(e)}
                    checked={checkedLoadType2}/>
                </Col>
              </Row>
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm="1">期間</Form.Label>
            <Col sm="4" className="input-group">
              <DatePicker id="loadDateFrom" selected={loadDateFrom}
                          onChange={(date) => setLoadDateFrom(date)} dateFormat="yyyy年MM月dd日" locale="ja"/>
              <Button as={Link} variant="link" to={"/"} style={{paddingRight: "0"}}
                      onClick={e => setFocusCalendar(e, 'loadDateFrom')}>
                <FontAwesomeIcon icon={faCalendarAlt} size="2x"/>
              </Button>
              <InputGroup.Text style={{border: "0px", backgroundColor: "#ffffff"}}>～</InputGroup.Text>
              <DatePicker id="loadDateTo" selected={loadDateTo} onChange={(date) => setLoadDateTo(date)}
                          dateFormat="yyyy年MM月dd日" locale="ja"/>
              <Button as={Link} variant="link" to={"/"} style={{paddingRight: "0"}}
                      onClick={e => setFocusCalendar(e, 'loadDateTo')}>
                <FontAwesomeIcon icon={faCalendarAlt} size="2x"/>
              </Button>
            </Col>
          </Form.Group>
        </Container>
      </Card>

      <Container className="container-main-list">
        <InitialLoading loadingCss={loadingCss}/>
        <MdDatatable
          keyField="in_out_id"
          listData={listData}
          columns={columns}
          loading={loading}
          onTableChange={onTableChange}
          page={locPage}
          sizePerPage={locSizePerPage}
          totalSize={locTotalSize}
          sortField={locSortField}
          sortOrder={locSortOrder}
          showPagenation={true}
          linkToAdd="/stk_in_out/"
        />
      </Container>
    </>
  );
}

export default withRouter(StkInOutList);