import React from 'react';
import {withRouter} from "react-router-dom";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import {PoList} from "./PoList";

export const PoOpnList = () => {

  return (
    <>
      <PoList poType={"作業"}/>
    </>
  );
}

export default withRouter(PoOpnList);