import React, {useEffect, useRef, useState} from 'react';
import {Link, useHistory, withRouter} from "react-router-dom";
import {Card, Col, Container, Form, Row, Modal} from "react-bootstrap";
import {deleteAPI, getAPI, postAPI, putAPI} from "../APIUtil";
import Button from "react-bootstrap/Button";
import AppNav from "./AppNav";
import 'react-toastify/dist/ReactToastify.css';
import Toast, {success} from "../Toast";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt, faPen, faPlusCircle, faQrcode, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker"
import AsyncSelect from "react-select/async";
import {
  setFocusCalendar,
  toNull,
  nvl,
  dateformatDB,
  parseDate,
  addField,
  formatNumber,
  parseNumber, onSearchCo, onSearchBook
} from "../CMUtil";
import {InvDtlRow} from "./InvDtlRow";
import {InvDtlDeposRow} from "./InvDtlDeposRow";
import {PDFDownloadLink} from "@react-pdf/renderer";
import PoPDF from "../doc/PoPDF";
import InvPDF from "../doc/InvPDF";


export const InvDtl = ({match: {params: {inv_id}}}) => {

  const hist = useHistory();

  const [invId, setInvId] = useState("");
  const [invNo, setInvNo] = useState("");
  const [invTitle, setInvTitle] = useState("");
  const [constSelected, setConstSelected] = useState([]);
  const [constId, setConstId] = useState("");
  const [coSelected, setCoSelected] = useState([]);
  const [coId, setCoId] = useState("");
  const [coTitle, setCoTitle] = useState("");
  const [bookSelected, setBookSelected] = useState([]);
  const [bookId, setBookId] = useState("");
  const [invDate, setInvDate] = useState("");
  const [note, setNote] = useState("");
  const [subTotal, setSubTotal] = useState("");
  const [tax, setTax] = useState("");
  const [ttlAmount, setTtlAmount] = useState("");
  const [updateCnt, setUpdateCnt] = useState(0);

  const [dtlList, setDtlList] = useState([]);
  const dtlListRef = useRef([]);

  const [deposModal, setDeposModal] = useState(false);
  const [deposId, setDeposId] = useState("");
  const [deposDate, setDeposDate] = useState("");
  const [deposTtlAmount, setDeposTtlAmount] = useState("");
  const [deposConstName, setDeposConstName] = useState("");

  const [dtlDeposList, setDtlDeposList] = useState([]);
  const dtlDeposListRef = useRef([]);

  //初期化
  useEffect(() => {

    const update = async () => {

      let locDtlList = [];
      if (!inv_id) {
        //INSERT
        for (let i = 0; i < 10; i++) {
          locDtlList.push({
            index: i,
            line_no: i + 1,
            id: null,
            item_type: null,
            item_id: null,
            qty: null,
            unit: null,
            price: null,
            amount: null
          });
        }

      } else {
        //UPDATE
        let obj = await getAPI("tr_inv/" + inv_id);
        setInvId(inv_id);
        setInvId(obj.inv_id);
        setInvNo(obj.inv_no);
        setInvTitle(nvl(obj.inv_title));
        if (obj.const) {
          setConstId(obj.const.const_id);
          setConstSelected([{const_id: obj.const.const_id, name: obj.const.name}]);
        }
        if (obj.co) {
          setCoId(obj.co.co_id);
          setCoSelected([{id: obj.co.co_id, name: obj.co.co_no + ' ' + obj.co.co_title}]);
        }
        if (obj.book) {
          setBookId(obj.book.book_id);
          setBookSelected([{id: obj.book.book_id, name: obj.book.book_no + ' ' + obj.book.book_name}]);
        }

        setInvDate(nvl(parseDate(obj.inv_date)));
        setSubTotal(formatNumber(obj.sub_total));
        setTax(formatNumber(obj.tax));
        setTtlAmount(formatNumber(obj.amount_total));
        setNote(nvl(obj.note));

        let condition = {};
        addField(condition, "inv_id", inv_id);
        const res = await getAPI("tr_inv_dtl_search_view", condition);

        for (let i = 0; i < Math.max(10, res.results.length); i++) {
          locDtlList.push({
            id: i < res.results.length ? res.results[i].inv_dtl_id : null,
            index: i,
            line_no: i + 1,
            item_type: null,
            item_id: i < res.results.length ? res.results[i].item_id : null,
            item_name: i < res.results.length ? res.results[i].item_name : null,
            qty: i < res.results.length ? res.results[i].qty : null,
            unit: i < res.results.length ? res.results[i].unit : null,
            price: i < res.results.length ? res.results[i].price : null,
            amount: i < res.results.length ? res.results[i].amount : null
          });
        }
      }

      for (let i = 0; i < locDtlList.length; i++) {
        dtlListRef.current[i] = React.createRef();
      }
      setDtlList(locDtlList);

    }
    update();
  }, []);

  const onClickAddRow = () => {
    let locDtlList = JSON.parse(JSON.stringify(dtlList));
    locDtlList.push({
      index: locDtlList.length,
      line_no: locDtlList.length + 1,
      id: null,
      item_type: null,
      item_id: null,
      item_name: null,
      qty: null,
      unit: null,
      price: null,
      amount: null
    });
    for (let i = 0; i < locDtlList.length; i++) {
      dtlListRef.current[i] = React.createRef();
    }
    setDtlList(locDtlList);
  }

  const onClickAddDepRow = () => {
    let locDtlDeposList = JSON.parse(JSON.stringify(dtlDeposList));
    locDtlDeposList.push({
      index: locDtlDeposList.length,
      line_no: locDtlDeposList.length + 1,
      id: null,
      item_name: null,
      amount: null
    });
    for (let i = 0; i < locDtlDeposList.length; i++) {
      dtlDeposListRef.current[i] = React.createRef();
    }
    setDtlDeposList(locDtlDeposList);
  }

  const onClickInsert = async () => {

    let obj = newData();
    obj = await postAPI("tr_inv/", obj);

    let id = obj.inv_id;
    for (const dtl of dtlListRef.current) {
      let dtlObj = dtl.current.getStates();
      if (!dtlObj.item_name || !dtlObj.amount) {
        continue;
      }
      dtlObj = newDtlData(id, dtlObj);
      dtlObj = await postAPI("tr_inv_dtl/", dtlObj);
    }

    setInvId(obj.inv_id);
    hist.push("/inv/" + obj.inv_id);
    setUpdateCnt(updateCnt + 1);
    success("登録完了");
  }

  const onClickUpdate = async () => {
    let obj = newData();
    await putAPI("tr_inv/" + invId + "/", obj);

    let updateDtlIdList = [];
    for (const dtl of dtlListRef.current) {
      let dtlObj = dtl.current.getStates();
      if (!dtlObj.item_name || !dtlObj.amount) {
        continue;
      }
      dtlObj = newDtlData(invId, dtlObj);
      if (!dtlObj.inv_dtl_id) {
        dtlObj = await postAPI("tr_inv_dtl/", dtlObj);
      } else {
        dtlObj = await putAPI("tr_inv_dtl/" + dtlObj.inv_dtl_id, dtlObj);
      }
      updateDtlIdList.push(dtlObj.inv_dtl_id);
    }

    let condition = {};
    addField(condition, "inv_id", invId);
    let res = await getAPI("tr_inv_dtl_search_view", condition);
    for (const dbDtl of res.results) {
      if (!updateDtlIdList.includes(dbDtl.inv_dtl_id)) {
        await deleteAPI("tr_inv_dtl/" + dbDtl.inv_dtl_id + "/", obj);
      }
    }

    success("更新完了");
    setUpdateCnt(updateCnt + 1);
  }

  const onClickDelete = async () => {

    let condition = {};
    addField(condition, "inv_id", invId);
    res = await getAPI("tr_inv_dtl_search_view", condition);

    let dtlList = res.results;
    for (const dtl of dtlList) {
      await deleteAPI("tr_inv_dtl/" + dtl.inv_dtl_id + "/", {'inv_dtl_id': dtl.inv_dtl_id});
    }

    let res = await deleteAPI("tr_inv/" + invId + "/", {"inv_id": invId});
    success("削除完了");
    hist.push("/inv_list/");
  }

  const newData = () => {
    return {
      inv_id: toNull(invId),
      inv_no: toNull(invNo),
      inv_title: toNull(invTitle),
      inv_date: toNull(dateformatDB(invDate)),
      sub_total: toNull(parseNumber(subTotal)),
      const_id: toNull(constId),
      co_id: toNull(coId),
      book_id: toNull(bookId),
      tax: toNull(parseNumber(tax)),
      ttl_amount: toNull(parseNumber(ttlAmount)),
      note: toNull(note),
      create_pg: 'InvDtl',
      update_pg: 'InvDtl',
    };
  }

  const newDtlData = (invId, data) => {
    return {
      inv_dtl_id: toNull(data.inv_dtl_id),
      inv_id: toNull(invId),
      item_id: toNull(data.item_id),
      item_name: toNull(data.item_name),
      line_no: toNull(data.line_no),
      qty: toNull(parseNumber(data.qty)),
      unit: toNull(data.unit),
      price: toNull(parseNumber(data.price)),
      amount: toNull(parseNumber(data.amount)),
      create_pg: 'InvDtl',
      update_pg: 'InvDtl',
    };
  }

  const showDeposModal = async () => {

    let condition = {};
    addField(condition, "inv_id", inv_id);
    const resDepos = await getAPI("tr_depos_search_view", condition);

    //UPDATE
    let locDeposId = "";
    let locDeposList = [];
    let dbDeposList = [];
    if (resDepos.count === 1) {
      locDeposId = resDepos.results[0].depos_id;
      let depos = await getAPI("tr_depos/" + locDeposId);
      setDeposId(nvl(locDeposId));
      setDeposDate(nvl(parseDate(depos.depos_date)));

      let condition = {};
      addField(condition, "depos_id", locDeposId);
      const res = await getAPI("tr_depos_dtl_search_view", condition);
      dbDeposList = res.results;

    }

    for (let i = 0; i < Math.max(3, dbDeposList.length); i++) {
      locDeposList.push({
        id: i < dbDeposList.length ? dbDeposList[i].depos_dtl_id : null,
        index: i,
        line_no: i + 1,
        item_name: i < dbDeposList.length ? dbDeposList[i].item_name : null,
        amount: i < dbDeposList.length ? dbDeposList[i].amount : null
      });
    }

    for (let i = 0; i < locDeposList.length; i++) {
      dtlDeposListRef.current[i] = React.createRef();
    }
    setDtlDeposList(locDeposList);
    if (constId) {
      let constObj = await getAPI("ms_const/" + constId);
      setDeposConstName(constObj.name);
    }

    setDeposModal(true);
  }

  const onClickDeposInsert = async () => {

    let obj = newDeposData();
    obj = await postAPI("tr_depos/", obj);

    let id = obj.depos_id;
    for (const dtl of dtlDeposListRef.current) {
      let dtlObj = dtl.current.getStates();
      if (!dtlObj.item_name || !dtlObj.amount) {
        continue;
      }
      dtlObj = newDeposDtlData(id, dtlObj);
      dtlObj = await postAPI("tr_depos_dtl/", dtlObj);
    }

    setDeposId(obj.depos_id);
    hist.push("/inv/" + obj.inv_id);
    setUpdateCnt(updateCnt + 1);
    success("登録完了");
  }

  const onClickDeposUpdate = async () => {
    let obj = newDeposData();
    await putAPI("tr_depos/" + deposId + "/", obj);

    let updateDeposDtlIdList = [];
    for (const dtl of dtlDeposListRef.current) {
      let dtlObj = dtl.current.getStates();
      if (!dtlObj.item_name || !dtlObj.amount) {
        continue;
      }
      dtlObj = newDeposDtlData(deposId, dtlObj);
      if (!dtlObj.depos_dtl_id) {
        dtlObj = await postAPI("tr_depos_dtl/", dtlObj);
      } else {
        dtlObj = await putAPI("tr_depos_dtl/" + dtlObj.inv_depos_id, dtlObj);
      }
      updateDeposDtlIdList.push(dtlObj.depos_dtl_id);
    }

    let condition = {};
    addField(condition, "depos_id", deposId);
    let res = await getAPI("tr_depos_dtl_search_view", condition);
    for (const dbDtl of res.results) {
      if (!updateDeposDtlIdList.includes(dbDtl.depos_dtl_id)) {
        await deleteAPI("tr_depos_dtl/" + dbDtl.depos_dtl_id + "/", obj);
      }
    }

    success("更新完了");
    setUpdateCnt(updateCnt + 1);
  }

  const newDeposData = () => {
    return {
      depos_id: toNull(deposId),
      inv_id: toNull(invId),
      const_id: toNull(constId),
      depos_date: toNull(dateformatDB(deposDate)),
      ttl_amount: toNull(parseNumber(ttlAmount)),
      create_pg: 'InvDtl',
      update_pg: 'InvDtl',
    };
  }

  const newDeposDtlData = (deposId, data) => {
    return {
      depos_dtl_id: toNull(data.depos_dtl_id),
      depos_id: toNull(deposId),
      line_no: toNull(data.dep_line_no),
      item_name: toNull(data.item_name),
      amount: toNull(parseNumber(data.amount)),
      create_pg: 'InvDtl',
      update_pg: 'InvDtl',
    };
  }

  const handleChangeConst = (value) => {
    setConstSelected(value ? value : "");
    setConstId(value ? value.id : "");
    // setConstName(value ? value.name : "")
  }

  const handleChangeCo = (value) => {
    setCoSelected(value ? value : "");
    setCoId(value ? value.id : "");
  }

  const handleChangeBook = (value) => {
    setBookSelected(value ? value : "");
    setBookId(value ? value.id : "");
  }

  const onSearchConst = async (query) => {
    let searchCondition = {
      name: query
    }
    const res = await getAPI("ms_const_typeahead_view", searchCondition);
    let constOptions = [];
    res.results.map((result) => {
      constOptions.push({name: result.name, id: result.const_id});
    });
    return constOptions;
  }

  const totalCalcHandler = () => {
    let subTotal = 0;
    for (const row of dtlListRef.current) {
      let locAmount = parseNumber(row.current.getStates().amount);
      if (locAmount) {
        subTotal += locAmount;
      }
    }
    setSubTotal(formatNumber(subTotal));
    setTax(formatNumber(subTotal * 0.1));
    setTtlAmount(formatNumber(subTotal + (subTotal * 0.1)));
  }

  const deleteRowHandler = (rowIndex) => {
    let currIndex = 0;
    let newIndex = 0;
    for (currIndex = 0; currIndex < dtlListRef.current.length; currIndex++) {
      if (currIndex === rowIndex) {
        continue;
      }

      let currData = dtlListRef.current[currIndex].current.getStates();
      dtlListRef.current[newIndex].current.setData({
        index: newIndex,
        line_no: newIndex + 1,
        id: currData.inv_dtl_id,
        item_type: currData.item_type,
        item_id: currData.item_id,
        item_name: currData.item_name,
        qty: currData.qty,
        unit: currData.unit,
        price: currData.price,
        amount: currData.amount
      });
      newIndex++;
    }
    dtlListRef.current[dtlListRef.current.length - 1].current.setData({
      index: dtlListRef.current.length - 1,
      line_no: dtlListRef.current.length,
      id: null,
      item_type: null,
      item_id: null,
      item_name: null,
      qty: null,
      unit: null,
      price: null,
      amount: null
    });

    let locDtlList = JSON.parse(JSON.stringify(dtlList));
    if (locDtlList.length > 10) {
      locDtlList.splice(locDtlList.length - 1, 1);
      dtlListRef.current.splice(dtlListRef.current.length - 1, 1);
    }
    for (let i = 0; i < locDtlList.length; i++) {
      dtlListRef.current[i] = React.createRef();
    }
    setDtlList(locDtlList);

  }

  const totalCalcDeposHandler = () => {
    let locDeposTtlAmount = 0;
    for (const row of dtlDeposListRef.current) {
      let locAmount = parseNumber(row.current.getStates().amount);
      if (locAmount) {
        locDeposTtlAmount += locAmount;
      }
    }
    setDeposTtlAmount(formatNumber(locDeposTtlAmount));
  }

  const deleteRowDeposHandler = (rowIndex) => {
    let currIndex = 0;
    let newIndex = 0;
    for (currIndex = 0; currIndex < dtlDeposListRef.current.length; currIndex++) {
      if (currIndex === rowIndex) {
        continue;
      }

      let currData = dtlDeposListRef.current[currIndex].current.getStates();
      dtlDeposListRef.current[newIndex].current.setData({
        index: newIndex,
        line_no: newIndex + 1,
        id: currData.inv_dtl_id,
        item_type: currData.item_type,
        item_id: currData.item_id,
        item_name: currData.item_name,
        qty: currData.qty,
        unit: currData.unit,
        price: currData.price,
        amount: currData.amount
      });
      newIndex++;
    }
    dtlDeposListRef.current[dtlDeposListRef.current.length - 1].current.setData({
      index: dtlDeposListRef.current.length - 1,
      line_no: dtlDeposListRef.current.length,
      id: null,
      item_type: null,
      item_id: null,
      item_name: null,
      qty: null,
      unit: null,
      price: null,
      amount: null
    });

    let locDtlDeposList = JSON.parse(JSON.stringify(dtlDeposList));
    if (locDtlDeposList.length > 3) {
      locDtlDeposList.splice(locDtlDeposList.length - 1, 1);
      dtlDeposListRef.current.splice(dtlDeposListRef.current.length - 1, 1);
    }
    for (let i = 0; i < locDtlDeposList.length; i++) {
      dtlDeposListRef.current[i] = React.createRef();
    }
    setDtlDeposList(locDtlDeposList);

  }

  return (
    <>
      <div className="body-dtl body-dtl-const">
        <AppNav/>
        <div className="h1">
          <h1 className="float-left align-items-end">
            請求詳細
          </h1>
          <div className="float-none" style={{height: "30px"}}>&nbsp;</div>
        </div>

        <Container className="container-main-detail">

          <Row className="h3">
            <Col>
            </Col>
            <Col sm="1" className="text-right">
              {invId &&
                <PDFDownloadLink document={<InvPDF inv_id={invId} updateCnt={updateCnt}/>}
                                 fileName={invNo + "_" + invTitle + ".pdf"} className=".btn .btn-secondary">
                  {({blob, url, loading, error}) => (loading ? <Button disabled variant="secondary">印刷</Button> :
                    <Button variant="secondary">印刷</Button>)}
                </PDFDownloadLink>
              }
            </Col>
            <Col sm="1" className="text-right">
              <Button variant="secondary" onClick={showDeposModal} disabled={invId ? false : true}>
                <FontAwesomeIcon icon={faQrcode} size="lg"/>
                入金処理
              </Button>
            </Col>
            <Col sm="1" className="text-right">
              {!invId &&
                <Button variant="primary" onClick={onClickInsert}><FontAwesomeIcon icon={faPen} size="lg"/>登録</Button>}
              {invId &&
                <Button variant="primary" onClick={onClickUpdate}><FontAwesomeIcon icon={faPen} size="lg"/>登録</Button>}
            </Col>
          </Row>

          <Card className="card-secondary w-80 mx-auto mb-0">
            <Form.Group as={Row}>
              <Form.Label column sm="1">施工業者名</Form.Label>
              <Col sm="4">
                <AsyncSelect
                  isClearable
                  className="async-select"
                  cacheOptions
                  defaultOptions
                  value={constSelected}
                  getOptionLabel={e => e.name}
                  getOptionValue={e => e.id}
                  loadOptions={onSearchConst}
                  onChange={handleChangeConst}
                  placeholder=""
                />
              </Col>
              <Col sm="1"></Col>
              <Form.Label column sm="1">請求日</Form.Label>
              <Col sm="4" className="input-group">
                <DatePicker id="coDate" selected={invDate} onChange={(date) => setInvDate(date)}
                            dateFormat="yyyy年MM月dd日" locale="ja"/>
                <Button as={Link} variant="link" to={"/"} style={{paddingRight: "0"}}
                        onClick={e => setFocusCalendar(e, 'coDate')}>
                  <FontAwesomeIcon icon={faCalendarAlt} size="2x"/>
                </Button>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="1">件名</Form.Label>
              <Col sm="4">
                <Form.Control
                  id="inv_title"
                  type="text"
                  value={invTitle}
                  maxLength={80}
                  onChange={e => setInvTitle(e.target.value)}
                />
              </Col>
              <Col sm="1"></Col>
              <Form.Label column sm="1">請求番号</Form.Label>
              <Col sm="4">
                <Form.Control
                  id="invNo"
                  type="text"
                  value={invNo}
                  maxLength={20}
                  onChange={e => setInvNo(e.target.value)}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="1">受注</Form.Label>
              <Col sm="4">
                <AsyncSelect
                  isClearable
                  className="async-select"
                  cacheOptions
                  defaultOptions
                  value={coSelected}
                  getOptionLabel={e => e.name}
                  getOptionValue={e => e.id}
                  loadOptions={onSearchCo}
                  onChange={handleChangeCo}
                  placeholder=""
                />
              </Col>
              <Col sm="1"></Col>
              <Form.Label column sm="1">現場</Form.Label>
              <Col sm="4">
                <AsyncSelect
                  isClearable
                  className="async-select"
                  cacheOptions
                  defaultOptions
                  value={bookSelected}
                  getOptionLabel={e => e.name}
                  getOptionValue={e => e.id}
                  loadOptions={onSearchBook}
                  onChange={handleChangeBook}
                  placeholder=""
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="1"></Form.Label>
              <Col sm="4">
              </Col>
              <Col sm="1"></Col>
              <Form.Label column sm="1">小計</Form.Label>
              <Col sm="2" className="text-right">
                <Form.Label column sm="10">{subTotal} 円</Form.Label>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="1"></Form.Label>
              <Col sm="4">
              </Col>
              <Col sm="1"></Col>
              <Form.Label column sm="1">消費税</Form.Label>
              <Col sm="2" className="text-right">
                <Form.Label column sm="10">{tax} 円</Form.Label>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="1"></Form.Label>
              <Col sm="4">
              </Col>
              <Col sm="1"></Col>
              <Form.Label column sm="1">合計金額</Form.Label>
              <Col sm="2" className="text-right">
                <Form.Label column sm="10">{ttlAmount} 円</Form.Label>
              </Col>
            </Form.Group>

            <Container className="container-main-list">
              <Row className="dtl_list_header">
                <Col sm="5" className="dtl-head-col">詳細</Col>
                <Col sm="1" className="dtl-head-col">数量</Col>
                <Col sm="1" className="dtl-head-col">単位</Col>
                <Col sm="2" className="dtl-head-col">単価</Col>
                <Col sm="2" className="dtl-head-col">金額</Col>
                <Col sm="1"></Col>
              </Row>
              {dtlList.map((dtl, index) => (
                <InvDtlRow key={index}
                           ref={dtlListRef.current[index]}
                           index={index}
                           dtl={dtl}
                           totalCalcHandler={totalCalcHandler}
                           deleteRowHandler={deleteRowHandler}
                           isLastRow={dtlList.length === index + 1}
                />
              ))}
            </Container>
            <Row>
              <Col sm="9" className="justify-content-md-center">
              </Col>
              <Col sm="3" className="text-right">
                <Button onClick={onClickAddRow}>
                  <FontAwesomeIcon icon={faPlusCircle} size="lg"/>
                  行追加
                </Button>
              </Col>
            </Row>
            <Form.Group as={Row}>
              <Col>
                <Form.Control as="textarea" rows={10} value={note} onChange={e => setNote(e.target.value)}/>
              </Col>
            </Form.Group>
          </Card>
          <Row>
            <Col>
              {invId && <Button variant="danger" onClick={onClickDelete}>削除</Button>}
            </Col>
          </Row>
        </Container>

      </div>

      <Modal size='lg' centered show={deposModal} onHide={() => setDeposModal(false)}>
        <Form>
          <Modal.Header id="depos_modal_header" closeButton>
          </Modal.Header>

          <Modal.Body id="depos_modal_body" className="pr-2 pl-2 pb-1 mb-2">
            <Form.Group as={Row}>
              <Col sm="9">
              </Col>
              <Col sm="3" className="text-right mt-1 pr-4 ">
                {!deposId &&
                  <Button variant="primary" onClick={onClickDeposInsert}><FontAwesomeIcon icon={faPen}
                                                                                          size="lg"/>登録</Button>}
                {deposId &&
                  <Button variant="primary" onClick={onClickDeposUpdate}><FontAwesomeIcon icon={faPen}
                                                                                          size="lg"/>登録</Button>}
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-1 mt-1 ml-1">
              <Form.Label column sm="2">施工業者名</Form.Label>
              <Col sm="5">
                <Form.Control
                  type="text"
                  value={deposConstName}
                  readOnly
                  maxLength={80}
                />
              </Col>
              <Form.Label column sm="2">請求日</Form.Label>
              <Col sm="3" className="input-group">
                <DatePicker
                  className="border-0"
                  id="invDate"
                  selected={invDate}
                  dateFormat="yyyy年MM月dd日"
                  locale="ja"
                  readOnly
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-1 ml-1">
              <Form.Label column sm="2">件名</Form.Label>
              <Col sm="5">
                <Form.Control
                  type="text"
                  value={invTitle}
                  readOnly
                  maxLength={80}
                />
              </Col>
              <Form.Label column sm="2">請求書番号</Form.Label>
              <Col sm="3">
                <Form.Control
                  type="text"
                  value={invNo}
                  readOnly
                  maxLength={80}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-1">
              <Col sm="7">
              </Col>
              <Form.Label column sm="2">小計</Form.Label>
              <Form.Label column sm="3" className="text-right pr-4">{subTotal} 円</Form.Label>
            </Form.Group>
            <Form.Group as={Row} className="mb-1">
              <Col sm="7">
              </Col>
              <Form.Label column sm="2">消費税</Form.Label>
              <Form.Label column sm="3" className="text-right pr-4">{tax} 円</Form.Label>
            </Form.Group>
            <Form.Group as={Row} className="mb-1 pb-3">
              <Col sm="7">
              </Col>
              <Form.Label column sm="2">合計金額</Form.Label>
              <Form.Label column sm="3" className="text-right pr-4">{ttlAmount} 円</Form.Label>
            </Form.Group>
            <Form.Group as={Row} className="mb-1 ml-1">
              <Col sm="7">
              </Col>
              <Form.Label column sm="2">入金日</Form.Label>
              <Col sm="3" className="input-group">
                <DatePicker id="deposDate" selected={deposDate} onChange={(date) => setDeposDate(date)}
                            dateFormat="yyyy年MM月dd日" locale="ja"/>
                <Button as={Link} variant="link" to={"/"} style={{paddingRight: "0"}}
                        onClick={e => setFocusCalendar(e, 'deposDate')}>
                  <FontAwesomeIcon icon={faCalendarAlt} size="2x"/>
                </Button>
              </Col>
            </Form.Group>

            <Container className="container-main-list">
              <Row className="dtl_list_header">
                <Col sm="8" className="dtl-head-col">詳細</Col>
                <Col sm="3" className="dtl-head-col text-right">金額</Col>
                <Col sm="1"></Col>
              </Row>
              {dtlDeposList.map((dtl, index) => (
                <InvDtlDeposRow key={index}
                                ref={dtlDeposListRef.current[index]}
                                index={index}
                                dtl={dtl}
                                totalCalcDeposHandler={totalCalcDeposHandler}
                                deleteRowDeposHandler={deleteRowDeposHandler}
                                isLastRow={dtlDeposList.length === index + 1}
                />
              ))}
            </Container>

            <Row>
              <Col sm="9" className="justify-content-md-center">
              </Col>
              <Col sm="3" className="text-right pr-4 pb-1">
                <Button onClick={onClickAddDepRow}>
                  <FontAwesomeIcon icon={faPlusCircle} size="lg"/>
                  行追加
                </Button>
              </Col>
            </Row>

            <Form.Group as={Row} className="mb-1 pb-3 ml-1">
              <Form.Label column sm="9">入金金額</Form.Label>
              <Form.Label column sm="3" className="text-right pr-4">{deposTtlAmount} 円</Form.Label>
            </Form.Group>
          </Modal.Body>
        </Form>
      </Modal>
      <Toast/>
    </>
  );
}

export default withRouter(InvDtl);