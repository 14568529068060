import React, {useEffect, useRef, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import {Card, Col, Container, Form, FormGroup, InputGroup, Nav, Row} from "react-bootstrap";
import {deleteAPI, getAPI, patchAPI, postAPI, putAPI} from "../APIUtil";
import Button from "react-bootstrap/Button";
import {withRouter} from "react-router";
import AppNav from "./AppNav";
import 'react-toastify/dist/ReactToastify.css';
import Toast, {success} from "../Toast";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen, faCalendarAlt} from "@fortawesome/free-solid-svg-icons";
import {
  setFocusCalendar,
  addField,
  checkKey,
  dateformatDB,
  parseDate,
  nvl,
  toNull, onSearchItem, onSearchCodeByCategory
} from "../CMUtil";
import DatePicker from "react-datepicker";
import AsyncSelect from "react-select/async";

export const StkInOutDtl = ({match: {params: {in_out_id}}}) => {

  const hist = useHistory();

  const iniItemId = useRef(true);

  const [updateType, setUpdateType] = useState("");

  const [inOutId, setInOutId] = useState("");
  const [itemId, setItemId] = useState("");
  const [itemUnit, setItemUnit] = useState("");
  const [loadDate, setLoadDate] = useState(0);
  const [loadType, setLoadType] = useState("入庫");
  const [causeType, setCauseType] = useState("");
  const [remarks, setRemarks] = useState("");
  const [readOnlyremarks, setReadOnlyremarks] = useState(true);
  const [stkQty, setStkQty] = useState("");
  const [newStkQty, setNewStkQty] = useState("");
  const [inOutQty, setInOutQty] = useState("");

  const [itemSelected, setItemSelected] = useState([]);
  const [causeTypeSelected, setCauseTypeSelected] = useState([]);

  //初期化
  useEffect(() => {
    const update = async () => {
      if (!in_out_id) {
        return;
      }
      let obj = await getAPI("tr_in_out/" + in_out_id);
      setUpdateType("update");
      setInOutId(obj.in_out_id);
      if (obj.item) {
        setItemId(nvl(obj.item.item_id));
        setItemSelected({id: obj.item.item_id, name: obj.item.item_name});
      }
      setLoadDate(nvl(parseDate(obj.load_date)));
      setLoadType(nvl(obj.load_type));
      setCauseType(nvl(obj.cause_type));
      setCauseTypeSelected({id: obj.cause_type, name: obj.cause_type});
      setRemarks(nvl(obj.remarks));
      setStkQty(nvl(obj.stk_qty));
      setInOutQty(nvl(obj.in_out_qty));
      setNewStkQty(nvl(obj.new_stk_qty));
    }
    update();

  }, []);


  useEffect(() => {
    const update = async () => {
      setRemarks("");
      if ("その他" === causeType) {
        setReadOnlyremarks(false);
      } else {
        setReadOnlyremarks(true);
      }
    }
    update();

  }, [causeType]);

  useEffect(() => {
    const update = async () => {
      let locStkQty = stkQty ? stkQty : 0;
      let locInOutQty = inOutQty ? inOutQty : 0;
      let locNewStkQty = "";
      if (locInOutQty) {
        if (loadType === "入庫") {
          locNewStkQty = parseInt(locStkQty) + parseInt(locInOutQty);
        } else {
          locNewStkQty = parseInt(locStkQty) - parseInt(locInOutQty);
        }
      }
      setNewStkQty(locNewStkQty);
    }
    update();

  }, [inOutQty]);

  const updateItemId = async (itemId) => {

    let locItemUnit = "";
    let locStkQty = "";
    let locInOutQty = "";
    let locNewStkQty = "";

    if (itemId) {
      let obj = await getAPI("ms_item/" + itemId + "/");
      locItemUnit = obj.unit;

      let conditionItem = {};
      addField(conditionItem, "item_id", itemId);
      const res = await getAPI("ms_stk_search_view", conditionItem);
      locStkQty = res.results[0].qty;
    }

    setItemUnit(nvl(locItemUnit));
    setStkQty(nvl(locStkQty));
    setLoadType("入庫");
    setCauseType("");
    setCauseTypeSelected({});
    setInOutQty(nvl(locInOutQty));
    setNewStkQty(nvl(locNewStkQty));
  }

  const onClickInsert = async () => {

    let condition = null;
    condition = {};
    addField(condition, "item_id", itemId);
    const stkRes = await getAPI("ms_stk_search_view", condition);

    let locStk = stkRes.results[0];
    condition = {};
    addField(condition, "stk_id", locStk.stk_id);
    if (loadType === "入庫") {
      addField(condition, "add_qty", inOutQty);
    } else {
      addField(condition, "subtract_qty", inOutQty);
    }
    addField(condition, "update_pg", "StkInOutDtl");
    locStk = await patchAPI("ms_stk/" + locStk.item_id + "/", condition);

    let obj = await postAPI("tr_in_out/", newData());

    setInOutId(obj.in_out_id);
    hist.push("/stk_in_out/" + obj.in_out_id);
    success("登録完了");
  }

  const newData = () => {
    return {
      item_id: toNull(itemId),
      load_date: toNull(dateformatDB(loadDate)),
      load_type: toNull(loadType),
      cause_type: toNull(causeType),
      remarks: toNull(remarks),
      stk_qty: toNull(stkQty),
      in_out_qty: toNull(inOutQty),
      new_stk_qty: toNull(newStkQty),
      create_pg: 'StkInOutDtl',
      update_pg: 'StkInOutDtl'
    };
  }

  const handleChangeItem = async (value) => {
    setItemSelected(value ? value : "");
    setItemId(value ? value.id : "");
    await updateItemId(value ? value.id : "");
  }

  const handleCauseType = (value) => {
    setCauseTypeSelected(value ? value : "");
    setCauseType(value ? value.id : "");
  }

  return (
    <>
      <div className="body-dtl body-dtl-const">
        <AppNav/>
        <div className="h1">
          <h1 className="float-left align-items-end">
            資材入出庫詳細
          </h1>
          <div className="float-none" style={{height: "30px"}}>&nbsp;</div>
        </div>

        <Container className="container-main-detail">

          <Row className="h3">
            <Col>
              <h3 style={{paddingTop: ".7rem"}}>
                基本情報
              </h3>
            </Col>
            <Col className="text-right">
              {updateType !== 'update' &&
                <Button variant="primary" onClick={onClickInsert}><FontAwesomeIcon icon={faPen} size="lg"/>登録</Button>}
            </Col>
          </Row>

          <Card className="card-secondary w-80 mx-auto mb-0">
            <Form.Group as={Row}>
              <Form.Label column sm="1">資材</Form.Label>
              <Col sm="6">
                <AsyncSelect
                  className="async-select"
                  isClearable
                  cacheOptions
                  defaultOptions
                  value={itemSelected}
                  getOptionLabel={e => e.name}
                  getOptionValue={e => e.id}
                  loadOptions={e => onSearchItem(e, 10)}
                  onChange={handleChangeItem}
                  placeholder=""
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="1">現在庫</Form.Label>
              <Col sm="2" className="input-group">
                <Form.Control
                  id="stkQty"
                  type="text"
                  value={stkQty}
                  style={{textAlign: "right"}}
                  maxLength={10}
                  readOnly={true}
                />
                <Form.Control
                  id="itemUnit"
                  value={itemUnit}
                  style={{textAlign: "left", paddingLeft: "0.5rem"}}
                  plaintext
                  readOnly
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="1">入出庫日</Form.Label>
              <Col sm="2" className="input-group">
                <DatePicker
                  id="loadDate"
                  selected={loadDate}
                  onChange={(date) => setLoadDate(date)}
                  dateFormat="yyyy年MM月dd日"
                  locale="ja"
                />
                <Button as={Link} variant="link" to={"/"} style={{paddingRight: "0"}}
                        onClick={e => setFocusCalendar(e, 'loadDate')}>
                  <FontAwesomeIcon icon={faCalendarAlt} size="2x"/>
                </Button>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="1">入出庫</Form.Label>
              <Col sm="2">
                <Row>
                  <Col>
                    <Form.Check
                      id="inOutType1"
                      type="radio"
                      name="loadType"
                      label="入庫"
                      style={{marginRight: "0rem"}}
                      onChange={e => setLoadType("入庫")}
                      checked={loadType === '入庫'}
                    />
                  </Col>
                </Row>
              </Col>
              <Col sm="2">
                <Row>
                  <Col>
                    <Form.Check
                      id="inOutType2"
                      type="radio"
                      name="loadType"
                      label="出庫"
                      style={{marginRight: "0rem"}}
                      onChange={e => setLoadType("出庫")}
                      checked={loadType === '出庫'}
                    />
                  </Col>
                </Row>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="1">理由</Form.Label>
              <Col sm="4">
                {
                  loadType === "入庫" &&
                  <AsyncSelect
                    className="async-select"
                    isClearable
                    defaultOptions
                    value={causeTypeSelected}
                    getOptionLabel={e => e.name}
                    getOptionValue={e => e.id}
                    loadOptions={e => onSearchCodeByCategory("CAUSE_TYPE_IN")}
                    onChange={handleCauseType}
                    placeholder=""
                  />
                }
                {
                  loadType !== "入庫" &&
                  <AsyncSelect
                    className="async-select"
                    isClearable
                    defaultOptions
                    value={causeTypeSelected}
                    getOptionLabel={e => e.name}
                    getOptionValue={e => e.id}
                    loadOptions={e => onSearchCodeByCategory("CAUSE_TYPE_OUT")}
                    onChange={handleCauseType}
                    placeholder=""
                  />
                }
              </Col>
              <Col sm="6">
                <Form.Control
                  id="remarks"
                  type="text"
                  value={remarks}
                  readOnly={readOnlyremarks}
                  maxLength={80}
                  onChange={e => setRemarks(e.target.value)}
                  onBlur={e => setRemarks(e.target.value)}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="1">数量</Form.Label>
              <Col sm="2" className="input-group">
                <Form.Control
                  id="qty"
                  type="text"
                  value={inOutQty}
                  maxLength={10}
                  onKeyPress={e => checkKey(e, /[0-9\-]/)}
                  style={{marginRight: "0rem", textAlign: "right"}}
                  onChange={e => setInOutQty(e.target.value)}
                />
                <Form.Control
                  id="itemUnit"
                  value={itemUnit}
                  style={{textAlign: "left", paddingLeft: "0.5rem"}}
                  plaintext
                  readOnly
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="1">変更後在庫</Form.Label>
              <Col sm="2" className="input-group">
                <Form.Control
                  id="newStkQty"
                  type="text"
                  value={newStkQty}
                  style={{textAlign: "right"}}
                  maxLength={10}
                  readOnly={true}
                />
                <Form.Control
                  id="itemUnit"
                  value={itemUnit}
                  style={{textAlign: "left", paddingLeft: "0.5rem"}}
                  plaintext
                  readOnly
                />
              </Col>
            </Form.Group>

          </Card>

        </Container>
      </div>
      <Toast/>
    </>
  );
}

export default withRouter(StkInOutDtl);