import React, {useEffect, useState} from 'react';
import {Link, useHistory, useLocation} from "react-router-dom";
import {Card, Col, Container, Form, FormLabel, InputGroup, Modal, Row} from "react-bootstrap";
import {deleteAPI, downloadAPI, downloadImageAPI, getAPI, postAPI, putAPI} from "../APIUtil";
import Button from "react-bootstrap/Button";
import {withRouter} from "react-router";
import AppNav from "./AppNav";
import 'react-toastify/dist/ReactToastify.css';
import Toast, {validationError, success} from "../Toast";
import BookNav from "./BookNav";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt, faFile, faMapMarkerAlt, faPen, faQrcode, faYenSign} from "@fortawesome/free-solid-svg-icons";
import MdDatatable, {sortCaret} from "../MdDataTable";
import DatePicker from "react-datepicker";
import {
  addField,
  checkKey, dateformat,
  dateformatDB, dateformatJPN, dateformatYYYYMMDD, finishLoadingBtn, formatNumber, formatZip,
  nvl,
  onSearchCategory, onSearchCo,
  onSearchConst, onSearchConstEnd,
  onSearchDesign, onSearchNote2, onSearchOpnArea,
  onSearchPref,
  parseDate, parseLocationSearch, parseNumber,
  setFocusCalendar, setZipAuto, startLoadingBtn, toMultibyte,
  toNull,
  toSingleByte,
  toSingleByteAlphaNumberOnly,
} from "../CMUtil";

import AsyncSelect from 'react-select/async';


export const BookDtl = ({match: {params: {p_book_id}}}) => {

  const hist = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(true);

  const [updateType, setUpdateType] = useState("");
  const [bookId, setBookId] = useState("");
  const [bookNo, setBookNo] = useState("");
  const [bookTypeId, setBookTypeId] = useState("BKTYPE01");
  const [coId, setCoId] = useState("");
  const [bookName, setBookName] = useState("");
  const [quotId, setQuotId] = useState("");
  const [constId, setConstId] = useState("");
  const [constEndId, setConstEndId] = useState("");
  const [extDesignId, setExtDesignId] = useState("");
  const [strDesignId, setStrDesignId] = useState("");
  const [zip, setZip] = useState("");
  const [pref, setPref] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [officeZip, setOfficeZip] = useState("");
  const [officePref, setOfficePref] = useState("");
  const [officeCity, setOfficeCity] = useState("");
  const [officeAddress, setOfficeAddress] = useState("");
  const [category, setCategory] = useState("");
  const [mainUsage, setMainUsage] = useState("");
  const [opnArea, setOpnArea] = useState("");
  const [note1, setNote1] = useState("");
  const [note2, setNote2] = useState("");
  const [note3, setNote3] = useState("");
  const [startDateSchedule, setStartDateSchedule] = useState("");
  const [startDate, setStartDate] = useState("");
  const [completeDateSchedule, setCompleteDateSchedule] = useState("");
  const [completeDate, setCompleteDate] = useState("");
  const [warrantyDate, setWarrantyDate] = useState("");
  const [warrantyDocId, setWarrantyDocId] = useState("");
  const [memo, setMemo] = useState("");
  const [invPrice, setInvPrice] = useState("");
  const [receiptAmount, setReceiptAmount] = useState("");
  const [specialNote, setSpecialNote] = useState("");

  const [coSelected, setCoSelected] = useState([]);
  const [constSelected, setConstSelected] = useState([]);
  const [constEndSelected, setConstEndSelected] = useState([]);
  const [extDesignSelected, setExtDesignSelected] = useState([]);
  const [strDesignSelected, setStrDesignSelected] = useState([]);
  const [prefSelected, setPrefSelected] = useState([]);
  const [officePrefSelected, setOfficePrefSelected] = useState([]);
  const [categorySelected, setCategorySelected] = useState([]);
  const [opnAreaSelected, setOpnAreaSelected] = useState([]);
  const [note2Selected, setNote2Selected] = useState([]);

  const [histPage, setHistPage] = useState(null);
  const [histSizePerPage, setHistSizePerPage] = useState(null);
  const [histTotalSize, setHistTotalSize] = useState(null);
  const [histSortField, setHistSortField] = useState(null);
  const [histSortOrder, setHistSortOrder] = useState(null);
  const [histList, setHistList] = useState([]);

  const [reportPage, setReportPage] = useState(null);
  const [reportSizePerPage, setReportSizePerPage] = useState(null);
  const [reportTotalSize, setReportTotalSize] = useState(null);
  const [reportSortField, setReportSortField] = useState(null);
  const [reportSortOrder, setReportSortOrder] = useState(null);
  const [reportList, setReportList] = useState([]);

  const [defaultSelectedConst, setDefaultSelectedConst] = useState([]);

  const [modalShow, setModalShow] = useState(false);
  const [modalBookId, setModalBookId] = useState("");
  const [modalBookNo, setModalBookNo] = useState("");
  const [modalBookName, setModalBookName] = useState("");
  const [modalConstName, setModalConstName] = useState("");
  const [modalExtDesignName, setModalExtDesignName] = useState("");
  const [modalStrDesignName, setModalStrDesignName] = useState("");
  const [modalZip, setModalZip] = useState("");
  const [modalPref, setModalPref] = useState("");
  const [modalCity, setModalCity] = useState("");
  const [modalAddress, setModalAddress] = useState("");
  const [modalQrImage, setModalQrImage] = useState("");

  const [picConstName1, setPicConstName1] = useState("");
  const [picConstTel1, setPicConstTel1] = useState("");
  const [picConstEmail1, setPicConstEmail1] = useState("");
  const [picConstName2, setPicConstName2] = useState("");
  const [picConstTel2, setPicConstTel2] = useState("");
  const [picConstEmail2, setPicConstEmail2] = useState("");
  const [picConstName3, setPicConstName3] = useState("");
  const [picConstTel3, setPicConstTel3] = useState("");
  const [picConstEmail3, setPicConstEmail3] = useState("");
  const [picDesignName1, setPicDesignName1] = useState("");
  const [picDesignTel1, setPicDesignTel1] = useState("");
  const [picDesignEmail1, setPicDesignEmail1] = useState("");
  const [picDesignName2, setPicDesignName2] = useState("");
  const [picDesignTel2, setPicDesignTel2] = useState("");
  const [picDesignEmail2, setPicDesignEmail2] = useState("");
  const [picDesignName3, setPicDesignName3] = useState("");
  const [picDesignTel3, setPicDesignTel3] = useState("");
  const [picDesignEmail3, setPicDesignEmail3] = useState("");

  // 項目定義
  const histColumns = [
    {
      dataField: 'history_id',
      text: 'ID',
      hidden: true,
      sort: false,
      headerStyle: {width: '80px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      },
    },
    {
      dataField: 'opn_date',
      text: '作業日',
      classes: 'tbl-col',
      headerStyle: {width: '150px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      },
      formatter: (cell, row) => (
        <div>{dateformatJPN(parseDate(cell))}</div>
      )
    },
    {
      dataField: 'wire_mesh',
      text: 'メッシュ使用量',
      classes: 'tbl-col',
      headerStyle: {width: '150px'},
      formatter: (cell, row) => (
        <span>{cell} 枚</span>
      )
    },
    {
      dataField: 'takei_amount',
      text: 'タケイ２号液使用量',
      classes: 'tbl-col',
      headerStyle: {width: '150px'},
      formatter: (cell, row) => (
        <span>{cell} 立米</span>
      )
    },
  ];

  // 項目定義
  const reportColumns = [
    {
      dataField: 'id',
      text: 'ID',
      hidden: true,
      headerStyle: {width: '80px'},
    },
    {
      dataField: 'opn_date',
      text: '日付',
      classes: 'tbl-col',
      headerStyle: {width: '150px'},
      formatter: (cell, row) => (
        <div>{dateformatJPN(parseDate(cell))}</div>
      )
    },
    {
      dataField: 'opn_name',
      text: '作業内容',
      classes: 'tbl-col',
      headerStyle: {width: '150px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'opn_user_name',
      text: '作業者名',
      classes: 'tbl-col',
      headerStyle: {width: '150px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'opn_user_count',
      text: '人数',
      classes: 'tbl-col',
      headerStyle: {width: '150px'},
      formatter: (cell, row) => (
        <span>{cell} 名</span>
      )
    },
  ];

  // バリデーション用
  const fields = {
    book_no: "工事番号",
    book_type_id: "書籍の種類ID", // no field
    book_name: "現場名",
    const_id: "施工業者",
    const_end_id: "元請業者",
    ext_design_id: "意匠設計",
    str_design_id: "構造設計",
    zip: "住所（郵便番号）", // translated manually
    pref: "住所（都道府県）", // translated manually
    city: "住所（市）", // translated manually
    address: "住所（住所）", // translated manually
    office_zip: "事務所住所（郵便番号）", // translated manually
    office_pref: "事務所住所（都道府県）", // translated manually
    office_city: "事務所住所（市）", // translated manually
    office_address: "事務所住所（住所）", // translated manually
    category: "分類",
    main_usage: "主要用途",
    opn_area: "施工箇所",
    note1: "備考",
    note2: "改修",
    note3: "デベロッパ",
    pic_const_name_1: "工事担当➀（氏名）", // translated manually
    pic_const_tel_1: "工事担当➀（電話）", // translated manually
    pic_const_email_1: "工事担当➀（メール）", // translated manually
    pic_const_name_2: "工事担当➁（氏名）", // translated manually
    pic_const_tel_2: "工事担当➁（電話）", // translated manually
    pic_const_email_2: "工事担当➁（メール）", // translated manually
    pic_const_name_3: "工事担当➂（氏名）", // translated manually
    pic_const_tel_3: "工事担当➂（電話）", // translated manually
    pic_const_email_3: "工事担当➂（メール）", // translated manually
    pic_design_name_1: "設計担当➀（氏名）", // translated manually
    pic_design_tel_1: "設計担当➀（電話）", // translated manually
    pic_design_email_1: "設計担当➀（メール）", // translated manually
    pic_design_name_2: "設計担当➁（氏名）", // translated manually
    pic_design_tel_2: "設計担当➁（電話）", // translated manually
    pic_design_email_2: "設計担当➁（メール）", // translated manually
    pic_design_name_3: "設計担当➂（氏名）", // translated manually
    pic_design_tel_3: "設計担当➂（電話）", // translated manually
    pic_design_email_3: "設計担当➂（メール）", // translated manually
    start_date_schedule: "着工予定日",
    start_date: "着工日",
    complete_date_schedule: "完了予定日",
    complete_date: "完了日",
    warranty_date: "保証書",
    memo: "メモ",
    depos_amount: "入金金額",
    special_note: "特記事項",
  };

  //初期化
  useEffect(() => {

    const update = async () => {

      let res = null;

      setLoading(true);

      let seachQuot = parseLocationSearch(location.search);
      let locCoId = seachQuot['co_id'];
      if (locCoId) {
        setCoId(locCoId);
        let co = await getAPI("tr_co/" + locCoId);
        setCoId(co.co_id);
        setCoSelected({name: nvl(co.co_no) + (co.co_no ? ' ' : '') + co.co_title, id: co.co_id});
        setBookName(co.co_title);
        if (co.const) {
          setConstId(co.const.const_id);
          setConstSelected({id: co.const.const_id, name: co.const.name});
        }

        return;
      }

      if (!p_book_id) {
        return;
      }
      setUpdateType("update");
      setBookId(p_book_id);

      let obj = await getAPI("tr_book/" + p_book_id);
      setBookId(obj.book_id);
      setBookNo(obj.book_no);
      if (obj.book_type) {
        setBookTypeId(obj.book_type.code_id);
      }
      setBookName(obj.book_name);
      if (obj.co) {
        setCoId(obj.co.co_id);
        setCoSelected([{name: nvl(obj.co.co_no) + (obj.co.co_no ? ' ' : '') + obj.co.co_title, id: obj.co.co_id}]);
      }
      if (obj.const) {
        setConstId(obj.const.const_id);
        setConstSelected([{const_id: obj.const.const_id, name: obj.const.name}]);
      }
      if (obj.const_end) {
        setConstEndId(obj.const_end.const_id);
        setConstEndSelected([{const_id: obj.const_end.const_id, name: obj.const_end.name}]);
      }
      if (obj.const) {
        setConstId(obj.const.const_id);
        setConstSelected([{const_id: obj.const.const_id, name: obj.const.name}]);
      }
      if (obj.ext_design) {
        setExtDesignId(obj.ext_design.design_id);
        setExtDesignSelected([{design_id: obj.ext_design.design_id, name: obj.ext_design.name}]);
      }
      if (obj.str_design) {
        setStrDesignId(obj.str_design.design_id);
        setStrDesignSelected([{design_id: obj.str_design.design_id, name: obj.str_design.name}]);
      }

      setZip(nvl(obj.zip));
      setPref(nvl(obj.pref));
      setPrefSelected({name: obj.pref, id: obj.pref});
      setCity(nvl(obj.city));
      setAddress(nvl(obj.address));
      setOfficeZip(nvl(obj.office_zip));
      setOfficePref(nvl(obj.office_pref));
      setOfficePrefSelected({name: obj.office_pref, id: obj.office_pref});
      setOfficeCity(nvl(obj.office_city));
      setOfficeAddress(nvl(obj.office_address));

      setCategory(nvl(obj.category));
      setCategorySelected({name: obj.category, id: obj.category});
      setMainUsage(nvl(obj.main_usage));
      setOpnArea(nvl(obj.opn_area));
      setOpnAreaSelected({name: obj.opn_area, id: obj.opn_area});
      setNote1(nvl(obj.note1));
      setNote2(nvl(obj.note2));
      setNote2Selected({name: obj.note2, id: obj.note2});
      setNote3(nvl(obj.note3));
      setPicConstName1(nvl(obj.pic_const_name_1));
      setPicConstTel1(nvl(obj.pic_const_tel_1));
      setPicConstEmail1(nvl(obj.pic_const_email_1));
      setPicConstName2(nvl(obj.pic_const_name_2));
      setPicConstTel2(nvl(obj.pic_const_tel_2));
      setPicConstEmail2(nvl(obj.pic_const_email_2));
      setPicConstName3(nvl(obj.pic_const_name_3));
      setPicConstTel3(nvl(obj.pic_const_tel_3));
      setPicConstEmail3(nvl(obj.pic_const_email_3));
      setPicDesignName1(nvl(obj.pic_design_name_1));
      setPicDesignTel1(nvl(obj.pic_design_tel_1));
      setPicDesignEmail1(nvl(obj.pic_design_email_1));
      setPicDesignName2(nvl(obj.pic_design_name_2));
      setPicDesignTel2(nvl(obj.pic_design_tel_2));
      setPicDesignEmail2(nvl(obj.pic_design_email_2));
      setPicDesignName3(nvl(obj.pic_design_name_3));
      setPicDesignTel3(nvl(obj.pic_design_tel_3));
      setPicDesignEmail3(nvl(obj.pic_design_email_3));
      setStartDateSchedule(nvl(parseDate(obj.start_date_schedule)));
      setStartDate(nvl(parseDate(obj.start_date)));
      setCompleteDateSchedule(nvl(parseDate(obj.complete_date_schedule)));
      setCompleteDate(nvl(parseDate(obj.complete_date)));
      setWarrantyDate(nvl(parseDate(obj.warranty_date)));
      setMemo(nvl(obj.memo));

      // 見積確認
      let searchCondition = {
        book_id: obj.book_id,
      }
      let quotObjList = await getAPI("tr_quot_search_view", searchCondition);
      if (quotObjList.count > 0) {
        setQuotId(nvl(quotObjList.results[0].quot_id));
      }

      //保証書
      let condition = {};
      addField(condition, "book_id", p_book_id);
      addField(condition, "category", "保証書");
      res = await getAPI("tr_doc_search_view", condition);
      setWarrantyDocId("");
      if (res.count > 0) {
        setWarrantyDocId(res.results[0].doc_id);
      }

      let locInvPrice = 0;
      searchCondition = {
        book_id: obj.book_id,
      }
      res = await getAPI("tr_inv_search_view", searchCondition);
      res.results.map(inv => {
        locInvPrice = locInvPrice + (inv.total ? inv.total : 0);
      })

      setInvPrice(nvl(locInvPrice));
      // TODO
      // setReceiptAmount(obj.receipt_amount ? formatNumber(obj.receipt_amount) : "");
      setSpecialNote(nvl(obj.special_note));

      let locHistList = await getAPI("tr_history_search_view", {book_id: obj.book_id});
      setHistList(locHistList);

      let locReportList = await getAPI("tr_report_search_for_book_view", {book_id: obj.book_id});
      setReportList(locReportList);

      setLoading(false);
    }
    update();

  }, []);

  const handleChangeConst = (value) => {
    setConstSelected(value);
    setConstId(value.id);
  }

  const handleChangeConstEnd = (value) => {
    setConstEndSelected(value);
    setConstEndId(value.id);
  }

  const handleChangeCo = (value) => {
    setCoSelected(value);
    setCoId(value.id);
  }

  const handleChangeExtDesign = (value) => {
    setExtDesignSelected(value);
    setExtDesignId(value.id);
  }

  const handleChangeStrDesign = (value) => {
    setStrDesignSelected(value);
    setStrDesignId(value.id);
  }

  const handleChangePref = (value) => {
    setPrefSelected(value ? value : "");
    setPref(value ? value.id : "");
  }

  const handleOfficeChangePref = (value) => {
    setOfficePrefSelected(value ?? "");
    setOfficePref(value?.id ?? "");
  }

  const handleChangeCategory = (value) => {
    setCategorySelected(value ?? "");
    setCategory(value?.id ?? "");
  }

  const handleChangeOpnArea = (value) => {
    setOpnAreaSelected(value ?? "");
    setOpnArea(value?.id ?? "");
  }

  const handleChangeNote2 = (value) => {
    setNote2Selected(value ?? "");
    setNote2(value?.id ?? "");
  }

  const onClickInsert = async () => {
    try {
      let obj = newData();
      obj = await postAPI("tr_book/", obj);
      setBookId(obj.book_id);
      hist.push("/book/" + obj.book_id);
      success("登録完了");
    } catch (result) {
      validationError(result, fields);
    }
  }

  const onClickUpdate = async () => {
    let obj = newData();
    try {
      await putAPI("tr_book/" + bookId + "/", obj);
      success("更新完了");
    } catch (result) {
      validationError(result, fields);
    }
  }

  const onClickDelete = async () => {
    let obj = newData();
    let res = await deleteAPI("tr_book/" + bookId + "/", obj);
    if (res) {
      success("削除完了");
      hist.push("/book_list/");
    }
  }

  const onClickWarrantyDoc = () => {
    if (warrantyDocId) {
      hist.push("/doc/" + warrantyDocId);
    } else {
      hist.push("/doc/" + bookId + "/保証書");
    }
  }

  const onClickWarranty = () => {
    hist.push("/warranty_registration/" + bookId);
  }

  const newData = () => {
    return {
      book_no: toNull(bookNo),
      book_type_id: toNull(bookTypeId),
      book_name: toNull(bookName),
      co_id: toNull(coId),
      const_id: toNull(constId),
      const_end_id: toNull(constEndId),
      ext_design_id: toNull(extDesignId),
      str_design_id: toNull(strDesignId),
      zip: toNull(zip),
      pref: toNull(pref),
      city: toNull(city),
      address: toNull(address),
      office_zip: toNull(officeZip),
      office_pref: toNull(officePref),
      office_city: toNull(officeCity),
      office_address: toNull(officeAddress),
      category: toNull(category),
      main_usage: toNull(mainUsage),
      opn_area: toNull(opnArea),
      note1: toNull(note1),
      note2: toNull(note2),
      note3: toNull(note3),
      pic_const_name_1: toNull(picConstName1),
      pic_const_tel_1: toNull(picConstTel1),
      pic_const_email_1: toNull(picConstEmail1),
      pic_const_name_2: toNull(picConstName2),
      pic_const_tel_2: toNull(picConstTel2),
      pic_const_email_2: toNull(picConstEmail2),
      pic_const_name_3: toNull(picConstName3),
      pic_const_tel_3: toNull(picConstTel3),
      pic_const_email_3: toNull(picConstEmail3),
      pic_design_name_1: toNull(picDesignName1),
      pic_design_tel_1: toNull(picDesignTel1),
      pic_design_email_1: toNull(picDesignEmail1),
      pic_design_name_2: toNull(picDesignName2),
      pic_design_tel_2: toNull(picDesignTel2),
      pic_design_email_2: toNull(picDesignEmail2),
      pic_design_name_3: toNull(picDesignName3),
      pic_design_tel_3: toNull(picDesignTel3),
      pic_design_email_3: toNull(picDesignEmail3),
      start_date_schedule: toNull(dateformatDB(startDateSchedule)),
      start_date: toNull(dateformatDB(startDate)),
      complete_date_schedule: toNull(dateformatDB(completeDateSchedule)),
      complete_date: toNull(dateformatDB(completeDate)),
      warranty_date: toNull(dateformatDB(warrantyDate)),
      memo: toNull(memo),
      receipt_amount: parseNumber(receiptAmount),
      special_note: toNull(specialNote),
      create_pg: 'BookDtl',
      update_pg: 'BookDtl'
    };
  }

  const onClickModalClose = () => setModalShow(false);

  const onClickModalShow = async () => {
    let obj = await getAPI("tr_book/" + p_book_id);
    setModalBookId(nvl(obj.book_id));
    setModalBookNo(nvl(obj.book_no));
    setModalBookName(nvl(obj.book_name));
    setModalConstName(nvl(obj.const.name));
    setModalExtDesignName(obj.ext_design ? obj.ext_design.name : "");
    setModalStrDesignName(obj.str_design ? obj.str_design.name : "");
    setModalZip(nvl(obj.zip));
    setModalPref(nvl(obj.pref));
    setModalCity(nvl(obj.city));
    setModalAddress(nvl(obj.address));
    const qrBlob = await downloadImageAPI("download_qr/" + bookId);
    const url = (window.URL || window.webkitURL).createObjectURL(qrBlob);
    setModalQrImage(url);
    setModalShow(true);
  }

  const onClickPrint = () => {
    let printPage = $(this).closest('.print-page').html();
    $('body').append('<div id="print"></div>');
    $('#print').append(printPage);
    $('body > :not(#print)').addClass('print-off');
    $(".modal-header").css("display", "none");
    $(".modal-footer").css("display", "none");
    $(".modal-content").css("border-width", "0px");
    $(".modal-lg").css("max-width", "100%");

    window.print();

    $('#print').remove();
    $('.print-off').removeClass('print-off');
    $(".modal-header").css("display", "inline");
    $(".modal-footer").css("display", "");
    $(".modal-content").css("border-width", "1px");
    $(".modal-lg").css("max-width", "800px");
  }

  const onClickDownload = async () => {
    startLoadingBtn("btn-download", "secondary");
    let searchCondition = {
      book_id: bookId
    }
    await downloadAPI("download/ship_list", searchCondition);
    finishLoadingBtn("btn-download");
  }

  return (
    <>
      <div className="body-dtl">
        <AppNav/>
        <BookNav bookId={bookId}/>

        <Container className="container-main-detail">

          <Row className="h3">
            <Col>
              <h3 style={{paddingTop: ".7rem"}}>
                基本情報
              </h3>
            </Col>
            <Col className="text-right">
              <Button variant="secondary" onClick={onClickModalShow} disabled={bookId ? false : true}>
                <FontAwesomeIcon icon={faQrcode} size="lg"/>
                QRコード
              </Button>
              &nbsp;
              {!bookId &&
                <Button variant="primary" onClick={onClickInsert}><FontAwesomeIcon icon={faPen} size="lg"/>登録</Button>}
              {bookId &&
                <Button variant="primary" onClick={onClickUpdate}><FontAwesomeIcon icon={faPen} size="lg"/>登録</Button>}
            </Col>
          </Row>

          <Card className="card-secondary w-80 mx-auto mb-0">
            <Form.Group as={Row}>
              <Form.Label column sm="1">{fields.book_no}</Form.Label>
              <Col sm="4">
                <Form.Control
                  id="bookNo"
                  type="text"
                  value={bookNo}
                  onChange={e => setBookNo(e.target.value)}
                  onBlur={e => setBookNo(toSingleByte(e.target.value))}
                />
              </Col>
              <Col sm="1"></Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="1">{fields.book_name}</Form.Label>
              <Col sm="4">
                <Form.Control
                  id="bookName"
                  type="text"
                  value={bookName}
                  onChange={e => setBookName(e.target.value)}
                  onBlur={e => setBookName(toSingleByteAlphaNumberOnly(e.target.value))}
                />
              </Col>
              <Col sm="1"></Col>
              <Form.Label column sm="1">受注番号</Form.Label>
              <Col sm="4">
                <AsyncSelect
                  className="async-select"
                  cacheOptions
                  defaultOptions
                  isClearable
                  value={coSelected}
                  getOptionLabel={e => e.name}
                  getOptionValue={e => e.id}
                  loadOptions={onSearchCo}
                  onChange={handleChangeCo}
                  placeholder=""
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="1">{fields.const_id}</Form.Label>
              <Col sm="4">
                <AsyncSelect
                  className="async-select"
                  cacheOptions
                  defaultOptions
                  isClearable
                  value={constSelected}
                  getOptionLabel={e => e.name}
                  getOptionValue={e => e.const_id}
                  loadOptions={onSearchConst}
                  onChange={handleChangeConst}
                  placeholder=""
                />
              </Col>
              <Col sm="1"></Col>
              <Form.Label column sm="1">{fields.const_end_id}</Form.Label>
              <Col sm="4">
                <AsyncSelect
                  className="async-select"
                  cacheOptions
                  defaultOptions
                  isClearable
                  value={constEndSelected}
                  getOptionLabel={e => e.name}
                  getOptionValue={e => e.const_id}
                  loadOptions={onSearchConstEnd}
                  onChange={handleChangeConstEnd}
                  placeholder=""
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="1">{fields.ext_design_id}</Form.Label>
              <Col sm="4">
                <AsyncSelect
                  className="async-select"
                  cacheOptions
                  defaultOptions
                  isClearable
                  value={extDesignSelected}
                  getOptionLabel={e => e.name}
                  getOptionValue={e => e.design_id}
                  loadOptions={(query) => onSearchDesign(query, "意匠")}
                  onChange={handleChangeExtDesign}
                  placeholder=""
                />
              </Col>
              <Col sm="1"></Col>
              <Form.Label column sm="1">{fields.str_design_id}</Form.Label>
              <Col sm="4">
                <AsyncSelect
                  className="async-select"
                  cacheOptions
                  defaultOptions
                  isClearable
                  value={strDesignSelected}
                  getOptionLabel={e => e.name}
                  getOptionValue={e => e.design_id}
                  loadOptions={(query) => onSearchDesign(query, "構造")}
                  onChange={handleChangeStrDesign}
                  placeholder=""
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="1">{fields._address}</Form.Label>
              <Col sm="2" className="input-group">
                <InputGroup.Text className="input-group-text-cm" style={{fontWeight: "700"}}>〒</InputGroup.Text>
                <Form.Control id="zip" type="tel"
                              value={zip}
                              style={{textAlign: "center"}}
                              onKeyPress={e => checkKey(e, /[0-9\-]/)}
                              maxLength={8}
                              onChange={e => setZipAuto(e.target.value, setZip, setPref, setPrefSelected, setCity)}
                              onBlur={e => formatZip(e.target.value, setZip, setPref, setPrefSelected, setCity)}
                />
              </Col>
              <Col sm="2">
                <AsyncSelect sm="2"
                             id="pref"
                             className="async-select"
                             cacheOptions
                             defaultOptions
                             isClearable
                             value={prefSelected}
                             getOptionLabel={e => e.name}
                             getOptionValue={e => e.id}
                             loadOptions={onSearchPref}
                             onChange={handleChangePref}
                             placeholder=""
                />
              </Col>
              <Col sm="6" className="input-group">
                <Form.Control type="text" value={city} id="city" style={{borderRadius: "0"}}
                              onChange={e => setCity(e.target.value)}/>

                <Form.Control type="text" value={address}
                              style={{width: "20%", borderTopLeftRadius: "0", borderBottomLeftRadius: "0"}}
                              onChange={e => setAddress(e.target.value)}
                              onBlur={e => setAddress(toMultibyte(e.target.value))}/>
                <Button as={Link} variant="link" to={"/map?book_id=" + bookId} style={{paddingRight: "0"}}>
                  <FontAwesomeIcon icon={faMapMarkerAlt} size="2x"/>
                </Button>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="1">事務所住所</Form.Label>
              <Col sm="2" className="input-group">
                <InputGroup.Text className="input-group-text-cm" style={{fontWeight: "700"}}>〒</InputGroup.Text>
                <Form.Control id="zip" type="tel"
                              value={officeZip}
                              style={{textAlign: "center"}}
                              onKeyPress={e => checkKey(e, /[0-9\-]/)}
                              maxLength={8}
                              onChange={e => setZipAuto(e.target.value, setOfficeZip, setOfficePref, setOfficePrefSelected, setOfficeCity)}
                              onBlur={e => formatZip(e.target.value, setOfficeZip, setOfficePref, setOfficePrefSelected, setOfficeCity)}
                />
              </Col>
              <Col sm="2">
                <AsyncSelect sm="2"
                             id="pref"
                             className="async-select"
                             cacheOptions
                             defaultOptions
                             isClearable
                             value={officePrefSelected}
                             getOptionLabel={e => e.name}
                             getOptionValue={e => e.id}
                             loadOptions={onSearchPref}
                             onChange={handleOfficeChangePref}
                             placeholder=""
                />
              </Col>
              <Col sm="6" className="input-group">
                <Form.Control type="text" value={officeCity} id="city" style={{borderRadius: "0"}}
                              onChange={e => setOfficeCity(e.target.value)}/>

                <Form.Control type="text" value={officeAddress}
                              style={{width: "20%", borderTopLeftRadius: "0", borderBottomLeftRadius: "0"}}
                              onChange={e => setOfficeAddress(e.target.value)}
                              onBlur={e => setOfficeAddress(toMultibyte(e.target.value))}
                />
                <Button as={Link} variant="link" to={""} style={{paddingRight: "0", opacity: 0}} className="disabled"
                        disabled>
                  <FontAwesomeIcon icon={faMapMarkerAlt} size="2x"/>
                </Button>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="1">{fields.category}</Form.Label>
              <Col sm="2">
                <AsyncSelect
                  className="async-select"
                  cacheOptions
                  defaultOptions
                  value={categorySelected}
                  getOptionLabel={e => e.name}
                  getOptionValue={e => e.id}
                  loadOptions={onSearchCategory}
                  onChange={handleChangeCategory}
                  placeholder=""
                />
              </Col>
              <Form.Label column sm="1"></Form.Label>
              <Form.Label column sm="1">{fields.main_usage}</Form.Label>
              <Col sm="2">
                <Form.Control type="text" value={mainUsage} onChange={e => setMainUsage(e.target.value)}/>
              </Col>
              <Form.Label column sm="1">{fields.opn_area}</Form.Label>
              <Col sm="2">
                <AsyncSelect
                  className="async-select"
                  cacheOptions
                  defaultOptions
                  value={opnAreaSelected}
                  getOptionLabel={e => e.name}
                  getOptionValue={e => e.id}
                  loadOptions={onSearchOpnArea}
                  onChange={handleChangeOpnArea}
                  placeholder=""
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="1">{fields.note1}</Form.Label>
              <Col sm="4">
                <Form.Control type="text" value={note1} onChange={e => setNote1(e.target.value)}/>
              </Col>
              <Form.Label column sm="1"></Form.Label>
              <Form.Label column sm="1">{fields.note2}</Form.Label>
              <Col sm="4">
                <AsyncSelect
                  className="async-select"
                  cacheOptions
                  defaultOptions
                  value={note2Selected}
                  getOptionLabel={e => e.name}
                  getOptionValue={e => e.id}
                  loadOptions={onSearchNote2}
                  onChange={handleChangeNote2}
                  placeholder=""
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="1">{fields.note3}</Form.Label>
              <Col sm="4">
                <Form.Control type="text" value={note3} onChange={e => setNote3(e.target.value)}/>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="1">工事担当</Form.Label>
              <Col sm="4" className="input-group">
                <Form.Control
                  id="pic_const_name_1"
                  type="text"
                  value={picConstName1}
                  onChange={(e) => {
                    setPicConstName1(e.target.value)
                  }}
                  placeholder="氏名"
                  className="rounded-left"
                  style={{borderRadius: 0}}
                />
                <Form.Control
                  id="pic_const_tel_1"
                  type="tel"
                  value={picConstTel1}
                  onChange={(e) => {
                    setPicConstTel1(e.target.value)
                  }}
                  placeholder="電話"
                  style={{borderRadius: 0}}
                />
                <Form.Control
                  id="pic_const_email_1"
                  type="email"
                  value={picConstEmail1}
                  onChange={(e) => {
                    setPicConstEmail1(e.target.value)
                  }}
                  placeholder="メール"
                  className="rounded-right"
                  style={{borderRadius: 0}}
                />
              </Col>
              <Form.Label column sm="1"></Form.Label>
              <Form.Label column sm="1">設計担当</Form.Label>
              <Col sm="4" className="input-group">
                <Form.Control
                  id="pic_design_name_1"
                  type="text"
                  value={picDesignName1}
                  onChange={(e) => {
                    setPicDesignName1(e.target.value)
                  }}
                  placeholder="氏名"
                  className="rounded-left"
                  style={{borderRadius: 0}}
                />
                <Form.Control
                  id="pic_design_tel_1"
                  type="tel"
                  value={picDesignTel1}
                  onChange={(e) => {
                    setPicDesignTel1(e.target.value)
                  }}
                  placeholder="電話"
                  style={{borderRadius: 0}}
                />
                <Form.Control
                  id="pic_design_email_1"
                  type="email"
                  value={picDesignEmail1}
                  onChange={(e) => {
                    setPicDesignEmail1(e.target.value)
                  }}
                  placeholder="メール"
                  className="rounded-right"
                  style={{borderRadius: 0}}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="1"></Form.Label>
              <Col sm="4" className="input-group">
                <Form.Control
                  id="pic_const_name_2"
                  type="text"
                  value={picConstName2}
                  onChange={(e) => {
                    setPicConstName2(e.target.value)
                  }}
                  placeholder="氏名"
                  className="rounded-left"
                  style={{borderRadius: 0}}
                />
                <Form.Control
                  id="pic_const_tel_2"
                  type="tel"
                  value={picConstTel2}
                  onChange={(e) => {
                    setPicConstTel2(e.target.value)
                  }}
                  placeholder="電話"
                  style={{borderRadius: 0}}
                />
                <Form.Control
                  id="pic_const_email_2"
                  type="email"
                  value={picConstEmail2}
                  onChange={(e) => {
                    setPicConstEmail2(e.target.value)
                  }}
                  placeholder="メール"
                  className="rounded-right"
                  style={{borderRadius: 0}}
                />
              </Col>
              <Form.Label column sm="1"></Form.Label>
              <Form.Label column sm="1"></Form.Label>
              <Col sm="4" className="input-group">
                <Form.Control
                  id="pic_design_name_2"
                  type="text"
                  value={picDesignName2}
                  onChange={(e) => {
                    setPicDesignName2(e.target.value)
                  }}
                  placeholder="氏名"
                  className="rounded-left"
                  style={{borderRadius: 0}}
                />
                <Form.Control
                  id="pic_design_tel_2"
                  type="tel"
                  value={picDesignTel2}
                  onChange={(e) => {
                    setPicDesignTel2(e.target.value)
                  }}
                  placeholder="電話"
                  style={{borderRadius: 0}}
                />
                <Form.Control
                  id="pic_design_email_2"
                  type="email"
                  value={picDesignEmail2}
                  onChange={(e) => {
                    setPicDesignEmail2(e.target.value)
                  }}
                  placeholder="メール"
                  className="rounded-right"
                  style={{borderRadius: 0}}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="1"></Form.Label>
              <Col sm="4" className="input-group">
                <Form.Control
                  id="pic_const_name_3"
                  type="text"
                  value={picConstName3}
                  onChange={(e) => {
                    setPicConstName3(e.target.value)
                  }}
                  placeholder="氏名"
                  className="rounded-left"
                  style={{borderRadius: 0}}
                />
                <Form.Control
                  id="pic_const_tel_3"
                  type="tel"
                  value={picConstTel3}
                  onChange={(e) => {
                    setPicConstTel3(e.target.value)
                  }}
                  placeholder="電話"
                  style={{borderRadius: 0}}
                />
                <Form.Control
                  id="pic_const_email_3"
                  type="email"
                  value={picConstEmail3}
                  onChange={(e) => {
                    setPicConstEmail3(e.target.value)
                  }}
                  placeholder="メール"
                  className="rounded-right"
                  style={{borderRadius: 0}}
                />
              </Col>
              <Form.Label column sm="1"></Form.Label>
              <Form.Label column sm="1"></Form.Label>
              <Col sm="4" className="input-group">
                <Form.Control
                  id="pic_design_name_3"
                  type="text"
                  value={picDesignName3}
                  onChange={(e) => {
                    setPicDesignName3(e.target.value)
                  }}
                  placeholder="氏名"
                  className="rounded-left"
                  style={{borderRadius: 0}}
                />
                <Form.Control
                  id="pic_design_tel_3"
                  type="tel"
                  value={picDesignTel3}
                  onChange={(e) => {
                    setPicDesignTel3(e.target.value)
                  }}
                  placeholder="電話"
                  style={{borderRadius: 0}}
                />
                <Form.Control
                  id="pic_design_email_3"
                  type="email"
                  value={picDesignEmail3}
                  onChange={(e) => {
                    setPicDesignEmail3(e.target.value)
                  }}
                  placeholder="メール"
                  className="rounded-right"
                  style={{borderRadius: 0}}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="1">{fields.start_date_schedule}</Form.Label>
              <Col sm="2" className="input-group">
                <DatePicker id="startDateSchedule" selected={startDateSchedule} onChange={(date) => setStartDateSchedule(date)}
                            dateFormat="yyyy年MM月dd日" locale="ja"/>
                <Button as={Link} variant="link" to={"/"} style={{paddingRight: "0"}}
                        onClick={e => setFocusCalendar(e, 'startDateSchedule')}>
                  <FontAwesomeIcon icon={faCalendarAlt} size="2x"/>
                </Button>
              </Col>
              <Col sm="1"></Col>
              <Form.Label column sm="1">{fields.complete_date_schedule}</Form.Label>
              <Col sm="2" className="input-group">
                <DatePicker id="completeDateSchedule" selected={completeDateSchedule} onChange={(date) => setCompleteDateSchedule(date)}
                            dateFormat="yyyy年MM月dd日" locale="ja"/>
                <Button as={Link} variant="link" to={"/"} style={{paddingRight: "0"}}
                        onClick={e => setFocusCalendar(e, 'completeDateSchedule')}>
                  <FontAwesomeIcon icon={faCalendarAlt} size="2x"/>
                </Button>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="1">{fields.start_date}</Form.Label>
              <Col sm="2" className="input-group">
                <DatePicker id="startDate" selected={startDate} onChange={(date) => setStartDate(date)}
                            dateFormat="yyyy年MM月dd日" locale="ja"/>
                <Button as={Link} variant="link" to={"/"} style={{paddingRight: "0"}}
                        onClick={e => setFocusCalendar(e, 'startDate')}>
                  <FontAwesomeIcon icon={faCalendarAlt} size="2x"/>
                </Button>
              </Col>
              <Col sm="1"></Col>
              <Form.Label column sm="1">{fields.complete_date}</Form.Label>
              <Col sm="2" className="input-group">
                <DatePicker id="completeDate" selected={completeDate} onChange={(date) => setCompleteDate(date)}
                            dateFormat="yyyy年MM月dd日" locale="ja"/>
                <Button as={Link} variant="link" to={"/"} style={{paddingRight: "0"}}
                        onClick={e => setFocusCalendar(e, 'completeDate')}>
                  <FontAwesomeIcon icon={faCalendarAlt} size="2x"/>
                </Button>
              </Col>
              <Col sm="1"></Col>
              <Form.Label column sm="1">{fields.warranty_date}</Form.Label>
              <Col sm="2" className="input-group">
                <DatePicker id="warrantyDate" selected={warrantyDate} onChange={(date) => setWarrantyDate(date)}
                            dateFormat="yyyy年MM月dd日" locale="ja"/>
                <Button as={Link} variant="link" to={"/"} style={{paddingRight: "0"}}
                        onClick={e => setFocusCalendar(e, 'warrantyDate')}>
                  <FontAwesomeIcon icon={faCalendarAlt} size="2x"/>
                </Button>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Col sm="9"></Col>
              <Col sm="2" className="text-right">
                <Button variant="secondary" id="btn-download" onClick={onClickWarranty}
                        disabled={histList.length === 0}>
                <span>
                <FontAwesomeIcon icon={faFile} size="lg"/>保証書
                </span>
                </Button>
                {warrantyDocId &&
                  <span style={{padding: "0.7rem", color: "#0067a2", fontWeight: "700"}}>
                  済
                </span>
                }
                {!warrantyDocId &&
                  <span style={{padding: "0.7rem", color: "#0067a2", fontWeight: "700"}}>
                  未
                </span>
                }
              </Col>
              <Col sm="1"></Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="1">{fields.memo}</Form.Label>
              <Col sm="10">
                <Form.Control type="text" value={memo} onChange={e => setMemo(e.target.value)}/>
              </Col>
            </Form.Group>
          </Card>
          <Card className="card-thirdly w-80 mx-auto" style={{padding: "1rem"}}>
            <Form.Group as={Row}>
              <Col sm="1"></Col>
              <Col sm="3"></Col>
              <Col sm="4" className="text-center">
                <FormLabel>請求金額 : </FormLabel>
                {invPrice ? (
                  <span style={{fontSize: "1.5rem"}}>
                  <FontAwesomeIcon icon={faYenSign} size="xs" style={{marginBlock: "0.1rem", marginLeft: "0.5rem"}}/>
                  <span style={{fontWeight: "700", fontSize: "1.5rem"}}>
                    {formatNumber(invPrice)}
                  </span>
                </span>
                ) : (
                  <span style={{fontSize: "1.5rem"}}>
                  <span style={{fontWeight: "700", fontSize: "0.8rem"}}>
                  　－－－
                  </span>
                  </span>
                )
                }
              </Col>
              <Form.Label column sm="1">{fields.depos_amount}</Form.Label>
              <Col sm="2">
                <Form.Control
                  type="text"
                  value={receiptAmount}
                  style={{textAlign: "right"}}
                  onKeyPress={e => checkKey(e, /[0-9]/)}
                  onBlur={e => setReceiptAmount(formatNumber(e.target.value))}
                  onChange={e => setReceiptAmount(e.target.value)}/>
              </Col>
              <Col sm="1"></Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="1">{fields.special_note}</Form.Label>
              <Col sm="10">
                <Form.Control id="specialNote" as="textarea" rows={3} value={specialNote}
                              onChange={e => setSpecialNote(e.target.value)}/>
              </Col>
              <Col sm="1"></Col>
            </Form.Group>
          </Card>

          <Row className="h3">
            <Col>
              <h3 style={{paddingTop: ".7rem"}}>
                工事履歴
              </h3>
            </Col>
            <Col className="text-right">
              <Button variant="secondary" id="btn-download" onClick={onClickDownload} disabled={histList.length === 0}>
                <span>
                <FontAwesomeIcon icon={faFile} size="lg"/>出荷証明
                </span>
              </Button>
            </Col>
          </Row>
          <Card className="card-thirdly w-80 mx-auto">
            <MdDatatable
              keyField="history_id"
              listData={histList}
              columns={histColumns}
              // onTableChange={onTableChange}
              page={histPage}
              sizePerPage={histSizePerPage}
              totalSize={histTotalSize}
              sortField={histSortField}
              sortOrder={histSortOrder}
            />
          </Card>

          <Row className="h3">
            <Col>
              <h3 style={{paddingTop: ".7rem"}}>
                作業履歴
              </h3>
            </Col>
          </Row>
          <Card className="card-thirdly w-80 mx-auto">
            <MdDatatable
              keyField="id"
              listData={reportList}
              columns={reportColumns}
              // onTableChange={onTableChange}
              page={reportPage}
              sizePerPage={reportSizePerPage}
              totalSize={reportTotalSize}
              sortField={reportSortField}
              sortOrder={reportSortOrder}
            />
          </Card>

          <Row>
            <Col>
              {updateType === 'update' && <Button variant="danger" onClick={onClickDelete}>削除</Button>}
            </Col>
          </Row>

        </Container>
      </div>

      <Modal size="lg" show={modalShow} onHide={onClickModalClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="print-page" style={{margin: "3rem 5rem"}}>
            <Row>
              <Form.Label column sm="4" style={{fontSize: "1.3rem", fontWeight: "700"}}>工事番号</Form.Label>
              <Col>
                <Form.Control plaintext readOnly value={modalBookNo}
                              style={{fontSize: "1.3rem !important", fontWeight: "700"}}/>
              </Col>
            </Row>
            <Row>
              <Form.Label column sm="4" style={{fontSize: "1.3rem", fontWeight: "700"}}>現場名</Form.Label>
              <Col>
                <Form.Control plaintext readOnly value={modalBookName} style={{fontSize: "1.3rem", fontWeight: "700"}}/>
              </Col>
            </Row>
            <Row>
              <Form.Label column sm="4" style={{fontSize: "1.3rem", fontWeight: "700"}}>施工業者</Form.Label>
              <Col>
                <Form.Control plaintext readOnly value={modalConstName}
                              style={{fontSize: "1.3rem", fontWeight: "700"}}/>
              </Col>
            </Row>
            <Row>
              <Form.Label column sm="4" style={{fontSize: "1.3rem", fontWeight: "700"}}>設計事務所</Form.Label>
              <Col>
                <Form.Control plaintext readOnly value={modalExtDesignName}
                              style={{fontSize: "1.3rem", fontWeight: "700"}}/>
              </Col>
            </Row>
            <Row>
              <Form.Label column sm="4" style={{fontSize: "1.3rem", fontWeight: "700"}}>構造設計</Form.Label>
              <Col>
                <Form.Control plaintext readOnly value={modalStrDesignName}
                              style={{fontSize: "1.3rem", fontWeight: "700"}}/>
              </Col>
            </Row>
            <Row>
              <Form.Label column sm="4" style={{fontSize: "1.3rem", fontWeight: "700"}}>現場住所</Form.Label>
              <Col>
                {zip &&
                  <Form.Control plaintext readOnly value={zip} style={{fontSize: "1.3rem", fontWeight: "700"}}/>
                }
                {prefSelected.name &&
                  <Form.Control plaintext readOnly value={prefSelected.name}
                                style={{fontSize: "1.3rem", fontWeight: "700"}}/>
                }
                {city &&
                  <Form.Control plaintext readOnly value={city} style={{fontSize: "1.3rem", fontWeight: "700"}}/>
                }
                {address &&
                  <Form.Control plaintext readOnly value={address} style={{fontSize: "1.3rem", fontWeight: "700"}}/>
                }
              </Col>
            </Row>
            <div style={{marginTop: "3rem"}}>
              <img src={modalQrImage} alt={bookId}
                   style={{marginLeft: "auto", marginRight: "auto", display: "block", height: "450px"}}/>
            </div>
            <div className="text-center" style={{fontSize: "1.3rem"}}>
              {modalBookId}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClickModalClose}>
            キャンセル
          </Button>
          <Button variant="primary" onClick={onClickPrint}>
            印刷
          </Button>
        </Modal.Footer>
      </Modal>

      <Toast/>
    </>
  );
}

export default withRouter(BookDtl);