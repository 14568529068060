import React, {useEffect, useImperativeHandle, useRef, useState} from "react";
import {Col, Form, Row} from "react-bootstrap";
import {
  formatNumber, nvl,
  onFocusSelect,
  parseNumber,
} from "../CMUtil";

export const ChDtlRowInvDtl = React.forwardRef((props, ref) => {

  const iniQty = useRef(true);
  const iniAmount = useRef(true);

  const [invDtlId, setInvDtlId] = useState("");
  const [chNo, setChNo] = useState("");
  const [lineNo, setLineNo] = useState("");
  const [price, setPrice] = useState("");
  const [qty, setQty] = useState("");
  const [amount, setAmount] = useState("");

  useEffect(() => {

    const update = async () => {
      setChNo(nvl(props.invDtl.ch_no));
      setLineNo(nvl(props.invDtl.line_no));
      setPrice(nvl(props.invDtl.price));
      setQty(nvl(props.invDtl.qty));
      setAmount(nvl(props.invDtl.amount));
    }
    update();
  }, []);

  useEffect(() => {
    if (iniQty.current) {
      iniQty.current = false;
      return;
    }

    let locQty = qty ? parseInt(qty) : 0;
    let locPrice = price ? parseInt(price) : 0;
    let locAmount = locQty * locPrice;
    setAmount(locAmount ? formatNumber(parseNumber(locAmount)) : "");
  }, [qty]);

  useEffect(() => {
    if (iniAmount.current) {
      iniAmount.current = false;
      return;
    }
    props.lineTotalCalcHandler(lineNo);
  }, [amount]);

  useImperativeHandle(ref, () => ({
    getStates: () => {
      let obj = {
        inv_dtl_id:invDtlId,
        ch_no: chNo,
        line_no: lineNo,
        price: price,
        qty: qty,
        amount: amount
      }
      return obj;
    },
    setData: (data) => {
      setInvDtlId(nvl(data.inv_dtl_id));
      setChNo(nvl(data.ch_no));
      setLineNo(nvl(data.line_no));
      setQty(nvl(data.qty));
      setPrice(nvl(data.price));
      setAmount(nvl(data.amount));
    }
  }), [
    amount
  ]);

  return (
    <Row key={"inv_dtl_index_" + props.invIndex + "_" + props.chNo}>
      <Col sm="5"
           className={"dtl-data-col dtl-data-col-left " + (props.isLastRow ? "dtl-data-col-bottom" : "")}>
        <Form.Control
          id="poQty"
          type="tel"
          className="text-right"
          value={qty}
          maxLength={4}
          onFocus={e => onFocusSelect(e)}
          onChange={e => setQty(e.target.value)}
          onBlur={e => setQty(formatNumber(parseNumber(e.target.value)))}
          placeholder=""
        />
      </Col>
      <Col sm="7" className={"dtl-data-col " + (props.isLastRow ? "dtl-data-col-bottom" : "")}>
        <Form.Control
          id="amount"
          type="tel"
          className="text-right"
          value={amount}
          readOnly={true}
          placeholder=""
        />
      </Col>
    </Row>
  )
});

export default React.memo(ChDtlRowInvDtl);