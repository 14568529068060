import {withRouter} from "react-router";
import React, {useEffect, useRef, useState} from 'react';
import AppNav from "./AppNav";
import 'react-toastify/dist/ReactToastify.css';
import Toast, {success, error} from "../Toast";
import {deleteAPI, getAPI, getSpAPI, patchAPI, postAPI, putAPI} from "../APIUtil";
import Button from "react-bootstrap/Button";
import {Card, Col, Container, Form, InputGroup, Modal, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faBan,
  faChevronLeft,
  faChevronRight,
  faMinusCircle,
  faPaperPlane,
  faPen,
  faPlusCircle,
  faSyncAlt,
  faTimes, faTrash
} from "@fortawesome/free-solid-svg-icons";
import {
  dateformat,
  dateformatDB,
  dateformatJPN,
  dateformatJPNByType,
  dateformatYYYYMMDD, finishLoadingBtn, lpad,
  parseDate,
  parseDatetime,
  startLoadingBtn,
  timeformatHHMM,
  toNull
} from "../CMUtil";
import {ScheduleDtl} from "./ScheduleDtl";
import {DndProvider, useDrag, useDrop} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {TouchBackend} from "react-dnd-touch-backend";
import {useHistory} from "react-router-dom";
import {BOOK_ID_OFF} from "../Const";
import AsyncSelect from "react-select/async";

export const Schedule = ({match: {params: {p_opn_date}}}) => {

  const history = useHistory();

  const iniOpnDate = useRef(true);

  const [comId, setComId] = useState("");
  const [count, setCount] = useState(0);
  const [opnDate, setOpnDate] = useState(new Date());
  const [scheduleIdOff, setScheduleIdOff] = useState("");

  const [note, setNote] = useState("");

  const [scheduleList, setScheduleList] = useState([]);
  // const [assignList, setAssignList] = useState({});
  const [reportListDB, setReportListDB] = useState([]);

  const [userList, setUserList] = useState([]);

  const [carList, setCarList] = useState([]);
  const [opnList, setOpnList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [opnOrderList, setOpnOrderList] = useState([]);

  const scheduleListRef = useRef([]);

  // 通知詳細モーダル
  const [modalShow, setModalShow] = useState(false);
  const [modalOpnUserId, setModalOpnUserId] = useState("");
  const [modalScheduleId, setModalScheduleId] = useState("");
  const [modalOpnUserName, setModalOpnUserName] = useState("");
  const [modalBookName, setModalBookName] = useState("");
  const [modalOpnDate, setModalOpnDate] = useState("");
  const [modalNoticeDate, setModalNoticeDate] = useState("");
  const [modalConfirmDate, setModalConfirmDate] = useState("");
  const [modalStatus, setModalStatus] = useState("");

  // 手配ボード
  const [modalAssignShow, setModalAssignShow] = useState(false);

  // 勤務時間詳細モーダル
  const [modalWorkShow, setModalWorkShow] = useState(false);
  const [modalWorkOpnUserName, setModalWorkOpnUserName] = useState("");
  const [modalWorkDailyReportList, setModalWorkDailyReportList] = useState([]);
  const [modalWorkTotalWorkHour, setWorkTotalWorkHour] = useState(0.0);

  // 初期化
  useEffect(() => {

    const update = async () => {

      let obj = await getSpAPI("current_login_user");
      setComId(obj.results[0].com.com_id);

      let date = null;
      if (p_opn_date) {
        date = parseDate(p_opn_date);
        date.setFullYear(date.getFullYear());
        date.setMonth(date.getMonth());
        date.setDate(date.getDate());

      } else {
        date = new Date();

      }
      setOpnDate(date);

    }

    update();

  }, []);

  useEffect(() => {

    if (iniOpnDate.current) {
      iniOpnDate.current = false;
      return;
    }

    const update = async () => {

      let searchCondition = null;
      let res = null;

      //休日スケジュールＩＤの作成
      setScheduleIdOff("S" + dateformatYYYYMMDD(opnDate).substr(2) + "00");

      //車両マスタ
      searchCondition = {
        categ_id: "CAR"
      }
      res = await getAPI("ms_code_typeahead_view", searchCondition);
      let locCarList = [];
      res.results.map((obj) => {
        locCarList.push({id: obj.code_id, name: obj.code_name});
      })
      setCarList(locCarList);

      //作業マスタ
      searchCondition = {
        categ_id: "OPN"
      }
      res = await getAPI("ms_code_typeahead_view", searchCondition);
      let locOpnList = [];
      res.results.map((obj) => {
        locOpnList.push({id: obj.code_id, name: obj.code_name});
      })
      setOpnList(locOpnList);

      //役割マスタ
      searchCondition = {
        categ_id: "ROLE"
      }
      res = await getAPI("ms_code_typeahead_view", searchCondition);
      let locRoleList = [];
      res.results.map((obj) => {
        locRoleList.push({id: obj.code_id, name: obj.code_name});
      })
      setRoleList(locRoleList);

      //作業順マスタ
      let locOpnOrderList = [];
      locOpnOrderList.push({id: '1', name: '1'});
      locOpnOrderList.push({id: '2', name: '2'});
      locOpnOrderList.push({id: '3', name: '3'});
      setOpnOrderList(locOpnOrderList);

      searchCondition = {
        opn_date: dateformatDB(opnDate)
      }

      let locNote = "";
      res = await getAPI("tr_cal_search_view", searchCondition);
      if (res.count === 1) {
        locNote = res.results[0].note;
      }

      searchCondition = {
        opn_date: dateformatDB(opnDate)
      }
      res = await getAPI("tr_schedule_search_view", searchCondition);
      let locScheduleList = await processArray(res.results); //日報追加
      locScheduleList = locScheduleList.filter((schedule) => schedule.book && schedule.book.book_id !== BOOK_ID_OFF);

      //最小表示５件
      while (locScheduleList.length <= 7) {
        locScheduleList.push({});
      }

      searchCondition = {
        opn_date: dateformatDB(opnDate)
      }
      res = await getAPI("tr_report_search_view", searchCondition);
      let locReportList = res.results;

      for (let i = 0; i < locScheduleList.length; i++) {
        scheduleListRef.current[i] = React.createRef();
      }

      setNote(locNote);
      setScheduleList(locScheduleList);
      setReportListDB(locReportList);
      // setAssignList(locAssignList);

      let locItems = [];

      searchCondition = {
        dept_id: comId + "DEPT_CONST"
      };
      res = await getAPI("ms_user_search_view", searchCondition);
      let locUserList = res.results;

      //労働時間リスト（過去6日感）
      let opnDateFrom = new Date();
      opnDateFrom.setFullYear(opnDate.getFullYear());
      opnDateFrom.setMonth(opnDate.getMonth());
      opnDateFrom.setDate(opnDate.getDate() - 7);
      let opnDateTo = new Date();
      opnDateTo.setFullYear(opnDate.getFullYear());
      opnDateTo.setMonth(opnDate.getMonth());
      opnDateTo.setDate(opnDate.getDate() - 1);

      searchCondition = {
        opn_date_from: dateformatDB(opnDateFrom),
        opn_date_to: dateformatDB(opnDateTo),
      }
      res = await getAPI("tr_report_work_hour_list_view", searchCondition);

      for (let i = 0; i < locReportList.length; i++) {
        let locReport = locReportList[i];
        locItems.push({
          id: locItems.length,
          user_id: locReport.opn_user.user_id,
          name: locReport.opn_user.last_name,
          column: locReport.schedule.schedule_id,
          schedule_id: locReport.schedule.schedule_id,
          confirm_date: locReport.confirm_date,
          assign_status: 0,
          report_db: locReport,
          role_id: locReport.role ? locReport.role.code_id : "",
          opn_order: locReport.opn_order ? locReport.opn_order : ""
        });
      }

      locItems = setAssignStatus(locItems);
      setItems(locItems);
      setUserList(locUserList);

      $("#loading_schedule_list").css("z-index", -10);
    }

    update();

  }, [opnDate, count]);

  async function processArray(array) {
    let locScheduleList = [];
    for (const item of array) {
      let param = {
        schedule_id: item.schedule_id
      }
      let report = await getAPI("tr_report_search_view", param);
      let locSchedule = JSON.parse(JSON.stringify(item));
      locSchedule['report_list'] = report.results;
      locScheduleList.push(locSchedule);
    }
    return locScheduleList;
  }

  const onClickAdd = () => {

    let locScheduleList = JSON.parse(JSON.stringify(scheduleList));
    locScheduleList.push({});
    setScheduleList(locScheduleList);

    scheduleListRef.current[locScheduleList.length - 1] = React.createRef();
  }

  const onClickUpdate = async () => {

    startLoadingBtn("btn-update");

    let searchCondition = null;
    let res = null;

    //カレンダー更新
    searchCondition = {
      opn_date: dateformatDB(opnDate)
    }
    res = await getAPI("tr_cal_search_view", searchCondition);
    let param = {
      opn_date: dateformatDB(opnDate),
      note: note,
      pg: 'Schedule',
    }
    if (res.count === 0) {
      await postAPI("tr_cal/", param);
    } else {
      let cal = res.results[0];
      await putAPI("tr_cal/" + cal.cal_id + "/", param);
    }

    //有給用スケジュール
    searchCondition = {
      opn_date: dateformatDB(opnDate),
      book_id: BOOK_ID_OFF
    }
    res = await getAPI("tr_schedule_search_view", searchCondition);
    let locScheduleOff = null;
    if (res.count === 0) {
      let param = {
        schedule_id: scheduleIdOff,
        opn_date: dateformatDB(opnDate),
        book_id: BOOK_ID_OFF,
        cancel: false,
        create_type: 'PC',
        create_pg: 'Schedule',
        update_pg: 'Schedule'
      };
      locScheduleOff = await postAPI("tr_schedule/", param);
    } else {
      locScheduleOff = res.results[0];
    }

    let reportListOff = items.filter((item) => item.schedule_id == scheduleIdOff);
    await updateReportList(BOOK_ID_OFF, locScheduleOff.schedule_id, reportListOff);

    searchCondition = {
      schedule_id: scheduleIdOff
    }
    res = await getAPI("tr_report_search_view", searchCondition);
    let dbReportList = await res.results; //ＤＢ上の担当一覧(日報)
    let scnReportList = items.filter((report) => report.schedule_id === locScheduleOff.schedule_id); //画面上の担当一覧
    dbReportList.map(async (db) => {
      let scns = scnReportList.filter((scn) => db.opn_user.user_id === scn.user_id);
      if (scns.length === 0) {
        let report = await deleteAPI("tr_report/" + db.report_id + "/", db);
      }
    });

    let locScheduleList = [];
    scheduleListRef.current.map(scheduleDtl => {
      if (!scheduleDtl.current) {
        return;
      }
      locScheduleList.push(scheduleDtl.current.getStates());
    });

    locScheduleList = await bulkUpdateSchedule(locScheduleList, dbReportList, scnReportList);

    setCount(count + 1);
    setScheduleList([]);

    finishLoadingBtn("btn-update");
    success("更新完了");
  }

  const bulkUpdateSchedule = async (locScheduleList) => {

    let updatedScheduleList = [];
    for (const locSchedule of locScheduleList) {

      // 1.スケジュール更新
      let updatedSchedule = null;
      if (!locSchedule.schedule_id) {
        //新規
        if (!locSchedule.book_id) {
          return false;
        }
        locSchedule["opn_date"] = dateformatDB(opnDate);
        locSchedule["create_type"] = "PC";
        updatedSchedule = await postAPI("tr_schedule/", locSchedule);

      } else {
        //既存
        updatedSchedule = await putAPI("tr_schedule/" + locSchedule.schedule_id + "/", locSchedule);
      }

      // 2.日報更新
      let scnReportList = items.filter((report) => report.schedule_id === locSchedule.schedule_id); //画面上の担当一覧
      let searchCondition = {
        schedule_id: locSchedule.schedule_id
      }
      let res = await getAPI("tr_report_search_view", searchCondition);
      let dbReportList = await res.results; //ＤＢ上の担当一覧(日報)
      await bulkUpdateReport(updatedSchedule, scnReportList, dbReportList);

      updatedScheduleList.push(updatedSchedule);
    }

    return updatedScheduleList;
  }

  const bulkUpdateReport = async (schedule, scnReportList, dbReportList) => {

    //追加・更新
    for (const scn of scnReportList) {
      let dbs = dbReportList.filter((db) => db.opn_user.user_id === scn.user_id);
      if (dbs.length === 0) {
        //新規登録
        let report = {
          book_id: toNull(schedule.book.book_id),
          schedule_id: toNull(schedule.schedule_id),
          opn_date: toNull(dateformat(opnDate)),
          opn_user_id: toNull(scn.user_id),
          role_id: toNull(scn.role_id),
          opn_order: toNull(scn.opn_order),
          opn_id: schedule.opn ? toNull(schedule.opn.code_id) : null,
          dep_time: toNull(schedule.dep_time),
          mtg_start_time: toNull(schedule.mtg_start_time),
          update_pg: 'Schedule'
        };
        await postAPI("tr_report/", report);

      } else {

        let db = dbs[0];
        if (
          schedule.book.book_id !== db.book_id ||
          schedule.dep_time !== timeformatHHMM(db.dep_time) ||
          schedule.mtg_start_time !== timeformatHHMM(db.mtg_start_time) ||
          schedule.opn.code_id !== db.opn_id
        ) {
          //更新
          let report = {
            book_id: toNull(schedule.book.book_id),
            schedule_id: toNull(schedule.schedule_id),
            opn_date: toNull(dateformat(opnDate)),
            opn_user_id: toNull(scn.user_id),
            role_id: toNull(scn.role_id),
            opn_order: toNull(scn.opn_order),
            opn_id: schedule.opn ? toNull(schedule.opn.code_id) : null,
            dep_time: toNull(schedule.dep_time),
            mtg_start_time: toNull(schedule.mtg_start_time),
            update_pg: 'Schedule'
          };
          await putAPI("tr_report/" + db.report_id + "/", report);
        }
      }
    }

    //削除
    for (const db of dbReportList) {
      let scn = scnReportList.filter((scn) => db.opn_user.user_id === scn.user_id);
      if (scn.length === 0) {
        await deleteAPI("tr_report/" + db.report_id + "/");
      }
    }
  }

  const onClickReload = async () => {

    let locItems = await updateStatus(items);
    setItems(locItems);

  }

  const setAssignStatus = (list) => {

    for (let i = 0; i < list.length; i++) {
      let locAssignList = list.filter(function (item) {
        return item.user_id === list[i].user_id && item.schedule_id !== "000";
      });
      list[i].assign_status = locAssignList.length;
    }

    return list;
  }

  const removeAssign = (userId, scheduleId) => {

    let locItems = items.filter(function (item) {
      return item.user_id !== userId || item.schedule_id !== scheduleId
    });

    locItems = setAssignStatus(locItems);
    setItems(locItems);
  }

  const onClickPrevMonth = () => {
    let locOpnDate = new Date(opnDate.getTime());
    locOpnDate.setDate(locOpnDate.getDate() - 1);
    history.push("/schedule/" + dateformatYYYYMMDD(locOpnDate));
    setOpnDate(locOpnDate);

    $("#loading_schedule_list").css("z-index", 10);
    setScheduleList([]);
  }

  const onClickNextMonth = () => {
    let locOpnDate = new Date(opnDate.getTime());
    locOpnDate.setDate(locOpnDate.getDate() + 1);
    history.push("/schedule/" + dateformatYYYYMMDD(locOpnDate));
    setOpnDate(locOpnDate);

    $("#loading_schedule_list").css("z-index", 10);
    setScheduleList([]);
  }

  const onClickCancel = async (scheduleId, cancel) => {
    let param = {
      cancel: !cancel,
      pg: 'Schedule'
    };
    await patchAPI("tr_schedule/" + scheduleId + "/", param);
    setScheduleList([]);
    setCount(count + 1);
  }

  const onClickDelete = async (scheduleId) => {
    //日報削除
    let param = {
      schedule_id: scheduleId
    }
    let res = await getAPI("tr_report_search_view", param);
    let deleteReportList = res.results;
    let deletedReportList = await deleteReport(deleteReportList);

    //スケジュール削除
    let deleteSchedule = deleteAPI("tr_schedule/" + scheduleId + "/");
    setScheduleList([]);
    setCount(count + 1);
  }

  async function updateReportList(bookId, scheduleId, reportList) {
    let updatedReportList = [];
    for (const report of reportList) {
      if (!report.report_db) {
        let param = {
          book_id: toNull(bookId),
          schedule_id: toNull(scheduleId),
          opn_date: toNull(dateformat(opnDate)),
          opn_user_id: toNull(report.user_id),
          update_pg: 'Schedule'
        };
        await postAPI("tr_report/", param);
      }

      updatedReportList.push(report);
    }
    return updatedReportList;
  }

  async function deleteReport(deleteReportList) {

    let deletedReportList = [];
    for (const report of deleteReportList) {
      let obj = await deleteAPI("tr_report/" + report.report_id + "/");
      deletedReportList.push(report);
    }
    return deletedReportList;
  }

  // 担当者手配ツール・開始 ///////////////////////////////////////////////////////////////////////////
  const isMobile = window.innerWidth < 10;

  const [items, setItems] = useState([]);

  const moveCardHandler = (dragIndex, hoverIndex) => {
    const dragItem = items[dragIndex];

    if (dragItem) {
      setItems((prevState => {
        const coppiedStateArray = [...prevState];
        const prevItem = coppiedStateArray.splice(hoverIndex, 1, dragItem);
        coppiedStateArray.splice(dragIndex, 1, prevItem[0]);
        return coppiedStateArray;
      }));
    }
  }

  const returnItemsForColumn = (columnName) => {
    return items
      .filter((item) => item.column === columnName)
      .map((item, index) => (
        <MovableItem key={item.id}
                     name={item.name}
                     setItems={setItems}
                     index={index}
                     moveCardHandler={moveCardHandler}
                     userId={item.user_id}
                     currentScheduleId={columnName}
                     prevScheduleId={item.schedule_id}
                     assignStatus={item.assign_status}
                     confirmDate={item.confirm_date}
                     pastWork={item.past_work}
                     reportDB={item.report_db}
        />
      ));
  }

  const MovableItem = ({
                         name,
                         index,
                         moveCardHandler,
                         setItems,
                         userId,
                         currentScheduleId,
                         prevScheduleId,
                         assignStatus,
                         confirmDate,
                         pastWork,
                         reportDB
                       }) => {

    const changeItemColumn = (currentItem, columnName, userId, prevScheduleId) => {
      setItems((prevState) => {

        let newState = JSON.parse(JSON.stringify(prevState));

        newState = newState.filter(function (item) {
          return item.user_id !== userId || item.schedule_id !== prevScheduleId || item.schedule_id === "000"
        })

        let maxId = 0;
        prevState.map((obj) => {
          maxId = Math.max(maxId, obj.id);
        });

        let report_db = null;
        let l = reportListDB.filter((report) => report.opn_user.user_id === userId && report.schedule.schedule_id === columnName);
        if (l.length === 1) {
          report_db = l[0];
        }

        newState.push({
          id: maxId + 1,
          name: currentItem.name,
          column: columnName,
          user_id: userId,
          schedule_id: columnName,
          report_db: report_db
        });

        //並び替え
        // newState = newState.sort(function (a, b) {
        //   return a.id < b.id ? -1 : 1;
        // })

        newState = setAssignStatus(newState); //手配現場数のセット
        // newState = setWorkingHour(userId, newState); //超過時間のセット

        return newState;
      })
    }

    const ref = useRef(null);

    const [, drop] = useDrop({
      accept: 'opn_user',
      hover(item, monitor) {
        if (!ref.current) {
          return;
        }
        const dragIndex = item.index;
        const hoverIndex = index;
        if (dragIndex === hoverIndex) {
          return;
        }
        const hoverBoundingRect = ref.current?.getBoundingClientRect();
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        const clientOffset = monitor.getClientOffset();
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }
        moveCardHandler(dragIndex, hoverIndex);
        item.index = hoverIndex;
      },
    });

    const [{isDragging}, drag] = useDrag({
      type: "opn_user",
      item: {index, name, type: 'opn_user', userId, prevScheduleId},
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult();
        if (dropResult && dropResult.name) {
          const {name} = dropResult;
          changeItemColumn(item, name, userId, prevScheduleId);
        }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const opacity = isDragging ? 0.4 : 1;

    drag(drop(ref));

    const showCloseButton = (userId, prevScheduleId) => {
      $("#btn_close_" + userId + prevScheduleId).css("visibility", "visible");
    }

    const hideCloseButton = (userId, prevScheduleId) => {
      $("#btn_close_" + userId + prevScheduleId).css("visibility", "hidden");
    }

    const getStatusTitle = () => {
      if (reportDB) {
        let noticeDate = parseDate(reportDB.notice_date);
        let confirmDate = parseDate(reportDB.confirm_date);
        return getStatus(noticeDate, confirmDate, "title");
      }
      return "未"; //未通知
    }

    const getWorkStatus = () => {
      if (pastWork >= 2400) {
        //週40時間(2400分)以上は警告
        return "red";
      }
    }

    const getStatusColor = () => {
      if (reportDB) {
        let noticeDate = parseDate(reportDB.notice_date);
        let confirmDate = parseDate(reportDB.confirm_date);
        return getStatus(noticeDate, confirmDate, "color");
      }
      return "gray"; //未通知
    }

    return (
      <div
        ref={ref}
        className={"movable-item assign_count_" + assignStatus} style={{opacity}}
        onMouseOver={((e) => showCloseButton(userId, prevScheduleId))}
        onMouseOut={((e) => hideCloseButton(userId, prevScheduleId))}
      >
        {prevScheduleId === "000" &&
          <div
            className="float-md-left"
            style={{
              width: "0.9rem",
              height: "0.9rem",
              borderRadius: "50%",
              backgroundColor: getWorkStatus(),
              float: "right",
              margin: "0.2rem",
              textAlign: "center"
            }}
            onClick={(e) => onClickShowModalWork(userId)}>
          </div>
        }
        {prevScheduleId !== "000" &&
          <div
            className="float-md-left"
            style={{
              width: "1.1rem",
              height: "1.1rem",
              borderRadius: "0.2rem",
              backgroundColor: getStatusColor(),
              float: "right",
              fontSize: "0.8rem",
              margin: "0.1rem",
              textAlign: "center"
            }}
            onClick={(e) => onClickShowStatus(userId, prevScheduleId)}>
            {getStatusTitle()}
          </div>
        }
        {name}

        {prevScheduleId === "000" &&
          <div
            className="float-md-right"
            style={{
              width: "0.9rem",
              height: "0.9rem",
              borderRadius: "50%",
              float: "right",
              margin: "0.2rem",
              textAlign: "center"
            }}
          >
          </div>
        }

        {
          currentScheduleId !== "000" &&
          <div
            id={"btn_close_" + userId + prevScheduleId}
            className="float-md-right"
            style={{
              width: "1.1rem",
              height: "1.1rem",
              borderRadius: "50%",
              float: "right",
              fontSize: "0.8rem",
              margin: "0.1rem",
              textAlign: "center",
              visibility: "hidden"
            }}
            onClick={e => removeAssign(userId, prevScheduleId)}
          >
            <FontAwesomeIcon icon={faTimes}/>
          </div>
        }
      </div>
    )
  }

  const getStatus = (noticeDate, confirmDate, returnType) => {
    if (!noticeDate) {
      return returnType === "title" ? "未" : "gray"; //未通知
    }
    if (!confirmDate) {
      return returnType === "title" ? "待" : "green"; //確認待
    }
    if (noticeDate > confirmDate) {
      return returnType === "title" ? "待" : "green"; //確認待
    }
    return returnType === "title" ? "　" : ""; //確認済
  }

  const getStatusColor = (reportDB) => {
    if (reportDB) {
      let noticeDate = parseDate(reportDB.notice_date);
      let confirmDate = parseDate(reportDB.confirm_date);
      return getStatus(noticeDate, confirmDate, "color");
    }
    return "gray"; //未通知
  }

  const Column = ({children, className, title}) => {
    const [, drop] = useDrop({
      accept: "opn_user",
      drop: () => ({name: title}),
    })

    return (
      <div ref={drop} className={className}>
        {children}
      </div>
    )
  }
  // 担当者手配ツール・終了 ///////////////////////////////////////////////////////////////////////////

  const [modalAssignScheduleId, setModalAssignScheduleId] = useState("");
  const [modalAssignBookName, setModalAssignBookName] = useState("");
  const [modalAssignList, setModalAssignList] = useState([]);
  const onClickAssignBoard = async (scheduleId) => {

    let searchCondition = {
      dept_id: comId + "DEPT_CONST"
    };
    let res = await getAPI("ms_user_search_view", searchCondition);

    let scheduleObj = await getAPI("tr_schedule/" + scheduleId);

    // let locReportList = assignList[scheduleId].report_list;
    let locModalAssignList = [];
    res.results.map((user) => {
      let iUsers = items.filter((i) => i.schedule_id === scheduleId && i.user_id === user.user_id);
      if (iUsers.length === 1) {
        user['selected'] = true;
        if (iUsers[0].role_id) {
          user['role'] = iUsers[0].role_id;
        }
        if (iUsers[0].opn_order) {
          user['opn_order'] = iUsers[0].opn_order;
        }

      } else {
        user['selected'] = false;
      }

      let role = roleList.filter((r) => r.id === user.role);
      if (role.length === 1) {
        user.roleSelected = [role[0]];
      } else {
        user.roleSelected = null;
      }

      let opnOrder = opnOrderList.filter((r) => r.id === user.opn_order);
      if (opnOrder.length === 1) {
        user.opnOrderSelected = [opnOrder[0]];
      } else {
        user.opnOrderSelected = null;
      }

      locModalAssignList.push(user);
    });

    setModalAssignScheduleId(scheduleId);
    setModalAssignBookName(scheduleObj.book.book_name);
    setModalAssignList(locModalAssignList);

    setModalAssignShow(true);
  }

  const onClickAssignTag = (assign) => {

    let locList = JSON.parse(JSON.stringify(modalAssignList));
    locList.filter((user) => user.user_id === assign.user_id)[0].selected =
      !locList.filter((user) => user.user_id === assign.user_id)[0].selected;
    if (!locList.filter((user) => user.user_id === assign.user_id)[0].selected) {
      locList.filter((user) => user.user_id === assign.user_id)[0].roleSelected = null;
      locList.filter((user) => user.user_id === assign.user_id)[0].opnOrderSelected = null;
    }
    setModalAssignList(locList);

  }

  const onClickModalAssignRegister = async () => {

    let param = {
      schedule_id: modalAssignScheduleId
    }
    let dbReportList = await getAPI("tr_report_search_view", param);
    dbReportList = dbReportList.results;

    let updateItems = JSON.parse(JSON.stringify(items));
    updateItems = updateItems.filter((item) => item.schedule_id !== modalAssignScheduleId);

    modalAssignList.filter((assign) => assign.selected).map((assign) => {

      let report_db = dbReportList.filter((r) => r.opn_user.user_id === assign.user_id);
      if (report_db.length === 1) {
        report_db = report_db[0];
      }

      updateItems.push({
        id: updateItems.length,
        user_id: assign.user_id,
        name: assign.last_name,
        column: modalAssignScheduleId,
        schedule_id: modalAssignScheduleId,
        report_db: report_db,
        assign_status: modalAssignList.filter((item) => item.schedule_id !== "000" && item.user_id === assign.user_id).length,
        confirm_date: null,
        role_id: assign.role ? assign.role : null,
        opn_order: assign.opn_order ? assign.opn_order : null,
      });
    });

    setItems(updateItems);
    onClickModalAssignClose();
  }

  const onClickModalAssignClose = () => setModalAssignShow(false);

  const onClickModalClose = () => setModalShow(false);

  const onClickModalShow = () => setModalShow(true);

  const onClickShowStatus = async (e, userId, scheduleId) => {

    e.stopPropagation(); //PREVENT PARENT ON CLICK;

    let locModalOpnUserName = "";
    let locModalBookName = "";
    let locModalOpnDate = "";
    let locModalNoticeDate = "";
    let locModalConfirmDate = "";
    let locModalStatus = "未通知";

    let user = await getAPI("ms_user/" + userId);
    locModalOpnUserName = user.last_name;

    let schedule = await getAPI("tr_schedule/" + scheduleId);
    locModalBookName = schedule.book.book_name;
    locModalOpnDate = dateformatJPN(parseDate(schedule.opn_date));

    let param = {
      opn_user_id: userId,
      schedule_id: scheduleId
    }
    let res = await getAPI("tr_report_search_view", param);

    if (res.count === 1) {

      let dateNotice = parseDate(res.results[0].notice_date);
      if (dateNotice) {
        locModalStatus = "通知済";
        locModalNoticeDate = dateformatJPN(dateNotice) + " " + dateformatJPNByType(dateNotice, "hours") + ":" + dateformatJPNByType(dateNotice, "minutes");
      }

      let dateConfirm = parseDate(res.results[0].confirm_date);
      if (dateConfirm) {
        if (dateNotice < dateConfirm) {
          locModalStatus = "確認済";
          locModalConfirmDate = dateformatJPN(dateConfirm) + " " + dateformatJPNByType(dateConfirm, "hours") + ":" + dateformatJPNByType(dateConfirm, "minutes");
        }
      }
    }

    setModalOpnUserId(userId);
    setModalScheduleId(scheduleId);
    setModalOpnUserName(locModalOpnUserName);
    setModalBookName(locModalBookName);
    setModalOpnDate(locModalOpnDate);
    setModalNoticeDate(locModalNoticeDate);
    setModalConfirmDate(locModalConfirmDate);
    setModalStatus(locModalStatus);

    onClickModalShow();
  }

  const onClickModalWorkClose = () => setModalWorkShow(false);

  const onClickModalWorkShow = () => setModalWorkShow(true);

  const onClickShowModalWork = async (userId) => {

    let user = await getAPI("ms_user/" + userId);
    setModalWorkOpnUserName(user.last_name + " " + user.first_name);

    let opnDateFrom = new Date();
    opnDateFrom.setFullYear(opnDate.getFullYear());
    opnDateFrom.setMonth(opnDate.getMonth());
    opnDateFrom.setDate(opnDate.getDate() - 8);
    let opnDateTo = new Date();
    opnDateTo.setFullYear(opnDate.getFullYear());
    opnDateTo.setMonth(opnDate.getMonth());
    opnDateTo.setDate(opnDate.getDate() - 1);
    let param = {
      opn_user_id: userId,
      opn_date_from: dateformatDB(opnDateFrom),
      opn_date_to: dateformatDB(opnDateTo),

    }
    let res = await getAPI("tr_report_search_view", param);

    let reportLilst = res.results;
    let locModalWorkDailyReportList = [];
    reportLilst.map(report => {
      if (!locModalWorkDailyReportList.find(dailyReport => dailyReport.opn_date === report.opn_date)) {
        locModalWorkDailyReportList.push({
          opn_date: report.opn_date,
          report_list: reportLilst.filter(r => r.opn_date === report.opn_date)
        });
      }
    });

    // 勤務時間算出（現場事）
    for (let i = 0; i < locModalWorkDailyReportList.length; i++) {
      if (!locModalWorkDailyReportList[i].report_list) {
        return;
      }
      let locModalWorkReport = locModalWorkDailyReportList[i];
      for (let j = 0; j < locModalWorkReport.report_list.length; j++) {
        let report = locModalWorkReport.report_list[j];
        //勤務時間
        let mtgStartTime = parseDatetime(report.opn_date + " " + report.mtg_start_time);
        let opnEndTime = parseDatetime(report.opn_date + " " + report.opn_end_time);
        let workHourMilSec = opnEndTime - mtgStartTime;
        let workHour = Math.floor(workHourMilSec / 1000 / 60 / 60 * Math.pow(10, 2)) / Math.pow(10, 2);
        locModalWorkDailyReportList[i].report_list[j].work_hour = workHour;
      }
    }

    // 勤務時間算出（日毎）
    let locWorkTotalWorkHour = 0;
    for (let i = 0; i < locModalWorkDailyReportList.length; i++) {
      if (!locModalWorkDailyReportList[i].report_list) {
        return;
      }
      let locModalWorkReport = locModalWorkDailyReportList[i];
      let dailyWorkHour = 0;
      for (let j = 0; j < locModalWorkReport.report_list.length; j++) {
        let report = locModalWorkReport.report_list[j];
        dailyWorkHour += report.work_hour;
      }
      locModalWorkDailyReportList[i].daily_work_hour = dailyWorkHour;
      locWorkTotalWorkHour += dailyWorkHour;
    }

    setModalWorkDailyReportList(locModalWorkDailyReportList);
    setWorkTotalWorkHour(locWorkTotalWorkHour);
    onClickModalWorkShow();
  }

  const onClickSendSingleNotice = async () => {

    let param = {
      opn_user_id: modalOpnUserId,
      schedule_id: modalScheduleId
    }
    let res = await getAPI("tr_report_search_view", param);
    let report = res.results[0];
    if (report) {
      let param = {
        report_id: report.report_id,
        pg: 'Schedule'
      };
      let res = await postAPI("send_single_notice/", param);
      let locItems = await updateStatus(items);
      setItems(locItems);
    }

    onClickModalClose();
  }

  const onClickSendMultiNotice = async () => {

    let updateNoticeList = items.filter((item) => item.schedule_id !== "000");
    if (updateNoticeList.filter((item) => item.report_db == null).length > 0) {
      error("登録を完了して通知してください");
      return;
    }

    let res = await sendMultiNotice(updateNoticeList);
    let locItems = await updateStatus(items);
    setItems(locItems);
  }

  async function sendMultiNotice(items) {
    let newItems = [];
    for (const item of items) {

      let noticeDate = parseDate(item.report_db.notice_date);
      let confirmDate = parseDate(item.report_db.confirm_date);
      let status = getStatus(noticeDate, confirmDate, "title");
      if (status !== "未") {
        continue;
      }

      let param = {
        report_id: item.report_db.report_id,
        pg: 'Schedule'
      };
      let res = await postAPI("send_single_notice/", param);
      newItems.push(item);
    }
    return newItems;
  }

  const updateStatus = async (currentItems) => {
    let newItems = [];
    for (const item of currentItems) {
      if (item.schedule_id !== '000') {
        let param = {
          opn_user_id: item.user_id,
          schedule_id: item.schedule_id
        }
        let res = await getAPI("tr_report_search_view", param);
        if (res.results) {
          item['report_db'] = res.results[0]
        }
      }
      newItems.push(item);
    }
    return newItems;
  }

  const onSearchRole = async (query) => {

    let locOptions = [];
    roleList.map((l) => {
      if (l.name.indexOf(query) > -1) {
        locOptions.push(l);
      }
    });

    return locOptions;
  }

  const onSearchOpnOrder = async (query) => {

    let locOptions = [];
    opnOrderList.map((l) => {
      if (l.name.indexOf(query) > -1) {
        locOptions.push(l);
      }
    });

    return locOptions;
  }

  const handleChangeRole = (assign, value) => {

    let locList = JSON.parse(JSON.stringify(modalAssignList));
    locList.filter((user) => user.user_id === assign.user_id)[0].roleSelected = [value];
    if (value) {
      locList.filter((user) => user.user_id === assign.user_id)[0].role = value.id;
    } else {
      locList.filter((user) => user.user_id === assign.user_id)[0].role = null;
    }

    setModalAssignList(locList);

  }

  const handleChangeOpnOrder = (assign, value) => {

    let locList = JSON.parse(JSON.stringify(modalAssignList));
    locList.filter((user) => user.user_id === assign.user_id)[0].opnOrderSelected = [value];
    if (value) {
      locList.filter((user) => user.user_id === assign.user_id)[0].opn_order = value.id;
    } else {
      locList.filter((user) => user.user_id === assign.user_id)[0].opn_order = null;
    }

    setModalAssignList(locList);

  }

  return (
    <div>
      <div className="body-dtl body-dtl-const">
        <AppNav/>
        <Row className="h1">
          <Col sm={8} style={{paddingTop: "7px", paddingBottom: "7px"}}>
            <h1>
              日別工事予定表
            </h1>
          </Col>
          <Col sm="4" className="text-right" style={{position: "fixed", zIndex: "10", right: "0"}}>
            <Button variant="secondary" onClick={onClickReload}
                    style={{marginRight: "0.5rem"}}><FontAwesomeIcon icon={faSyncAlt} size="lg"/>更新</Button>
            <Button variant="secondary" onClick={onClickSendMultiNotice}
                    style={{marginRight: "0.5rem"}}><FontAwesomeIcon icon={faPaperPlane}
                                                                     size="lg"/>通知</Button>
            <Button id="btn-update" variant="primary" onClick={onClickUpdate}><span><FontAwesomeIcon
              icon={faPen} size="lg"/>登録</span></Button>
          </Col>
        </Row>

        <Container className="container-main-detail pb-2">
          <Card className="card-secondary w-80 mx-auto mb-0">
            <Row>
              <Col sm="3"></Col>
              <Col sm="1" className="text-center">
                <Button variant="link" style={{paddingRight: "0"}} onClick={onClickPrevMonth}>
                  <FontAwesomeIcon icon={faChevronLeft} size="2x"/>
                </Button>
              </Col>
              <Col sm="4" className="text-center" style={{fontWeight: "700"}}>
                {dateformatJPNByType(opnDate, 'year')}年&nbsp;
                <span style={{fontSize: "1.2rem"}}>
                {dateformatJPNByType(opnDate, 'month')}
                </span>
                月&nbsp;
                <span style={{fontSize: "1.2rem"}}>
                {dateformatJPNByType(opnDate, 'date')}
                </span>
                日（{dateformatJPNByType(opnDate, 'dateOfWeek')}）
              </Col>
              <Col sm="1" className="text-center">
                <Button variant="link" style={{paddingRight: "0"}} onClick={onClickNextMonth}>
                  <FontAwesomeIcon icon={faChevronRight} size="2x"/>
                </Button>
              </Col>
              <Col sm="4"></Col>
            </Row>
          </Card>
        </Container>

        <div id="schedule-dtl">
          <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
            <div>
              {/*<Row>*/}
              {/*  <Col className="text-right mt-2" sm="2">*/}
              {/*    メモ*/}
              {/*  </Col>*/}
              {/*  <Col sm="9">*/}
              {/*    <Form.Control className="mt-2" as="textarea" rows={2} value={note}*/}
              {/*                  onChange={e => setNote(e.target.value)}/>*/}
              {/*  </Col>*/}
              {/*  <Col sm="1">*/}
              {/*  </Col>*/}
              {/*</Row>*/}
              <Row style={{marginTop: "0.5rem"}}>
                <Col className="text-right mt-2" sm="2">
                  作業者
                </Col>
                <Col sm="9">
                  <div style={{display: "flex", justifyContent: "left", flexWrap: "wrap"}}>
                    {
                      userList.map((user, index) => (
                        <div key={index}
                             className={"movable-item assign_count_" + items.filter((i) => i.user_id === user.user_id).length}>
                          {user.last_name}
                        </div>
                      ))
                    }
                  </div>
                </Col>
                <Col sm="1">
                </Col>
              </Row>
              {/*<Row style={{marginTop: "0.5rem"}}>*/}
              {/*  <Col className="text-right mt-2" sm="2">*/}
              {/*    有給*/}
              {/*  </Col>*/}
              {/*  <Col sm="9">*/}
              {/*    <Column title={scheduleIdOff} className="column assign-column-off"*/}
              {/*            style={{height: "100%"}}>*/}
              {/*      {returnItemsForColumn(scheduleIdOff)}*/}
              {/*    </Column>*/}
              {/*  </Col>*/}
              {/*  <Col sm="1">*/}
              {/*  </Col>*/}
              {/*</Row>*/}
              <Row style={{marginTop: "0.5rem", paddingBottom: "0.5rem"}}>
                <Col className="text-right">
                  <Button variant="add" onClick={onClickAdd}><FontAwesomeIcon icon={faPlusCircle}
                                                                              size="lg"/>追加</Button>
                </Col>
              </Row>
            </div>
            <div>
              <div id="loading_schedule_list" style={{
                position: "absolute",
                backgroundColor: "#ffffff",
                width: "100%",
                height: "100%",
                zIndex: "10"
              }}>
                <div className="loading_div" style={{top: "5rem", color: "#656565"}}>
                  <div>ローディング....</div>
                  <img src="/static/loading_200_black.gif"/>
                </div>
              </div>
              {scheduleList.filter((schedule) => schedule.schedule_id !== scheduleIdOff).map((obj, index) => (
                <Row key={dateformatYYYYMMDD(opnDate) + ":" + index}>
                  <Col sm="8" style={{paddingRight: "0rem"}}>
                    <ScheduleDtl key={index}
                                 ref={scheduleListRef.current[index]}
                                 index={index}
                                 schedule={obj}
                                 carList={carList}
                                 opnList={opnList}/>
                  </Col>
                  {obj.schedule_id &&
                    <Col sm="3"
                         style={{cursor: "pointer", padding: "0rem", backgroundColor: "#CCF4D5"}}
                         onClick={e => onClickAssignBoard(obj.schedule_id)}>
                      <div style={{height: "2.2rem", backgroundColor: "#eeeeee"}}>&nbsp;</div>
                      <div style={{display: "flex", justifyContent: "left", flexWrap: "wrap"}}>
                        {
                          items.filter((tag) => tag.schedule_id === obj.schedule_id && tag.role_id === (comId + 'ROLE001'))
                            .map((tag, index) => (
                              <div key={index}
                                   className="movable-item movable-item-role001">
                                <div
                                  className="float-md-left assign-status"
                                  style={{backgroundColor: getStatusColor(tag.report_db)}}
                                  onClick={e => onClickShowStatus(e, tag.user_id, obj.schedule_id)}>
                                  {getStatus(tag.report_db.notice_date, tag.report_db.confirm_date, 'title')}
                                </div>
                                {tag.name}{tag.opn_order}
                                <div className="float-md-right assign-status"></div>
                              </div>
                            ))
                        }
                        {
                          items.filter((tag) => tag.schedule_id === obj.schedule_id && tag.role_id === (comId + 'ROLE002'))
                            .map((tag, index) => (
                              <div key={index} className="movable-item">
                                <div
                                  className="float-md-left assign-status"
                                  style={{backgroundColor: getStatusColor(tag.report_db)}}
                                  onClick={e => onClickShowStatus(e, tag.user_id, obj.schedule_id)}>
                                  {getStatus(tag.report_db.notice_date, tag.report_db.confirm_date, 'title')}
                                </div>
                                {tag.name}{tag.opn_order}
                                <div className="float-md-right assign-status"></div>
                              </div>
                            ))
                        }
                        {
                          items.filter((tag) => tag.schedule_id === obj.schedule_id && (tag.role_id === null || tag.role_id === ''))
                            .map((tag, index) => (
                              <div key={index} className="movable-item">
                                <div
                                  className="float-md-left assign-status"
                                  style={{backgroundColor: getStatusColor(tag.report_db)}}
                                  onClick={e => onClickShowStatus(e, tag.user_id, obj.schedule_id)}>
                                  {getStatus(tag.report_db.notice_date, tag.report_db.confirm_date, 'title')}
                                </div>
                                {tag.name}{tag.opn_order}
                                <div className="float-md-right assign-status"></div>
                              </div>
                            ))
                        }
                      </div>
                    </Col>
                  }
                  {!obj.schedule_id &&
                    <Col sm="3" style={{padding: "0rem"}}>
                      <div style={{height: "2.2rem", backgroundColor: "#eeeeee"}}>&nbsp;</div>
                      <div style={{
                        display: "flex",
                        justifyContent: "left",
                        flexWrap: "wrap"
                      }}></div>
                    </Col>
                  }

                  <Col sm="1" style={{padding: "0rem"}}>
                    <div style={{height: "2.2rem", backgroundColor: "#eeeeee"}}>&nbsp;</div>
                    <div style={{cursor: "pointer", textAlign: "right"}}
                         onClick={e => onClickAssignBoard(obj.schedule_id)}
                         className={"justify-content-around assign-column " + (obj.schedule_id ? "" : "assign-column-disabled")}>
                      {/*<Button variant="danger"*/}
                      {/*        style={{width: "80%"}}*/}
                      {/*        onClick={(e) => onClickCancel(obj.schedule_id, obj.cancel)}*/}
                      {/*        disabled={!obj.schedule_id}>*/}
                      {/*  <FontAwesomeIcon icon={faBan} size="lg"/>中止*/}
                      {/*</Button>*/}
                      <Button variant="danger"
                              onClick={(e) => onClickDelete(obj.schedule_id)}
                              disabled={!obj.schedule_id}>
                        <FontAwesomeIcon icon={faTrash} size="lg"/>削除
                      </Button>
                    </div>
                  </Col>
                </Row>
              ))}
            </div>
          </DndProvider>
        </div>
      </div>

      <Modal
        show={modalAssignShow}
        onHide={onClickModalAssignClose}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalAssignBookName}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{lineHeight: "2.2rem"}}>
          {/*<Row>*/}
          {/*  <Col sm="3" className="text-center">*/}
          {/*    作業者*/}
          {/*  </Col>*/}
          {/*  <Col sm="9" className="text-center">*/}
          {/*    役割*/}
          {/*  </Col>*/}
          {/*</Row>*/}
          <div>
            <div key="title1" className="float-left w-50">
              <Row style={{
                height: "3.2rem",
                margin: "0.2rem",
                borderBottom: "solid 1px #CCCCCC",
                marginBottom: "0.4rem"
              }}>
                <Col sm="4">
                  <div className="text-center">
                    作業者
                  </div>
                </Col>
                <Col sm="5" className="text-center">
                  役割
                </Col>
                <Col sm="3" className="text-center">
                  順序
                </Col>
              </Row>
            </div>
            <div key="title2" className="float-left w-50">
              <Row style={{
                height: "3.2rem",
                margin: "0.2rem",
                borderBottom: "solid 1px #CCCCCC",
                marginBottom: "0.4rem"
              }}>
                <Col sm="4">
                  <div className="text-center">
                    作業者
                  </div>
                </Col>
                <Col sm="5" className="text-center">
                  役割
                </Col>
                <Col sm="3" className="text-center">
                  順序
                </Col>
              </Row>
            </div>
            {modalAssignList.map((assign, index) => (
              <div key={index} className="float-left w-50">
                <Row key={assign.user_id} style={{height: "3.2rem", margin: "0.2rem"}}>
                  <Col sm="4">
                    <div
                      className={"assign-user " + (!assign.selected ? 'avail' : assign.role ? 'assigned-' + assign.role.substring(3) : 'assigned')}
                      style={{cursor: "pointer"}}
                      onClick={e => onClickAssignTag(assign)}>
                      {assign.last_name}
                    </div>
                  </Col>
                  <Col sm="5" style={{paddingRight: "1px", paddingLeft: "1px"}}>
                    {assign.selected &&
                      <AsyncSelect
                        className="async-select"
                        cacheOptions
                        defaultOptions
                        isClearable
                        placeholder=""
                        value={assign.roleSelected}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e.id}
                        loadOptions={onSearchRole}
                        onChange={e => handleChangeRole(assign, e)}
                        isDisabled={!assign.selected}
                      />
                    }
                  </Col>
                  <Col sm="3">
                    {assign.selected &&
                      <AsyncSelect
                        className="async-select"
                        cacheOptions
                        defaultOptions
                        isClearable
                        placeholder=""
                        value={assign.opnOrderSelected}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e.id}
                        loadOptions={onSearchOpnOrder}
                        onChange={e => handleChangeOpnOrder(assign, e)}
                        isDisabled={!assign.selected}
                      />
                    }
                  </Col>
                </Row>
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClickModalAssignClose}>
            キャンセル
          </Button>
          <Button id="btn-update-assign" variant="primary" onClick={onClickModalAssignRegister}>
              <span>
                <FontAwesomeIcon icon={faPen} size="lg"/>登録
              </span>
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={modalShow} onHide={onClickModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>通知ステータス</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{lineHeight: "2.2rem"}}>
          <Row>
            <Form.Label column sm="4" className="text-right">氏名；</Form.Label>
            <Col sm="8">{modalOpnUserName}</Col>
          </Row>
          <Row>
            <Form.Label column sm="4" className="text-right">作業日：</Form.Label>
            <Col sm="8">{modalOpnDate}</Col>
          </Row>
          <Row>
            <Form.Label column sm="4" className="text-right">現場：</Form.Label>
            <Col sm="8">{modalBookName}</Col>
          </Row>
          <Row>
            <Form.Label column sm="4" className="text-right">確認状況；</Form.Label>
            <Col sm="8">
              {modalStatus === "未通知" &&
                <div className="text-center"
                     style={{
                       backgroundColor: "#EEEEEE",
                       borderRadius: "0.3rem",
                       width: "6rem",
                       fontWeight: "700",
                       fontSize: "0.9rem",
                       lineHeight: "2rem"
                     }}>
                  未通知
                </div>
              }
              {modalStatus === "確認待" &&
                <div className="text-center"
                     style={{
                       backgroundColor: "green",
                       color: "white",
                       borderRadius: "0.3rem",
                       width: "6rem",
                       fontWeight: "700",
                       fontSize: "0.9rem",
                       lineHeight: "2rem"
                     }}>
                  確認待
                </div>
              }
              {modalStatus === "確認済" &&
                <div className="text-center"
                     style={{
                       backgroundColor: "blue",
                       color: "white",
                       borderRadius: "0.3rem",
                       width: "6rem",
                       fontWeight: "700",
                       fontSize: "0.9rem",
                       lineHeight: "2rem"
                     }}>
                  確認済
                </div>
              }
            </Col>
          </Row>
          <Row>
            <Form.Label column sm="4" className="text-right">通知日時；</Form.Label>
            <Col sm="8">{modalNoticeDate}</Col>
          </Row>
          <Row>
            <Form.Label column sm="4" className="text-right">確認日時；</Form.Label>
            <Col sm="8">{modalConfirmDate}</Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClickModalClose}>
            キャンセル
          </Button>
          <Button variant="secondary" onClick={onClickSendSingleNotice}>
            <FontAwesomeIcon icon={faPaperPlane} size="lg"/>
            通知
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={modalWorkShow} onHide={onClickModalWorkClose}>
        <Modal.Header closeButton>
          <Modal.Title>勤務時間（直近7日間）</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{lineHeight: "2.2rem"}}>
          <Row>
            <Form.Label column sm="4" className="text-right">氏名；</Form.Label>
            <Col sm="8">{modalWorkOpnUserName}</Col>
          </Row>
          <Row>
            <Form.Label column sm="4" className="text-right">合計；</Form.Label>
            <Col sm="8">
              {lpad(parseInt(modalWorkTotalWorkHour), 2, '0')}：
              {lpad((modalWorkTotalWorkHour * 60) % 60, 2, '0')}
            </Col>
          </Row>
          {modalWorkDailyReportList.map(dailyReport => (
            <Row key={dailyReport.opn_date}>
              <Form.Label column sm="4" className="text-right">
                {dateformatJPNByType(parseDate(dailyReport.opn_date), 'month')}月
                {dateformatJPNByType(parseDate(dailyReport.opn_date), 'date')}日
                （{dateformatJPNByType(parseDate(dailyReport.opn_date), 'dateOfWeek')}）
              </Form.Label>
              <Col sm="8">
                {dailyReport.report_list.map(report => (
                  <div key={dailyReport.opn_date + report.book.book_id}>
                    <Row>
                      <Col sm={3}>
                        {lpad(parseInt(report.work_hour), 2, '0')}：
                        {lpad((report.work_hour * 60) % 60, 2, '0')}
                      </Col>
                      <Col sm={9}>
                        {report.book.book_name}
                      </Col>
                    </Row>
                  </div>
                ))}
              </Col>
            </Row>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClickModalWorkClose}>
            キャンセル
          </Button>
        </Modal.Footer>
      </Modal>

      <Toast/>
    </div>
  );
}

export default withRouter(Schedule);