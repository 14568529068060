import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {Card, Col, Container, Form, InputGroup, Nav, Row} from "react-bootstrap";
import {getAPI, postAPI, putAPI} from "../APIUtil";
import Button from "react-bootstrap/Button";
import {withRouter} from "react-router";
import AppNav from "./AppNav";
import 'react-toastify/dist/ReactToastify.css';
import Toast, {success} from "../Toast";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMapMarkerAlt, faPen} from "@fortawesome/free-solid-svg-icons";
import {
  checkKey,
  formatZip,
  onSearchPref,
  setZipAuto,
  toMultibyte,
  toNull,
  toSingleByte,
  toSingleByteAlphaNumberOnly, onSearchAccountType, nvl
} from "../CMUtil";
import AsyncSelect from "react-select/async";

export const ComDtl = () => {

  const [comId, setComId] = useState("");
  const [comName, setComName] = useState("");

  const [zip, setZip] = useState("");
  const [pref, setPref] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [tel, setTel] = useState("");
  const [fax, setFax] = useState("");

  const [repPosition, setRepPosition] = useState("");
  const [repName, setRepName] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankBranch, setBankBranch] = useState("");

  const [accountType, setAccountType] = useState("");
  const [accountNo, setAccountNo] = useState("");
  const [accountHolder, setAccountHolder] = useState("");

  const [prefSelected, setPrefSelected] = useState([]);
  const [accountTypeSelected, setAccountTypeSelected] = useState([]);

  //蛻晄悄蛹�
  useEffect(() => {
    const update = async () => {

      let res = null;
      res = await getAPI("current_login_user");
      let user = res.results[0];
      let com = await getAPI("ms_com/" + user.com.com_id);

      setComId(nvl(com.com_id));
      setComName(nvl(com.com_name));
      setZip(nvl(com.zip))
      setPref(nvl(com.pref));
      if (com.pref) {
        setPrefSelected({name: com.pref, id: com.pref});
      }
      setCity(nvl(com.city));
      setTel(nvl(com.tel));
      setFax(nvl(com.fax));
      setAddress(nvl(com.address));
      setRepPosition(nvl(com.rep_position));
      setRepName(nvl(com.rep_name));
      setBankName(nvl(com.bank_name));
      setBankBranch(nvl(com.bank_branch));
      setAccountType(nvl(com.account_type));
      if (com.account_type) {
        setAccountTypeSelected({name: com.account_type, id: com.account_type});
      }
      setAccountNo(nvl(com.account_no));
      setAccountHolder(nvl(com.account_holder));
    }
    update();

  }, []);

  const handleChangePref = (value) => {
    setPrefSelected(value ? value : "");
    setPref(value ? value.id : "");
  }

  const handleChangeAccountType = (value) => {
    setAccountTypeSelected(value ? value : "");
    setAccountType(value ? value.id : "");
  }

  const onClickUpdate = async () => {
    let data = newData();
    if (comId) {
      await putAPI("ms_com/" + comId, newData());
    } else {
      await postAPI("ms_com/", newData());
    }
    success("更新完了");
  }

  const newData = () => {
    return {
      com_id: toNull(comId),
      com_name: toNull(comName),
      zip: toNull(zip),
      pref: toNull(pref),
      city: toNull(city),
      tel: toNull(tel),
      fax: toNull(fax),
      address: toNull(address),
      rep_position: toNull(repPosition),
      rep_name: toNull(repName),
      bank_name: toNull(bankName),
      bank_branch: toNull(bankBranch),
      account_type: toNull(accountType),
      account_no: toNull(accountNo),
      account_holder: toNull(accountHolder),
      update_pg: 'ComDtl'
    };
  }

  return (
    <>
      <div className="body-dtl body-dtl-const">
        <AppNav/>
        <div className="h1">
          <h1 className="float-left align-items-end">
            会社情報
          </h1>
          <div className="float-none" style={{height: "30px"}}>&nbsp;</div>
        </div>

        <Container className="container-main-detail">

          <Row className="h3">
            <Col>
              <h3 style={{paddingTop: ".7rem"}}>
                会社情報
              </h3>
            </Col>
            <Col className="text-right">
              <Button variant="primary" onClick={onClickUpdate}><FontAwesomeIcon icon={faPen} size="lg"/>登録</Button>
            </Col>
          </Row>

          <Card className="card-secondary w-80 mx-auto mb-0">
            <Form.Group as={Row}>
              <Form.Label column sm="1">会社名</Form.Label>
              <Col sm="4">
                <Form.Control
                  id="name"
                  type="text"
                  value={comName}
                  maxLength={80}
                  onChange={e => setComName(e.target.value)}
                />
              </Col>
              <Col sm="1"></Col>
              <Form.Label column sm="1">代表者氏名</Form.Label>
              <Col sm="4">
                <Form.Control
                  id="nameSub"
                  type="text"
                  value={repName}
                  maxLength={80}
                  onChange={e => setRepName(e.target.value)}
                />
              </Col>
              <Col sm="1"></Col>
              <br/>
              <Form.Label column sm="1">代表者役職</Form.Label>
              <Col className='mt-1' sm="4">
                <Form.Control
                  id="nameSub"
                  type="text"
                  value={repPosition}
                  maxLength={80}
                  onChange={e => setRepPosition(e.target.value)}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="1">電話</Form.Label>
              <Col sm="4">
                <Form.Control
                  id="tel"
                  type="tel"
                  value={tel}
                  style={{imeMode: "disabled"}}
                  onKeyPress={e => checkKey(e, /[0-9\-]/)}
                  maxLength={20}
                  onChange={e => setTel(e.target.value)}
                  onBlur={e => setTel(toSingleByte(e.target.value))}
                />
              </Col>
              <Col sm="1"></Col>
              <Form.Label column sm="1">FAX</Form.Label>
              <Col sm="4">
                <Form.Control
                  id="fax"
                  type="text"
                  value={fax}
                  onKeyPress={e => checkKey(e, /[0-9\-]/)}
                  maxLength={20}
                  onChange={e => setFax(e.target.value)}
                  onBlur={e => setFax(toSingleByte(e.target.value))}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="1">住所</Form.Label>
              <Col sm="2" className="input-group">
                <InputGroup.Text className="input-group-text-cm" style={{fontWeight: "700"}}>〒</InputGroup.Text>
                <Form.Control id="zip"
                              type="text"
                              value={zip}
                              style={{textAlign: "center"}}
                              onKeyPress={e => checkKey(e, /[0-9\-]/)}
                              maxLength={8}
                              onChange={e => setZipAuto(e.target.value, setZip, setPref, setPrefSelected, setCity)}
                              onBlur={e => formatZip(e.target.value, setZip, setPref, setPrefSelected, setCity)}
                />
              </Col>
              <Col sm="2">
                <AsyncSelect sm="2"
                             className="async-select"
                             cacheOptions
                             defaultOptions
                             isClearable
                             value={prefSelected}
                             getOptionLabel={e => e.name}
                             getOptionValue={e => e.id}
                             loadOptions={onSearchPref}
                             onChange={handleChangePref}
                             placeholder=""
                />
              </Col>
              <Col sm="6" className="input-group">
                <Form.Control type="text" value={city} id="address2"
                              style={{borderRadius: "0"}}
                              onChange={e => setCity(e.target.value)}/>
                <Form.Control type="text" value={address}
                              style={{width: "20%", borderTopLeftRadius: "0", borderBottomLeftRadius: "0"}}
                              onChange={e => setAddress(e.target.value)}
                              onBlur={e => setAddress(toMultibyte(e.target.value))}/>
                <Button as={Link} variant="link" to={"/map?com_id=" + comId} style={{paddingRight: "0"}}>
                  <FontAwesomeIcon icon={faMapMarkerAlt} size="2x"/>
                </Button>
              </Col>
            </Form.Group>
          </Card>

        </Container>

        <Container className="container-main-detail">

          <Row className="h3">

            <div className="h1">
              <h3 style={{paddingTop: ".7rem"}}>
                口座情報
              </h3>
              <div className="float-none" style={{height: "7px"}}>&nbsp;</div>
            </div>

          </Row>

          <Card className="card-secondary w-80 mx-auto mb-0">
            <Form.Group as={Row}>
              <Form.Label column sm="1">銀行名</Form.Label>
              <Col sm="4">
                <Form.Control
                  id="name"
                  type="text"
                  value={bankName}
                  maxLength={80}
                  onChange={e => setBankName(e.target.value)}
                />
              </Col>
              <Col sm="1"></Col>
              <Form.Label column sm="1">支店名</Form.Label>
              <Col sm="4">
                <Form.Control
                  id="nameSub"
                  type="text"
                  value={bankBranch}
                  maxLength={80}
                  onChange={e => setBankBranch(e.target.value)}
                />
              </Col>

            </Form.Group>
            <Col sm="1"></Col>
            <Form.Group as={Row}>
              <Form.Label column sm="1">口座種別</Form.Label>
              <Col sm="2">
                <AsyncSelect sm="1"
                             className="async-select"
                             cacheOptions
                             defaultOptions
                             isClearable
                             value={accountTypeSelected}
                             getOptionLabel={e => e.name}
                             getOptionValue={e => e.id}
                             loadOptions={onSearchAccountType}
                             onChange={handleChangeAccountType}
                             placeholder=""
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="1">口座番号</Form.Label>
              <Col sm="4">
                <Form.Control
                  id="account_no"
                  type="tel"
                  value={accountNo}
                  style={{imeMode: "disabled"}}
                  onKeyPress={e => checkKey(e, /[0-9\-]/)}
                  maxLength={20}
                  onChange={e => setAccountNo(e.target.value)}
                  onBlur={e => setAccountNo(toSingleByte(e.target.value))}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="1">名義人</Form.Label>
              <Col sm="4">
                <Form.Control
                  id="account_holder"
                  type="text"
                  value={accountHolder}
                  maxLength={80}
                  onChange={e => setAccountHolder(e.target.value)}
                />
              </Col>
            </Form.Group>
          </Card>
        </Container>
      </div>
      <Toast/>
    </>
  );
}

export default withRouter(ComDtl);