import {withRouter} from "react-router";
import React, {useEffect, useRef, useState} from 'react';
import AppNav from "./AppNav";
import 'react-toastify/dist/ReactToastify.css';
import {Card, Col, Container, Form, InputGroup, Row, Table} from "react-bootstrap";
import {
  addField,
  dateformat, dateformatDB, dateformatJPNByType, dateformatJPNYYMMDD,
  dateformatYYYYMMDD, formatNumber, nvl,
  parseDate,
  setFocusCalendar,
  useDebounce
} from "../CMUtil";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons";
import {getAPI} from "../APIUtil";

export const BookStatus = () => {

  const iniSearch = useRef(true);
  const [locPage, setLocPage] = useState(null);

  const [bookNo, setBookNo] = useState("");
  const debouncedBookNo = useDebounce(bookNo, 500, setLocPage);
  const [dateFrom, setDateFrom] = useState(function () {
    let locDateFrom = new Date();
    locDateFrom.setDate(1);
    return locDateFrom;
  });
  const debouncedDateFrom = useDebounce(dateFrom, 500, setLocPage);
  const [dateTo, setDateTo] = useState(function () {
    let locDateTo = new Date();
    locDateTo.setMonth(locDateTo.getMonth() + 3);
    return locDateTo;
  });
  const debouncedDateTo = useDebounce(dateTo, 500, setLocPage);

  const [listData, setListData] = useState([]);
  const [dateList, setDateList] = useState([]);

  // 初期化
  useEffect(() => {

    const update = async () => {
      await searchBookStatus();
    }

    update();

  }, []);

  //検索
  useEffect(() => {

      if (iniSearch.current) {
        iniSearch.current = false;
        return;
      }
      const update = async () => {
        await searchBookStatus();
      }

      update();
    },
    [
      debouncedBookNo,
      debouncedDateFrom,
      debouncedDateTo,
    ]);


  const onClickClear = () => {
    setBookNo("");
    setDateFrom("");
    setDateTo("");
  }

  const searchBookStatus = async () => {

    let condition = {};
    addField(condition, "book_no", bookNo);
    addField(condition, "date_from", dateformat(dateFrom));
    addField(condition, "date_to", dateformat(dateTo));
    const res = await getAPI("tr_book_status_search_view", condition);
    setListData(res.results);

    let locDateList = [];
    let countDate = new Date(dateFrom.getTime());
    while (countDate.getTime() < dateTo.getTime()) {
      locDateList.push(new Date(countDate.getTime()));
      countDate.setDate(countDate.getDate() + 1);
    }

    setDateList(locDateList);
  }

  const isLastDateInMonth = (calendarDate) => {
    let date = new Date(calendarDate);
    date.setDate(date.getDate() + 1);
    return date.getDate() === 1;
  }

  const getCalendarDateStatus = (startDateSchedule, startDate, endDateSchedule, endDate, calendarDate) => {

    let calDate = dateformatDB(calendarDate);
    let status = "off" //off:期間外 schedule:実施予定 going:実施中

    if (startDate && startDate <= calDate &&
      (
        (!endDate && endDateSchedule >= calDate) ||
        (endDate >= calDate)
      )
    ) {
      //実施中
      status = "going";
    } else if (startDateSchedule && startDateSchedule <= calDate && (!endDateSchedule || endDateSchedule >= calDate)) {
      //実施予定
      status = "schedule";
    }

    return "cal-status-" + status;
  }

  const getBookStatus = (startDateSchedule, startDate, endDateSchedule, endDate, calendarDate) => {

    if ("cal-status-off" === getCalendarDateStatus(startDateSchedule, startDate, endDateSchedule, endDate, calendarDate)) {
      return "";
    }

    let status = "schedule" //schedule:着工待ち going:実施中 delay:遅延 complete:完了
    let today = dateformatDB(new Date());
    if (endDate && endDate <= today) {
      status = "complete";
    } else if (endDateSchedule && endDateSchedule < today) {
      status = "delay";
    } else if (startDate && startDate <= today) {
      status = "going";
    }

    return "book-status-" + status;
  }

  return (
    <div>
      <div className="body-dtl body-dtl-const">
        <AppNav/>
        <Row className="h1">
          <Col sm={8} style={{paddingTop: "7px", paddingBottom: "7px"}}>
            <h1>
              現場別予定表
            </h1>
          </Col>
          <Col sm="4" className="text-right" style={{position: "fixed", zIndex: "10", right: "0"}}>
          </Col>
        </Row>

        <Card className="card-search">
          <Container className="container-main-search">
            <Form.Group as={Row}>
              <Form.Label column sm="1">工事</Form.Label>
              <Col sm="4">
                <Form.Control type="text" value={bookNo} onChange={e => setBookNo(e.target.value)}/>
              </Col>
              <Form.Label column sm="1">期間</Form.Label>
              <Col sm="4" className="input-group">
                <DatePicker id="dateFrom" selected={dateFrom} onChange={(date) => setDateFrom(date)}
                            dateFormat="yyyy年MM月dd日" locale="ja"/>
                <Button as={Link} variant="link" to={"/"} style={{paddingRight: "0"}}
                        onClick={e => setFocusCalendar(e, 'dateFrom')}>
                  <FontAwesomeIcon icon={faCalendarAlt} size="2x"/>
                </Button>
                <InputGroup.Text style={{border: "0px", backgroundColor: "#ffffff"}}>～</InputGroup.Text>
                <DatePicker id="dateTo" selected={dateTo} onChange={(date) => setDateTo(date)}
                            dateFormat="yyyy年MM月dd日" locale="ja"/>
                <Button as={Link} variant="link" to={"/"} style={{paddingRight: "0"}}
                        onClick={e => setFocusCalendar(e, 'dateTo')}>
                  <FontAwesomeIcon icon={faCalendarAlt} size="2x"/>
                </Button>
              </Col>
              <Col sm="1">
              </Col>
              <Col sm="1">
                <Button variant="clear" onClick={e => onClickClear()}>条件クリア</Button>
              </Col>
            </Form.Group>
          </Container>
        </Card>

        <Container className="container-main-list">
          <Table className="tbl_status" style={{width: (930 + (dateList.length * 25)) + "px"}}>
            <thead>
            <tr>
              <td className="tbl-h-00">
                <Table className="tbl_status_inner">
                  <tbody>
                  <tr>
                    <td style={{width: "80px", textAlign: "center"}}>工事No.</td>
                    <td>現場</td>
                    <td style={{width: "110px", textAlign: "right"}}>受注</td>
                    <td style={{width: "110px", textAlign: "right"}}>請求</td>
                    <td style={{width: "110px", textAlign: "right"}}>入金</td>
                    <td style={{width: "130px", textAlign: "center"}}>着工日</td>
                    <td style={{width: "130px", textAlign: "center"}}>完了日</td>
                    <td style={{width: "60px", textAlign: "center", borderRight: "solid 1px #ced4da"}}>状況</td>
                  </tr>
                  </tbody>
                </Table>
              </td>
              {dateList.map(date => (
                <td key={"h_" + dateformatYYYYMMDD(date)}
                    className={"tbl-h-10 col-chart text-center " + isLastDateInMonth(date)}
                >{dateformatJPNByType(date, 'date')}</td>
              ))}
            </tr>
            </thead>

            <tbody>
            {listData.map((obj, index) => (
              <tr key={index}>
                <td className="tbl-h-01">
                  <Table className="tbl_status_inner">
                    <tbody>
                    <tr>
                      <td style={{width: "80px"}}>
                        <Button as={Link} variant="link" to={"/book/" + obj.book_id}>
                          {obj.book_no}
                        </Button>
                      </td>
                      <td>{obj.book_name}</td>
                      <td style={{
                        width: "110px",
                        textAlign: "right"
                      }}>{(obj.co_ttl_amount ? "￥" : "―") + formatNumber(nvl(obj.co_ttl_amount))}</td>
                      <td style={{
                        width: "110px",
                        textAlign: "right"
                      }}>{(obj.iv_ttl_amount ? "￥" : "―") + formatNumber(nvl(obj.iv_ttl_amount))}</td>
                      <td style={{
                        width: "110px",
                        textAlign: "right"
                      }}>{(obj.de_ttl_amount ? "￥" : "―") + formatNumber(nvl(obj.de_ttl_amount))}</td>
                      <td style={{width: "130px"}}>
                        <div style={{textAlign: "center"}}>
                          {obj.start_date_schedule && dateformatJPNYYMMDD(parseDate(obj.start_date_schedule))}
                          {!obj.start_date_schedule && <span>―</span>}
                        </div>
                        <div style={{textAlign: "center"}}>
                          {obj.start_date && dateformatJPNYYMMDD(parseDate(obj.start_date))}
                          {!obj.start_date && <span>―</span>}
                        </div>
                      </td>
                      <td style={{width: "130px"}}>
                        <div style={{textAlign: "center"}}>
                          {obj.complete_date_schedule && dateformatJPNYYMMDD(parseDate(obj.complete_date_schedule))}
                          {!obj.complete_date_schedule && <span>―</span>}
                        </div>
                        <div style={{textAlign: "center"}}>
                          {obj.complete_date && dateformatJPNYYMMDD(parseDate(obj.complete_date))}
                          {!obj.complete_date && <span>―</span>}
                        </div>
                      </td>
                      <td style={{width: "60px", textAlign: "center", borderRight: "solid 1px #ced4da"}}>
                        {
                          obj.complete_date &&
                          <span>
                              完了
                            </span>
                        }
                        {
                          !obj.complete_date && obj.start_date &&
                          <span>
                              工事中
                            </span>
                        }
                        {
                          !obj.start_date &&
                          <span>
                              着工前
                            </span>
                        }
                      </td>
                    </tr>
                    </tbody>
                  </Table>
                </td>
                {dateList.map(date => (
                  <td key={"b_" + obj.book_id + "_" + dateformatYYYYMMDD(date)}
                      className="tbl-b-00"
                  >
                    <div
                      className={
                        "book-status-chart " +
                        " " + getCalendarDateStatus(obj.start_date_schedule, obj.start_date, obj.complete_date_schedule, obj.complete_date, date) +
                        " " + getBookStatus(obj.start_date_schedule, obj.start_date, obj.complete_date_schedule, obj.complete_date, date)}
                    >&nbsp;</div>
                  </td>
                ))}
              </tr>
            ))}
            </tbody>
          </Table>
          {/*</div>*/}
        </Container>
      </div>
    </div>
  );
}

export default withRouter(BookStatus);