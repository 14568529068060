import React, {useEffect, useRef, useState} from 'react';
import {useHistory, withRouter} from "react-router-dom";
import Button from 'react-bootstrap/Button';
import {getAPI} from "../APIUtil";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import AppNav from "./AppNav";
import {Card, Col, Container, Form, InputGroup, Row} from "react-bootstrap";
import {addField, formatNumber, checkKey, formatZip, nvl, onSearchPref, useDebounce} from "../CMUtil";
import MdDatatable, {InitialLoading, sortCaret} from "../MdDataTable";

export const ItemList = () => {

  const history = useHistory();
  const iniSearch = useRef(true);

  const [loadingCss, setLoadingCss] = useState("loading_panel_init");

  const [loading, setLoading] = useState(true);
  const [locPage, setLocPage] = useState(null);
  const [locSizePerPage, setLocSizePerPage] = useState(50);
  const [locTotalSize, setLocTotalSize] = useState(null);
  const [locSortField, setLocSortField] = useState(null);
  const [locSortOrder, setLocSortOrder] = useState(null);

  const [itemName, setItemName] = useState("");
  const debouncedItemName = useDebounce(itemName, 500, setLocPage);
  const [note, setNote] = useState("");
  const debouncedNote = useDebounce(note, 500, setLocPage);
  const [itemType, setItemType] = useState({
    "資材": true,
    "作業": true
  });
  const debouncedItemType = useDebounce(itemType, 500, setLocPage);

  const [listData, setListData] = useState([]);

  // 項目定義
  const columns = [
    {
      dataField: 'item_id',
      text: 'ID',
      hidden: true,
      sort: false,
      headerStyle: {width: '80px'}
    },
    {
      dataField: 'item_name',
      text: '資材名',
      classes: 'tbl-col link',
      sort: true,
      headerStyle: {width: '500px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      },
      formatter: (cell, row) => (
        <div><a onClick={() => onClickLinkToDetail(row.item_id)}>{cell}</a></div>
      )
    },
    {
      dataField: 'item_type',
      text: '種別',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '100px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'unit',
      text: '単位',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '100px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'cost',
      text: '社内原価',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '100px', textAlign: "right"},
      align: 'right',
      formatter: (cell, row) => (
        <span>{formatNumber(cell)}</span>
      ),
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'std_cost',
      text: '仕入れ価格',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '100px', textAlign: "right"},
      align: 'right',
      formatter: (cell, row) => (
        <span>{formatNumber(cell)}</span>
      ),
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'price',
      text: '販売価格',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '100px', textAlign: "right"},
      align: 'right',
      formatter: (cell, row) => (
        <span>{formatNumber(cell)}</span>
      ),
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'note',
      text: '備考',
      classes: 'tbl-col',
      sort: false
    },
  ];

  // 初期化
  useEffect(() => {
    const init = async () => {
      setLocPage(1);
    }
    init();
  }, []);

  // 検索処理
  useEffect(() => {
    if (iniSearch.current) {
      iniSearch.current = false;
      return;
    }
    const update = async () => {

      setLoading(true);
      let condition = {};
      addField(condition, "page", locPage);
      addField(condition, "size_per_page", locSizePerPage);
      addField(condition, "sort_field", locSortField);
      addField(condition, "sort_order", locSortOrder);
      addField(condition, "item_name", itemName);
      addField(condition, "note", note);
      let itemTypeList = [];
      for (let key in itemType) {
        if (itemType[key]) {
          itemTypeList.push(key);
        }
      }
      addField(condition, "item_type", itemTypeList);

      const res = await getAPI("ms_item_search_view", condition);
      setListData(res);
      setLoading(false);
      setLoadingCss("loading_panel_fadeout");
    }
    update();
  }, [
    locPage,
    locSizePerPage,
    locSortField,
    locSortOrder,
    debouncedItemName,
    debouncedItemType,
    debouncedNote
  ]);

  const onChangeItemType = e => {
    setItemType({...itemType, [e.target.value]: e.target.checked});
  }

  const onTableChange = (type, {page, sizePerPage, filters, sortField, sortOrder, cellEdit}) => {
    if ("pagination" === type) {
      setLocPage(page);
    }
    if ("sizePerPage" === type) {
      setLocSizePerPage(sizePerPage);
    }
    if ("sort" === type) {
      setLocSortField(sortField);
      setLocSortOrder(sortOrder);
    }
  }

  const onClickLinkToDetail = (item_id) => {
    history.push("/item/" + item_id);
  }

  return (
    <>
      <AppNav/>
      <Row className="h1">
        <Col sm={8}>
          <h1>
            資材（作業）一覧
          </h1>
        </Col>
      </Row>

      <Card className="card-search">
        <Container className="container-main-search">
          <Form.Group as={Row}>
            <Form.Label column sm="1">材料名</Form.Label>
            <Col sm="5">
              <Form.Control type="text" value={itemName} onChange={e => setItemName(e.target.value)}/>
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm="1">種別</Form.Label>
            <Col sm="5">
              <Form.Check
                inline
                id="status1"
                type="checkbox"
                label="資材"
                value="資材"
                onChange={onChangeItemType}
                checked={itemType["資材"] ?? false}
              />
              <Form.Check
                inline
                id="status2"
                type="checkbox"
                label="作業"
                value="作業"
                onChange={onChangeItemType}
                checked={itemType["作業"] ?? false}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm="1">備考</Form.Label>
            <Col sm="5">
              <Form.Control type="text" value={note} onChange={e => setNote(e.target.value)}/>
            </Col>
          </Form.Group>
        </Container>
      </Card>

      <Container className="container-main-list">
        <InitialLoading loadingCss={loadingCss}/>
        <MdDatatable
          keyField="item_id"
          listData={listData}
          columns={columns}
          loading={loading}
          onTableChange={onTableChange}
          page={locPage}
          sizePerPage={locSizePerPage}
          totalSize={locTotalSize}
          sortField={locSortField}
          sortOrder={locSortOrder}
          showPagenation={true}
          linkToAdd="/item/"
        />
      </Container>
    </>
  );
}

export default withRouter(ItemList);