import React, {useEffect, useRef, useState} from 'react';
import {Link, useHistory, withRouter} from "react-router-dom";
import Button from 'react-bootstrap/Button';
import {getAPI} from "../APIUtil";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import AppNav from "./AppNav";
import {Card, Col, Container, Form, InputGroup, Row} from "react-bootstrap";
import {
  addField,
  useDebounce,
  dateformat,
  dateformatJPNYYYYMMDD,
  parseDate,
  formatNumber,
  setFocusCalendar, onSearchBook, onSearchCo, onSearchConst
} from "../CMUtil";
import MdDatatable, {InitialLoading, sortCaret} from "../MdDataTable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons";
import DatePicker, {registerLocale} from "react-datepicker"
import ja from "date-fns/locale/ja";
import AsyncSelect from "react-select/async";

registerLocale('ja', ja)

export const InvList = () => {

  const history = useHistory();
  const iniSearch = useRef(true);

  const [loadingCss, setLoadingCss] = useState("loading_panel_init");

  const [loading, setLoading] = useState(true);
  const [locPage, setLocPage] = useState(null);
  const [locSizePerPage, setLocSizePerPage] = useState(10);
  const [locTotalSize, setLocTotalSize] = useState(null);
  const [locSortField, setLocSortField] = useState(null);
  const [locSortOrder, setLocSortOrder] = useState(null);

  const [constSelected, setConstSelected] = useState([]);
  const [constId, setConstId] = useState("");
  const [bookSelected, setBookSelected] = useState([]);
  const [bookId, setBookId] = useState("");
  const [coSelected, setCoSelected] = useState([]);
  const [coId, setCoId] = useState("");

  const [name, setName] = useState("");
  const debouncedName = useDebounce(name, 500, setLocPage);
  const [invNo, setInvNo] = useState("");
  const debouncedInvNo = useDebounce(invNo, 500, setLocPage);
  const [invDateFrom, setInvDateFrom] = useState("");
  const debouncedInvDateFrom = useDebounce(invDateFrom, 500, setLocPage);
  const [invDateTo, setInvDateTo] = useState("");
  const debouncedInvDateTo = useDebounce(invDateTo, 500, setLocPage);

  const [listData, setListData] = useState([]);

  // 項目定義
  const columns = [
    {
      dataField: "inv_no",
      text: '請求番号',
      classes: 'tbl-col link',
      sort: true,
      headerStyle: {width: '70px'},
      formatter: (cell, row) => (
        <div><a onClick={() => onClickLinkToDetail(row.inv_id)}>{cell}</a></div>
      )
    },
    {
      dataField: 'const.name',
      text: '施工業者',
      sort: true,
      classes: 'tbl-col',
      headerStyle: {width: '70px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'inv_date',
      text: '請求日',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '70px'},
      formatter: (cell, row) => (
        <span>{dateformatJPNYYYYMMDD(parseDate(cell))}</span>
      ),
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'inv_title',
      text: '件名',
      classes: 'tbl-col',
      sort: false,
      headerStyle: {width: '70px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'ttl_amount',
      text: '金額',
      classes: 'tbl-col',
      sort: false,
      headerStyle: {width: '0px'},
      align:'right',
      formatter: (cell, row) => (
        <span>{formatNumber(cell)}</span>
      ),
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
  ];

  // 初期化
  useEffect(() => {
    const init = async () => {
      // setLocPage(1);
    }
    init();
  }, []);

  // 検索処理
  useEffect(() => {
    if (iniSearch.current) {
      iniSearch.current = false;
      return;
    }
    const update = async () => {

      setLoading(true);
      let sortField = locSortField;
      let sortOrder = locSortOrder;
      if (!sortField && !sortOrder) {
        //デフォルト　着工日降順
        sortField = null;
        sortOrder = null;
      }
      let condition = {};
      addField(condition, "page", locPage);
      addField(condition, "size_per_page", locSizePerPage);
      addField(condition, "sort_field", sortField);
      addField(condition, "sort_order", sortOrder);
      addField(condition, "inv_no", invNo);
      addField(condition, "inv_date_from", dateformat(invDateFrom));
      addField(condition, "inv_date_to", dateformat(invDateTo));
      addField(condition, "const_id", constId);
      addField(condition, "book_id", bookId);
      addField(condition, "co_id", coId);

      const res = await getAPI("tr_inv_search_view", condition);
      setListData(res);
      setLoading(false);
      setLoadingCss("loading_panel_fadeout");
    }
    update();
  }, [
    locPage,
    locSizePerPage,
    locSortField,
    locSortOrder,
    constId,
    debouncedInvNo,
    debouncedName,
    debouncedInvDateFrom,
    debouncedInvDateTo
  ]);

  const onTableChange = (type, {page, sizePerPage, filters, sortField, sortOrder, cellEdit}) => {
    if ("pagination" === type) {
      setLocPage(page);
    }
    if ("sizePerPage" === type) {
      setLocSizePerPage(sizePerPage);
    }
    if ("sort" === type) {
      setLocSortField(sortField);
      setLocSortOrder(sortOrder);
    }
  }

  const onClickLinkToDetail = (inv_id) => {
    history.push("/inv/" + inv_id);
  }

  const handleChangeConst = (value) => {
    setConstSelected(value ? value : "");
    setConstId(value ? value.id : "");
  }

  const handleChangeBook = (value) => {
    setBookSelected(value);
    setBookId(value.id);
  }

  const handleChangeCo = (value) => {
    setCoSelected(value ? value : "");
    setCoId(value ? value.id : "");
  }

  return (
    <>
      <AppNav/>
      <Row className="h1">
        <Col sm={8}>
          <h1>
            請求一覧
          </h1>
        </Col>
      </Row>

      <Card className="card-search">
        <Container className="container-main-search">
          <Form.Group as={Row}>
            <Form.Label column sm="1">請求書番号</Form.Label>
            <Col sm="4">
                <Form.Control type="text" value={invNo} onChange={e => setInvNo(e.target.value)}/>
            </Col>
            <Col sm="1"></Col>
            <Form.Label column sm="1">請求日</Form.Label>
            <Col sm="4" className="input-group">
              <DatePicker id="invDateFrom" selected={invDateFrom}
                          onChange={(date) => setInvDateFrom(date)} dateFormat="yyyy年MM月dd日" locale="ja"/>
              <Button as={Link} variant="link" to={"/"} style={{paddingRight: "0"}}
                      onClick={e => setFocusCalendar(e, 'invDateFrom')}>
                <FontAwesomeIcon icon={faCalendarAlt} size="2x"/>
              </Button>
              <InputGroup.Text style={{border: "0px", backgroundColor: "#ffffff"}}>～</InputGroup.Text>
              <DatePicker id="invDateTo" selected={invDateTo} onChange={(date) => setInvDateTo(date)}
                          dateFormat="yyyy年MM月dd日" locale="ja"/>
              <Button as={Link} variant="link" to={"/"} style={{paddingRight: "0"}}
                      onClick={e => setFocusCalendar(e, 'invDateTo')}>
                <FontAwesomeIcon icon={faCalendarAlt} size="2x"/>
              </Button>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="1">施工業者</Form.Label>
            <Col sm="4">
              <AsyncSelect
                isClearable
                className="async-select"
                cacheOptions
                defaultOptions
                value={constSelected}
                getOptionLabel={e => e.name}
                getOptionValue={e => e.id}
                loadOptions={onSearchConst}
                onChange={handleChangeConst}
                placeholder=""
              />
            </Col>
            <Col sm="1"></Col>
            <Form.Label column sm="1">現場</Form.Label>
            <Col sm="4">
              <AsyncSelect
                isClearable
                className="async-select"
                cacheOptions
                defaultOptions
                value={bookSelected}
                getOptionLabel={e => e.name}
                getOptionValue={e => e.id}
                loadOptions={onSearchBook}
                onChange={handleChangeBook}
                placeholder=""
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="1">受注番号</Form.Label>
            <Col sm="4">
              <AsyncSelect
                isClearable
                className="async-select"
                cacheOptions
                defaultOptions
                value={bookSelected}
                getOptionLabel={e => e.name}
                getOptionValue={e => e.id}
                loadOptions={onSearchCo}
                onChange={handleChangeCo}
                placeholder=""
              />
            </Col>
          </Form.Group>
        </Container>
      </Card>

      <Container className="container-main-list">
        <InitialLoading loadingCss={loadingCss}/>
        <MdDatatable
          keyField="inv_id"
          listData={listData}
          columns={columns}
          loading={loading}
          onTableChange={onTableChange}
          page={locPage}
          sizePerPage={locSizePerPage}
          totalSize={locTotalSize}
          sortField={locSortField}
          sortOrder={locSortOrder}
          showPagenation={true}
          linkToAdd="/inv/"
        />
      </Container>
    </>
  );
}

export default withRouter(InvList);