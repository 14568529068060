import React, {useEffect, useState} from 'react';
import {getAPI} from "../APIUtil";
import {Document, Page, Text, View, StyleSheet, Font} from '@react-pdf/renderer';
import {formatNumber, parseDate, dateformatJPNYYYYMMDD} from "../CMUtil";
import InvDtlPDF from "./InvDtlPDF";

Font.register({
  family: "jpFont",
  fonts: [
    {src: '/static/font/NotoSansJP-Regular.otf'},
    {src: '/static/font/NotoSansJP-Bold.otf'},
  ]
});

// Create styles
const styles = StyleSheet.create({
  page: {
    fontFamily: "jpFont",
    padding: 40
  },
  header: {
    paddingBottom: 40,
    fontSize: 12,
    textAlign: "center"
  },
  header2: {
    fontWeight: 'bold',
    padding: 10,
    paddingTop: 10,
    paddingRight: 0,
    paddingBottom: 10,
    paddingLeft: 0,
    fontSize: 12,
    textAlign: "left"
  },
  table: {
    display: "table",
    width: "auto",
    fontSize: 10,
    paddingBottom: 10
  },
  tableNotes: {
    display: "table",
    width: "auto",
    fontSize: 10,
    borderStyle: "solid",
    borderTopWidth: 1,
    marginRight: 10,
    marginLeft: 10,
    marginTop: 50
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row"
  },
  tableCol1: {
    width: "100%"
  },
  tableCol2: {
    width: "50%"
  },
  tableColMR: {
    marginRight: 10
  },
  tableColML: {
    marginLeft: 10
  },
  tableCol5Total: {
    width: "20%",
    borderStyle: "solid",
    borderBottomWidth: 1
  },
  tableCol5: {
    width: "20%"
  },
  tableCol6: {
    width: "70%"
  },
  tableCol7: {
    width: "30%"
  },
  tableCol8: {
    width: "10%"
  },
  tableCol9: {
    width: "40%"
  },
  tableCol10: {
    width: "60%"
  },
  tableCol11: {
    width: "15%"
  },
  tableCol13: {
    width: "12%"
  },
  tableCol12: {
    width: "38%"
  },
  tableColDet1: {
    width: "13%",
    fontSize: 10,
    borderLeft: 1,
    borderTop: 1
  },
  tableColDet2: {
    width: "12%",
    fontSize: 10,
    borderRight: 1,
    borderTop: 1
  },
  tableColDet3: {
    width: "15%",
    fontSize: 10,
    borderLeft: 1,
    borderTop: 1
  },
  tableColDet4: {
    width: "35%",
    fontSize: 10,
    borderRight: 1,
    borderTop: 1
  },
  tableColDet5: {
    width: "15%",
    fontSize: 10,
    borderLeft: 1,
  },
  tableColDet6: {
    width: "35%",
    fontSize: 10,
    borderRight: 1
  },
  tableColDet7: {
    width: "15%",
    fontSize: 10,
    borderLeft: 1,
    borderBottom: 1
  },
  tableColDet8: {
    width: "35%",
    fontSize: 10,
    borderRight: 1,
    borderBottom: 1
  },
  cellFontSize: {
    fontSize: 10
  },
  cellBorderBottom: {
    borderBottomColor: "black",
    borderBottomWidth: 1,
    borderStyle: 'solid'
  },
  cellBorderTop: {
    borderTopColor: "black",
    borderTopWidth: 1,
    borderStyle: 'solid'
  },
  tableCell: {
    margin: "auto",
    marginTop: 5
  },
  tableCell2: {
    margin: 0,
    marginTop: 5
  },
  tableCell3: {
    margin: 0,
    marginTop: 20
  },
  label: {
    textAlign: "left",
    paddingLeft: 50
  },
  label2: {
    textAlign: "left",
    paddingLeft: 60
  },
  textR: {
    textAlign: "right"
  },
  textL: {
    textAlign: "left"
  },
  textC: {
    textAlign: "center"
  },
  textB: {
    fontWeight: 'bold'
  },
  labelCell: {
    width: "25%"
  },
  headerBGR: {
    backgroundColor: "black",
    color: "white",
    fontWeight: 'bold',
    textAlign: "right"
  },
  headerBG2: {
    fontWeight: 'bold',
    padding: 10,
    fontSize: 15,
    textAlign: "center"
  },
  headerBG: {
    backgroundColor: "black",
    color: "white",
    fontWeight: 'bold',
    textAlign: "center"
  },
  blankMargin: {
    margin: 10
  },
  underline: {
    textDecoration: "underline"
  },
  tableDtlCol1: {
    width: "55%"
  },
  tableDtlCol2: {
    width: "10%"
  },
  tableDtlCol3: {
    width: "5%"
  },
  tableDtlCol4: {
    width: "15%"
  },
});

export const InvPDF = ({inv_id, updateCnt}) => {

  // const [currDate, setCurrDate] = useState("");
  const [invDate, setInvDate] = useState("");
  // const [subTotal, setSubTotal] = useState("");
  // const [tax, setTax] = useState("");
  const [ttlAmount, setTtlAmount] = useState("");
  const [invObj, setInvObj] = useState({});
  // const [bookObj, setBookObj] = useState({});
  const [constObj, setConstObj] = useState({});
  const [comObj, setComObj] = useState({});
  // const [coObj, setCoObj] = useState({});
  // const [invDtlObj, setInvDtlObj] = useState([]);

  //初期化
  useEffect(() => {
    const load = async () => {
      const invObj = await getAPI("tr_inv/" + inv_id);
      // let currentDate = new Date().toJSON().slice(0, 10);

      setInvDate(dateformatJPNYYYYMMDD((parseDate(invObj.inv_date))));
      // setCurrDate(dateformatJPNYYYYMMDD((parseDate(currentDate))));
      // setSubTotal(formatNumber(invObj.sub_total));
      // setTax(formatNumber(invObj.tax));
      setTtlAmount(formatNumber(invObj.ttl_amount));
      setInvObj(invObj);
      // setBookObj(invObj.book);
      setConstObj(invObj.const);
      setComObj(invObj.com);
      // setCoObj(invObj.co);
    }
    load();

  }, [updateCnt]);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol1}>
              <Text style={styles.tableCell, styles.textR}>{invDate}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View>
              <Text style={styles.tableCell2, styles.header2}>御請求書</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={{width: "100%"}}>
              <Text style={styles.tableCell2, styles.header2}>{constObj.name}&nbsp;&nbsp;御中</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol9}>
              <Text style={styles.tableCell3, styles.textL}>下記の通りご請求申し上げます</Text>
            </View>
            <View style={styles.tableCol8}>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell3, styles.textL, styles.textB}>{comObj.com_name}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol2}>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell3, styles.textL}>{comObj.pref} {comObj.city}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol2}>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell3, styles.textL}>〒 {comObj.zip} TEL {comObj.tel}（代表）</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={{width: "100%"}}>
              <Text style={styles.tableCell2, styles.header2}>御請求金額&nbsp;&nbsp;&nbsp;&nbsp;￥{ttlAmount}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol13}>
              <Text style={styles.tableCell2, styles.textL, styles.cellFontSize}>件名: </Text>
            </View>
            <View style={styles.tableCol12}>
              <Text style={styles.tableCell2, styles.textL, styles.cellFontSize}>{invObj.inv_title}</Text>
            </View>
            <View style={styles.tableColDet3}>
              <Text style={styles.tableCell2, styles.textL}>お振込み先</Text>
            </View>
            <View style={styles.tableColDet4}>
              <Text style={styles.tableCell2, styles.textL}>{comObj.bank_name} {comObj.bank_branch}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol13}>
              <Text style={styles.tableCell2, styles.textL, styles.cellFontSize}></Text>
            </View>
            <View style={styles.tableCol12}>
              <Text style={styles.tableCell2, styles.textL, styles.cellFontSize}></Text>
            </View>
            <View style={styles.tableColDet5}>
              <Text style={styles.tableCell2, styles.textL}></Text>
            </View>
            <View style={styles.tableColDet6}>
              <Text style={styles.tableCell2, styles.textL}>{comObj.account_type} {comObj.account_no}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol13}>
              <Text style={styles.tableCell2, styles.textL, styles.cellFontSize}></Text>
            </View>
            <View style={styles.tableCol12}>
              <Text
                style={styles.tableCell2, styles.textL, styles.cellFontSize}></Text>
            </View>
            <View style={styles.tableColDet7}>
              <Text style={styles.tableCell2, styles.textL}></Text>
            </View>
            <View style={styles.tableColDet8}>
              <Text style={styles.tableCell2, styles.textL}>{comObj.account_holder}</Text>
            </View>
          </View>

          <View style={styles.blankMargin}>
          </View>

          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableDtlCol1}>
                <Text style={styles.tableCell, styles.headerBG}>詳細</Text>
              </View>
              <View style={{width:"15%"}}>
                <Text style={styles.tableCell, styles.headerBGR}>数量</Text>
              </View>
              <View style={styles.tableDtlCol4}>
                <Text style={styles.tableCell, styles.headerBGR}>単価</Text>
              </View>
              <View style={styles.tableDtlCol4}>
                <Text style={styles.tableCell, styles.headerBGR}>金額</Text>
              </View>
              {/*<View style={styles.tableDtlCol4}>*/}
              {/*  <Text style={styles.tableCell, styles.headerBG}>備考</Text>*/}
              {/*</View>*/}
            </View>

            <InvDtlPDF inv_id={inv_id} updateCnt={updateCnt}/>
          </View>

          <View style={styles.blankMargin}>
          </View>

          <View style={styles.tableNotes}>
            <View style={styles.tableRow}>
              <View style={styles.tableCol1}>
                <Text style={styles.tableCell, styles.textL}>{invObj.note}</Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )

};

export default InvPDF;