import {Link} from "react-router-dom";
import React from "react";
import Button from "react-bootstrap/Button";

export const WntyRegNav = ({bookId}) => {
  return (
    <div className="h1 clearfix">
      <h1 className="float-left">
        保証書登録
      </h1>
      <Button as={Link} to={"/book/" + bookId} variant="link-menu" className="float-left">
        工事台帳
      </Button>
      <Button as={Link} to="#" variant="link-menu" className="float-left">
        お見積り
      </Button>
      <Button as={Link} to="#" variant="link-menu" className="float-left">
        出来高調書
      </Button>
      <Button as={Link} to="#" variant="link-menu" className="float-left">
      保証書
      </Button>
    </div>
  )
};

export default WntyRegNav;