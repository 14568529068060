import React, {useEffect, useRef, useState} from 'react';
import {useHistory} from "react-router-dom";
import {Card, Col, Container, Form, InputGroup, Nav, Row} from "react-bootstrap";
import {downloadAPI, getAPI, postAPI, putAPI} from "../APIUtil";
import Button from "react-bootstrap/Button";
import {withRouter} from "react-router";
import AppNav from "./AppNav";
import 'react-toastify/dist/ReactToastify.css';
import Toast, {success} from "../Toast";
import BookNav from "./BookNav";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from 'react-bootstrap-table2-editor';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFile, faMinusCircle, faPen, faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import AsyncSelect from "react-select/async";
import {
  amountFormatter,
  finishLoadingBtn,
  formatNumber,
  nvl,
  parseNumber,
  qtyFormatetr,
  startLoadingBtn,
  toNull,
  toSingleByte
} from "../CMUtil";

export const ResultDtl = ({match: {params: {p_book_id, p_ver}}}) => {

  const [loading, setLoading] = useState(true);

  const hist = useHistory();

  const iniVer = useRef(true);

  const [verList, setVerList] = useState([]);

  const [subTotalIdx, setSubTotalIdx] = useState(-1);
  const [expenseIdx, setExpenseIdx] = useState(-1);
  const [transportIdx, setTransportIdx] = useState(-1);
  const [discountIdx, setDiscountIdx] = useState(-1);
  const [benefitIdx, setBenefitIdx] = useState(-1);
  const [totalTotalIdx, setTotalTotalIdx] = useState(-1);

  const [count, setCount] = useState(0);

  const [resultId, setResultId] = useState("");
  const [quotId, setQuotId] = useState("");
  const [ver, setVer] = useState("");

  const [bookId, setBookId] = useState("");
  const [orderNo, setOrderNo] = useState("");
  const [bookNo, setBookNo] = useState("");
  const [bookName, setBookName] = useState("");
  const [pref, setPref] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  // const [constName, setConstName] = useState();

  const [verSelected, setVerSelected] = useState();

  const onClickAddCol = () => {
    let addColIndex = 1;
    for (let i = 1; i <= 10; i++) {
      if (typeof (resultList[0]['rc_qty_' + i]) === "undefined") {
        addColIndex = i;
        break;
      }
    }

    let locResultList = JSON.parse(JSON.stringify(resultList));
    for (let i = 0; i < locResultList.length; i++) {
      locResultList[i]['rc_qty_' + addColIndex] = null;
      locResultList[i]['rc_amount_' + addColIndex] = null;
    }
    setResultList(locResultList);

    let locResultBottomList = JSON.parse(JSON.stringify(resultBottomList));
    for (let i = 0; i < locResultBottomList.length; i++) {
      locResultBottomList[i]['rc_qty_' + addColIndex] = null;
      locResultBottomList[i]['rc_amount_' + addColIndex] = null;
    }

    setResultBottomList(locResultBottomList);

    generateColumnsFromResultList(locResultList);
  }

  const onClickDeleteCol = (resultChargeId) => {
    setCount(count + 1);
  }

  const onClickAddRow = (e, row) => {
    let locResultList = JSON.parse(JSON.stringify(resultList));
    let newRow = {
      id: 0,
      opn_detail: null,
      qty: null,
      unit: null,
      price: null,
      rc_qty_1: null,
      rc_amount_1: null,
      result_charge_id: null,
      total_qty: null,
      total_amount: null,
    };
    locResultList.splice(row.id - 1, 0, newRow);
    for (let i = 0; i < locResultList.length; i++) {
      locResultList[i]["id"] = i + 1;
    }
    setResultList(locResultList);
  }

  const onClickDeleteRow = (e, row) => {
    let locResultList = JSON.parse(JSON.stringify(resultList));
    locResultList.splice(row.id - 1, 1);
    for (let i = 0; i < locResultList.length; i++) {
      locResultList[i]["id"] = i + 1;
    }
    setResultList(locResultList);
  }

  const [resultTopList, setResultTopList] = useState([]);
  const [resultTopColumns, setResultTopColumns] = useState([
    {
      dataField: 'id',
      text: '',
      align: 'center',
      headerStyle: {width: '40px'}
    }
  ]);
  const [resultColumns, setResultColumns] = useState([
    {
      dataField: 'id',
      text: '',
      align: 'center',
      headerStyle: {width: '40px'},
    }
  ]);
  const resultRightColumns = [
    {
      dataField: 'id',
      text: '',
      hidden: true,
      headerStyle: {width: '3rem'},
    },
    {
      dataField: 'btn',
      text: '　',
      classes: 'tbl-col-btn',
      align: 'center',
      editable: false,
      headerStyle: {width: '8rem'},
      formatter: (cell, row) => {
        return (
          <>
            <Button variant="add" style={{
              width: "2.5rem",
              height: "2.2rem",
              margin: "0rem 0.5rem 0rem 0.05rem",
              borderRadius: "0.5rem"
            }}
                    onClick={e => onClickAddRow(e, row)}>
              <FontAwesomeIcon icon={faPlusCircle} size="lg" style={{marginBottom: "0.1rem"}}/>
            </Button>
            <Button variant="add" style={{
              width: "2.5rem",
              height: "2.2rem",
              margin: "0rem 0.05rem 0rem 0.05rem",
              borderRadius: "0.5rem"
            }}
                    onClick={e => onClickDeleteRow(e, row)}>
              <FontAwesomeIcon icon={faMinusCircle} size="lg" style={{marginBottom: "0.1rem"}}/>
            </Button>
          </>
        );
      }
    }
  ];
  const [resultList, setResultList] = useState([]);
  const [resultBottomColumns, setResultBottomColumns] = useState([
    {
      dataField: 'id',
      text: '',
      align: 'center',
      headerStyle: {width: '40px'},
    }
  ]);
  const [resultBottomList, setResultBottomList] = useState([]);

  //初期化
  useEffect(() => {
    const update = async () => {

      setBookId(p_book_id);
      setVer(p_ver ? p_ver : 1);
    }
    update();

  }, []);

  useEffect(() => {

    if (iniVer.current) {
      iniVer.current = false;
      return;
    }

    const update = async () => {

      let res = null;
      let searchCondition = null;

      // searchCondition = {
      //   book_id: bookId,
      //   ver: ver
      // }
      // res = await getAPI("tr_quot_search_view", searchCondition);
      //
      searchCondition = {
        book_id: p_book_id,
        ver: ver
      }
      res = await getAPI("tr_result_search_view", searchCondition);
      setVerSelected({name: ver + "版" + (res.count === 1 ? " （登録済み）" : ""), id: ver});

      searchCondition = {
        book_id: bookId,
        ver: ver
      }
      res = await getAPI("tr_quot_search_view", searchCondition);
      let locQuot = res.results[0];
      let locResult = {
        "result_id": null,
        "quot": locQuot,
        "book": locQuot.book,
        "const": locQuot.const
      }

      searchCondition = {
        book_id: bookId,
        ver: ver
      }
      res = await getAPI("tr_result_search_view", searchCondition);
      if (res.count > 0) {
        locResult = res.results[0];
      }

      setQuotId(nvl(locResult.quot.quot_id));
      setBookNo(nvl(locResult.book.book_no));
      setBookName(nvl(locResult.book.book_name));
      setPref(nvl(locResult.book.pref));
      setCity(nvl(locResult.book.city));
      setAddress(nvl(locResult.book.address));
      setOrderNo(nvl(locResult.order_no));
      // setConstName(nvl(locResult.book.const.name));
      setResultId(nvl(locResult.result_id));

      if (locResult.result_id) {
        searchCondition = {
          result_id: locResult.result_id
        }
        res = await getAPI("tr_result_charge_search_for_list_view", searchCondition);
      } else {
        //初回（未登録時）は見積書より取得
        searchCondition = {
          quot_id: locResult.quot.quot_id
        }
        res = await getAPI("tr_result_charge_search_for_list_from_quot_view", searchCondition);
      }
      let dbList = res.results;

      let locResultList = [];
      let locResultChargeList = [];
      for (let rowIdx = 0; rowIdx < dbList.length; rowIdx++) {

        let obj = dbList[rowIdx];

        //請求書保持
        if (obj.ordr && obj.ordr.startsWith("2") && obj.ordr.endsWith("1")) {
          locResultChargeList.push(obj);
        }

        //行と列の入れ替え
        for (let colIdx = 1; colIdx <= 50; colIdx++) {
          if (!locResultList[colIdx]) {
            locResultList[colIdx - 1] = {id: colIdx, result_charge_id: obj.result_charge_id};
          }
          locResultList[colIdx - 1][obj.type] = obj["row_" + colIdx];
        }

        //1行目にresult_charge_idをセットする
        if (obj.type.startsWith("rc_qty")) {
          if (locResultList.length > 0) {
            let index = obj.type.substring(obj.type.lastIndexOf("_") + 1);
            locResultList[0]['result_charge_id_' + index] = obj.result_charge_id;
          }
        }
      }

      //不要行の削除
      let lastRowNo = 0;
      for (let i = 0; i < locResultList.length; i++) {
        Object.entries(locResultList[i]).map(([key, value], index) => {
          if (key !== 'id' && value) {
            lastRowNo = i;
          }
        });
      }
      lastRowNo++;

      locResultList.splice(lastRowNo, locResultList.length - lastRowNo);

      //集計行
      let locResultBottomList = [];
      let locSubTotalIdx = -1;
      let locExpenseIdx = -1;
      let locTransportIdx = -1;
      let locDiscountIdx = -1;
      let locBenefitsIdx = -1;
      let locTotalTotalIdx = -1;

      if (locResult.quot.sub_total != null) {
        locResultBottomList.push({
          amount: locResult.quot.sub_total,
          id: "sub_total",
          opn_detail: "小計",
          qty: null,
          unit: null,
          price: null,
          rc_amount_1: null,
          rc_qty_1: null,
          result_charge_id: null,
          result_charge_id_1: null,
          total_amount: locResult.result_id ? locResult.total_sub_total : '',
          total_qty: null
        });
        locSubTotalIdx = locResultBottomList.length - 1;
      }
      if (locResult.quot.expense != null) {
        locResultBottomList.push({
          amount: locResult.quot.expense,
          id: "expense",
          opn_detail: "諸経費",
          qty: null,
          unit: null,
          price: null,
          rc_amount_1: null,
          rc_qty_1: null,
          result_charge_id: null,
          result_charge_id_1: null,
          total_amount: locResult.result_id ? locResult.total_expense : '',
          total_qty: null
        });
        locExpenseIdx = locResultBottomList.length - 1;
      }
      if (locResult.quot.transport != null) {
        locResultBottomList.push({
          amount: locResult.quot.transport,
          id: "transport",
          opn_detail: "交通宿泊費",
          qty: null,
          unit: null,
          price: null,
          rc_amount_1: null,
          rc_qty_1: null,
          result_charge_id: null,
          result_charge_id_1: null,
          total_amount: locResult.result_id ? locResult.total_transport : '',
          total_qty: null
        });
        locTransportIdx = locResultBottomList.length - 1;
      }
      if (locResult.quot.discount != null) {
        locResultBottomList.push({
          amount: locResult.quot.discount,
          id: "discount",
          opn_detail: "出精値引き",
          qty: null,
          unit: null,
          price: null,
          rc_amount_1: null,
          rc_qty_1: null,
          result_charge_id: null,
          result_charge_id_1: null,
          total_amount: locResult.result_id ? locResult.total_discount : '',
          total_qty: null
        });
        locDiscountIdx = locResultBottomList.length - 1;
      }
      if (locResult.quot.benefits != null) {
        locResultBottomList.push({
          amount: locResult.quot.benefits,
          id: "benefits",
          opn_detail: "法定福利費",
          qty: null,
          unit: null,
          price: null,
          rc_amount_1: null,
          rc_qty_1: null,
          result_charge_id: null,
          result_charge_id_1: null,
          total_amount: locResult.result_id ? locResult.total_benefits : '',
          total_qty: null
        });
        locBenefitsIdx = locResultBottomList.length - 1;
      }
      if (locResult.quot.total_total != null) {
        locResultBottomList.push({
          amount: locResult.quot.total_total,
          id: "total_total",
          opn_detail: "合計",
          qty: null,
          unit: null,
          price: null,
          rc_amount_1: null,
          rc_qty_1: null,
          result_charge_id: null,
          result_charge_id_1: null,
          total_amount: locResult.result_id ? locResult.total_total : '',
          total_qty: null
        });
        locTotalTotalIdx = locResultBottomList.length - 1;
      }

console.log(locResultBottomList);

      let rcList = dbList.filter((row) => {
        return row.result_charge_id && row.type.startsWith("rc_amount");
      })

      for (let i = 0; i < rcList.length; i++) {

        if (locSubTotalIdx >= 0) {
          locResultBottomList[locSubTotalIdx]['rc_amount_' + (i + 1)] = rcList[i].sub_total
        }
        if (locExpenseIdx >= 0) {
          locResultBottomList[locExpenseIdx]['rc_amount_' + (i + 1)] = rcList[i].expense
        }
        if (locTransportIdx >= 0) {
          locResultBottomList[locTransportIdx]['rc_amount_' + (i + 1)] = rcList[i].transport
        }
        if (locDiscountIdx >= 0) {
          locResultBottomList[locDiscountIdx]['rc_amount_' + (i + 1)] = rcList[i].discount
        }
        if (locBenefitsIdx >= 0) {
          locResultBottomList[locBenefitsIdx]['rc_amount_' + (i + 1)] = rcList[i].benefits
        }
        if (locTotalTotalIdx >= 0) {
          locResultBottomList[locTotalTotalIdx]['rc_amount_' + (i + 1)] = rcList[i].total
        }
      }

      generateColumnsFromResultList(locResultList);
      setResultList(locResultList);

console.log(locResultBottomList);
      setResultBottomList(locResultBottomList);
      setSubTotalIdx(locSubTotalIdx);
      setExpenseIdx(locExpenseIdx);
      setTransportIdx(locTransportIdx);
      setDiscountIdx(locDiscountIdx);
      setBenefitIdx(locBenefitsIdx);
      setTotalTotalIdx(locTotalTotalIdx);
    }
    update();
  }, [ver, count]);

  const calcResultBottomList = () => {

    //合計小計
    let subTotal = 0;
    let subTotal_1 = 0;
    let subTotal_2 = 0;
    let subTotal_3 = 0;
    let subTotal_4 = 0;
    let subTotal_5 = 0;
    let subTotalTotal = 0;
    resultList.map((row) => {
      subTotal = subTotal + (row.amount ? parseNumber(row.amount) : 0);
      subTotal_1 = subTotal_1 + (row.rc_amount_1 ? parseNumber(row.rc_amount_1) : 0);
      subTotal_2 = subTotal_2 + (row.rc_amount_2 ? parseNumber(row.rc_amount_2) : 0);
      subTotal_3 = subTotal_3 + (row.rc_amount_3 ? parseNumber(row.rc_amount_3) : 0);
      subTotal_4 = subTotal_4 + (row.rc_amount_4 ? parseNumber(row.rc_amount_4) : 0);
      subTotal_5 = subTotal_5 + (row.rc_amount_5 ? parseNumber(row.rc_amount_5) : 0);
      subTotalTotal = subTotalTotal + (row.total_amount ? parseNumber(row.total_amount) : 0);
    })

    let locResultBottomList = JSON.parse(JSON.stringify(resultBottomList));
    // 諸経費
    // subTotal = subTotal + (locResultBottomList[EXPENSE].amount ? parseNumber(locResultBottomList[EXPENSE].amount) : 0);
    // subTotal_1 = subTotal_1 + (locResultBottomList[EXPENSE].rc_amount_1 ? parseNumber(locResultBottomList[EXPENSE].rc_amount_1) : 0);
    // subTotal_2 = subTotal_2 + (locResultBottomList[EXPENSE].rc_amount_2 ? parseNumber(locResultBottomList[EXPENSE].rc_amount_2) : 0);
    // subTotal_3 = subTotal_3 + (locResultBottomList[EXPENSE].rc_amount_3 ? parseNumber(locResultBottomList[EXPENSE].rc_amount_3) : 0);
    // subTotal_4 = subTotal_4 + (locResultBottomList[EXPENSE].rc_amount_4 ? parseNumber(locResultBottomList[EXPENSE].rc_amount_4) : 0);
    // subTotal_5 = subTotal_5 + (locResultBottomList[EXPENSE].rc_amount_5 ? parseNumber(locResultBottomList[EXPENSE].rc_amount_5) : 0);
    // subTotalTotal = subTotalTotal + (locResultBottomList[EXPENSE].total_amount ? parseNumber(locResultBottomList[EXPENSE].total_amount) : 0);

    // 交通宿泊日
    // subTotal = subTotal + (locResultBottomList[TRANSPORT].amount ? parseNumber(locResultBottomList[TRANSPORT].amount) : 0);
    // subTotal_1 = subTotal_1 + (locResultBottomList[TRANSPORT].rc_amount_1 ? parseNumber(locResultBottomList[TRANSPORT].rc_amount_1) : 0);
    // subTotal_2 = subTotal_2 + (locResultBottomList[TRANSPORT].rc_amount_2 ? parseNumber(locResultBottomList[TRANSPORT].rc_amount_2) : 0);
    // subTotal_3 = subTotal_3 + (locResultBottomList[TRANSPORT].rc_amount_3 ? parseNumber(locResultBottomList[TRANSPORT].rc_amount_3) : 0);
    // subTotal_4 = subTotal_4 + (locResultBottomList[TRANSPORT].rc_amount_4 ? parseNumber(locResultBottomList[TRANSPORT].rc_amount_4) : 0);
    // subTotal_5 = subTotal_5 + (locResultBottomList[TRANSPORT].rc_amount_5 ? parseNumber(locResultBottomList[TRANSPORT].rc_amount_5) : 0);
    // subTotalTotal = subTotalTotal + (locResultBottomList[TRANSPORT].total_amount ? parseNumber(locResultBottomList[TRANSPORT].total_amount) : 0);

    // 出精値引き
    // subTotal = subTotal + (locResultBottomList[DISCOUNT].amount ? parseNumber(locResultBottomList[DISCOUNT].amount) : 0);
    // subTotal_1 = subTotal_1 + (locResultBottomList[DISCOUNT].rc_amount_1 ? parseNumber(locResultBottomList[DISCOUNT].rc_amount_1) : 0);
    // subTotal_2 = subTotal_2 + (locResultBottomList[DISCOUNT].rc_amount_2 ? parseNumber(locResultBottomList[DISCOUNT].rc_amount_2) : 0);
    // subTotal_3 = subTotal_3 + (locResultBottomList[DISCOUNT].rc_amount_3 ? parseNumber(locResultBottomList[DISCOUNT].rc_amount_3) : 0);
    // subTotal_4 = subTotal_4 + (locResultBottomList[DISCOUNT].rc_amount_4 ? parseNumber(locResultBottomList[DISCOUNT].rc_amount_4) : 0);
    // subTotal_5 = subTotal_5 + (locResultBottomList[DISCOUNT].rc_amount_5 ? parseNumber(locResultBottomList[DISCOUNT].rc_amount_5) : 0);
    // subTotalTotal = subTotalTotal + (locResultBottomList[DISCOUNT].total_amount ? parseNumber(locResultBottomList[DISCOUNT].total_amount) : 0);

    // let total = subTotal + (locResultBottomList[BENEFITS].amount ? parseNumber(locResultBottomList[BENEFITS].amount) : 0);
    // let total_1 = subTotal_1 + (locResultBottomList[BENEFITS].rc_amount_1 ? parseNumber(locResultBottomList[BENEFITS].rc_amount_1) : 0);
    // let total_2 = subTotal_2 + (locResultBottomList[BENEFITS].rc_amount_2 ? parseNumber(locResultBottomList[BENEFITS].rc_amount_2) : 0);
    // let total_3 = subTotal_3 + (locResultBottomList[BENEFITS].rc_amount_3 ? parseNumber(locResultBottomList[BENEFITS].rc_amount_3) : 0);
    // let total_4 = subTotal_4 + (locResultBottomList[BENEFITS].rc_amount_4 ? parseNumber(locResultBottomList[BENEFITS].rc_amount_4) : 0);
    // let total_5 = subTotal_5 + (locResultBottomList[BENEFITS].rc_amount_5 ? parseNumber(locResultBottomList[BENEFITS].rc_amount_5) : 0);
    // let totalTotal = subTotalTotal + (locResultBottomList[BENEFITS].total_amount ? parseNumber(locResultBottomList[BENEFITS].total_amount) : 0);

    let total = 0;
    let total_1 = 0;
    let total_2 = 0;
    let total_3 = 0;
    let total_4 = 0;
    let total_5 = 0;
    let totalTotal = 0;

    if (expenseIdx >= 0) {
      total = subTotal + (locResultBottomList[expenseIdx].amount ? parseNumber(locResultBottomList[expenseIdx].amount) : 0);
      total_1 = subTotal_1 + (locResultBottomList[expenseIdx].rc_amount_1 ? parseNumber(locResultBottomList[expenseIdx].rc_amount_1) : 0);
      total_2 = subTotal_2 + (locResultBottomList[expenseIdx].rc_amount_2 ? parseNumber(locResultBottomList[expenseIdx].rc_amount_2) : 0);
      total_3 = subTotal_3 + (locResultBottomList[expenseIdx].rc_amount_3 ? parseNumber(locResultBottomList[expenseIdx].rc_amount_3) : 0);
      total_4 = subTotal_4 + (locResultBottomList[expenseIdx].rc_amount_4 ? parseNumber(locResultBottomList[expenseIdx].rc_amount_4) : 0);
      total_5 = subTotal_5 + (locResultBottomList[expenseIdx].rc_amount_5 ? parseNumber(locResultBottomList[expenseIdx].rc_amount_5) : 0);
      totalTotal = subTotalTotal + (locResultBottomList[expenseIdx].total_amount ? parseNumber(locResultBottomList[expenseIdx].total_amount) : 0);
    }

    if (transportIdx >= 0) {
      total = total + (locResultBottomList[transportIdx].amount ? parseNumber(locResultBottomList[transportIdx].amount) : 0);
      total_1 = total_1 + (locResultBottomList[transportIdx].rc_amount_1 ? parseNumber(locResultBottomList[transportIdx].rc_amount_1) : 0);
      total_2 = total_2 + (locResultBottomList[transportIdx].rc_amount_2 ? parseNumber(locResultBottomList[transportIdx].rc_amount_2) : 0);
      total_3 = total_3 + (locResultBottomList[transportIdx].rc_amount_3 ? parseNumber(locResultBottomList[transportIdx].rc_amount_3) : 0);
      total_4 = total_4 + (locResultBottomList[transportIdx].rc_amount_4 ? parseNumber(locResultBottomList[transportIdx].rc_amount_4) : 0);
      total_5 = total_5 + (locResultBottomList[transportIdx].rc_amount_5 ? parseNumber(locResultBottomList[transportIdx].rc_amount_5) : 0);
      totalTotal = totalTotal + (locResultBottomList[transportIdx].total_amount ? parseNumber(locResultBottomList[transportIdx].total_amount) : 0);
    }

    if (discountIdx >= 0) {
      total = total + (locResultBottomList[discountIdx].amount ? parseNumber(locResultBottomList[discountIdx].amount) : 0);
      total_1 = total_1 + (locResultBottomList[discountIdx].rc_amount_1 ? parseNumber(locResultBottomList[discountIdx].rc_amount_1) : 0);
      total_2 = total_2 + (locResultBottomList[discountIdx].rc_amount_2 ? parseNumber(locResultBottomList[discountIdx].rc_amount_2) : 0);
      total_3 = total_3 + (locResultBottomList[discountIdx].rc_amount_3 ? parseNumber(locResultBottomList[discountIdx].rc_amount_3) : 0);
      total_4 = total_4 + (locResultBottomList[discountIdx].rc_amount_4 ? parseNumber(locResultBottomList[discountIdx].rc_amount_4) : 0);
      total_5 = total_5 + (locResultBottomList[discountIdx].rc_amount_5 ? parseNumber(locResultBottomList[discountIdx].rc_amount_5) : 0);
      totalTotal = totalTotal + (locResultBottomList[discountIdx].total_amount ? parseNumber(locResultBottomList[discountIdx].total_amount) : 0);
    }

    if (benefitIdx >= 0) {
      total = total + (locResultBottomList[benefitIdx].amount ? parseNumber(locResultBottomList[benefitIdx].amount) : 0);
      total_1 = total_1 + (locResultBottomList[benefitIdx].rc_amount_1 ? parseNumber(locResultBottomList[benefitIdx].rc_amount_1) : 0);
      total_2 = total_2 + (locResultBottomList[benefitIdx].rc_amount_2 ? parseNumber(locResultBottomList[benefitIdx].rc_amount_2) : 0);
      total_3 = total_3 + (locResultBottomList[benefitIdx].rc_amount_3 ? parseNumber(locResultBottomList[benefitIdx].rc_amount_3) : 0);
      total_4 = total_4 + (locResultBottomList[benefitIdx].rc_amount_4 ? parseNumber(locResultBottomList[benefitIdx].rc_amount_4) : 0);
      total_5 = total_5 + (locResultBottomList[benefitIdx].rc_amount_5 ? parseNumber(locResultBottomList[benefitIdx].rc_amount_5) : 0);
      totalTotal = totalTotal + (locResultBottomList[benefitIdx].total_amount ? parseNumber(locResultBottomList[benefitIdx].total_amount) : 0);
    }

    locResultBottomList[subTotalIdx].amount = subTotal ? formatNumber(subTotal) : 0;
    if (locResultBottomList[subTotalIdx].rc_amount_1 !== undefined) {
      locResultBottomList[subTotalIdx].rc_amount_1 = subTotal_1 ? formatNumber(subTotal_1) : 0;
    }
    if (locResultBottomList[subTotalIdx].rc_amount_2 !== undefined) {
      locResultBottomList[subTotalIdx].rc_amount_2 = subTotal_2 ? formatNumber(subTotal_2) : 0;
    }
    if (locResultBottomList[subTotalIdx].rc_amount_3 !== undefined) {
      locResultBottomList[subTotalIdx].rc_amount_3 = subTotal_3 ? formatNumber(subTotal_3) : 0;
    }
    if (locResultBottomList[subTotalIdx].rc_amount_4 !== undefined) {
      locResultBottomList[subTotalIdx].rc_amount_4 = subTotal_4 ? formatNumber(subTotal_4) : 0;
    }
    if (locResultBottomList[subTotalIdx].rc_amount_5 !== undefined) {
      locResultBottomList[subTotalIdx].rc_amount_5 = subTotal_5 ? formatNumber(subTotal_5) : 0;
    }
    locResultBottomList[subTotalIdx].total_amount = subTotalTotal ? formatNumber(subTotalTotal) : 0;

    locResultBottomList[totalTotalIdx].amount = total ? formatNumber(total) : 0;

    if (locResultBottomList[totalTotalIdx].rc_amount_1 !== undefined) {
      locResultBottomList[totalTotalIdx].rc_amount_1 = total_1 ? formatNumber(total_1) : 0;
    }
    if (locResultBottomList[totalTotalIdx].rc_amount_2 !== undefined) {
      locResultBottomList[totalTotalIdx].rc_amount_2 = total_2 ? formatNumber(total_2) : 0;
    }
    if (locResultBottomList[totalTotalIdx].rc_amount_3 !== undefined) {
      locResultBottomList[totalTotalIdx].rc_amount_3 = total_3 ? formatNumber(total_3) : 0;
    }
    if (locResultBottomList[totalTotalIdx].rc_amount_4 !== undefined) {
      locResultBottomList[totalTotalIdx].rc_amount_4 = total_4 ? formatNumber(total_4) : 0;
    }
    if (locResultBottomList[totalTotalIdx].rc_amount_5 !== undefined) {
      locResultBottomList[totalTotalIdx].rc_amount_5 = total_5 ? formatNumber(total_5) : 0;
    }
    locResultBottomList[totalTotalIdx].total_amount = totalTotal ? formatNumber(totalTotal) : 0;

    setResultBottomList(locResultBottomList);
  }

  const generateColumnsFromResultList = (locResultList) => {

    let displayColumnIndexes = [];
    for (let i = 1; i <= 5; i++) {
      if (locResultList[0]['rc_qty_' + i] !== undefined) {
        displayColumnIndexes.push(i);
      }
    }

    let resultTopColumns = generateResultTopColumns(displayColumnIndexes);
    setResultTopColumns(resultTopColumns);
    setResultTopList([
      {
        id: 1,
        result_charge_id_1: locResultList[0]['result_charge_id_1'],
        result_charge_id_2: locResultList[0]['result_charge_id_2'],
        result_charge_id_3: locResultList[0]['result_charge_id_3'],
        result_charge_id_4: locResultList[0]['result_charge_id_4'],
        result_charge_id_5: locResultList[0]['result_charge_id_5']
      }
    ]);

    setResultColumns(generateResultColumns(displayColumnIndexes));
    setResultBottomColumns(generateResultColumns(displayColumnIndexes, true));
  }

  const generateResultTopColumns = (displayColumnIndexes) => {
    let columns = [];
    columns.push(
      {
        dataField: 'id',
        text: '',
        headerStyle: {width: '3rem'},
        formatter: (cell, row) => {
          return (
            <>
            </>
          );
        }
      }
    );
    columns.push(
      {
        dataField: 'opn_detail',
        text: '',
      }
    );
    columns.push(
      {
        dataField: 'qty',
        text: '',
        headerStyle: {width: '4rem'},
      }
    );
    columns.push(
      {
        dataField: 'unit',
        text: '',
        headerStyle: {width: '5rem'},
      }
    );
    columns.push(
      {
        dataField: 'price',
        text: '',
        headerStyle: {width: '5rem'},
      }
    );
    columns.push(
      {
        dataField: 'amount',
        text: '',
        headerStyle: {width: '5rem'},
      }
    );

    for (let i = 1; i <= 5; i++) {
      if (displayColumnIndexes.includes(i)) {
        columns.push(
          {
            dataField: 'result_charge_id_' + i,
            text: i + '回目',
            headerStyle: {width: '9rem', textAlign: 'center'},
            formatter: (cell, row) => {
              return (
                <>
                  <Button variant="secondary" onClick={e => onClickInvoice(cell)} disabled={!cell}>請求書</Button>
                  <Button variant="del" style={{marginTop: "0.2rem"}} onClick={e => onClickDeleteCol(cell)}
                          disabled={!cell}>
                    <FontAwesomeIcon icon={faMinusCircle} size="2x"/>
                    削除
                  </Button>
                </>
              );
            }
          }
        );
      }
    }

    columns.push(
      {
        dataField: 'btn',
        text: '累計',
        align: "center",
        headerStyle: {width: '9rem', textAlign: 'center'},
      }
    );

    return columns;

  }

  const generateResultColumns = (displayColumnIndexes, isBottom = false) => {

    let columns = [];
    columns.push(
      {
        dataField: 'id',
        text: '',
        align: 'center',
        headerStyle: {width: '3rem'},
        formatter: (cell, row) => {
          return isBottom ? '' : cell
        }
      }
    );
    columns.push(
      {
        dataField: 'opn_detail',
        text: !isBottom ? '施工箇所及び施工内容' : '',
        classes: 'tbl-col-break-spaces tbl-col-break-bold',
        align: isBottom ? 'right' : 'left',
        headerStyle: {textAlign: 'center'},
        editCellClasses: 'tbl-col-editing'
      }
    );
    columns.push(
      {
        dataField: 'qty',
        text: !isBottom ? '数量' : '',
        classes: 'tbl-col tbl-col-editable',
        align: 'right',
        headerStyle: {width: '4rem', textAlign: 'center'},
        editCellClasses: 'tbl-col-editing tbl-col-editing-number',
        formatter: (cell, row) => {
          return qtyFormatetr(cell, row, 1);
        }
      }
    );
    columns.push(
      {
        dataField: 'unit',
        text: !isBottom ? '単位' : '',
        classes: 'tbl-col tbl-col-editable',
        align: 'center',
        headerStyle: {width: '5rem', textAlign: 'center'},
        editCellClasses: 'tbl-col-editing',
      }
    );
    columns.push(
      {
        dataField: 'price',
        text: !isBottom ? '単価' : '',
        classes: 'tbl-col tbl-col-editable',
        align: 'right',
        editable: !isBottom,
        headerStyle: {width: '5rem', textAlign: 'center'},
        editCellClasses: !isBottom ? 'tbl-col-editing tbl-col-editing-number' : 'tbl-col-editing-number',
        formatter: amountFormatter,
      }
    );
    columns.push(
      {
        dataField: 'amount',
        text: !isBottom ? '金額' : '',
        classes: 'tbl-col tbl-col-primary',
        align: 'right',
        editable: isBottom,
        headerStyle: {width: '6rem', textAlign: 'center'},
        editCellClasses: !isBottom ? 'tbl-col-editing-number' : 'tbl-col-editing tbl-col-editing-number',
        formatter: amountFormatter,
      }
    );

    for (let i = 1; i <= 5; i++) {
      if (displayColumnIndexes.includes(i)) {
        columns.push(
          {
            dataField: 'rc_qty_' + i,
            text: !isBottom ? '数量' : '',
            headerClasses: 'result-col-qty-' + i,
            classes: 'tbl-col tbl-col-left-border result-col-qty-' + i + (!isBottom ? ' tbl-col-editable' : ''),
            align: 'right',
            editable: !isBottom,
            headerStyle: {width: '4rem', textAlign: 'center'},
            editCellClasses: 'tbl-col-editing tbl-col-editing-number',
            formatter: (cell, row) => {
              return qtyFormatetr(cell, row, 1);
            }
          }
        );
        columns.push(
          {
            dataField: 'rc_amount_' + i,
            text: !isBottom ? '金額' : '',
            headerClasses: 'result-col-amount-' + i,
            classes: 'tbl-col tbl-col-primary result-col-amount-' + i + ' tbl-col-editable',
            align: 'right',
            editable: true,
            headerStyle: {width: '6rem', textAlign: 'center'},
            editCellClasses: 'tbl-col-editing tbl-col-editing-number',
            formatter: amountFormatter,
          }
        );
      }
    }

    columns.push(
      {
        dataField: 'total_qty',
        text: !isBottom ? '数量' : '',
        classes: 'tbl-col tbl-col-primary tbl-col-left-border',
        align: 'right',
        editable: false,
        headerStyle: {width: '4rem', textAlign: 'center'},
        formatter: (cell, row) => {
          return qtyFormatetr(cell, row, 1);
        }
      }
    );
    columns.push(
      {
        dataField: 'total_amount',
        text: !isBottom ? '金額' : '',
        classes: 'tbl-col tbl-col-primary',
        align: 'right',
        editable: false,
        headerStyle: {width: '6rem', textAlign: 'center'},
        formatter: amountFormatter,
      }
    );

    return columns;
  }

  const onSearchVer = async (query) => {
    let searchCondition = {
      book_id: p_book_id
    }
    let res = await getAPI("tr_quot_search_view", searchCondition);
    let res2 = await getAPI("tr_result_search_view", searchCondition);
    let locVerResultList = [];
    if (res2.count) {
      locVerResultList = res2.results;
    }

    let locOptions = [];
    for (let i = 1; i <= 5; i++) {
      let suffix = "";
      if (res.results.filter(quot => quot.ver === i).length !== 0) {

        locVerResultList.map((result) => {
          if (i === result.ver) {
            suffix = " （登録済み）";
          }
        });

        locOptions.push({name: i + "版" + suffix, id: i});
      }
    }
    return locOptions;
  }

  const handleChangeVer = (value) => {
    setVerSelected(value);
    setVer(value.id);
    // hist.push("/result/" + bookId + "/" + value.id + "/");
  }

  const onClickInsert = async () => {
    startLoadingBtn("btn-insert");

    let obj = newDataResult();
    let resultObj = await postAPI("tr_result/", obj);

    let objList = newDataResultCharge(resultObj.result_id);
    let resultChargeList = await processArray(objList);

    setResultId(resultObj.result_id);
    setCount(count + 1);

    finishLoadingBtn("btn-insert");
    success("登録完了");
  }

  const onClickUpdate = async () => {
    startLoadingBtn("btn-update");

    let obj = newDataResult();
    obj['result_id'] = resultId;
    let resultObj = await putAPI("tr_result/" + resultId + "/", obj);

    let objList = newDataResultCharge(resultId);
    let resultChargeList = await processArray(objList);

    setCount(count + 1);

    finishLoadingBtn("btn-update");
    success("登録完了");
  }

  const onClickInvoice = async (result_charge_id) => {
    if (!result_charge_id) {
      return false;
    }
    let searchCondition = {
      result_charge_id: result_charge_id,
    }
    const res = await getAPI("tr_inv_search_view", searchCondition);
    if (res.count === 0) {
      hist.push("/inv_dtl/new?result_charge_id=" + result_charge_id);
    } else {
      hist.push("/inv_dtl/" + res.results[0].inv_id);
    }
  }

  const onClickDownload = async () => {
    startLoadingBtn("btn-download", "secondary");
    let searchCondition = {
      result_id: resultId
    }

    await downloadAPI("download/result", searchCondition);
    finishLoadingBtn("btn-download");
  }

  async function processArray(array) {
    let resultChargeObjList = [];
    for (const obj of array) {
      let resultObj = null;
      if (!obj.result_charge_id) {
        resultObj = await postAPI("tr_result_charge/", obj);
      } else {
        resultObj = await putAPI("tr_result_charge/" + obj.result_charge_id + "/", obj);
      }
      resultChargeObjList.push(resultObj);
    }
    return resultChargeObjList;
  }

  const newDataResult = () => {
    let obj = {
      book_id: bookId,
      ver: ver,
      order_no: orderNo,
      update_pg: 'ResultDtl',
    };
    resultList.forEach((row, i) => {
      obj['opn_detail_' + (i + 1)] = toNull(row.opn_detail);
      obj['qty_' + (i + 1)] = toNull(parseNumber(row.qty));
      obj['unit_' + (i + 1)] = toNull(row.unit);
      obj['price_' + (i + 1)] = toNull(parseNumber(row.price));
      obj['amount_' + (i + 1)] = toNull(parseNumber(row.amount));
      obj['total_qty_' + (i + 1)] = toNull(parseNumber(row.total_qty));
      obj['total_amount_' + (i + 1)] = toNull(parseNumber(row.total_amount));
    });
    obj['order_no'] = toNull(orderNo);
    if (expenseIdx >= 0) {
      obj['expense'] = toNull(parseNumber(resultBottomList[expenseIdx].amount));
      obj['total_expense'] = toNull(parseNumber(resultBottomList[expenseIdx].total_amount));
    }
    if (transportIdx >= 0) {
      obj['transport'] = toNull(parseNumber(resultBottomList[transportIdx].amount));
      obj['total_transport'] = toNull(parseNumber(resultBottomList[transportIdx].total_amount));
    }
    if (discountIdx >= 0) {
      obj['discount'] = toNull(parseNumber(resultBottomList[discountIdx].amount));
      obj['total_discount'] = toNull(parseNumber(resultBottomList[discountIdx].total_amount));
    }
    if (benefitIdx >= 0) {
      obj['benefits'] = toNull(parseNumber(resultBottomList[benefitIdx].amount));
      obj['total_benefits'] = toNull(parseNumber(resultBottomList[benefitIdx].total_amount));
    }
    if (subTotalIdx >= 0) {
      obj['sub_total'] = toNull(parseNumber(resultBottomList[subTotalIdx].amount));
      obj['total_sub_total'] = toNull(parseNumber(resultBottomList[subTotalIdx].total_amount));
    }
    if (totalTotalIdx >= 0) {
      obj['total'] = toNull(parseNumber(resultBottomList[totalTotalIdx].amount));
      obj['total_total'] = toNull(parseNumber(resultBottomList[totalTotalIdx].total_amount));
    }

    return obj;
  }

  const newDataResultCharge = (result_id) => {
    let objList = [];
    for (let i = 1; i <= 5; i++) {
      let obj = {
        result_id: result_id,
        charge_no: i,
        result_charge_id: resultList.length > 0 ? toNull(resultList[0]['result_charge_id_' + i]) : null,
        update_pg: 'ResultDtl',
      };
      let dataExists = false;
      resultList.forEach((row, rowIdx) => {
        let rc_qty = toNull(parseNumber(row['rc_qty_' + i]));
        let rc_amount = toNull(parseNumber(row['rc_amount_' + i]));
        obj['qty_' + (rowIdx + 1)] = rc_qty;
        obj['amount_' + (rowIdx + 1)] = rc_amount;
        if (rc_qty || rc_amount) {
          dataExists = true;
        }
      });
      if (expenseIdx >= 0) {
        obj['expense'] = toNull(parseNumber(resultBottomList[expenseIdx]['rc_amount_' + i]));
      }
      if (transportIdx >= 0) {
        obj['transport'] = toNull(parseNumber(resultBottomList[transportIdx]['rc_amount_' + i]));
      }
      if (discountIdx >= 0) {
        obj['discount'] = toNull(parseNumber(resultBottomList[discountIdx]['rc_amount_' + i]));
      }
      if (subTotalIdx >= 0) {
        obj['sub_total'] = toNull(parseNumber(resultBottomList[subTotalIdx]['rc_amount_' + i]));
      }
      if (benefitIdx >= 0) {
        obj['benefits'] = toNull(parseNumber(resultBottomList[benefitIdx]['rc_amount_' + i]));
      }
      if (totalTotalIdx >= 0) {
        obj['total'] = toNull(parseNumber(resultBottomList[totalTotalIdx]['rc_amount_' + i]));
      }

      if (dataExists) {
        objList.push(obj);
      }
    }
    return objList;
  }

  return (
    <>
      <div className="body-dtl">
        <AppNav/>
        <BookNav bookId={bookId} ver={ver} quotId={quotId}/>

        <Container className="container-main-detail">

          <Row className="h3">
            <Col>
              <h3 style={{paddingTop: ".7rem"}}>
                出来高調書
              </h3>
            </Col>
            <Col>
              <div className="form-inline float-right">
                <Button variant="secondary" id="btn-download" onClick={onClickDownload} style={{width: "120px"}}>
                  <span>
                  <FontAwesomeIcon icon={faFile} size="lg"/>出来高調書
                  </span>
                </Button>&nbsp;
                <AsyncSelect
                  className="async-select async-select-ver"
                  cacheOptions
                  defaultOptions
                  value={verSelected}
                  getOptionLabel={e => e.name}
                  getOptionValue={e => e.id}
                  loadOptions={onSearchVer}
                  onChange={handleChangeVer}
                  placeholder=""
                />&nbsp;
                {!resultId &&
                  <Button id="btn-insert" variant="primary" onClick={onClickInsert}><span><FontAwesomeIcon icon={faPen}
                                                                                                           size="lg"/>登録</span></Button>}
                {resultId &&
                  <Button id="btn-update" variant="primary" onClick={onClickUpdate}><span><FontAwesomeIcon icon={faPen}
                                                                                                           size="lg"/>登録</span></Button>}
              </div>
            </Col>
          </Row>

          <Card className="card-secondary w-80 mx-auto mb-0">

            <Form.Group as={Row}>
              <Form.Label column sm="1">工事番号</Form.Label>
              <Col sm="5">
                <Form.Control plaintext readOnly value={bookNo}/>
              </Col>
              <Form.Label column sm="1"></Form.Label>
              <Col sm="5">
                <Form.Control
                  id="orderNo"
                  type="text"
                  value={orderNo}
                  onChange={e => setOrderNo(e.target.value)}
                  placeholder="注文番号など"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="1">工事名称</Form.Label>
              <Col sm="4">
                <Form.Control plaintext readOnly value={bookName}/>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="1">工事場所</Form.Label>
              <Col sm="4">
                <Form.Control plaintext readOnly value={pref + city + address}/>
              </Col>
            </Form.Group>

          </Card>

          {/* *** 一覧 *** */}
          <Card className="card-thirdly w-80 mx-auto">

            <Row>
              <Col sm="11" className="tbl-result-header tbl-result-main">
                <BootstrapTable
                  keyField="id"
                  data={resultTopList}
                  columns={resultTopColumns}
                />
              </Col>
              <Col sm="1" className="tbl-result-body tbl-result-btn">
                <Button variant="add" style={{width: "7rem"}} onClick={onClickAddCol}>
                  <FontAwesomeIcon icon={faPlusCircle} size="2x"/>
                  請求
                </Button>
              </Col>
            </Row>
            <Row>
              <Col sm="11" className="tbl-result-body tbl-result-main">
                <BootstrapTable
                  keyField="id"
                  classes="dm-datatable"
                  striped={true}
                  data={resultList}
                  columns={resultColumns}
                  cellEdit={cellEditFactory({
                    mode: 'click',
                    blurToSave: true,
                    onStartEdit: (row, column, rowIndex, columnIndex) => {
                      $("input[class*=edit-text]")[0].select();
                    },
                    afterSaveCell: (oldValue, newValue, row, column) => {

                      let dataField = column.dataField;
                      newValue = toSingleByte(newValue);

                      //見積もり・数量の変更時
                      if (dataField === "qty") {
                        let qty = newValue ? parseNumber(newValue) : 0;
                        let price = row.price ? parseNumber(row.price) : 0;
                        let amount = null;

                        amount = price * qty;
                        row['amount'] = amount ? formatNumber(amount) : "";
                      }

                      //見積もり・単価の変更時
                      if (dataField === "price") {

                        let qty = row['qty'] ? parseNumber(row['qty']) : 0;
                        let price = newValue ? parseNumber(newValue) : 0;
                        let amount = null;

                        amount = price * qty;
                        row['amount'] = amount ? formatNumber(amount) : "";

                        let i = 1;
                        while (row['rc_qty_' + i] !== undefined) {
                          qty = row['rc_qty_' + i] ? parseNumber(row['rc_qty_' + i]) : 0;
                          amount = price * qty;
                          row['rc_amount_' + i] = amount ? formatNumber(amount) : "";　//x回目・金額のセット
                          i++;
                        }
                      }

                      //x回目・数量の変更時
                      if (dataField.startsWith("rc_qty")) {
                        let index = dataField.substring(dataField.lastIndexOf("_") + 1);
                        let price = row.price ? parseNumber(row.price) : 0;
                        let amount = null;

                        amount = price * newValue;
                        row['rc_amount_' + index] = amount ? formatNumber(amount) : "";　//x回目・金額のセット
                      }

                      //累計・数量の計算
                      let total_qty = 0;
                      for (let i = 1; i < 5; i++) {
                        if (row['rc_qty_' + i]) {
                          total_qty += Number(row['rc_qty_' + i]);
                        }
                      }

                      row['total_qty'] = total_qty === 0 ? "" : formatNumber(total_qty);

                      //累計・金額の計算
                      let total_amount = 0;
                      for (let i = 1; i < 5; i++) {
                        if (row['rc_amount_' + i]) {
                          total_amount += parseNumber(row['rc_amount_' + i]);
                        }
                      }
                      row['total_amount'] = total_amount === 0 ? "" : formatNumber(total_amount);

                      calcResultBottomList();
                    }
                  })}
                />
              </Col>
              <Col sm="1" className="tbl-result-body tbl-result-btn">
                <BootstrapTable
                  keyField="id"
                  data={resultList}
                  columns={resultRightColumns}
                />
              </Col>
            </Row>
            <Row>
              <Col sm="11" className="tbl-result-body tbl-result-main">
                <BootstrapTable
                  keyField="id"
                  classes="dm-datatable"
                  striped={true}
                  data={resultBottomList}
                  columns={resultBottomColumns}
                  cellEdit={cellEditFactory({
                    mode: 'click',
                    blurToSave: true,
                    onStartEdit: (row, column, rowIndex, columnIndex) => {
                      $("input[class*=edit-text]")[0].select();
                    },
                    afterSaveCell: (oldValue, newValue, row, column) => {

                      let dataField = column.dataField;
                      newValue = toSingleByte(newValue);

                      //x回目・数量の変更時
                      if (dataField.startsWith("rc_qty")) {
                        let index = dataField.substring(dataField.lastIndexOf("_") + 1);
                        let price = row.price ? parseNumber(row.price) : 0;
                        let amount = null;

                        amount = price * newValue;
                        row['rc_amount_' + index] = amount ? formatNumber(amount) : "";　//x回目・金額のセット
                      }

                      //累計・数量の計算
                      let total_qty = 0;
                      for (let i = 1; i < 5; i++) {
                        if (row['rc_qty_' + i]) {
                          total_qty += Number(row['rc_qty_' + i]);
                        }
                      }
                      row['total_qty'] = total_qty === 0 ? "" : formatNumber(total_qty);

                      //累計・金額の計算
                      let total_amount = 0;
                      for (let i = 1; i < 5; i++) {
                        if (row['rc_amount_' + i]) {
                          total_amount += parseNumber(row['rc_amount_' + i]);
                        }
                      }
                      row['total_amount'] = total_amount === 0 ? "" : formatNumber(total_amount);

                      calcResultBottomList();
                    }
                  })}
                />
              </Col>
              <Col sm="1">
              </Col>
            </Row>
          </Card>

        </Container>
      </div>
      <Toast/>
    </>
  );
}

export default withRouter(ResultDtl);