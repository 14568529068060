import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import {addField} from "../CMUtil";
import {getAPI} from "../APIUtil";

export const BookNav = ({bookId}) => {

  const [chId, setChId] = useState("");
  useEffect(() => {

    const update = async () => {
      let locChId = "";

      let condition = {};
      addField(condition, "book_id", bookId);
      let res = await getAPI("tr_ch_search_view", condition);
      if (res.count > 0) {
        locChId = res.results[0].ch_id;
      }
      setChId(locChId);
    }
    update();

  }, []);


  return (
    <div className="h1 clearfix">
      <h1 className="float-left">
        工事物件詳細
      </h1>
      <Button as={Link} to={"/book/" + bookId} variant="link-menu" className="float-left">
        工事台帳
      </Button>
      {bookId &&
        <Button as={Link} to={"/ch/" + bookId} variant="link-menu" className="float-left">
          出来高調書
        </Button>
      }
    </div>
  )
};

export default BookNav;