import React, {useEffect, useImperativeHandle, useRef, useState} from 'react';
import {Link, useHistory, useLocation, withRouter} from "react-router-dom";
import {Card, Col, Container, Form, Row, Table} from "react-bootstrap";
import {deleteAPI, getAPI, getSpAPI, postAPI, putAPI} from "../APIUtil";
import Button from "react-bootstrap/Button";
import AppNav from "./AppNav";
import 'react-toastify/dist/ReactToastify.css';
import Toast, {success} from "../Toast";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt, faPen, faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import {
  toNull,
  dateformatDB,
  addField,
  parseNumber, formatNumber, dateformat, setFocusCalendar, onSearchCo, onSearchBook, dateformatJPNYYYYMMDD
} from "../CMUtil";
import {ChDtlRowPo} from "./ChDtlRowPo";
import {ChDtlRowInv} from "./ChDtlRowInv";
import {ChDtlRowLineTtl} from "./ChDtlRowLineTtl";
import BookNav from "./BookNav";

export const ChDtl = ({match: {params: {p_book_id}}}) => {

  const hist = useHistory();
  const location = useLocation();

  const [chId, setChId] = useState("");

  const [bookId, setBookId] = useState(p_book_id);
  const [bookName, setBookName] = useState("");
  const [constName, setConstName] = useState("");

  const [coId, setCoId] = useState("");
  const [coNo, setCoNo] = useState("");
  const [coTitle, setCoTitle] = useState("");
  const [updateCnt, setUpdateCnt] = useState(0);

  const [dtlList, setDtlList] = useState([]);
  const dtlListRef = useRef([]);

  const [invList, setInvList] = useState([]);
  const invListRef = useRef([]);

  const [lineTtlList, setLineTtlList] = useState([]);
  const lineTtlListRef = useRef([]);

  //初期化
  useEffect(() => {

    const update = async () => {

      let condition = null;
      let res = null;
      let locDtlList = [];
      let locInvList = [];

      let bookObj = await getAPI("tr_book/" + bookId + "/");
      setBookName(bookObj.book_name);
      if (bookObj.const) {
        setConstName(bookObj.const.name);
      }
      if (bookObj.co) {
        setCoNo(bookObj.co.co_no);
        setCoTitle(bookObj.co.co_title);
      }

      condition = {};
      addField(condition, "book_id", bookId);
      res = await getAPI("tr_ch_search_view", condition);

      let locChId = null;
      if (res.count > 0) {
        locChId = res.results[0].ch_id;
      }

      if (locChId) {

        // CH EXISTS
        condition = {};
        addField(condition, "ch_id", locChId);
        res = await getAPI("tr_ch_dtl_search_view", condition);
        res.results.map((chDtl) => {
          locDtlList.push({
            ch_dtl_id: chDtl.ch_dtl_id,
            co_dtl_id: chDtl.co_dtl ? chDtl.co_dtl.co_dtl_id : null,
            line_no: chDtl.line_no,
            item_id: chDtl.item ? chDtl.item.item_id : null,
            item_name: chDtl.item_name,
            po_qty: chDtl.po_qty,
            unit: chDtl.unit,
            price: chDtl.price,
            amount: chDtl.amount,
            line_ttl_qty: chDtl.line_ttl_qty,
            line_ttl_amount: chDtl.line_ttl_amount
          })
        });
        while (locDtlList.length < 10) {
          locDtlList.push({
            ch_dtl_id: null,
            co_dtl_id: null,
            line_no: locDtlList.length + 1,
            item_id: null,
            item_name: null,
            po_qty: null,
            unit: null,
            price: null,
            amount: null
          });
        }

        condition = {};
        addField(condition, "ch_id", locChId);
        res = await getAPI("tr_inv_search_view", condition);
        for (let inv of res.results) {
          let invDtlRes = await getAPI("tr_inv_dtl_search_view", {inv_id: inv.inv_id});
          let locInvDtlList = [];
          for (let i = 0; i < locDtlList.length; i++) {
            let dbs = invDtlRes.results.filter(invDtl => {
              return (i + 1) === invDtl.line_no;
            });
            locInvDtlList.push({
              inv_dtl_id: dbs.length > 0 ? dbs[0].inv_dtl_id : null,
              line_no: i + 1,
              price: locDtlList[i].price,
              qty: dbs.length ? dbs[0].qty : null,
              amount: dbs.length ? dbs[0].amount : null
            });
          }
          locInvList.push({
            inv_id: inv.inv_id,
            ch_id: inv.ch_id,
            ch_no: locInvList.length + 1,
            inv_dtl_list: locInvDtlList
          });
        }

      } else {

        //NO CH EXISTS
        let bookObj = await getAPI("tr_book/" + bookId + "/");
        if (bookObj.co) {

          //CO EXISTS
          condition = {};
          addField(condition, "co_id", bookObj.co.co_id);
          res = await getAPI("tr_co_dtl_search_view", condition);
          res.results.map((coDtl) => {
            locDtlList.push({
              ch_dtl_id: null,
              co_dtl_id: coDtl.co_dtl_id,
              line_no: locDtlList.length + 1,
              item_id: coDtl.item ? coDtl.item.item_id : null,
              item_name: coDtl.item_name,
              po_qty: coDtl.qty,
              unit: coDtl.unit,
              price: coDtl.price,
              amount: coDtl.amount,
              line_ttl_qty: null,
              line_ttl_amount: null
            })
          });
        }
        while (locDtlList.length < 10) {
          locDtlList.push({
            ch_dtl_id: null,
            co_dtl_id: null,
            line_no: locDtlList.length + 1,
            item_id: null,
            item_name: null,
            po_qty: null,
            unit: null,
            price: null,
            amount: null
          });
        }
      }

      while (locInvList.length < 3) {
        let locInvDtlList = [];
        while (locInvDtlList.length < locDtlList.length) {
          locInvDtlList.push({
            inv_dtl_id: null,
            line_no: locInvDtlList.length + 1,
            price: locDtlList[locInvDtlList.length].price,
            qty: null,
            amount: null
          });
        }

        locInvList.push({
          inv_id: null,
          ch_id: null,
          ch_no: locInvList.length + 1,
          inv_dtl_list: locInvDtlList
        });
      }

      for (let i = 0; i < locDtlList.length; i++) {
        dtlListRef.current[i] = React.createRef();
      }
      for (let i = 0; i < locInvList.length; i++) {
        invListRef.current[i] = React.createRef();
      }
      for (let i = 0; i < locDtlList.length; i++) {
        lineTtlListRef.current[i] = React.createRef();
      }

      setChId(locChId);
      setDtlList(locDtlList);
      setInvList(locInvList);
    }
    update();

  }, []);

  const onClickInsert = async () => {
    let obj = newCh();
    obj = await postAPI("tr_ch/", obj);

    let locChId = obj.ch_id;

    for (let i = 0; i < dtlListRef.current.length; i++) {
      let dtlObj = dtlListRef.current[i].current.getStates();
      if (!dtlObj.item_name || !dtlObj.amount) {
        continue;
      }
      dtlObj['line_ttl_qty'] = lineTtlListRef.current[i].current.getStates().line_ttl_qty;
      dtlObj['line_ttl_amount'] = lineTtlListRef.current[i].current.getStates().line_ttl_amount;
      dtlObj = newChDtl(locChId, dtlObj);
      dtlObj = await postAPI("tr_ch_dtl/", dtlObj);
      dtlObj['item_id'] = dtlObj.item ? dtlObj.item.item_id : null;
      dtlObj['co_dtl_id'] = dtlObj.co_dtl ? dtlObj.co_dtl.co_dtl_id : null;
      dtlListRef.current[i].current.setData(dtlObj)
    }

    //INSRET INV.
    await updateInv(locChId);

    setChId(locChId);
    hist.push("/ch/" + obj.book.book_id);
    success("登録完了");
  }

  const onClickUpdate = async () => {

    let locChId = chId;

    let chObj = newCh();
    let obj = await putAPI("tr_ch/" + locChId + "/", chObj);

    let updateDtlIdList = [];
    for (let i = 0; i < dtlListRef.current.length; i++) {
      let dtlObj = dtlListRef.current[i].current.getStates();
      if (!dtlObj.item_name || !dtlObj.amount) {
        continue;
      }
      dtlObj['line_ttl_qty'] = lineTtlListRef.current[i].current.getStates().line_ttl_qty;
      dtlObj['line_ttl_amount'] = lineTtlListRef.current[i].current.getStates().line_ttl_amount;
      dtlObj = newChDtl(locChId, dtlObj);
      if (!dtlObj.ch_dtl_id) {
        dtlObj = await postAPI("tr_ch_dtl/", dtlObj);
      } else {
        dtlObj = await putAPI("tr_ch_dtl/" + dtlObj.ch_dtl_id, dtlObj);
      }
      dtlObj['item_id'] = dtlObj.item ? dtlObj.item.item_id : null;
      dtlObj['co_dtl_id'] = dtlObj.co_dtl ? dtlObj.co_dtl.co_dtl_id : null;
      dtlListRef.current[i].current.setData(dtlObj)
      updateDtlIdList.push(dtlObj.ch_dtl_id);
    }

    let condition = {};
    addField(condition, "ch_id", locChId);
    let res = await getAPI("tr_ch_dtl_search_view", condition);
    for (const dbDtl of res.results) {
      if (!updateDtlIdList.includes(dbDtl.ch_dtl_id)) {
        await deleteAPI("tr_ch_dtl/" + dbDtl.ch_dtl_id + "/", obj);
      }
    }

    //INSRET UPDATE INV.
    await updateInv(locChId);

    success("更新完了");
    setUpdateCnt(updateCnt + 1);
  }

  const updateInv = async (locChId) => {

    // UPDATE INV
    let locChNo = 0;
    let updateInvDtlIdList = [];
    for (let inv of invListRef.current) {

      let locInv = inv.current.getStates();
      let hasData = false;
      for (let invDtl of locInv.inv_dtl_list) {
        if (invDtl.qty) {
          hasData = true;
        }
      }
      if (!hasData) {
        continue;
      }
      locChNo++;

      let locInvId = locInv.inv_id;
      if (!locInvId) {
        let obj = await newInv(locChId, locChNo);
        obj = await postAPI("tr_inv/", obj);
        locInvId = obj.inv_id;
      }

      for (let i = 0; i < locInv.inv_dtl_list.length; i++) {
        let dtlObj = locInv.inv_dtl_list[i];
        let poDtl = dtlListRef.current[i].current.getStates();

        if (!dtlObj.qty || !poDtl.price || !poDtl.item_name) {
          continue;
        }

        let obj = newInvDtl(locInvId, dtlObj, poDtl, i + 1);
        if (!obj.inv_dtl_id) {
          obj = await postAPI("tr_inv_dtl/", obj);
        } else {
          obj = await putAPI("tr_inv_dtl/" + obj.inv_dtl_id, obj);
        }
        obj['item_id'] = obj.item ? obj.item.item_id : null;
        obj['co_dtl_id'] = obj.co_dtl ? obj.co_dtl.co_dtl_id : null;
        // dtlListRef.current[i].current.setData(obj)
        updateInvDtlIdList.push(obj.ch_dtl_id);
      }
    }
  }

  const onClickDelete = async () => {

    let condition = {};
    addField(condition, "co_id", coId);
    res = await getAPI("tr_co_dtl_search_view", condition);

    let dtlList = res.results;
    for (const dtl of dtlList) {
      await deleteAPI("tr_co_dtl/" + dtl.co_dtl_id + "/", {'co_dtl_id': dtl.co_dtl_id});
    }

    let res = await deleteAPI("tr_co/" + coId + "/", {"co_id": coId});
    success("削除完了");
    hist.push("/co_list/");
  }

  const newCh = () => {
    return {
      ch_id: toNull(chId),
      book_id: toNull(bookId),
      create_pg: 'ChDtl',
      update_pg: 'ChDtl',
    };
  }

  const newChDtl = (chId, data) => {
    return {
      ch_dtl_id: toNull(data.ch_dtl_id),
      ch_id: toNull(chId),
      co_dtl_id: toNull(data.co_dtl_id),
      line_no: toNull(data.line_no),
      item_id: toNull(data.item_id),
      item_name: toNull(data.item_name),
      po_qty: toNull(parseNumber(data.po_qty)),
      unit: toNull(data.unit),
      price: toNull(parseNumber(data.price)),
      amount: toNull(parseNumber(data.amount)),
      line_ttl_qty: toNull(parseNumber(data.line_ttl_qty)),
      line_ttl_amount: toNull(parseNumber(data.line_ttl_amount)),
      create_pg: 'ChDtl',
      update_pg: 'ChDtl',
    };
  }

  const newInv = async (chId, chNo) => {

    let chObj = await getAPI("tr_ch/" + chId + "/");
    let bookObj = await getAPI("tr_book/" + chObj.book.book_id + "/");

    return {
      inv_no: bookObj.book_no + "-" + chNo,
      inv_title: toNull(bookObj.book_name),
      inv_date: dateformatDB(new Date()),
      ch_id: toNull(chId),
      ch_no: toNull(chNo),
      const_id: bookObj.const ? bookObj.const.const_id : null,
      co_id: bookObj.co ? bookObj.co.co_id : null,
      book_id: toNull(bookObj.book_id),
      create_pg: 'ChDtl',
      update_pg: 'ChDtl'
    };
  }

  const newInvDtl = (invId, dtlObj, poDtl, lineNo) => {
    return {
      inv_dtl_id: toNull(dtlObj.inv_dtl_id),
      inv_id: toNull(invId),
      line_no: toNull(lineNo),
      item_id: toNull(poDtl.item_id),
      item_name: toNull(poDtl.item_name),
      qty: toNull(parseNumber(dtlObj.qty)),
      unit: toNull(poDtl.unit),
      price: toNull(parseNumber(poDtl.price)),
      amount: toNull(parseNumber(dtlObj.amount)),
      create_pg: 'ChDtl',
      update_pg: 'ChDtl',
    };
  }


  const totalCalcHandler = () => {

  }

  const lineTotalCalcHandler = (lineNo) => {

    let locLineTtlQty = 0;
    let locLineTtlAmount = 0;
    for (const row of invListRef.current) {
      let locQty = row.current.getStates().inv_dtl_list[lineNo - 1].qty;
      locLineTtlQty += locQty ? parseNumber(locQty) : 0;

      let locAmount = row.current.getStates().inv_dtl_list[lineNo - 1].amount;
      locLineTtlAmount += locAmount ? parseNumber(locAmount) : 0;

    }

    lineTtlListRef.current[lineNo - 1].current.setData({
      line_no: lineNo,
      line_ttl_qty: formatNumber(locLineTtlQty),
      line_ttl_amount: formatNumber(locLineTtlAmount)
    });
  }

  const deleteRowHandler = (rowIndex) => {

  }

  return (
    <>
      <div className="body-dtl body-dtl-const">
        <AppNav/>
        <BookNav bookId={bookId}/>

        <Container className="container-main-detail">

          <Row className="h3">
            <Col>
              <h3 style={{paddingTop: ".7rem"}}>
                出来高一覧
              </h3>
            </Col>
            <Col sm="1" className="text-right">
              {coId &&
                <Button variant="secondary" as={Link} to={"/book/?co_id=" + coId}>工事台帳</Button>
              }
            </Col>
            <Col sm="1" className="text-right">
              {!chId &&
                <Button variant="primary" onClick={onClickInsert}><FontAwesomeIcon icon={faPen} size="lg"/>登録</Button>}
              {chId &&
                <Button variant="primary" onClick={onClickUpdate}><FontAwesomeIcon icon={faPen} size="lg"/>登録</Button>}
            </Col>
          </Row>

          <Card className="card-secondary w-80 mx-auto mb-0">

            <Form.Group as={Row}>
              <Form.Label column sm="1">施工業者名</Form.Label>
              <Col sm="4">
                <Form.Control
                  id="const_name"
                  type="text"
                  value={constName}
                  readOnly={true}
                />
              </Col>
              <Col sm="1"></Col>
              <Form.Label column sm="1">受注番号</Form.Label>
              <Col sm="4" className="input-group">
                <Form.Control
                  id="co_no"
                  type="text"
                  value={coNo}
                  readOnly={true}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="1">現場</Form.Label>
              <Col sm="4">
                <Form.Control
                  id="const_name"
                  type="text"
                  value={bookName}
                  readOnly={true}
                />
              </Col>
              <Col sm="1"></Col>
              <Form.Label column sm="1">受注件名</Form.Label>
              <Col sm="4">
                <Form.Control
                  id="co_title"
                  type="text"
                  value={coTitle}
                  readOnly={true}
                />
              </Col>
            </Form.Group>

            <Container className="container-main-list">
              <Table>
                <tbody>
                <tr>
                  <td style={{width: "*", border: "none"}}>
                    <Row className="dtl_list_header">
                      <Col sm="6" className="dtl-head-col">詳細</Col>
                      <Col sm="1" className="dtl-head-col">数量</Col>
                      <Col sm="1" className="dtl-head-col">単位</Col>
                      <Col sm="2" className="dtl-head-col">価格</Col>
                      <Col sm="2" className="dtl-head-col">金額</Col>
                    </Row>
                    {dtlList.map((dtl, index) => (
                      <ChDtlRowPo key={index}
                                  ref={dtlListRef.current[index]}
                                  index={index}
                                  dtl={dtl}
                                  totalCalcHandler={totalCalcHandler}
                                  deleteRowHandler={deleteRowHandler}
                                  isLastRow={dtlList.length === index + 1}
                      />
                    ))}
                  </td>
                  <td style={{width: "30%", padding: "0px", border: "none"}}>
                    <Table>
                      <tbody>
                      <tr>
                        {invList.map((inv, invIndex) => (
                          <ChDtlRowInv key={"inv_" + invIndex}
                                       ref={invListRef.current[invIndex]}
                                       inv={inv}
                                       inv_id={inv.inv_id}
                                       inv_index={invIndex}
                                       dtlList={dtlList}
                                       lineTotalCalcHandler={lineTotalCalcHandler}
                          />
                        ))}
                      </tr>
                      </tbody>
                    </Table>
                  </td>
                  <td style={{width: "15%", border: "none"}}>
                    <Row className="dtl_list_header">
                      <Col sm="5" className="dtl-head-col">総数</Col>
                      <Col sm="7" className="dtl-head-col">総額</Col>
                    </Row>
                    {dtlList.map((dtl, index) => (
                      <ChDtlRowLineTtl key={index}
                                       ref={lineTtlListRef.current[index]}
                                       index={index}
                                       dtl={dtl}
                                       totalCalcHandler={totalCalcHandler}
                                       deleteRowHandler={deleteRowHandler}
                                       isLastRow={dtlList.length === index + 1}
                      />
                    ))}
                  </td>
                </tr>
                </tbody>
              </Table>

            </Container>
            <Row>
              <Col sm="9" className="justify-content-md-center">
              </Col>
              <Col sm="3" className="text-right">
                <Button onClick={lineTotalCalcHandler}>
                  <FontAwesomeIcon icon={faPlusCircle} size="lg"/>
                  行追加
                </Button>
              </Col>
            </Row>
          </Card>
          <Row>
            <Col>
              {coId && <Button variant="danger" onClick={onClickDelete}>削除</Button>}
            </Col>
          </Row>
        </Container>

      </div>
      <Toast/>
    </>
  );
}

export default withRouter(ChDtl);