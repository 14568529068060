import React, {useEffect, useRef, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import Button from 'react-bootstrap/Button';
import {withRouter} from 'react-router';
import {downloadAPI, getAPI} from "../APIUtil";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import AppNav from "./AppNav";
import MdDatatable, {InitialLoading, sortCaret} from "../MdDataTable";
import {Card, Col, Container, Form, InputGroup, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBuilding, faCaretDown, faFileDownload, faSearch} from "@fortawesome/free-solid-svg-icons";
import {addField, checkKey, formatZip, nvl, onSearchPref, useDebounce} from "../CMUtil";
import AsyncSelect from "react-select/async";

export const SuppList = () => {

  const history = useHistory();
  const iniSearch = useRef(true);

  const [loadingCss, setLoadingCss] = useState("loading_panel_init");

  const [loading, setLoading] = useState(true);
  const [locPage, setLocPage] = useState(null);
  const [locSizePerPage, setLocSizePerPage] = useState(null);
  const [locTotalSize, setLocTotalSize] = useState(null);
  const [locSortField, setLocSortField] = useState(null);
  const [locSortOrder, setLocSortOrder] = useState(null);

  const [name, setName] = useState("");
  const debouncedName = useDebounce(name, 500, setLocPage);
  const [zip, setZip] = useState("");
  const debouncedZip = useDebounce(zip, 500, setLocPage);
  const [pref, setPref] = useState("");
  const debouncedPref = useDebounce(pref, 500, setLocPage);
  const [city, setCity] = useState("");
  const debouncedCity = useDebounce(city, 500, setLocPage);
  const [address, setAddress] = useState("");
  const debouncedAddress = useDebounce(address, 500, setLocPage);
  const [rep, setRep] = useState("");
  const debouncedRep = useDebounce(rep, 500, setLocPage);
  const [note, setNote] = useState("");
  const debouncedNote = useDebounce(note, 500, setLocPage);

  const [listData, setListData] = useState([]);

  const [prefSelected, setPrefSelected] = useState([]);

  // 項目定義
  const columns = [
    {
      dataField: 'supp_id',
      text: 'ID',
      hidden: true,
      sort: false,
      headerStyle: {width: '80px'}
    },
    {
      dataField: 'name',
      text: '仕入れ業者',
      classes: 'tbl-col link',
      sort: true,
      headerStyle: {width: '300px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      },
      formatter: (cell, row) => (
        <div><a onClick={() => onClickLinkToDetail(row.supp_id)}>{cell}</a></div>
      )
    },
    {
      dataField: 'zip',
      text: '住所',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '300px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      },
      formatter: (cell, row) => (
        <span>{nvl(row.zip) + " " + nvl(row.pref) + nvl(row.city) + nvl(row.address)}</span>
      )
    },
    {
      dataField: 'tel',
      text: '電話',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '90px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'fax',
      text: 'ＦＡＸ',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '90px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'rep',
      text: '代表者',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '90px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
  ];

  // 初期化
  useEffect(() => {
    const init = async () => {
      if (!history.location.state) {
        setLocPage(1);
      } else if (!history.location.state.pages) {
        setLocPage(1);
      } else if (!history.location.state.pages.filters) {
        setLocPage(1);
      } else if (Object.keys(history.location.state.pages.filters).length === 0) {
        setLocPage(1);
      } else {
        setLocPage(history.location.state.pages.page);
        setLocSizePerPage(history.location.state.pages.sizePerPage);
        setLocSortField(history.location.state.pages.sortField);
        setLocSortOrder(history.location.state.pages.sortOrder);
      }
    }
    init();
  }, []);

  // 検索処理
  useEffect(() => {
    if (iniSearch.current) {
      iniSearch.current = false;
      return;
    }
    const update = async () => {

      setLoading(true);
      let condition = {};
      addField(condition, "page", locPage);
      addField(condition, "size_per_page", locSizePerPage);
      addField(condition, "sort_field", locSortField);
      addField(condition, "sort_order", locSortOrder);
      addField(condition, "name", name);
      addField(condition, "zip", zip);
      addField(condition, "pref", pref);
      addField(condition, "city", city);
      addField(condition, "address", address);
      addField(condition, "rep", rep);
      addField(condition, "note", note);

      const res = await getAPI("ms_supp_search_view", condition);
      setListData(res);
      setLoading(false);
      setLoadingCss("loading_panel_fadeout");

    }
    update();
  }, [
    locPage,
    locSizePerPage,
    locSortField,
    locSortOrder,
    debouncedName,
    debouncedZip,
    debouncedPref,
    debouncedCity,
    debouncedAddress,
    debouncedRep,
    debouncedNote
  ]);

  const onTableChange = async (type, {page, sizePerPage, filters, sortField, sortOrder, cellEdit}) => {
    if ("pagination" === type) {
      setLocPage(page);
    }
    if ("sizePerPage" === type) {
      setLocSizePerPage(sizePerPage);
    }
    if ("sort" === type) {
      setLocSortField(sortField);
      setLocSortOrder(sortOrder);
    }
  }

  const handleChangePref = (value) => {
    setPrefSelected(value ? value : "");
    setPref(value ? value.id : "");
  }

  const onClickLinkToDetail = (supp_id) => {
    history.push("/supp/" + supp_id);
  }

  const onClickDownload = async () => {
    let searchCondition = {
      page: locPage,
      size_per_page: locSizePerPage,
      sort_field: locSortField,
      sort_order: locSortOrder,
      name: name,
      zip: zip,
      pref: pref,
      city: city,
      address: address,
      rep: rep,
      note: note
    }

    await downloadAPI("download/supp_list", searchCondition);
  }

  const onClickClear = () => {
    setName("");
    setZip("");
    setPref("");
    setPrefSelected(null);
    setCity("");
    setAddress("");
  }

  return (
    <>
      <AppNav/>
      <Row className="h1">
        <Col sm={8}>
          <h1>
            仕入れ業者
          </h1>
        </Col>
        <Col sm="4" className="text-right">
          <Button variant="secondary" onClick={onClickDownload}>
            <FontAwesomeIcon icon={faFileDownload} size="lg"/>
            設計会社
          </Button>
        </Col>
      </Row>
      <Card className="card-search">
        <Container className="container-main-search">
          <Form.Group as={Row}>
            <Form.Label column sm="1">仕入れ業者</Form.Label>
            <Col sm="4">
              <Form.Control type="text" value={name} onChange={e => setName(e.target.value)}/>
            </Col>
            <Col sm="6"></Col>
            <Col sm="1">
              <Button variant="clear" onClick={e => onClickClear()}>条件クリア</Button>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="1">住所</Form.Label>
            <Col sm="2" className="input-group">
              <InputGroup.Text className="input-group-text-cm" style={{fontWeight: "700", backgroundColor: "#ffffff"}}>〒</InputGroup.Text>
              <Form.Control id="zip" type="text"
                            value={zip} style={{textAlign: "center"}}
                            onKeyPress={e => checkKey(e, /[0-9\-]/)}
                            onChange={e => setZip(e.target.value)}
                            onBlur={e => formatZip(e.target.value, setZip)}
              />
            </Col>
            <Col sm="2">
              <AsyncSelect sm="2"
                           className="async-select"
                           cacheOptions
                           defaultOptions
                           isClearable
                           value={prefSelected}
                           getOptionLabel={e => e.name}
                           getOptionValue={e => e.id}
                           loadOptions={onSearchPref}
                           onChange={handleChangePref}
                           placeholder=""
              />
            </Col>
            <Col sm="6" className="input-group">
              <Form.Control type="text" value={city}
                            style={{borderRadius: "0"}}
                            placeholder="市区町村"
                            onChange={e => setCity(e.target.value)}/>
              <Form.Control type="text" value={address}
                            style={{width: "20%", borderTopLeftRadius: "0", borderBottomLeftRadius: "0"}}
                            placeholder="住所"
                            onChange={e => setAddress(e.target.value)}/>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="1">代表者</Form.Label>
            <Col sm="4">
              <Form.Control type="text" value={rep} onChange={e => setRep(e.target.value)}/>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="1">備考</Form.Label>
            <Col sm="10">
              <Form.Control type="text" value={note} onChange={e => setNote(e.target.value)}/>
            </Col>
          </Form.Group>
        </Container>
      </Card>

      <Container className="container-main-list">
        {/* *** 一覧 *** */}
        <InitialLoading loadingCss={loadingCss}/>
        <MdDatatable
          keyField="supp_id"
          listData={listData}
          columns={columns}
          loading={loading}
          onTableChange={onTableChange}
          page={locPage}
          sizePerPage={locSizePerPage}
          totalSize={locTotalSize}
          sortField={locSortField}
          sortOrder={locSortOrder}
          showPagenation={true}
          linkToAdd="/supp/"
        />
      </Container>
    </>
  );
}

export default withRouter(SuppList);