import React, {useEffect, useRef, useState} from 'react';
import {withRouter} from "react-router";
import AppNav from "./AppNav";
import 'react-toastify/dist/ReactToastify.css';
import Toast, {success} from "../Toast";
import {getAPI, postAPI, putAPI} from "../APIUtil";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import {Card, Col, Container, InputGroup, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt, faChevronLeft, faChevronRight, faPen} from "@fortawesome/free-solid-svg-icons";
import {
  dateformat,
  dateformatDB,
  dateformatJPN,
  dateformatYYYYMMDD,
  formatNumber, formatTimeHHMM,
  parseDate,
  parseNumber,
  setFocusCalendar,
  timeformatHHMM,
  toNull,
  toSingleByte
} from "../CMUtil";

export const ReportDtl = ({match: {params: {p_opn_user_id, p_month}}}) => {

  const [month, setMonth] = useState();
  const [opnUserId, setOpnUserId] = useState();

  const [dispYear, setDispYear] = useState();
  const [dispMonth, setDispMonth] = useState();

  const [updateType, setUpdateType] = useState("");

  const iniMonth = useRef(true);

  const repColumns = [
    {
      dataField: 'id',
      text: '',
      headerStyle: {width: '50px'},
      hidden: true,
      sort: false,
    },
    {
      dataField: 'opn_date',
      text: '日付',
      classes: 'tbl-col',
      align: 'center',
      headerStyle: {width: '200px'},
      editable: false,
      formatter: (cell, row) => (
        dateformatJPN(cell)
      )
    },
    {
      dataField: 'dep_time',
      text: '出発',
      classes: 'tbl-col',
      align: 'center',
      headerStyle: {width: '100px', textAlign: "center"},
      editCellClasses: 'tbl-col-editing tbl-col-editing-time'
    },
    {
      dataField: 'mtg_start_time',
      text: '始業',
      classes: 'tbl-col',
      align: 'center',
      headerStyle: {width: '100px', textAlign: "center"},
      editCellClasses: 'tbl-col-editing tbl-col-editing-time'
    },
    {
      dataField: 'opn_end_time',
      text: '終業',
      classes: 'tbl-col',
      align: 'center',
      headerStyle: {width: '100px', textAlign: "center"},
      editCellClasses: 'tbl-col-editing tbl-col-editing-time'
    },
    {
      dataField: 'arr_time',
      text: '帰社',
      classes: 'tbl-col',
      align: 'center',
      headerStyle: {width: '100px', textAlign: "center"},
      editCellClasses: 'tbl-col-editing tbl-col-editing-time'
    },
    {
      dataField: 'note',
      text: '備考',
      classes: 'tbl-col',
      headerStyle: {textAlign: "center"},
      editCellClasses: 'tbl-col-editing'
    }
  ];
  const [repList, setRepList] = useState([]);

  //初期化
  useEffect(() => {
    const update = async () => {

      setMonth(p_month);
      setOpnUserId(p_opn_user_id);

    }
    update();
  }, []);

  useEffect(() => {

    if (iniMonth.current) {
      iniMonth.current = false;
      return;
    }

    const update = async () => {

      let currentDate = parseDate(month);
      currentDate.setDate(1);
      currentDate.setHours(0);
      currentDate.setMinutes(0);
      currentDate.setSeconds(0);
      let opnDateFrom = new Date(currentDate.getTime());
      currentDate.setMonth(currentDate.getMonth() + 1);
      let opnDateTo = new Date(currentDate.getTime());

      let searchCondition = {
        opn_date_from: dateformatDB(opnDateFrom),
        opn_date_to: dateformatDB(opnDateTo),
        opn_user_id: opnUserId
      }
      let obj = await getAPI("tr_book_opn_rep_search_view", searchCondition);

      let locYear = month.substring(0, 4);
      let locMonth = month.substring(4, 6);
      let date = new Date(locYear, locMonth, 0).getDate();

      let locRepList = [];
      for (let i = 1; i <= date; i++) {

        let currentDate = new Date();
        currentDate.setFullYear(locYear);
        currentDate.setMonth(locMonth - 1);
        currentDate.setDate(i);

        let report = obj.results.filter(rep => {
          return rep.opn_date === dateformat(currentDate);
        });

        locRepList.push({
          id: i,
          opn_date: currentDate,
          dep_time: report.length ? timeformatHHMM(report[0].dep_time) : "",
          mtg_start_time: report.length ? timeformatHHMM(report[0].mtg_start_time) : "",
          opn_end_time: report.length ? timeformatHHMM(report[0].opn_end_time) : "",
          arr_time: report.length ? timeformatHHMM(report[0].arr_time) : "",
          note: report.length ? report[0].note : ""
        });

      }

      setDispYear(locYear);
      setDispMonth(locMonth);
      setRepList(locRepList);
    }
    update();
  }, [month, opnUserId]);

  const onClickInsert = async () => {
    // let obj = newData();
    // obj = await postAPI("ms_design/", obj);
    // setDesignId(obj.design_id);
    // hist.push("/design/" + obj.design_id);
    success("登録完了");
  }

  const onClickUpdate = async () => {

    repList.map(async (report) => {

      let reportId = dateformatYYYYMMDD(report.opn_date) + opnUserId;

      let param = {
        report_id: reportId,
        book_id: null,
        schedule_id: null,
        opn_user_id: toNull(opnUserId),
        opn_date: dateformat(report.opn_date),
        opn_id: null,
        dep_time: toNull(report.dep_time),
        mtg_start_time: toNull(report.mtg_start_time),
        opn_end_time: toNull(report.opn_end_time),
        arr_time: toNull(report.arr_time),
        note: toNull(report.note),
        create_user_id: 'USER001',
        create_pg: 'ReportDtl',
        update_user_id: 'USER001',
        update_pg: 'ReportDtl'
      };
      let obj = await postAPI("tr_report/", param);

    });

    success("更新完了");
  }

  const onClickPrevMonth = () => {
    let locYear = month.substring(0, 4);
    let locMonth = month.substring(4, 6);
    let locDate = new Date();
    locDate.setFullYear(locYear);
    locDate.setMonth(parseInt(locMonth) - 1);
    locDate.setMonth(locDate.getMonth() - 1);
    setMonth(dateformatYYYYMMDD(locDate).substr(0, 6));
  }

  const onClickNextMonth = () => {
    let locYear = month.substring(0, 4);
    let locMonth = month.substring(4, 6);
    let locDate = new Date();
    locDate.setFullYear(locYear);
    locDate.setMonth(parseInt(locMonth) - 1);
    locDate.setMonth(locDate.getMonth() + 1);
    setMonth(dateformatYYYYMMDD(locDate).substr(0, 6));
  }

  return (
    <>
      <div className="body-dtl body-dtl-const">
        <AppNav/>
        <div className="h1">
          <h1 className="float-left align-items-end">
            作業日報
          </h1>
          <div className="float-none" style={{height: "30px"}}>&nbsp;</div>
        </div>

        <Container className="container-main-detail">
          <Row className="h3">
            <Col>
              <h3 style={{paddingTop: ".7rem"}}>
                作業日報
              </h3>
            </Col>
            <Col className="text-right">
              <Button variant="primary" onClick={onClickUpdate}><FontAwesomeIcon icon={faPen} size="lg"/>登録</Button>
            </Col>
          </Row>
          <Row>
            <Col sm="4"></Col>
            <Col sm="1" className="text-center">
              <Button variant="link" style={{paddingRight: "0"}} onClick={onClickPrevMonth}>
                <FontAwesomeIcon icon={faChevronLeft} size="2x"/>
              </Button>
            </Col>
            <Col sm="2" className="text-center" style={{fontWeight: "700"}}>
              {dispYear}&nbsp;年&nbsp;
              <span style={{fontSize: "1.2rem"}}>
                  {dispMonth}&nbsp;月
                  </span>
            </Col>
            <Col sm="1" className="text-center">
              <Button variant="link" style={{paddingRight: "0"}} onClick={onClickNextMonth}>
                <FontAwesomeIcon icon={faChevronRight} size="2x"/>
              </Button>
            </Col>
            <Col sm="4"></Col>
          </Row>

          <Card className="card-thirdly w-80 mx-auto">
            <BootstrapTable
              keyField="id"
              data={repList}
              classes="dm-datatable"
              striped={true}
              columns={repColumns}
              cellEdit={cellEditFactory({
                mode: 'click',
                blurToSave: true,
                afterSaveCell: (oldValue, newValue, row, column) => {

                  let locRepList = [];
                  for (let i = 0; i < repList.length; i++) {
                    if (row.id === repList[i].id) {
                      if (column.dataField === 'dep_time' ||
                        column.dataField === 'mtg_start_time' ||
                        column.dataField === 'opn_end_time' ||
                        column.dataField === 'arr_time') {
                        repList[i][column.dataField] = formatTimeHHMM(newValue);
                      }
                    }
                    locRepList.push(repList[i]);
                  }
                  setRepList(locRepList);


                }

              })}
            />
          </Card>
        </Container>
      </div>
      <Toast/>
    </>
  );
}

export default withRouter(ReportDtl);