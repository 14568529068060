import React, {useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import { PDFViewer } from '@react-pdf/renderer';
import {Card, Container} from "react-bootstrap";
import QuotPDF from "../doc/QuotPDF"

export const PDFViewTest = ({match: {params: {pdf_id}}}) => {
    return (
    <>
      <Container>
        <Card>
          <PDFViewer height={1200}>
            <QuotPDF quot_id={pdf_id}/>
          </PDFViewer>
        </Card>
      </Container>
    </>
  );
}

export default withRouter(PDFViewTest);