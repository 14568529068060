import React from 'react';
import {Switch, Route} from 'react-router-dom';
import {ConstList} from "./ConstList";
import {ConstDtl} from "./ConstDtl";
import {DesignList} from "./DesignList";
import {DesignDtl} from "./DesignDtl";
import {UserList} from "./UserList";
import {UserDtl} from "./UserDtl";
import {CodeDtl} from "./CodeDtl";
import {CodeList} from "./CodeList";
import {BookList} from "./BookList";
import {BookDtl} from "./BookDtl";
import {Map} from "./Map";
import {PDFViewTest} from "./PDFViewTest";
import {QuotDtl} from "./QuotDtl";
import {ReportDtl} from "./ReportDtl";
import {DocList} from "./DocList";
import {DocDtl} from "./DocDtl";
import {Cal} from "./Cal";
import {BookOpn} from "./BookOpn";
import {ResultDtl} from "./ResultDtl";
import {Schedule} from "./Schedule";
import {CoList} from "./CoList";
import {CoDtl} from "./CoDtl";
import {QuotList} from "./QuotList";
import {PoDtl} from "./PoDtl";
import {RcvList} from "./RcvList";
import {RcvDtl} from "./RcvDtl";
import {ItemList} from "./ItemList";
import {ItemDtl} from "./ItemDtl";
import {StkList} from "./StkList";
import {StkInOutList} from "./StkInOutList";
import {StkInOutDtl} from "./StkInOutDtl";
import {ComDtl} from "./ComDtl";
import {PoOpnList} from "./PoOpnList";
import {PoItemList} from "./PoItemList";
import {WarrantyRegistration} from "./WarrantyRegistration";
import {BookStatus} from "./BookStatus";
import {CalWeek} from "./CalWeek";
import {SuppList} from "./SuppList";
import {SuppDtl} from "./SuppDtl";
import {InvList} from "./InvList";
import {InvDtl} from "./InvDtl";
import {ChDtl} from "./ChDtl";

export const RoutePaths = {
  root: {
    user_list: '/user_list/',
    user_ini: '/user/',
    user_dtl: '/user/:user_id',
    code_list: '/code_list/',
    code_ini: '/code/',
    code_dtl: '/code/:p_id/',
    const_list: '/const_list/',
    const_ini: '/const/',
    const_dtl: '/const/:const_id',
    design_list: '/design_list/',
    design_ini: '/design/',
    design_dtl: '/design/:design_id',
    supp_list: '/supp_list/',
    supp_ini: '/supp/',
    supp_dtl: '/supp/:supp_id',
    item_list: '/item_list/',
    item_ini: '/item/',
    item_dtl: '/item/:item_id',
    stk_list: '/stk_list/',
    stk_in_out_list: '/stk_in_out_list/',
    stk_in_out_list_by_item: '/stk_in_out_list/:item_id',
    stk_in_out_ini: '/stk_in_out/',
    stk_in_out_dtl: '/stk_in_out/:in_out_id',
    com_dtl: '/com/',
    map: '/map/',
    quot_list: '/quot_list/',
    quot_ini: '/quot/',
    quot_dtl: '/quot/:quot_id',
    pdf_view_test: '/pdf_view_test/:pdf_id',
    co_list: '/co_list/',
    co_ini: '/co/',
    co_dtl: '/co/:co_id',
    inv_list: '/inv_list/',
    inv_ini: '/inv/',
    inv_dtl: '/inv/:inv_id',
    po_opn_list: '/po_opn_list/',
    po_opn_ini: '/po_opn/',
    po_opn_dtl: '/po_opn/:po_id',
    po_item_list: '/po_item_list/',
    po_item_ini: '/po_item/',
    po_item_dtl: '/po_item/:po_id',
    rcv_list: '/rcv_list/',
    rcv_ini: '/rcv_ini/:po_id',
    rcv_dtl: '/rcv/:rcv_id',
    book_list: '/book_list/',
    book_ini: '/book/',
    book_dtl: '/book/:p_book_id',
    book_opn: '/book_opn/:book_opn_id',
    book_status: '/book_status/',
    ch_dtl: '/ch/:p_book_id',
    report_dtl: '/report_dtl/:p_opn_user_id/:p_month',
    result_dtl: '/result/:p_book_id/:p_ver',
    doc_list: '/doc_list/',
    doc_ini: '/doc/',
    doc_dtl: '/doc/:p_doc_id',
    doc_const_category: '/doc/:p_book_id/:p_category',
    cal: '/cal/:p_month',
    cal_week: '/cal_week/:p_opn_date',
    schedule: '/schedule/:p_opn_date',
    warranty_registration_dtl: '/warranty_registration/:book_id',
  },
};

export const PageRoutesAuthenticated = () => {

  return (
    <Switch>
      <Route exact path={RoutePaths.root.user_list} component={UserList}/>
      <Route exact path={RoutePaths.root.user_ini} component={UserDtl}/>
      <Route exact path={RoutePaths.root.user_dtl} component={UserDtl}/>
      <Route exact path={RoutePaths.root.item_list} component={ItemList}/>
      <Route exact path={RoutePaths.root.item_ini} component={ItemDtl}/>
      <Route exact path={RoutePaths.root.item_dtl} component={ItemDtl}/>

      <Route exact path={RoutePaths.root.stk_list} component={StkList}/>
      <Route exact path={RoutePaths.root.stk_in_out_list} component={StkInOutList}/>
      <Route exact path={RoutePaths.root.stk_in_out_list_by_item} component={StkInOutList}/>
      <Route exact path={RoutePaths.root.stk_in_out_ini} component={StkInOutDtl}/>
      <Route exact path={RoutePaths.root.stk_in_out_dtl} component={StkInOutDtl}/>
      <Route exact path={RoutePaths.root.const_list} component={ConstList}/>
      <Route exact path={RoutePaths.root.const_ini} component={ConstDtl}/>
      <Route exact path={RoutePaths.root.const_dtl} component={ConstDtl}/>
      <Route exact path={RoutePaths.root.design_list} component={DesignList}/>
      <Route exact path={RoutePaths.root.design_ini} component={DesignDtl}/>
      <Route exact path={RoutePaths.root.design_dtl} component={DesignDtl}/>
      <Route exact path={RoutePaths.root.supp_list} component={SuppList}/>
      <Route exact path={RoutePaths.root.supp_ini} component={SuppDtl}/>
      <Route exact path={RoutePaths.root.supp_dtl} component={SuppDtl}/>
      <Route exact path={RoutePaths.root.code_list} component={CodeList}/>
      <Route exact path={RoutePaths.root.code_ini} component={CodeDtl}/>
      <Route exact path={RoutePaths.root.code_dtl} component={CodeDtl}/>
      <Route exact path={RoutePaths.root.com_dtl} component={ComDtl}/>
      <Route exact path={RoutePaths.root.book_list} component={BookList}/>
      <Route exact path={RoutePaths.root.book_ini} component={BookDtl}/>
      <Route exact path={RoutePaths.root.book_dtl} component={BookDtl}/>
      <Route exact path={RoutePaths.root.book_opn} component={BookOpn}/>
      <Route exact path={RoutePaths.root.book_status} component={BookStatus}/>
      <Route exact path={RoutePaths.root.ch_dtl} component={ChDtl}/>
      <Route exact path={RoutePaths.root.report_dtl} component={ReportDtl}/>
      <Route exact path={RoutePaths.root.map} component={Map}/>
      <Route exact path={RoutePaths.root.quot_list} component={QuotList}/>
      <Route exact path={RoutePaths.root.quot_ini} component={QuotDtl}/>
      <Route exact path={RoutePaths.root.quot_dtl} component={QuotDtl}/>
      <Route exact path={RoutePaths.root.pdf_view_test} component={PDFViewTest}/>
      <Route exact path={RoutePaths.root.co_list} component={CoList}/>
      <Route exact path={RoutePaths.root.co_ini} component={CoDtl}/>
      <Route exact path={RoutePaths.root.co_dtl} component={CoDtl}/>
      <Route exact path={RoutePaths.root.inv_list} component={InvList}/>
      <Route exact path={RoutePaths.root.inv_ini} component={InvDtl}/>
      <Route exact path={RoutePaths.root.inv_dtl} component={InvDtl}/>
      <Route exact path={RoutePaths.root.po_opn_list} component={PoOpnList}/>
      <Route exact path={RoutePaths.root.po_opn_ini} component={PoDtl}/>
      <Route exact path={RoutePaths.root.po_opn_dtl} component={PoDtl}/>
      <Route exact path={RoutePaths.root.po_item_list} component={PoItemList}/>
      <Route exact path={RoutePaths.root.po_item_ini} component={PoDtl}/>
      <Route exact path={RoutePaths.root.po_item_dtl} component={PoDtl}/>
      <Route exact path={RoutePaths.root.rcv_list} component={RcvList}/>
      <Route exact path={RoutePaths.root.rcv_ini} component={RcvDtl}/>
      <Route exact path={RoutePaths.root.rcv_dtl} component={RcvDtl}/>
      <Route exact path={RoutePaths.root.result_dtl} component={ResultDtl}/>
      <Route exact path={RoutePaths.root.doc_list} component={DocList}/>
      <Route exact path={RoutePaths.root.doc_ini} component={DocDtl}/>
      <Route exact path={RoutePaths.root.doc_dtl} component={DocDtl}/>
      <Route exact path={RoutePaths.root.doc_const_category} component={DocDtl}/>
      <Route exact path={RoutePaths.root.cal} component={Cal}/>
      <Route exact path={RoutePaths.root.cal_week} component={CalWeek}/>
      <Route exact path={RoutePaths.root.schedule} component={Schedule}/>
      <Route exact path={RoutePaths.root.warranty_registration_dtl} component={WarrantyRegistration}/>
    </Switch>
  );
};

export default PageRoutesAuthenticated;
