import React, {useEffect, useImperativeHandle, useRef, useState} from "react";
import {Col, Form, Row} from "react-bootstrap";
import {ChDtlRowInvDtl} from "./ChDtlRowInvDtl";
import {nvl} from "../CMUtil";

export const ChDtlRowInv = React.forwardRef((props, ref) => {

  const [chNo, setChNo] = useState("");
  const [invId, setInvId] = useState("");

  const [invDtlList, setInvDtlList] = useState([]);
  const invDtlListRef = useRef([]);

  useEffect(() => {

    const update = async () => {
      let locInv = props.inv;
      setChNo(props.inv_index + 1);
      setInvId(nvl(locInv.inv_id));
      let locInvDtlList = locInv.inv_dtl_list;
      for (let i = 0; i < locInvDtlList.length; i++) {
        invDtlListRef.current[i] = React.createRef();
      }

      setInvDtlList(locInvDtlList);
    }
    update();
  }, []);

  useImperativeHandle(ref, () => ({
    getStates: () => {
      let invDtlList = [];
      for (let row of invDtlListRef.current) {
        invDtlList.push(row.current.getStates());
      }
      return {
        inv_id: invId,
        inv_dtl_list: invDtlList
      };
    },
    setData: (data) => {
      setInvId(nvl(data.inv_id));
      for (let i = 0; i < data.inv_dtl_list.length; i++) {
        invDtlListRef.current[i].setData(data.inv_dtl_list[i]);
      }
    }
  }), [
    chNo
  ]);

  const lineTotalCalcHandler = (lineNo) => {
    props.lineTotalCalcHandler(lineNo);
  }

  return (
    <td style={{border: "0px"}}>
      <Row className="dtl_list_header">
        <Col className="dtl-head-col">{chNo + "回目"}</Col>
      </Row>
      {invDtlList.map((invDtl, lineIndex) => (
        <ChDtlRowInvDtl
          key={"inv_index_" + lineIndex}
          ref={invDtlListRef.current[lineIndex]}
          chNo={lineIndex + 1}
          invDtl={invDtl}
          lineTotalCalcHandler={lineTotalCalcHandler}
          isLastRow={invDtlList.length === lineIndex + 1}
        />
      ))}
    </td>
  )

});

export default React.memo(ChDtlRowInv);