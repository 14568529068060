import React, {useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import {PoList} from "./PoList";

export const PoItemList = () => {

  useEffect(() => {
  }, []);

  return (
    <>
      <PoList poType={"資材"}/>
    </>
  );
}

export default withRouter(PoItemList);