import React, {useEffect, useRef, useState} from 'react';
import {Link, useHistory, withRouter} from "react-router-dom";
import Button from 'react-bootstrap/Button';
import {getAPI} from "../APIUtil";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import AppNav from "./AppNav";
import {Card, Col, Container, Form, InputGroup, Row} from "react-bootstrap";
import {
  addField,
  useDebounce,
  dateformat,
  dateformatJPNYYYYMMDD,
  parseDate,
  formatNumber,
  setFocusCalendar
} from "../CMUtil";
import MdDatatable, {InitialLoading, sortCaret} from "../MdDataTable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons";
import DatePicker, {registerLocale} from "react-datepicker"
import ja from "date-fns/locale/ja";
import AsyncSelect from "react-select/async";

registerLocale('ja', ja)

export const PoList = ({poType}) => {

  const history = useHistory();
  const iniSearch = useRef(true);

  const [loadingCss, setLoadingCss] = useState("loading_panel_init");

  const [loading, setLoading] = useState(true);
  const [locPage, setLocPage] = useState(null);
  const [locSizePerPage, setLocSizePerPage] = useState(50);
  const [locTotalSize, setLocTotalSize] = useState(null);
  const [locSortField, setLocSortField] = useState(null);
  const [locSortOrder, setLocSortOrder] = useState(null);

  const [suppId, setSuppId] = useState("");
  const debouncedSuppId = useDebounce(suppId, 500, setLocPage);
  const [name, setName] = useState("");
  const debouncedName = useDebounce(name, 500, setLocPage);
  const [poNo, setPoNo] = useState("");
  const debouncedPoNo = useDebounce(poNo, 500, setLocPage);
  const [poDateFrom, setPoDateFrom] = useState("");
  const debouncedPoDateFrom = useDebounce(poDateFrom, 500, setLocPage);
  const [poDateTo, setPoDateTo] = useState("");
  const debouncedPoDateTo = useDebounce(poDateTo, 500, setLocPage);

  const [suppSelected, setSuppSelected] = useState([]);

  const [listData, setListData] = useState([]);

  // 項目定義
  const columns = [
    {
      dataField: "po_no",
      text: '発注番号',
      classes: 'tbl-col link',
      sort: true,
      headerStyle: {width: '180px'},
      formatter: (cell, row) => (
        <div><a onClick={() => onClickLinkToDetail(row.po_id)}>{cell}</a></div>
      )
    },
    {
      dataField: 'supp.name',
      text: '仕入れ業者',
      sort: true,
      classes: 'tbl-col',
      headerStyle: {width: '180px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'po_date',
      text: '発注日',
      classes: 'tbl-col',
      sort: true,
      headerStyle: {width: '180px'},
      formatter: (cell, row) => (
        <span>{dateformatJPNYYYYMMDD(parseDate(cell))}</span>
      ),
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'po_title',
      text: '件名',
      classes: 'tbl-col',
      sort: false,
      headerStyle: {width: '200px'},
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
    {
      dataField: 'ttl_amount',
      text: '金額',
      classes: 'tbl-col ',
      sort: false,
      align: 'right',
      headerStyle: {width: '100px'},
      formatter: (cell, row) => (
        <span>{formatNumber(cell)}</span>
      ),
      sortCaret: (order, column) => {
        return sortCaret(order, column);
      }
    },
  ];

  // 初期化
  useEffect(() => {
    const init = async () => {
      setLocPage(1);
    }
    init();
  }, []);

  // 検索処理
  useEffect(() => {
    if (iniSearch.current) {
      iniSearch.current = false;
      return;
    }
    const update = async () => {

      let sortField = locSortField;
      let sortOrder = locSortOrder;
      if (!sortField && !sortOrder) {
        //デフォルト　着工日降順
        sortField = null;
        sortOrder = null;
      }
      let condition = {};
      addField(condition, "page", locPage);
      addField(condition, "size_per_page", locSizePerPage);
      addField(condition, "sort_field", sortField);
      addField(condition, "sort_order", sortOrder);
      addField(condition, "po_no", poNo);
      addField(condition, "supp_id", suppId);
      addField(condition, "po_name", name);
      addField(condition, "po_date_from", dateformat(poDateFrom));
      addField(condition, "po_date_to", dateformat(poDateTo));
      addField(condition, "po_type", poType);

      const res = await getAPI("tr_po_search_view", condition);
      setListData(res);
      setLoading(false);
      setLoadingCss("loading_panel_fadeout");
    }
    update();
  }, [
    locPage,
    locSizePerPage,
    locSortField,
    locSortOrder,
    debouncedSuppId,
    debouncedPoNo,
    debouncedName,
    debouncedPoDateFrom,
    debouncedPoDateTo
  ]);

  const onTableChange = (type, {page, sizePerPage, filters, sortField, sortOrder, cellEdit}) => {
    if ("pagination" === type) {
      setLocPage(page);
    }
    if ("sizePerPage" === type) {
      setLocSizePerPage(sizePerPage);
    }
    if ("sort" === type) {
      setLocSortField(sortField);
      setLocSortOrder(sortOrder);
    }
  }

  const onClickLinkToDetail = (po_id) => {
    history.push((poType === "資材" ? "/po_item/" : "/po_opn/") + po_id);
  }

  const handleChangeSupp = (value) => {
    setSuppSelected(value ? value : "");
    setSuppId(value ? value.id : "");
  }

  const onSearchSupp = async (query) => {
    let searchCondition = {
      name: query
    }
    const res = await getAPI("ms_supp_typeahead_view", searchCondition);

    let options = [];
    res.results.map((result) => {
      options.push({name: result.name, id: result.supp_id});
    });
    return options;
  }

  return (
    <>
      <AppNav/>
      <Row className="h1">
        <Col sm={8}>
          <h1>
            {poType === "資材" ? "資材発注一覧" : "作業発注一覧"}
          </h1>
        </Col>
      </Row>

      <Card className="card-search">
        <Container className="container-main-search">
          <Form.Group as={Row}>
            <Form.Label column sm="1">仕入れ先</Form.Label>
            <Col sm="4">
              <AsyncSelect
                isClearable
                className="async-select"
                cacheOptions
                defaultOptions
                value={suppSelected}
                getOptionLabel={e => e.name}
                getOptionValue={e => e.id}
                loadOptions={onSearchSupp}
                onChange={handleChangeSupp}
                placeholder=""
              />
            </Col>
            <Col sm="1"></Col>
            <Form.Label column sm="1">発注番号</Form.Label>
            <Col sm="4">
              <Form.Control type="text" value={poNo} onChange={e => setPoNo(e.target.value)}/>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="1">件名</Form.Label>
            <Col sm="4">
              <Form.Control type="text" value={name} onChange={e => setName(e.target.value)}/>
            </Col>
            <Col sm="1"></Col>
            <Form.Label column sm="1">発注日</Form.Label>
            <Col sm="4" className="input-group">
              <DatePicker id="poDateFrom" selected={poDateFrom}
                          onChange={(date) => setPoDateFrom(date)} dateFormat="yyyy年MM月dd日" locale="ja"/>
              <Button as={Link} variant="link" to={"/"} style={{paddingRight: "0"}}
                      onClick={e => setFocusCalendar(e, 'poDateFrom')}>
                <FontAwesomeIcon icon={faCalendarAlt} size="2x"/>
              </Button>
              <InputGroup.Text style={{border: "0px", backgroundColor: "#ffffff"}}>～</InputGroup.Text>
              <DatePicker id="poDateTo" selected={poDateTo} onChange={(date) => setPoDateTo(date)}
                          dateFormat="yyyy年MM月dd日" locale="ja"/>
              <Button as={Link} variant="link" to={"/"} style={{paddingRight: "0"}}
                      onClick={e => setFocusCalendar(e, 'poDateTo')}>
                <FontAwesomeIcon icon={faCalendarAlt} size="2x"/>
              </Button>
            </Col>
          </Form.Group>
        </Container>
      </Card>

      <Container className="container-main-list">
        <InitialLoading loadingCss={loadingCss}/>
        <MdDatatable
          keyField="po_id"
          listData={listData}
          columns={columns}
          loading={loading}
          onTableChange={onTableChange}
          page={locPage}
          sizePerPage={locSizePerPage}
          totalSize={locTotalSize}
          sortField={locSortField}
          sortOrder={locSortOrder}
          showPagenation={true}
          linkToAdd={(poType === "資材" ? "/po_item/" : "/po_opn/")}
        />
      </Container>
    </>
  );
}

export default withRouter(PoList);