import React, {useEffect, useRef, useState} from 'react';
import {withRouter} from "react-router";
import AppNav from "./AppNav";
import 'react-toastify/dist/ReactToastify.css';
import Toast, {success} from "../Toast";
import Button from "react-bootstrap/Button";
import {Col, Container, Form, Modal, Row} from "react-bootstrap";
import {dateformatDB, dateformatJPN, dateformatYYYYMMDD, parseDate, toNull} from "../CMUtil";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction"
import {useHistory} from "react-router-dom";
import {getAPI, postAPI} from "../APIUtil";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusCircle} from "@fortawesome/free-solid-svg-icons";

export const Cal = ({match: {params: {p_month}}}) => {

  const history = useHistory();
  const iniMonth = useRef(true);
  const iniDisp = useRef(true);

  const [month, setMonth] = useState();
  const [count, setCount] = useState(0);

  const [modalShow, setModalShow] = useState(false);
  const [modalOpnDate, setModalOpnDate] = useState(null);
  const [modalDispOpnDate, setDispModalOpnDate] = useState("");
  const [modalMemo, setModalMemo] = useState("");

  // const [modalInvShow, setModalInvShow] = useState(false);
  // const [modalInvDispOpnDate, setModalInvDispOpnDate] = useState("");
  // const [modalInvConstList, setModalInvConstList] = useState([]);

  const [scheduleList, setScheduleList] = useState([]);

  const [dispSch1, setDispSch1] = useState(true);
  const [dispSch2, setDispSch2] = useState(true);
  const [dispCutOff, setDispCutOff] = useState(false);
  const [dispMemo, setDispMemo] = useState(false);

  //INITIALIZE
  useEffect(() => {
    const update = async () => {

      if (p_month) {
        setMonth(parseDate(p_month));
      } else {
        setMonth(dateformatYYYYMMDD(new Date()));
      }
    }
    update();

  }, []);

  useEffect(() => {
    if (iniMonth.current) {
      iniMonth.current = false;
      return;
    }

    const update = async () => {

      let opnDateFrom = new Date();
      opnDateFrom.setFullYear(month.getFullYear());
      opnDateFrom.setMonth(month.getMonth());
      opnDateFrom.setDate(1);

      let opnDateTo = new Date();
      opnDateTo.setFullYear(month.getFullYear());
      opnDateTo.setMonth(month.getMonth() + 1);
      opnDateTo.setDate(0);

      let res = null;
      let searchCondition = null;
      let locScheduleList = [];
      let locInvMap = {};

      searchCondition = {
        opn_date_from: dateformatDB(opnDateFrom),
        opn_date_to: dateformatDB(opnDateTo),
        sort_field: 'opn_date'
      }
      // res = await getAPI("tr_schedule_search_view", searchCondition);
      res = await getAPI("tr_cal_search_for_calendar_view", searchCondition);

      res.results.map((result) => {
        locScheduleList.push({
          title: result.book_name,
          start: result.opn_date,
          type: "schedule",
          classNames:
            (result.opn_id === 'OPN002' || result.opn_id === 'OPN003') ?
              "cal-event-schedule-1" + (dispSch1 ? ""  : " d-none"):
              "cal-event-schedule-2" + (dispSch2 ? ""  : " d-none")
          ,
          order: (result.opn_id === 'OPN002' || result.opn_id === 'OPN003') ? 10 : 15,
          eventType: "event",
        });
      });

      //メモ追加
      res = await getAPI("tr_cal_memo_search_view", searchCondition);
      res.results.map((result) => {
        locScheduleList.push({
          title: result.memo,
          start: result.opn_date,
          type: "memo",
          classNames: "cal-event-memo" + (dispMemo ? ""  : " d-none"),
          memo: result.memo,
          order: 20,
          eventType: "memo",
        });
      });

      //請求書送付期日追加
      let invCutOffList = {};
      let monthEnd = new Date();
      monthEnd.setFullYear(month.getFullYear());
      monthEnd.setMonth(month.getMonth() + 1);
      monthEnd.setDate(0);
      res = await getAPI("ms_const_cut_off_search_for_calendar_view", searchCondition);
      res.results.map((result) => {

        let start = new Date();
        start.setFullYear(month.getFullYear());
        start.setMonth(month.getMonth());
        if(result.inv_cutoff_date === -1){
          start.setMonth(start.getMonth() + 1);
          start.setDate(0);
        }else{
          start.setDate(result.inv_cutoff_date);
        }

        locScheduleList.push({
          title: result.name,
          start: dateformatDB(start),
          type: "cut-off",
          classNames: "cut-off" + (dispCutOff ? ""  : " d-none"),
          memo: result.memo,
          order: 30,
          eventType: "cut-off"
        });
      });

      setScheduleList(locScheduleList);
    }
    update();

  }, [month, count]);

  //イベントの表示／非表示切り替え
  useEffect(() => {

    if (iniDisp.current) {
      iniDisp.current = false;
      return;
    }

    const update = async () => {

      let list = null;

      list = $(".cal-event-schedule-1");
      for (let i = 0; i < list.length; i++) {
        if(dispSch1){
          $(list[i]).removeClass("d-none")
        }else{
          $(list[i]).addClass("d-none")
        }
      }

      list = $(".cal-event-schedule-2");
      for (let i = 0; i < list.length; i++) {
        if(dispSch2){
          $(list[i]).removeClass("d-none")
        }else{
          $(list[i]).addClass("d-none")
        }
      }

      list = $(".cut-off");
      for (let i = 0; i < list.length; i++) {
        if(dispCutOff){
          $(list[i]).removeClass("d-none")
        }else{
          $(list[i]).addClass("d-none")
        }
      }

      list = $(".cal-event-memo");
      for (let i = 0; i < list.length; i++) {
        if(dispMemo){
          $(list[i]).removeClass("d-none")
        }else{
          $(list[i]).addClass("d-none")
        }
      }
    }
    update();

  }, [dispSch1, dispSch2, dispCutOff, dispMemo]);

  const onClickUpdate = (dateInfo) => {
    let date = dateInfo.start;
    date.setDate(date.getDate() + 15);
    setMonth(date);
  }

  // const onClickPrevMonth = () => {
  //   let locScheduleList = [
  //     {title: 'event 3', start: '2021-11-03', schedule_id: 'S001'},
  //     {title: 'event 4', start: '2021-11-04', schedule_id: 'S001'}
  //   ];
  //   setScheduleList(locScheduleList);
  // }
  //
  // const onClickNextMonth = () => {
  //   let locYear = month.substring(0, 4);
  //   let locMonth = month.substring(4, 6);
  //   let locDate = new Date();
  //   locDate.setFullYear(locYear);
  //   locDate.setMonth(parseInt(locMonth) - 1);
  //   locDate.setMonth(locDate.getMonth() + 1);
  //   setMonth(dateformatYYYYMMDD(locDate).substr(0, 6));
  // }

  const handleDateClick = (arg) => {

    history.push("/schedule/" + dateformatYYYYMMDD(arg.date));

    // let date = parseDate(arg.dateStr);
    // setModalOpnDate(date);
    // setDispModalOpnDate(dateformatJPN(date));
    // setModalMemo("");
    // onClickModalShow();
  }

  const handleEventClick = (eventClickInfo) => { // bind with an arrow function

    // if (eventClickInfo.event.extendedProps.type === "memo") {
      //メモ選択
    //   setModalOpnDate(eventClickInfo.event.start);
    //   setDispModalOpnDate(dateformatJPN(eventClickInfo.event.start));
    //   setModalMemo(eventClickInfo.event.extendedProps.memo);
    //   onClickModalShow();
    //
    // } else if (eventClickInfo.event.extendedProps.type === "cut-off") {
      // //請求書締め切り選択
      // setModalInvDispOpnDate(dateformatJPN(eventClickInfo.event.start));
      // setModalInvConstList(eventClickInfo.event.extendedProps.constList);
      // onClickModalInvShow();
    //
    // } else {
      //スケジュール選択
      history.push("/schedule/" + dateformatYYYYMMDD(eventClickInfo.event.start));
    // }
  }

  const onClickModalClose = () => setModalShow(false);

  const onClickModalShow = () => setModalShow(true);

  const onClickModalSave = async () => {

    let obj = newData();
    obj = await postAPI("tr_cal_memo/", obj);
    success("登録完了");
    setCount(count + 1);
    setModalShow(false);

  }

  // const onClickModalInvClose = () => setModalInvShow(false);
  //
  // const onClickModalInvShow = () => setModalInvShow(true);

  const newData = () => {
    return {
      cal_memo_id: null,
      cal_id: null,
      opn_date: toNull(dateformatDB(modalOpnDate)),
      no: 0,
      title: null,
      memo: toNull(modalMemo),
      create_pg: 'Cal',
      update_pg: 'Cal'
    };
  }

  function renderEventContent(eventInfo) {

    if (eventInfo.event.extendedProps.eventType === "add-event") {
      return (
        <div style={{textAlign: "right", color: "#007bff"}}>
          <FontAwesomeIcon icon={faPlusCircle} size="lg" style={{marginBottom: "0.1rem"}}/>
          追加
        </div>
      )
    } else {
      return (
        <>
          <b>{eventInfo.timeText}</b>
          <i>{eventInfo.event.title}</i>
        </>
      )
    }
  }

  return (
    <>
      <div className="body-dtl body-dtl-const">
        <AppNav/>
        <div className="h1">
          <h1 className="float-left align-items-end">
            作業予定管理
          </h1>
          <div className="float-none" style={{height: "30px"}}>&nbsp;</div>
        </div>

        <Container className="container-main-detail">
          <Row>
            <Col className="text-right m-3">
              <Form.Check
                inline
                id="invCutoffDate1"
                type="checkbox"
                className="ml-3"
                label="作業"
                onChange={e => { setDispSch1(!dispSch1)}}
                defaultChecked={dispSch1}/>
              <Form.Check
                inline
                id="invCutoffDate1"
                type="checkbox"
                className="ml-3"
                label="２次処理"
                value={dispSch2}
                onChange={e => { setDispSch2(!dispSch2)}}
                defaultChecked={dispSch2}/>
              <Form.Check
                inline
                id="invCutoffDate1"
                type="checkbox"
                className="ml-3"
                label="請求"
                value={dispCutOff}
                onChange={e => { setDispCutOff(!dispCutOff)}}
                defaultChecked={dispCutOff}/>
              {/*<Form.Check*/}
              {/*  inline*/}
              {/*  id="invCutoffDate1"*/}
              {/*  type="checkbox"*/}
              {/*  className="ml-3"*/}
              {/*  label="メモ"*/}
              {/*  value={dispMemo}*/}
              {/*  onChange={e => { setDispMemo(!dispMemo)}}*/}
              {/*  defaultChecked={dispMemo}/>*/}
            </Col>
          </Row>

          <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin]}
            locale="ja"
            initialView="dayGridMonth"
            datesSet={onClickUpdate}
            headerToolbar={
              {
                start: 'prev',
                center: 'title',
                end: 'next'
              }
            }
            events={scheduleList}
            dateClick={handleDateClick}
            eventContent={renderEventContent}
            eventClick={handleEventClick}
            eventOrder="order"
          />

        </Container>
      </div>

      <Modal show={modalShow} onHide={onClickModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>{modalDispOpnDate}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control as="textarea" rows={3} value={modalMemo} onChange={e => setModalMemo(e.target.value)}/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClickModalClose}>
            キャンセル
          </Button>
          <Button variant="primary" onClick={onClickModalSave}>
            登録
          </Button>
        </Modal.Footer>
      </Modal>

      {/*<Modal show={modalInvShow} onHide={onClickModalInvClose}>*/}
      {/*  <Modal.Header closeButton>*/}
      {/*    <Modal.Title>請求書 {modalInvDispOpnDate}締め切り</Modal.Title>*/}
      {/*  </Modal.Header>*/}
      {/*  <Modal.Body>*/}
      {/*    {modalInvConstList.map((cons, index) => {*/}
      {/*      return <div key={index} style={{fontWeight: "700"}}>{cons}</div>*/}
      {/*    })}*/}
      {/*  </Modal.Body>*/}
      {/*  <Modal.Footer>*/}
      {/*    <Button variant="secondary" onClick={onClickModalInvClose}>*/}
      {/*      キャンセル*/}
      {/*    </Button>*/}
      {/*  </Modal.Footer>*/}
      {/*</Modal>*/}

      <Toast/>
    </>
  );
}

export default withRouter(Cal);