import React, {useEffect, useRef, useState} from 'react';
import {Link, useHistory, withRouter} from "react-router-dom";
import {Card, Col, Container, Form, Row} from "react-bootstrap";
import {deleteAPI, getAPI, patchAPI, postAPI, putAPI} from "../APIUtil";
import Button from "react-bootstrap/Button";
import AppNav from "./AppNav";
import 'react-toastify/dist/ReactToastify.css';
import Toast, {success} from "../Toast";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt, faPen, faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker"
import {
  setFocusCalendar,
  toSingleByte,
  toNull,
  nvl,
  dateformatDB,
  parseDate,
  addField,
  formatNumber,
  parseNumber, dateformatJPNYYYYMMDD
} from "../CMUtil";
import {RcvDtlRow} from "./RcvDtlRow";

export const RcvDtl = ({match: {params: {rcv_id, po_id}}}) => {

  const hist = useHistory();


  const [rcvId, setRcvId] = useState("");
  const [suppId, setSuppId] = useState("");
  const [suppName, setSuppName] = useState("");
  const [rcvNo, setRcvNo] = useState("");
  const [rcvDate, setRcvDate] = useState("");
  const [poId, setPoId] = useState("");
  const [poNo, setPoNo] = useState("");
  const [poTitle, setPoTitle] = useState("");
  const [poDate, setPoDate] = useState("");
  const [subAmount, setSubAmount] = useState("");
  const [tax, setTax] = useState("");
  const [ttlAmount, setTtlAmount] = useState("");
  const [updateCnt, setUpdateCnt] = useState(0);

  const [dtlList, setDtlList] = useState([]);
  const dtlListRef = useRef([]);


  //初期化
  useEffect(() => {

    const update = async () => {

      let locDtlList = [];
      if (po_id) {
        //INSERT
        let obj = await getAPI("tr_po/" + po_id);

        setRcvDate(nvl(parseDate(new Date())));
        setPoId(nvl(obj.po_id));
        setPoNo(nvl(obj.po_no));
        setPoTitle(nvl(obj.po_title));
        setPoDate(nvl(parseDate(obj.po_date)));
        if (obj.supp) {
          setSuppId(nvl(obj.supp.supp_id));
          setSuppName(nvl(obj.supp.name));
        }
        setSubAmount(formatNumber(obj.sub_amount));
        setTax(formatNumber(obj.tax));
        setTtlAmount(formatNumber(obj.ttl_amount));

        let condition = {};
        addField(condition, "po_id", obj.po_id);
        const res = await getAPI("tr_po_dtl_search_view", condition);

        for (let i = 0; i < res.results.length; i++) {

          let po_qty = res.results.length ? res.results[i].qty : 0
          po_qty = parseInt(po_qty);

          let rcv_qty = 0;
          let condition = {};
          addField(condition, "po_dtl_id", res.results[i].po_dtl_id);
          const rcvDtlRes = await getAPI("tr_rcv_dtl_search_view", condition);
          for (let rcvDtl of rcvDtlRes.results) {
            rcv_qty += parseInt(rcvDtl.qty ? rcvDtl.qty : 0);
          }

          let remain_qty = po_qty - rcv_qty;

          locDtlList.push({
            po_dtl_id: i < res.results.length ? res.results[i].po_dtl_id : null,
            index: i,
            line_no: i + 1,
            item_type: null,
            item_id: res.results[i].item.item_id,
            item_name: res.results[i].item.item_name,
            unit: res.results[i].unit,
            po_qty: po_qty,
            rcv_qty: rcv_qty,
            qty: "",
            remain_qty: remain_qty
          });
        }

      } else {
        //UPDATE
        let obj = await getAPI("tr_rcv/" + rcv_id);
        setRcvId(rcv_id);
        setRcvNo(nvl(obj.rcv_no));
        setRcvDate(nvl(parseDate(obj.rcv_date)));
        setPoNo(nvl(obj.po.po_id));
        setPoNo(nvl(obj.po.po_no));
        setPoTitle(nvl(obj.po.po_title));
        setPoDate(nvl(parseDate(obj.po.po_date)));
        if (obj.supp) {
          setSuppId(nvl(obj.supp.supp_id));
          setSuppName(nvl(obj.supp.name));
        }
        setSubAmount(formatNumber(obj.sub_amount));
        setTax(formatNumber(obj.tax));
        setTtlAmount(formatNumber(obj.ttl_amount));

        let condition = {};
        addField(condition, "rcv_id", rcv_id);
        const res = await getAPI("tr_rcv_dtl_search_view", condition);

        for (let i = 0; i < res.results.length; i++) {
          console.log(res.results[i]);
          locDtlList.push({
            po_dtl_id: res.results[i].po_dtl_id,
            index: i,
            line_no: i + 1,
            item_type: null,
            item_id: res.results[i].item_id,
            item_name: res.results[i].item_name,
            unit: res.results[i].po_dtl.unit,
            price: res.results[i].price,
            po_qty: res.results[i].po_qty,
            rcv_qty: res.results[i].rcv_qty,
            qty: res.results[i].qty,
            remain_qty: res.results[i].remain_qty
          });
        }
      }

      for (let i = 0; i < locDtlList.length; i++) {
        dtlListRef.current[i] = React.createRef();
      }

      setDtlList(locDtlList);
    }
    update();

  }, []);

  const onClickInsert = async () => {
    let obj = newData();
    obj = await postAPI("tr_rcv/", obj);

    let id = obj.rcv_id;
    for (const dtl of dtlListRef.current) {
      let dtlObj = dtl.current.getStates();
      dtlObj = newDtlData(id, dtlObj);
      dtlObj = await postAPI("tr_rcv_dtl/", dtlObj);

      //在庫更新
      let condition = null;
      condition = {};
      addField(condition, "item_id", dtlObj.item.item_id);
      const stkRes = await getAPI("ms_stk_search_view", condition);

      let locStk = stkRes.results[0];
      condition = {};
      addField(condition, "stk_id", locStk.stk_id);
      addField(condition, "add_qty", dtlObj.qty);
      addField(condition, "update_pg", "RcvDtl");
      locStk = await patchAPI("ms_stk/" + locStk.item_id + "/", condition)

      //INOUT作成
      let inOut = {
        stk_id: locStk.stk_id,
        item_id: locStk.item.item_id,
        in_out_qty: dtlObj.qty,
        load_date: dateformatDB(new Date()),
        load_type: "入庫",
        stk_qty: locStk.qty,
        cause_type: "購入",
        remarks: dtlObj.po.po_no,
        create_pg: "RcvDtl",
        update_pg: "RcvDtl",
      }
      await postAPI("tr_in_out/", inOut);

      dtl.current.setData({
        read_only: true
      });
    }

    setRcvId(obj.rcv_id);
    hist.push("/rcv/" + obj.rcv_id);
    success("登録完了");
  }

  const newData = () => {
    return {
      rcv_id: null,
      rcv_date: toNull(dateformatDB(rcvDate)),
      supp_id: suppId,
      po_id: poId,
      rcv_no: rcvNo,
      create_pg: 'RcvDtl',
      update_pg: 'RcvDtl',
    };
  }

  const newDtlData = (rcvId, data) => {
    return {
      rcv_dtl_id: null,
      rcv_id: toNull(rcvId),
      po_id: poId,
      po_dtl_id: toNull(data.po_dtl_id),
      line_no: toNull(data.line_no),
      item_id: toNull(data.item_id),
      item_name: toNull(data.item_name),
      po_qty: toNull(parseNumber(data.po_qty)),
      rcv_qty: toNull(parseNumber(data.rcv_qty)),
      qty: toNull(parseNumber(data.qty)),
      remain_qty: toNull(parseNumber(data.remain_qty)),
      create_pg: 'RcvDtl',
      update_pg: 'RcvDtl',
    };
  }

  return (
    <>
      <div className="body-dtl body-dtl-const">
        <AppNav/>
        <div className="h1">
          <h1 className="float-left align-items-end">
            仕入詳細
          </h1>
          <div className="float-none" style={{height: "30px"}}>&nbsp;</div>
        </div>

        <Container className="container-main-detail">

          <Row className="h3">
            <Col>
            </Col>
            <Col sm="1" className="text-right">
            </Col>
            <Col sm="1" className="text-right">
            </Col>
            <Col sm="1" className="text-right">
              {!rcvId &&
                <Button variant="primary" onClick={onClickInsert}><FontAwesomeIcon icon={faPen} size="lg"/>登録</Button>
              }
            </Col>
          </Row>

          <Card className="card-secondary w-80 mx-auto mb-0">
            <Form.Group as={Row}>
              <Form.Label column sm="1">仕入れ業者</Form.Label>
              <Col sm="4">
                <Form.Control
                  id="supp_name"
                  type="text"
                  value={suppName}
                  readOnly={true}
                />
              </Col>
              <Col sm="1"></Col>
              <Form.Label column sm="1">仕入日</Form.Label>
              <Col sm="4" className="input-group">
                <DatePicker
                  id="rcvDate"
                  selected={rcvDate}
                  onChange={(date) => setRcvDate(date)}
                  dateFormat="yyyy年MM月dd日"
                  readOnly={rcvId}
                  locale="ja"/>
                <Button
                  as={Link}
                  variant="link"
                  to={"/"}
                  style={{paddingRight: "0"}}
                  onClick={e => setFocusCalendar(e, 'rcvDate')}>
                  <FontAwesomeIcon icon={faCalendarAlt} size="2x"/>
                </Button>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="1">発注日</Form.Label>
              <Col sm="4">
                <Form.Control
                  id="po_title"
                  type="text"
                  value={dateformatJPNYYYYMMDD(poDate)}
                  readOnly={true}
                />
              </Col>
              <Col sm="1"></Col>
              <Form.Label column sm="1">仕入番号</Form.Label>
              <Col sm="4">
                <Form.Control
                  id="rcvNo"
                  type="text"
                  value={rcvNo}
                  maxLength={20}
                  readOnly={rcvId}
                  onChange={e => setRcvNo(e.target.value)}
                  onBlur={e => setRcvNo(toSingleByte(e.target.value))}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="1">発注番号</Form.Label>
              <Col sm="4">
                <Form.Control
                  id="po_no"
                  type="text"
                  value={poNo}
                  readOnly={true}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="1">発注件名</Form.Label>
              <Col sm="4">
                <Form.Control
                  id="po_title"
                  type="text"
                  value={poTitle}
                  readOnly={true}
                />
              </Col>
            </Form.Group>

            <Container className="container-main-list">
              <Row className="dtl_list_header">
                <Col sm="5" className="dtl-head-col">詳細</Col>
                <Col sm="1" className="dtl-head-col">単位</Col>
                <Col sm="1" className="dtl-head-col">発注数</Col>
                <Col sm="1" className="dtl-head-col">入荷済数</Col>
                <Col sm="1" className="dtl-head-col">入荷数</Col>
                <Col sm="1" className="dtl-head-col">発注残数</Col>
              </Row>
              {dtlList.map((dtl, index) => (
                <RcvDtlRow key={index}
                           ref={dtlListRef.current[index]}
                           readOnly={rcvId}
                           index={index}
                           dtl={dtl}
                           isLastRow={dtlList.length === index + 1}
                />
              ))}
            </Container>
          </Card>
        </Container>

      </div>
      <Toast/>
    </>
  );
}

export default withRouter(RcvDtl);