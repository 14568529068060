import React, {Fragment, useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import {getAPI} from "../APIUtil";
import { Document, Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import {nvl, formatNumber, addField, parseDate, dateformatJPNYYYYMMDD} from "../CMUtil";

Font.register({
  family: "jpFont",
  fonts: [
    {src: '/static/font/NotoSansJP-Regular.otf'},
    {src: '/static/font/NotoSansJP-Bold.otf'},
  ]
});

// Create styles
const styles = StyleSheet.create({
  tableRow: {
    margin: "auto",
    flexDirection: "row"
  },
  tableDtlCol1: {
    width: "55%",
    borderStyle: "solid",
    borderBottomWidth: 1,
    borderTopWidth: 0,
  },
  tableDtlCol2: {
    width: "10%",
    borderStyle: "solid",
    borderBottomWidth: 1,
    borderLeftWidth: 0
  },
  tableDtlCol3: {
    width: "5%",
    borderStyle: "solid",
    borderBottomWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableDtlCol4: {
    width: "15%",
    borderStyle: "solid",
    borderBottomWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCell: {
    margin: "auto",
    marginTop: 5
  },
  textR: {
    paddingTop: 5,
    paddingRight: 2,
    textAlign: "right"
  },
  textL: {
    paddingTop: 5,
    paddingLeft: 2,
    textAlign: "left"
  },
  headerBGR: {
      backgroundColor: "black",
      color: "white",
      textAlign: "right"
  }
});

const PoDtlPDF = ({po_id, updateCnt}) => {

    const [quotDtls, setQuotDtls] = useState([]);

    //初期化
    useEffect(() => {
        const load = async () => {
            let condition = {};
            addField(condition, "po_id", po_id);
            const quotDtlObj = await getAPI("tr_po_dtl_search_view", condition);
            //load unit options
            const unitRes = await getAPI("ms_code_search_view", {categ_id: "ITEM_UNIT", sort_field: "code_id"});
            let unitOptions = [];
            unitRes.results.map((result) => {
                unitOptions.push({name: result.code_name, id: result.code_id});
            });
            const newObj = quotDtlObj.results.map( (obj) => {
                const unitIndex = unitOptions.findIndex((u => u.id == obj.unit));
                if (unitIndex >= 0) {
                    obj.unit = unitOptions[unitIndex].name;
                }
                obj.qty = formatNumber(obj.qty);
                obj.price = formatNumber(obj.price);
                obj.amount = formatNumber(obj.amount);
                if(!obj.item){
                  const newItemObj = {item_name: ""}
                  obj.item = newItemObj;
                }
                return obj;
            });

            setQuotDtls(newObj);
        }
        load();
    }, [updateCnt]);


    const rows = quotDtls.map( obj =>
        <View key={obj.line_no} style={styles.tableRow}>
            <View style={styles.tableDtlCol1}>
                <Text style={styles.tableCell, styles.textL}>{obj.item_name}</Text>
            </View>
            <View style={styles.tableDtlCol2}>
                <Text style={styles.tableCell, styles.textR}>{obj.qty}</Text>
            </View>
            <View style={styles.tableDtlCol3}>
                <Text style={styles.tableCell}>{obj.unit}</Text>
            </View>
            <View style={styles.tableDtlCol4}>
                <Text style={styles.tableCell, styles.textR}>{obj.price}</Text>
            </View>
            <View style={styles.tableDtlCol4}>
                <Text style={styles.tableCell, styles.textR}>{obj.amount}</Text>
            </View>
        </View>
    );

    return (<Fragment>{rows}</Fragment> )
};

export default PoDtlPDF;